import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { HttpMethod } from "src/app/constants/http-handlers";
import { AppService } from "src/app/services/app.service";
import { CommonService } from "src/app/services/common.service";

@Component({
  selector: "app-distributor-info",
  templateUrl: "./distributor-info.component.html",
  styleUrls: ["./distributor-info.component.scss"],
})
export class DistributorInfoComponent implements OnInit {
  @Output() sendAPMName: EventEmitter<string> = new EventEmitter<string>();
  showDropdown: boolean = false;
  selected: string = "monthly";
  selectedItem: string = "This Month";
  recId: number;
  recDetails: any = {};
  rating: string[] = ["zero", "one", "two", "three", "four", "five"];

  constructor(
    public dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    public commonService: CommonService,
    private appService: AppService,
    private router: Router
  ) {
    this.recId = this.activatedRoute.snapshot.params.id;
  }
  select(index) {
    this.selected = this.commonService.viewTypeOptions[index].paramName;
    this.selectedItem = this.commonService.viewTypeOptions[index].title;
    this.showDropdown = false;
    let apiUrl =
      this.appService.distributorDetails +
      this.recId +
      "/" +
      "?view_type=" +
      this.selected;
    const url = `${apiUrl}`;
    const apiObj = {
      url: url,
      methodType: HttpMethod.GET,
    };
    this.commonService.commonApiCall(apiObj, (res) => {
      if (res != null) {
        this.recDetails = res;
        this.recDetails.rating_class =
          "rating " + this.rating[this.recDetails.ratings] + "-star";
        this.sendAPMName.emit(this.recDetails.apm_username);
      }
    });
  }
  selectValfun() {
    this.showDropdown = !this.showDropdown;
  }
  isActive(item: string) {
    return this.selected === item;
  }
  ngOnInit(): void {
    let apiUrl =
      this.appService.distributorDetails +
      this.recId +
      "/" +
      "?view_type=monthly";
    const url = `${apiUrl}`;
    const apiObj = {
      url: url,
      methodType: HttpMethod.GET,
    };
    this.commonService.commonApiCall(apiObj, (res) => {
      if (res != null) {
        this.recDetails = res;
        this.recDetails.rating_class =
          "rating " + this.rating[this.recDetails.ratings] + "-star";
        this.sendAPMName.emit(this.recDetails.apm_username);
      }
    });
  }
  // openDialog() {
  //   let dialogRef = this.dialog.open(ConfirmDialogComponent, {
  //     width: "607px",
  //     height: "398px",
  //     disableClose: true,
  //   });
  //   dialogRef.componentInstance.recId = this.recId;
  // }
  backToDistlist() {
    this.router.navigate(["distributor-list"]);
  }
}
