<div class="container-dialog">
  <ng-container *ngIf="isFormShow">
    <h2>Are you sure, you want to delete<span>the MSR Profile?</span></h2>
    <form [formGroup]="frmDelete" autocomplete="off">
      <div class="wrap-reason">
        <span>Type the reason</span><span class="requiredfield">*</span>
      </div>
      <textarea
        class="form-control reason-text"
        formControlName="reason"
        id="reason-text"
      ></textarea>
      <ng-container *ngIf="!frmDelete.valid && frmDelete.controls['reason'].touched">
        <span class="error-message" *ngIf="frmDelete.controls['reason'].errors.required">Reason is required.</span>
        <span class="error-message" *ngIf="frmDelete.controls['reason'].errors.pattern"> Special characters are not allowed</span>
        <span class="error-message" *ngIf="!frmDelete.controls['reason'].errors.required && frmDelete.controls['reason'].errors.whitespace">
          Please enter valid Reason
        </span>
      </ng-container>
      <div class="btn-group dialogBtn-group">
        <button
          mat-stroked-button
          class="primary-stroke-button"
          (click)="closeModal()"
        >
          Cancel
        </button>
        <button
          mat-flat-button
          class="primary-flat-button"
          (click)="onSubmit()"
          [disabled]="!frmDelete.valid"
        >
          Confirm
        </button>
      </div>
    </form>
  </ng-container>

  <ng-container *ngIf="!isFormShow">
    <div class="content-center">
      <img src="./assets/images/delete-successfully.svg" alt="" />
      <h2>MSR Profile Deleted</h2>
    </div>
  </ng-container>
</div>
