import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable({
  providedIn: 'root'
})
export class ExcelService {

  constructor( private datepipe: DatePipe ) { }
  public exportCancellationAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    var wscols = [
    {wch:30},
    {wch:30},
    {wch:50},
    {wch:30},
    {wch:30},
    {wch:30},
    {wch:50},
    {wch:50},
    {wch:20},
    {wch:20},
    {wch:30},
    {wch:50},
    {wch:50},
    {wch:50},
    {wch:50},
    {wch:50},
    {wch:50},
    {wch:50},
    {wch:50},
    {wch:50},
    {wch:50},
    {wch:50},
    {wch:50},
    {wch:50},
    {wch:50},
    {wch:50},
    {wch:50},
    {wch:50},
    {wch:50},
    {wch:50},
    
    ];
    worksheet['!cols'] = wscols;
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }
  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {type: EXCEL_TYPE});
    var date = new  Date()
    var formatedDate= this.datepipe.transform(date, 'dd-MM-yyyy hh:mm a')
    FileSaver.saveAs(data, fileName + '_' + formatedDate + EXCEL_EXTENSION)
  }
}
