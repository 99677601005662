import { Component, OnInit, ViewChild } from "@angular/core";
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { MatSelect } from "@angular/material/select";
import { ReplaySubject, Subject } from "rxjs";
import { HttpMethod } from "src/app/constants/http-handlers";
import { AppService } from "src/app/services/app.service";
import { CommonService } from "src/app/services/common.service";
import { take, takeUntil } from "rxjs/operators";
import { MatDialog } from "@angular/material/dialog";
import { NgxSpinnerService } from "ngx-spinner";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTable, MatTableDataSource } from "@angular/material/table";

export interface PeriodicElement {
  desc: string;
  title: string;
  roles: string[];
  cat: string;
  action: any;
}

const ELEMENT_DATA: PeriodicElement[] = [
  {
    title: "test",
    desc: "Hydrogen",
    cat: "test",
    roles: ["admin", "msr"],
    action: "H",
  },
];
@Component({
  selector: "app-add-training",
  templateUrl: "./add-training.component.html",
  styleUrls: ["./add-training.component.scss"],
})
export class AddTrainingComponent implements OnInit {
  selected = null;
  addTrainingForm: FormGroup;
  categories: any;
  hashTagValue: any[] = [];
  roles: any[] = [];
  formData: FormData = new FormData();
  submittedTrainingForm: boolean = false;
  showfinalSuccessMsg: string;
  showfinalErrorMsg: string;
  showToastermessage: boolean = false;
  trainingPictureFile: any = "";
  trainingVideoFile: any = "";
  notificationAlert: boolean = false;
  deleteVideoModal: boolean = false;
  deletevideoId: number;
  displayedColumns: string[] = [
    "title",
    "short_desc",
    "long_desc",
    "upload_date",
    "action",
  ];
  //dataSource = ELEMENT_DATA;

  showNodata: boolean = false;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatTable) table!: MatTable<any>;
  dataSource = new MatTableDataSource<any>();

  isLoading = false;
  totalRows = 0;
  pageSize = 10;
  currentPage = 0;
  pgIndex: number = 0;

  public websiteMultiCtrl: FormControl = new FormControl(
    "",
    Validators.required
  );
  public websiteMultiFilterCtrl: FormControl = new FormControl();
  public filteredWebsitesMulti: any = new ReplaySubject(1);

  @ViewChild("multiSelect", { static: true }) multiSelect: MatSelect;

  protected _onDestroy = new Subject();

  constructor(
    private _formBuilder: FormBuilder,
    private appService: AppService,
    private commonService: CommonService,
    public dialog: MatDialog,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.addTrainingForm = this._formBuilder.group({
      trainingTitle: [
        "",
        [
          Validators.required,
          Validators.pattern(/^[a-zA-Z ]*$/),
          Validators.maxLength(50),
          this.noWhitespaceValidator,
        ],
      ],
      shortDesc: [
        "",
        [
          Validators.required,
          Validators.pattern(/^[a-zA-Z ]*$/),
          this.noWhitespaceValidator,
        ],
      ],
      longDesc: ["", [Validators.required, this.noWhitespaceValidator]],
      selectCategory: ["", Validators.required],
      hashtag: ["", Validators.pattern(/^[a-zA-Z]*$/)],
      userRole: this.websiteMultiCtrl.value,
      uploadthumbnailFile: [this.trainingPictureFile, Validators.required],
      uploadVideoFile: ["", Validators.required],
    });
    this.showTrainningCatItem();
    this.getUserRoles();
    this.trainingList();
  }
  getToolTipData(data) {
    if (data && data.length) {
      let msg = "";
      data.forEach((res) => {
        msg += res + ", ";
      });
      msg = msg.substring(0, msg.length - 2);
      return msg;
    } else {
      return "";
    }
  }
  addHashtagFun() {
    if (
      this.addTrainingForm.get("hashtag").valid &&
      this.addTrainingForm.get("hashtag").value
    ) {
      const controls = this.addTrainingForm.get("hashtag").value;
      this.hashTagValue.push(controls);
      for (var i = 0; i < this.hashTagValue.length; i++) {
        this.formData.append("tag", this.hashTagValue[i]);
      }
    }

    this.addTrainingForm.get("hashtag").reset();
  }
  showTrainningCatItem() {
    const url = `${this.appService.trainingVideoCategoryItems}`;
    const apiObj = {
      url: url,
      methodType: HttpMethod.GET,
    };
    this.commonService.commonApiCall(apiObj, (res) => {
      this.categories = res;
    });
  }

  getUserRoles() {
    const url = `${this.appService.userRoles}`;
    const apiObj = {
      url: url,
      methodType: HttpMethod.GET,
    };
    this.commonService.commonApiCall(apiObj, (res) => {
      for (var i = 0; i < res.length; i++) {
        this.roles.push(res[i].value);
      }
      this.filteredWebsitesMulti.next(this.roles.slice());
    });
    // this.filteredWebsitesMulti.next(this.roles.slice());
    this.websiteMultiFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterWebsiteMulti();
      });
  }

  addTrainingFormData() {
    //debugger;
    this.submittedTrainingForm = true;

    if (this.addTrainingForm.valid && this.websiteMultiCtrl.valid ) {
      this.notificationAlert = true;
    }
  }

  submitWithNotification() {
    this.formData.append(
      "title",
      this.addTrainingForm.get("trainingTitle").value
    );
    this.formData.append(
      "short_desc",
      this.addTrainingForm.get("shortDesc").value
    );

    this.formData.append(
      "long_desc",
      this.addTrainingForm.get("longDesc").value
    );

    this.formData.append(
      "category",
      this.addTrainingForm.get("selectCategory").value
    );

    //this.formData.append("notify", this.websiteMultiCtrl.value);

    for (var i = 0; i < this.websiteMultiCtrl.value.length; i++) {
      this.formData.append("notify", this.websiteMultiCtrl.value[i]);
    }
    let apiUrl = this.appService.addTrainingVideo;
    this.notificationAlert = false;
    const apiObj = {
      url: apiUrl,
      methodType: HttpMethod.POST,
      requestObj: this.formData,
    };
    this.spinner.show();
    this.commonService.commonApiCall(
      apiObj,
      (res: any, error) => {
        if (error != undefined) {
          this.showToastermessage = true;
          this.showfinalErrorMsg = error.error.msg;
          this.spinner.hide();
          setTimeout(() => {
            this.showfinalErrorMsg = "";
            this.showToastermessage = false;
          }, 3000);
        } else {
          this.showfinalSuccessMsg = "Training Video Uploaded Successfully !!!";
          this.showToastermessage = true;
          this.spinner.hide();
          this.trainingList();
          setTimeout(() => {
            this.showfinalSuccessMsg = "";
            this.showToastermessage = false;
          }, 3000);
        }
        this.formData.delete("title");
        this.formData.delete("short_desc");
        this.formData.delete("long_desc");
        this.formData.delete("category");
        this.formData.delete("notify");
        this.formData.delete("tag");
        this.addTrainingForm.reset();
        this.submittedTrainingForm = false;
        this.roles = [];
        this.trainingVideoFile = "";
        this.trainingPictureFile = "";
        this.hashTagValue = [];
        this.websiteMultiCtrl.reset();
        this.addTrainingForm.controls["selectCategory"].setValue("");
      },
      "multipart/form-data"
    );
  }
  cancelNotification() {
    this.notificationAlert = false;
  }

  uploadFile(event: Event) {
    //debugger;
    const target = event.target as HTMLInputElement;
    const files = target.files as FileList;
    if (files[0].type == "video/mp4") {
      if (files[0].size < 52428800) {
        this.formData.append("video", files[0]);
        this.trainingVideoFile = files[0].name;
      } else {
        this.showToastermessage = true;
        this.showfinalErrorMsg = "File size can’t be over 50MB.";
        setTimeout(() => {
          this.showfinalErrorMsg = "";
          this.showToastermessage = false;
        }, 3000);
      }
    } else {
      this.showToastermessage = true;
      this.showfinalErrorMsg = "mp4 video file formats are allowed.";
      setTimeout(() => {
        this.showfinalErrorMsg = "";
        this.showToastermessage = false;
      }, 3000);
    }
  }

  uploadFileDrop(evt) {
    if (evt[0].type == "video/mp4") {
      if (evt[0].size < 52428800) {
        this.formData.append("video", evt[0]);
        this.trainingVideoFile = evt[0].name;
        this.addTrainingForm.get("uploadVideoFile").setValidators(null);
      } else {
        this.showfinalSuccessMsg = "";
        this.showToastermessage = true;
        this.showfinalErrorMsg = "File size can’t be over 50MB.";
        setTimeout(() => {
          this.showToastermessage = false;
        }, 3000);
        this.addTrainingForm
          .get("uploadVideoFile")
          .setValidators([Validators.required]);
      }
    } else {
      this.showfinalSuccessMsg = "";
      this.showToastermessage = true;
      this.showfinalErrorMsg = "only mp4 file formats are allowed.";
      setTimeout(() => {
        this.showToastermessage = false;
      }, 3000);
      this.addTrainingForm
        .get("uploadVideoFile")
        .setValidators([Validators.required]);
    }
    this.addTrainingForm.get("uploadVideoFile").updateValueAndValidity();
  }

  uploadthumbnailFile(event: Event) {
    //debugger;
    const target = event.target as HTMLInputElement;
    const files = target.files as FileList;
    this.formData.delete("thumbnail");

    if (
      files[0].type == "image/png" ||
      files[0].type == "image/jpg" ||
      files[0].type == "image/jpeg"
    ) {
      if (files[0].size < 5242880) {
        this.formData.append("thumbnail", files[0]);
        this.trainingPictureFile = files[0].name;
      } else {
        this.showToastermessage = true;
        this.showfinalErrorMsg = "File size can’t be over 5MB.";
        setTimeout(() => {
          this.showfinalErrorMsg = "";
          this.showToastermessage = false;
        }, 3000);
      }
    } else {
      this.showToastermessage = true;
      this.showfinalErrorMsg = "png, jpeg, jpg file formats are allowed.";
      setTimeout(() => {
        this.showfinalErrorMsg = "";
        this.showToastermessage = false;
      }, 3000);
    }
  }

  protected filterWebsiteMulti() {
    if (!this.roles) {
      return;
    }

    let search = this.websiteMultiFilterCtrl.value;
    if (!search) {
      this.filteredWebsitesMulti.next(this.roles.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredWebsitesMulti.next(
      this.roles.filter((bank) => bank.toLowerCase().indexOf(search) > -1)
    );
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }
  trainingList() {
    let apiUrl = this.appService.trainingVideoItems;
    let queryParamList = [];

    //paging
    let pageParam = "";
    if (this.pgIndex != 0 && this.pgIndex != 1) {
      pageParam = `page=` + this.pgIndex;
      queryParamList.push(pageParam);
    }

    let apiQueryParam = "";
    if (queryParamList.length > 0) {
      apiQueryParam = `?` + queryParamList.join("&");
    }

    const url = `${apiUrl}` + apiQueryParam;
    //console.log(url);
    const apiObj = {
      url: url,
      methodType: HttpMethod.GET,
    };
    this.commonService.commonApiCall(apiObj, (res) => {
      if (
        res &&
        res.hasOwnProperty("results") &&
        res.results &&
        res.results.length
      ) {
        this.dataSource = new MatTableDataSource(res.results);
        this.dataSource.sort = this.sort;
        this.paginator.pageIndex = this.currentPage;
        this.paginator.length = res.count;
        this.isLoading = false;
      }
      if (res.count === 0) {
        this.showNodata = true;
      } else {
        this.showNodata = false;
      }
    });
    //this.isLoading = false;
  }
  pageChanged(event: PageEvent) {
    this.pgIndex = event.pageIndex;
    this.pgIndex = this.pgIndex + 1;
    this.currentPage = event.pageIndex;
    this.isLoading = true;
    this.trainingList();
  }
  deleteVideoItem(videoId) {
    this.deleteVideoModal = true;
    this.deletevideoId = videoId;
  }
  cancelDeleteVideoModal() {
    this.deleteVideoModal = false;
  }
  confirmDeleteVideo() {
    const apiObj = {
      url: this.appService.deleteTrainingVideo + this.deletevideoId,
      methodType: HttpMethod.PUT,
    };
    this.spinner.show();

    this.commonService.commonApiCall(apiObj, (res: any, error) => {
      if (error != undefined) {
        this.showToastermessage = true;
        this.showfinalErrorMsg = error.error.msg;
        this.spinner.hide();
        setTimeout(() => {
          this.showfinalErrorMsg = "";
          this.showToastermessage = false;
        }, 3000);
      } else {
        this.showfinalSuccessMsg = res.message;
        this.showToastermessage = true;
        this.deleteVideoModal = false;
        this.spinner.hide();
        this.trainingList();

        setTimeout(() => {
          this.showfinalSuccessMsg = "";
          this.showToastermessage = false;
        }, 3000);
      }
    });
  }
  removeHasTag(index):void{
    if(this.hashTagValue.length > 0){
      this.hashTagValue.splice(index,1)
    }
  }
  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || "").trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { whitespace: true };
  }
}
