import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { HttpMethod } from "src/app/constants/http-handlers";
import { UserDetails } from "src/app/models/apm";
import { AppService } from "src/app/services/app.service";
import { CommonService } from "src/app/services/common.service";
import { ConfirmDialogComponent } from "../confirm-dialog/confirm-dialog.component";


@Component({
  selector: "app-msr-info",
  templateUrl: "./msr-info.component.html",
  styleUrls: ["./msr-info.component.scss"],
})
export class MsrInfoComponent implements OnInit {

  @Output() sendMSRName: EventEmitter<string> = new EventEmitter<string>();
  showDropdown: boolean = false;
  selected: string = "monthly";
  selectedItem: string = "This Month";
  recId: number;
  recDetails: any = {};
  rating: string[] = ["zero", "one", "two", "three", "four", "five"];
  userDetails:UserDetails;

  constructor(
    public dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    public commonService: CommonService,
    private appService: AppService,
    private router: Router
  ) {
    this.recId = this.activatedRoute.snapshot.params.id;
  }
  select(index) {
    this.selected = this.commonService.viewTypeOptions[index].paramName;
    this.selectedItem = this.commonService.viewTypeOptions[index].title;
    this.showDropdown = false;
    let apiUrl = this.appService.MSRDetail + this.recId+"/?view_type="+this.selected;
    const url = `${apiUrl}`;
    const apiObj = {
      url: url,
      methodType: HttpMethod.GET,
    };
    this.commonService.commonApiCall(apiObj, (res) => {
      if (res != null) {
        this.recDetails = res;
        this.recDetails.rating_class =
          "rating " + this.rating[this.recDetails.ratings] + "-star";
        this.sendMSRName.emit(this.recDetails.msr_username);
      }
    });
  }
  selectValfun() {
    this.showDropdown = !this.showDropdown;
  }
  isActive(item: string) {
    return this.selected === item;
  }
  ngOnInit(): void {
    this.userDetails = JSON.parse(localStorage.getItem("userDetail"));
    let apiUrl = this.appService.MSRDetail + this.recId+"/?view_type=monthly";
    const url = `${apiUrl}`;
    const apiObj = {
      url: url,
      methodType: HttpMethod.GET,
    };
    this.commonService.commonApiCall(apiObj, (res) => {
      if (res != null) {
        this.recDetails = res;
        this.recDetails.rating_class =
          "rating " + this.rating[this.recDetails.ratings] + "-star";
        this.sendMSRName.emit(this.recDetails.msr_username);
      }
    });
  }
  openDialog() {
    let dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: "607px",
      height: "398px",
      disableClose: true,
    });
    dialogRef.componentInstance.recId = this.recId;
  }
  backToGurulist() {
    this.router.navigate(["msr-list"]);
  }
}
