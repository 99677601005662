import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import { MatTable, MatTableDataSource } from "@angular/material/table";
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { DashboardService } from 'src/app/services/dashboard.service';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-add-lass',
  templateUrl: './add-lass.component.html',
  styleUrls: ['./add-lass.component.scss']
})
export class AddLassComponent implements OnInit {
  isAddLass: boolean = false;
  showNodata: boolean = false;
  showfinalSuccessMsg: string;
  showfinalErrorMsg: string;
  showToastermessage: boolean = false;
  dataSource = new MatTableDataSource<any>();
  addLassForm: FormGroup;
  LassFilterForm: FormGroup;
  submitted: boolean = false;
  isLoading = false;
  totalRows = 0;
  pageSize = 10;
  currentPage = 0;
  pgIndex: number = 0;
  lass: any;
  sub: Subscription = new Subscription();
  isOpenDropdown:boolean = false;
  constructor(private _formBuilder: FormBuilder,private spinner: NgxSpinnerService,
    private dashboard:DashboardService,) { }
  displayedColumns = ["firstName", "lastName", "email", "phoneNumber", "status"];
  ngOnInit(): void {
    this.addLassForm = this._formBuilder.group({
      firstName: [
        "",
        [
          Validators.required,
          Validators.pattern(/^[a-zA-Z ]*$/),
          this.noWhitespaceValidator,
        ],
      ],
      lastName: [
        "",
        [
          Validators.required,
          Validators.pattern(/^[a-zA-Z ]*$/),
          this.noWhitespaceValidator,
        ],
      ],
      mobileNum: [
        "",
        [
          Validators.required,
          Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$"),
          Validators.min(6000000000),
          Validators.maxLength(10),
        ],
      ],
      email: [
        "",
        [
          Validators.required,
          // Validators.pattern(/^\w.+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/),
          Validators.pattern(/^[a-zA-Z0-9._%+-]+@tatamotors\.com$/),
        ],
      ],
    });
    this.LassFilterForm = this._formBuilder.group({
      mobileNumber: ["",
        [
          Validators.required,
          Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$"),
          Validators.min(6000000000),
          Validators.maxLength(10),
        ],
      ],
    });
    this.lassUserlist();
  }

  toggleLasStatus(isChecked: boolean, row: any) {
    const ListInput = {
      "phone_number": row.username,
      "is_active": isChecked
    };
  
    // Temporarily store the previous state in case we need to revert it
    const previousState = row.is_active;
  
    try {
      this.spinner.show();
      this.sub.add(this.dashboard.lasStatusUpdate(ListInput).subscribe({
        next: (response) => {
          this.spinner.hide();
          if (response.success==true) {
            row.is_active = isChecked; // Update the state based on the response
            Swal.fire({
              icon: 'success',
              text: 'The Lass status has been updated.',
              timer: 2000, 
              showConfirmButton: false
            });
          } else {
            this.showToastermessage = true;
            this.showfinalSuccessMsg = "";
            this.showfinalErrorMsg = response.error.msg;
            setTimeout(() => {
              this.showToastermessage = false;
            }, 2000);
            // Revert to previous state
            row.is_active = previousState;
          }
        },
        error: (err) => {
          this.spinner.hide();
          this.showToastermessage = true;
          this.showfinalSuccessMsg = "";
          this.showfinalErrorMsg = "Error. Please try again after some time";
          setTimeout(() => {
            this.showToastermessage = false;
          }, 2000);
          // Revert to previous state
          row.is_active = previousState;
        }
      }))
    }
    catch(error){
      this.spinner.hide();
      // Handle any additional errors
      row.is_active = previousState;
      throw(error);
    }
  }

  lassUserlist(page: number = 0, pageSize: number = 10) {
    const ListInput: any = {} as any;
    ListInput.phone_number = this.LassFilterForm.value.mobileNumber || "" ;
    ListInput.action_type = "show_lass";
    ListInput.offset = page;
    ListInput.limit = pageSize;
    
    try {
      this.spinner.show();
      this.sub.add(this.dashboard.Lass(ListInput).subscribe({
        next: (response) => {
          this.spinner.hide();
          if (response?.result) {
            if(response?.result != 'Data not found'){
              this.lass = response?.result;
              this.dataSource = this.lass;  
              this.totalRows = response?.total_count; 
              this.showNodata = false;
            }
            else{
              this.lass = null;
              this.dataSource = null;
              this.showNodata = true;
            }
          } else {
            this.lass = null;
            this.dataSource = null; 
            this.showNodata = true;
          }
        },
        error: (error) => {
          this.spinner.hide();
          throw error;
        }
      }));
    } catch (error) {
      this.spinner.hide();
      throw(error);
      
    }
  }

  addLassFormData() {
    if (this.addLassForm.valid) {
      const formData = this.addLassForm.value;
  
      const requestPayload = {
        phone_number: formData.mobileNum,
        email: formData.email,
        first_name: formData.firstName,
        last_name: formData.lastName,
        action_type: "add_lass"
      };
  
      this.spinner.show();
      this.dashboard.Lass(requestPayload).subscribe({
        next: (response) => {
          this.spinner.hide();
          if (response?.success==true) {
            this.showfinalSuccessMsg = response.msg;
            this.showToastermessage = true;
            this.addLassForm.reset();
            // Refresh the list
            this.lassUserlist();
          } else {
            this.showfinalSuccessMsg = "";
            this.showfinalErrorMsg = response.msg;
            this.showToastermessage = true;
          }
          setTimeout(() => {
            this.showToastermessage = false;
          }, 4000);
        },
        error: (error) => {
          this.spinner.hide();
          this.showfinalErrorMsg = "An error occurred";
          this.showToastermessage = true;
        }
      });
    } else {
      this.submitted = true;
    }
  }
  refresh(){
    this.lassUserlist();
    this.LassFilterForm.reset();
  }
  applyFilter() {
    this.isOpenDropdown = false;
    this.currentPage = 0;
    this.lassUserlist();
  }

  reset(){
    this.LassFilterForm.reset();
    this.lassUserlist();
    this.closeDropdown();
  }

  openDropdown(){
    this.isOpenDropdown = !this.isOpenDropdown;
  }
  closeDropdown(){
    this.isOpenDropdown = false;
  }

  pageChanged(event: PageEvent) {
    this.currentPage = event.pageIndex;
    this.pageSize = event.pageSize;
    this.isLoading = true;
    // Multiply pageIndex by pageSize to get the correct offset
    this.lassUserlist(this.currentPage * this.pageSize, this.pageSize);
  }
  
  
  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || "").trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { whitespace: true };
  }

  removeSpace(){
    const inputElement = document.getElementById("email") as HTMLInputElement;
    inputElement.addEventListener("keypress", (event: KeyboardEvent) => {
      if (event.keyCode === 32) {
        event.preventDefault();
      }
    });
  }
  keyPressNumbers(event) {
    var charCode = event.which ? event.which : event.keyCode;
    // Only Numbers 0-9
    if (charCode < 48 || charCode > 57) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }
}
