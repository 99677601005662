import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { TargetAchieved } from './target-achieved';
import {TableColumn} from "../../../shared/guru-table/TableColumn";
import {Sort} from "@angular/material/sort";
import { HttpMethod } from "src/app/constants/http-handlers";
import { AppService } from "src/app/services/app.service";
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-target-vs-achieved',
  templateUrl: './target-vs-achieved.component.html',
  styleUrls: ['./target-vs-achieved.component.scss']
})
export class TargetVsAchievedComponent implements OnInit, OnChanges {
  
  @Input() selectedBrand: any;
  @Input() selectedView: any;

  targetDetails : any;
  orders: TargetAchieved[];
  ordersTableColumns: TableColumn[];
  constructor(public commonService: CommonService,
    private appService: AppService) { }

  ngOnInit(): void {

    // this.initializeColumns();
    // this.orders = this.getOrders();
  }

  ngOnChanges(){
    this.getReportData();
  }
  async getReportData() {
    let apiUrl = this.appService.partTargetVsAchieved;
  
    let dtObj = this.commonService.getDateRangObject(
      this.selectedView,
      "",
      "YYYY-MM-dd"
    );

    let reqObj = {
     
      "part_brand" : this.selectedBrand,
      "from_date" : dtObj.from,
      "to_date" : dtObj.to
    };

    const url = `${apiUrl}`;
    //alert(url);
    const apiObj = {
      url: url,
      methodType: HttpMethod.POST,
      requestObj: reqObj,
    };
    let resArray = this.commonService.commonApiCall(apiObj, (res) => {
      this.targetDetails = res;
      //   {
      //     "parts_fitted": 29,
      //     "part": {
      //         "name": "ADAPTOR (BRAKE CIRCUIT)",
      //         "part_number": "270242806806"
      //     }
      // },
       
  
    });
    //this.isLoading = false;
    //console.log(resArray);
  }

  sortData(sortParameters: Sort) {
    const keyName = sortParameters.active;
    if (sortParameters.direction === 'asc') {
      this.orders = this.orders.sort((a: TargetAchieved, b: TargetAchieved) => a[keyName].localeCompare(b[keyName]));
      return '';
    } else if (sortParameters.direction === 'desc') {
      this.orders = this.orders.sort((a: TargetAchieved, b: TargetAchieved) => b[keyName].localeCompare(a[keyName]));
      return '';
    } else {
      return this.orders = this.getOrders();
    }
  }

  removeOrder(order: TargetAchieved) {
    this.orders = this.orders.filter(item => item.couponid !== order.couponid)
  }

  initializeColumns(): void {
    this.ordersTableColumns = [
      {
        name: 'Part/Product Name',
        dataKey: 'partname',
        position: 'center',
        isSortable: false
      },
      {
        name: 'Guru',
        dataKey: 'guru',
        position: 'center',
        isSortable: false
      },
      {
        name: 'Garage/Area',
        dataKey: 'garage',
        position: 'center',
        isSortable: false
      },
      {
        name: 'Distributor',
        dataKey: 'distributor',
        position: 'center',
        isSortable: false
      },
      {
        name: 'Brand',
        dataKey: 'brand',
        position: 'center',
        isSortable: false
      },
      {
        name: 'Points',
        dataKey: 'points',
        position: 'center',
        isSortable: false
      },
      {
        name: 'Coupon ID',
        dataKey: 'couponid',
        position: 'center',
        isSortable: false
      },
      {
        name: 'Fitment Date',
        dataKey: 'fitmentdate',
        position: 'center',
        isSortable: false
      },
    ];
  }

  getOrders(): any[] {
    return [
      {
        id: 1,
        partname: 'Clutch Plate (PG1)<span class="small-font">22/06/2019</span>',
        guru: '<a class="guru-name">Balu Singh</a><span class="small-font">123456789012</span>',
        garage: 'Khan Garage<span class="small-font">Balewadi</span>',
        distributor: 'BK Automobiles<span class="small-font">Pimpri</span>',
        brand:'TGP',
        points: 17,
        couponid: 1100114455,
        fitmentdate:'03/06/2020'
      },
      {
        id: 2,
        partname: 'Clutch Plate (PG1)<span class="small-font">22/06/2019</span>',
        guru: '<a class="guru-name">Balu Singh</a><span class="small-font">123456789012</span>',
        garage: 'Khan Garage<span class="small-font">Balewadi</span>',
        distributor: 'BK Automobiles<span class="small-font">Pimpri</span>',
        brand:'TGP',
        points: 17,
        couponid: 1100114456,
        fitmentdate:'04/06/2020'
      },
      {
        id: 3,
        partname: 'Clutch Plate (PG1)<span class="small-font">22/06/2019</span>',
        guru: '<a class="guru-name">Balu Singh</a><span class="small-font">123456789012</span>',
        garage: 'Khan Garage<span class="small-font">Balewadi</span>',
        distributor: 'BK Automobiles<span class="small-font">Pimpri</span>',
        brand:'TGP',
        points: 17,
        couponid: 1100114455,
        fitmentdate:'05/06/2020'
      },
      {
        id: 4,
        partname: 'Clutch Plate (PG1)<span class="small-font">22/06/2019</span>',
        guru: '<a class="guru-name">Balu Singh</a><span class="small-font">123456789012</span>',
        garage: 'Khan Garage<span class="small-font">Balewadi</span>',
        distributor: 'BK Automobiles<span class="small-font">Pimpri</span>',
        brand:'TGP',
        points: 17,
        couponid: 1100114455,
        fitmentdate:'06/06/2020'
      },
      {
        id: 5,
        partname: 'Clutch Plate (PG1)<span class="small-font">22/06/2019</span>',
        guru: '<a class="guru-name">Balu Singh</a><span class="small-font">123456789012</span>',
        garage: 'Khan Garage<span class="small-font">Balewadi</span>',
        distributor: 'BK Automobiles<span class="small-font">Pimpri</span>',
        brand:'TGP',
        points: 17,
        couponid: 1100114455,
        fitmentdate:'07/06/2020'
      },
      {
        id: 6,
        partname: 'Clutch Plate (PG1)<span class="small-font">22/06/2019</span>',
        guru: '<a class="guru-name">Balu Singh</a><span class="small-font">123456789012</span>',
        garage: 'Khan Garage<span class="small-font">Balewadi</span>',
        distributor: 'BK Automobiles<span class="small-font">Pimpri</span>',
        brand:'TGP',
        points: 17,
        couponid: 1100114455,
        fitmentdate:'08/06/2020'
      },
      {
        id: 7,
        partname: 'Clutch Plate (PG1)<span class="small-font">22/06/2019</span>',
        guru: '<a class="guru-name">Balu Singh</a><span class="small-font">123456789012</span>',
        garage: 'Khan Garage<span class="small-font">Balewadi</span>',
        distributor: 'BK Automobiles<span class="small-font">Pimpri</span>',
        brand:'TGP',
        points: 17,
        couponid: 1100114455,
        fitmentdate:'09/06/2020'
      },
      {
        id: 8,
        partname: 'Clutch Plate (PG1)<span class="small-font">22/06/2019</span>',
        guru: '<a class="guru-name">Balu Singh</a><span class="small-font">123456789012</span>',
        garage: 'Khan Garage<span class="small-font">Balewadi</span>',
        distributor: 'BK Automobiles<span class="small-font">Pimpri</span>',
        brand:'TGP',
        points: 17,
        couponid: 1100114455,
        fitmentdate:'10/06/2020'
      },
      {
        id: 9,
        partname: 'Clutch Plate (PG1)<span class="small-font">22/06/2019</span>',
        guru: '<a class="guru-name">Balu Singh</a><span class="small-font">123456789012</span>',
        garage: 'Khan Garage<span class="small-font">Balewadi</span>',
        distributor: 'BK Automobiles<span class="small-font">Pimpri</span>',
        brand:'TGP',
        points: 17,
        couponid: 1100114455,
        fitmentdate:'11/06/2020'
      },
      {
        id: 10,
        partname: 'Clutch Plate (PG1)<span class="small-font">22/06/2019</span>',
        guru: '<a class="guru-name">Balu Singh</a><span class="small-font">123456789012</span>',
        garage: 'Khan Garage<span class="small-font">Balewadi</span>',
        distributor: 'BK Automobiles<span class="small-font">Pimpri</span>',
        brand:'TGP',
        points: 17,
        couponid: 1100114455,
        fitmentdate:'12/06/2020'
      },
      {
        id: 11,
        partname: 'Clutch Plate (PG1)<span class="small-font">22/06/2019</span>',
        guru: '<a class="guru-name">Balu Singh</a><span class="small-font">123456789012</span>',
        garage: 'Khan Garage<span class="small-font">Balewadi</span>',
        distributor: 'BK Automobiles<span class="small-font">Pimpri</span>',
        brand:'TGP',
        points: 17,
        couponid: 1100114455,
        fitmentdate:'13/06/2020'
      },
      {
        id: 12,
        partname: 'Clutch Plate (PG1)<span class="small-font">22/06/2019</span>',
        guru: '<a class="guru-name">Balu Singh</a><span class="small-font">123456789012</span>',
        garage: 'Khan Garage<span class="small-font">Balewadi</span>',
        distributor: 'BK Automobiles<span class="small-font">Pimpri</span>',
        brand:'TGP',
        points: 17,
        couponid: 1100114455,
        fitmentdate:'14/06/2020'
      },
      {
        id: 13,
        partname: 'Clutch Plate (PG1)<span class="small-font">22/06/2019</span>',
        guru: '<a class="guru-name">Balu Singh</a><span class="small-font">123456789012</span>',
        garage: 'Khan Garage<span class="small-font">Balewadi</span>',
        distributor: 'BK Automobiles<span class="small-font">Pimpri</span>',
        brand:'TGP',
        points: 17,
        couponid: 1100114455,
        fitmentdate:'15/06/2020'
      },
      {
        id: 14,
        partname: 'Clutch Plate (PG1)<span class="small-font">22/06/2019</span>',
        guru: '<a class="guru-name">Balu Singh</a><span class="small-font">123456789012</span>',
        garage: 'Khan Garage<span class="small-font">Balewadi</span>',
        distributor: 'BK Automobiles<span class="small-font">Pimpri</span>',
        brand:'TGP',
        points: 17,
        couponid: 1100114455,
        fitmentdate:'16/06/2020'
      },
      {
        id: 15,
        partname: 'Clutch Plate (PG1)<span class="small-font">22/06/2019</span>',
        guru: '<a class="guru-name">Balu Singh</a><span class="small-font">123456789012</span>',
        garage: 'Khan Garage<span class="small-font">Balewadi</span>',
        distributor: 'BK Automobiles<span class="small-font">Pimpri</span>',
        brand:'TGP',
        points: 17,
        couponid: 1100114455,
        fitmentdate:'17/06/2020'
      },
      {
        id: 16,
        partname: 'Clutch Plate (PG1)<span class="small-font">22/06/2019</span>',
        guru: '<a class="guru-name">Balu Singh</a><span class="small-font">123456789012</span>',
        garage: 'Khan Garage<span class="small-font">Balewadi</span>',
        distributor: 'BK Automobiles<span class="small-font">Pimpri</span>',
        brand:'TGP',
        points: 17,
        couponid: 1100114455,
        fitmentdate:'18/06/2020'
      },
      {
        id: 17,
        partname: 'Clutch Plate (PG1)<span class="small-font">22/06/2019</span>',
        guru: '<a class="guru-name">Balu Singh</a><span class="small-font">123456789012</span>',
        garage: 'Khan Garage<span class="small-font">Balewadi</span>',
        distributor: 'BK Automobiles<span class="small-font">Pimpri</span>',
        brand:'TGP',
        points: 17,
        couponid: 1100114455,
        fitmentdate:'19/06/2020'
      },
      {
        id: 18,
        partname: 'Clutch Plate (PG1)<span class="small-font">22/06/2019</span>',
        guru: '<a class="guru-name">Balu Singh</a><span class="small-font">123456789012</span>',
        garage: 'Khan Garage<span class="small-font">Balewadi</span>',
        distributor: 'BK Automobiles<span class="small-font">Pimpri</span>',
        brand:'TGP',
        points: 17,
        couponid: 1100114455,
        fitmentdate:'20/06/2020'
      },
      {
        id: 19,
        partname: 'Clutch Plate (PG1)<span class="small-font">22/06/2019</span>',
        guru: '<a class="guru-name">Balu Singh</a><span class="small-font">123456789012</span>',
        garage: 'Khan Garage<span class="small-font">Balewadi</span>',
        distributor: 'BK Automobiles<span class="small-font">Pimpri</span>',
        brand:'TGP',
        points: 17,
        couponid: 1100114455,
        fitmentdate:'21/06/2020'
      },
      {
        id: 20,
        partname: 'Clutch Plate (PG1)<span class="small-font">22/06/2019</span>',
        guru: '<a class="guru-name">Balu Singh</a><span class="small-font">123456789012</span>',
        garage: 'Khan Garage<span class="small-font">Balewadi</span>',
        distributor: 'BK Automobiles<span class="small-font">Pimpri</span>',
        brand:'TGP',
        points: 17,
        couponid: 1100114455,
        fitmentdate:'22/06/2020'
      },
      {
        id: 21,
        partname: 'Clutch Plate (PG1)<span class="small-font">22/06/2019</span>',
        guru: '<a class="guru-name">Balu Singh</a><span class="small-font">123456789012</span>',
        garage: 'Khan Garage<span class="small-font">Balewadi</span>',
        distributor: 'BK Automobiles<span class="small-font">Pimpri</span>',
        brand:'TGP',
        points: 17,
        couponid: 1100114455,
        fitmentdate:'13/06/2020'
      },
      {
        id: 22,
        partname: 'Clutch Plate (PG1)<span class="small-font">22/06/2019</span>',
        guru: '<a class="guru-name">Balu Singh</a><span class="small-font">123456789012</span>',
        garage: 'Khan Garage<span class="small-font">Balewadi</span>',
        distributor: 'BK Automobiles<span class="small-font">Pimpri</span>',
        brand:'TGP',
        points: 17,
        couponid: 1100114455,
        fitmentdate:'14/06/2020'
      },
      {
        id: 23,
        partname: 'Clutch Plate (PG1)<span class="small-font">22/06/2019</span>',
        guru: '<a class="guru-name">Balu Singh</a><span class="small-font">123456789012</span>',
        garage: 'Khan Garage<span class="small-font">Balewadi</span>',
        distributor: 'BK Automobiles<span class="small-font">Pimpri</span>',
        brand:'TGP',
        points: 17,
        couponid: 1100114455,
        fitmentdate:'15/06/2020'
      },
      {
        id: 24,
        partname: 'Clutch Plate (PG1)<span class="small-font">22/06/2019</span>',
        guru: '<a class="guru-name">Balu Singh</a><span class="small-font">123456789012</span>',
        garage: 'Khan Garage<span class="small-font">Balewadi</span>',
        distributor: 'BK Automobiles<span class="small-font">Pimpri</span>',
        brand:'TGP',
        points: 17,
        couponid: 1100114455,
        fitmentdate:'16/06/2020'
      },
      {
        id: 25,
        partname: 'Clutch Plate (PG1)<span class="small-font">22/06/2019</span>',
        guru: '<a class="guru-name">Balu Singh</a><span class="small-font">123456789012</span>',
        garage: 'Khan Garage<span class="small-font">Balewadi</span>',
        distributor: 'BK Automobiles<span class="small-font">Pimpri</span>',
        brand:'TGP',
        points: 17,
        couponid: 1100114455,
        fitmentdate:'17/06/2020'
      },
      {
        id: 26,
        partname: 'Clutch Plate (PG1)<span class="small-font">22/06/2019</span>',
        guru: '<a class="guru-name">Balu Singh</a><span class="small-font">123456789012</span>',
        garage: 'Khan Garage<span class="small-font">Balewadi</span>',
        distributor: 'BK Automobiles<span class="small-font">Pimpri</span>',
        brand:'TGP',
        points: 17,
        couponid: 1100114455,
        fitmentdate:'18/06/2020'
      },
      {
        id: 27,
        partname: 'Clutch Plate (PG1)<span class="small-font">22/06/2019</span>',
        guru: '<a class="guru-name">Balu Singh</a><span class="small-font">123456789012</span>',
        garage: 'Khan Garage<span class="small-font">Balewadi</span>',
        distributor: 'BK Automobiles<span class="small-font">Pimpri</span>',
        brand:'TGP',
        points: 17,
        couponid: 1100114455,
        fitmentdate:'19/06/2020'
      },
      {
        id: 28,
        partname: 'Clutch Plate (PG1)<span class="small-font">22/06/2019</span>',
        guru: '<a class="guru-name">Balu Singh</a><span class="small-font">123456789012</span>',
        garage: 'Khan Garage<span class="small-font">Balewadi</span>',
        distributor: 'BK Automobiles<span class="small-font">Pimpri</span>',
        brand:'TGP',
        points: 17,
        couponid: 1100114455,
        fitmentdate:'20/06/2020'
      },
      {
        id: 29,
        partname: '<div>Clutch Plate</div><span>Hello</span>',
        partgroup: 'Engine',
        type: 'TGP',
        points: 17,
        couponid: 1100114455,
        fitmentdate:'21/06/2020'
      },
      {
        id: 30,
        partname: 'Clutch Plate (PG1)<span class="small-font">22/06/2019</span>',
        guru: '<a class="guru-name">Balu Singh</a><span class="small-font">123456789012</span>',
        garage: 'Khan Garage<span class="small-font">Balewadi</span>',
        distributor: 'BK Automobiles<span class="small-font">Pimpri</span>',
        brand:'TGP',
        points: 17,
        couponid: 1100114455,
        fitmentdate:'22/06/2020'
      },
      {
        id: 31,
        partname: 'Clutch Plate (PG1)<span class="small-font">22/06/2019</span>',
        guru: '<a class="guru-name">Balu Singh</a><span class="small-font">123456789012</span>',
        garage: 'Khan Garage<span class="small-font">Balewadi</span>',
        distributor: 'BK Automobiles<span class="small-font">Pimpri</span>',
        brand:'TGP',
        points: 17,
        couponid: 1100114455,
        fitmentdate:'22/06/2020'
      },
    ];
  }

}

