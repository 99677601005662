import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { ChartOptions, ChartType, ChartDataSets } from "chart.js";
import { Label } from "ng2-charts";
import { Subscription } from 'rxjs/internal/Subscription';
import { DashboardService } from 'src/app/services/dashboard.service';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  role:string;
  userdetails: any;
  constructor(private router: Router,private dashboard:DashboardService) { }
 jobcardmonthlydata:any

 
  ngOnInit(): void {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.role = JSON.parse(localStorage.getItem('userDetail')).userdetails.role;
    // this.userdetails = JSON.parse(localStorage.getItem('userDetails')).userdetails.role;
    // console.log('userdetails',this.userdetails);
    
    console.log('role',this.role);
    
  }

 
}
