import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators, FormControl } from "@angular/forms";
import { MatDialogRef } from "@angular/material/dialog";
import { HttpMethod } from "src/app/constants/http-handlers";
import { AppService } from "src/app/services/app.service";
import { CommonService } from "src/app/services/common.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-confirm-dialog",
  templateUrl: "./confirm-dialog.component.html",
  styleUrls: ["./confirm-dialog.component.scss"],
})
export class ConfirmDialogComponent implements OnInit {
  recId: number;
  frmDelete: FormGroup;
  isFormShow: boolean = true;

  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    private formBuilder: FormBuilder,
    private commonService: CommonService,
    private appService: AppService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.frmDelete = this.formBuilder.group({
      reason: ["", [Validators.required, Validators.pattern(/^[a-zA-Z ]*$/), this.noWhitespaceValidator]],
    });
  }
  closeModal(): void {
    this.dialogRef.close();
  }
  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }
  onSubmit() {
    if (this.frmDelete.invalid) {
      return;
    } else {
      const url = this.appService.msrDelete + this.recId + "/";
      const apiObj = {
        url: url,
        methodType: HttpMethod.PUT,
        requestObj: { reason: this.frmDelete.value.reason },
      };
      this.commonService.commonApiCall(apiObj, (res) => {
        if (res) {
          this.isFormShow = false;
          setTimeout(() => {
            this.dialogRef.close();
            this.router.navigate(["msr-list"]);
          }, 2000);
        }
      });
    }
  }
}
