import {
  AfterViewInit,
  Component,
  OnInit,
  Input,
  OnChanges,
  ChangeDetectorRef,
   AfterContentChecked
} from "@angular/core";
import { ChartOptions, ChartType, ChartDataSets } from "chart.js";
import { Color, Label } from "ng2-charts";
import { PartsFitment } from "./parts-fitment";
import { TableColumn } from "../../../shared/guru-table/TableColumn";
import { Sort } from "@angular/material/sort";

import { HttpMethod } from "src/app/constants/http-handlers";
import { CommonService } from "src/app/services/common.service";
import { AppService } from "src/app/services/app.service";
// declare let moment: any; //declare moment
import { DatePipe } from "@angular/common";
import { NgxSpinnerService } from "ngx-spinner";
import { ExcelService } from "src/app/services/excel.service";

@Component({
  selector: "app-parts-fitment",
  templateUrl: "./parts-fitment.component.html",
  styleUrls: ["./parts-fitment.component.scss"],
  providers: [DatePipe],
})
export class PartsFitmentComponent implements OnInit, OnChanges {
  filterObject: any = [];
 searchText: string = "";

  @Input() selectedBrand: any;
  @Input() selectedView: any;

  currentPage = 0;
  newpageind = 0;
  showNodata: boolean = false;
  orders: PartsFitment[];
  ordersTableColumns: TableColumn[];
  pageIndex = 0;
  isDataReady: boolean = false;
  recordCount: number = 0;
  step: string;
  tgpSoldLastYear: number;
  tgpSoldCurrentYear: number;
  tgpSoldPercent: number;
  ntgpSoldLastYear: number;
  ntgpSoldCurrentYear: number;
  ntgpSoldPercent: number;
  currentfy: string;
  previousfy: string;
  formcontrolValues:any;
  role: any;
  zone: any;
  constructor(
    public commonService: CommonService,
    private appService: AppService,
    private datePipe: DatePipe,
    private excelService:ExcelService,
    private changeDetector: ChangeDetectorRef,
    private spinner: NgxSpinnerService,
  ) {}
  ngOnInit(): void {
    this.role = JSON.parse(localStorage.getItem("userDetail")).userdetails.role;
    if (this.role === 'RPM'||this.role === 'DEALER') {
      this.zone = JSON.parse(localStorage.getItem('userDetail')).userdetails.region;
    }
    this.initializeColumns();
    this.isDataReady = false;
    this.getReportData();
  }
  ngOnChanges() {
    this.getBarchartData();
    // this.getReportData();
  }
  receivedPageHandler(valueEmitted: any) {
    this.pageIndex = valueEmitted;
    this.isDataReady = false;
    this.getReportData();
  }

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }

  public barChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    animation: {
      duration: 1,
      onComplete: function () {
        var chartInstance = this.chart,
          ctx = chartInstance.ctx;

        ctx.textAlign = "center";
        ctx.textBaseline = "bottom";
        var datacompare = [];
        var percentage;
        this.data.datasets.forEach(function (dataset, i) {
          var meta = chartInstance.controller.getDatasetMeta(i);
          if (meta.index == 0) {
            datacompare.push(dataset.data);
            //console.log(datacompare[0][1]);
          }
          //console.log(meta.index);

          meta.data.forEach(function (bar, index) {
            var data = dataset.data[index];
            //console.log(data);
            ctx.font = "500 11px Uni Neue";
            ctx.fillStyle = "#282828";
            ctx.fillText(data, bar._model.x, bar._model.y - 5);
            if (meta.index == 1) {
              if (datacompare[0][index] != 0) {
                percentage = Math.floor(
                  ((dataset.data[index] - datacompare[0][index]) /
                    datacompare[0][index]) *
                    100
                );
              } else {
                if (dataset.data[index] > 0) {
                  percentage = 100;
                } else {
                  percentage = 0;
                }
              }
              if (percentage > 0) {
                ctx.font = "500 11px Uni Neue";
                ctx.fillStyle = "#48BD00";
                ctx.fillText(percentage + "%", bar._model.x, bar._model.y - 20);
              } else if (percentage < 0) {
                ctx.font = "500 11px Uni Neue";
                ctx.fillStyle = "#DD352E";
                ctx.fillText(
                  Math.abs(percentage) + "%",
                  bar._model.x,
                  bar._model.y - 20
                );
              } else {
              }
              //console.log(Math.floor(percentage));
            }
          });
        });
      },
    },
    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 35,
        bottom: 0,
      },
    },
    scales: {
      xAxes: [
        {
          id: "x-axis-0",
          display: true,
          gridLines: {
            display: true,
            drawOnChartArea: false,
            drawTicks: false,
          },
          ticks: {
            display: true,
            fontColor: "#7B7B7B",
            fontFamily: "Uni Neue",
            fontSize: 14,
            fontStyle: "500",
            beginAtZero: true,
            padding: 15,
          },
          //offset: true,
        },
      ],
      yAxes: [
        {
          id: "y-axis-0",
          display: true,
          position: "left",
          gridLines: {
            display: true,
            drawOnChartArea: false,
            drawTicks: false,
          },

          ticks: {
            fontColor: "#7B7B7B",
            fontFamily: "Uni Neue",
            fontSize: 14,
            fontStyle: "500",
            beginAtZero: false,
            //max: 1000,
            min: 0,
            padding: 10,
            //stepSize: 300,
          },
        },
      ],
    },

    tooltips: { enabled: false },
    hover: { mode: null },
    events: ["click"], //"mousemove", "mouseout", "touchstart", "touchmove", "touchend"
  };
  public barChartLabels: Label[] = [
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
    "Jan",
    "Feb",
    "Mar",
  ];
  public barChartType: ChartType = "bar";
  public barChartLegend = false;
  public barChartPlugins = [];

  public barChartData: ChartDataSets[] = [];
  public barChartColors: Color[] = [
    {
      backgroundColor: "#ACCCF3",
    },
    {
      backgroundColor: "#F5B0B1",
    },
  ];

  searchRecords() {
    this.pageIndex = 0;
    this.isDataReady = false;
    this.getReportData();
  }
  checkText(event: KeyboardEvent) {
    if (event.code == "Enter" || this.searchText.trim() == "") {
      this.searchRecords();
    }
  }
  sortData(sortParameters: Sort) {
    //debugger;
    const keyName = sortParameters.active;
    if (sortParameters.direction === "asc") {
      this.orders = this.orders.sort((a: PartsFitment, b: PartsFitment) =>
        a[keyName].localeCompare(b[keyName])
      );
      return "";
    } else if (sortParameters.direction === "desc") {
      this.orders = this.orders.sort((a: PartsFitment, b: PartsFitment) =>
        b[keyName].localeCompare(a[keyName])
      );
      return "";
    } else {
      return "";
      //return this.getJobSummaryTableData();
    }
  }
  initializeColumns(): void {
    this.ordersTableColumns = [
      {
        name: "Part Number",
        dataKey: "partname",
        position: "center",
        isSortable: false,
      },

      {
        name: "Part Description",
        dataKey: "partDescription",
        position: "center",
        isSortable: false,
      },
      {
        name: "Part Price",
        dataKey: "price",
        position: "center",
        isSortable: false,
      },
      {
        name: "Quantity",
        dataKey: "quantity",
        position: "center",
        isSortable: false,
      },
      {
        name: "Total Amt.",
        dataKey: "total_part_amt",
        position: "center",
        isSortable: false,
      },
      {
        name: "Part Scanned",
        dataKey: "partScanned",
        position: "center",
        isSortable: false,
      },
      {
        name: "Lass Name",
        dataKey: "lassName",
        position: "center",
        isSortable: true,
      },
      {
        name: "Lass Number",
        dataKey: "lassNumber",
        position: "center",
        isSortable: false,
      },
      {
        name: "Garage/Area",
        dataKey: "garage",
        position: "center",
        isSortable: false,
      },

      {
        name: "Guru Name",
        dataKey: "guru",
        position: "center",
        isSortable: false,
      },
      {
        name: "Guru Number",
        dataKey: "guruNumber",
        position: "center",
        isSortable: true,
      },
      {
        name: "Dealer Name",
        dataKey: "dealer_name",
        position: "center",
        isSortable: true,
      },
      {
        name: "SSO",
        dataKey: "sso",
        position: "center",
        isSortable: true,
      },
      {
        name: "State",
        dataKey: "state",
        position: "center",
        isSortable: false,
      },
      {
        name: "Region",
        dataKey: "region",
        position: "center",
        isSortable: false,
      },

      {
        name: "Pincode",
        dataKey: "pincode",
        position: "center",
        isSortable: false,
      },

      {
        name: "Job Card Id",
        dataKey: "jobID",
        position: "center",
        isSortable: false,
      },
      {
        name: "Job Card Opening Date",
        dataKey: "jobOpeningDt",
        position: "center",
        isSortable: true,
      },
      {
        name: "Job Card Opening Time",
        dataKey: "jobOpeningTime",
        position: "center",
        isSortable: false,
      },
      {
        name: "Job Category",
        dataKey: "jobCategory",
        position: "center",
        isSortable: false,
      }
    ];
  }

  getBarchartData() {
    let apiUrl = this.appService.partFitmentReportChartData;
    let queryParamList = [];

    // let reqObj = {
    //   view_by: this.selectedView,
    //   brand_type: this.selectedBrand,
    // };
    let reqObj = {
      view_by: "yearly",
      brand_type: this.selectedBrand,
    };
    const url = `${apiUrl}`;
    //alert(url);
    const apiObj = {
      url: url,
      methodType: HttpMethod.POST,
      requestObj: reqObj,
    };
    // let resArray = this.commonService.commonApiCall(apiObj, (res) => {
    //   let prevYearData: any[12] = [];
    //   let currentYearData: any[12] = [];
    //   let growthPercentage: any[12] = [];
    //   for (let k = 0; k < 12; k++) {
    //     let mname = moment(
    //       `2021-${Object.keys(res)[k]}-01`,
    //       "YYYY-MMMM-DD"
    //     ).format("MMM");

    //     //get the index of month barchart month label
    //     let idx = this.barChartLabels.indexOf(mname);

    //     let monthObj = res[Object.keys(res)[k]];
    //     let yearKeys = Object.values(monthObj);

    //     //set the object at month lablel index
    //     currentYearData[idx] = Object.values(yearKeys[0])[0];
    //     prevYearData[idx] = Object.values(yearKeys[1])[0];
    //     growthPercentage[idx] = Object.values(yearKeys[2])[0];
    //     this.currentfy = Object.keys(yearKeys[0])[0];
    //     this.previousfy = Object.keys(yearKeys[1])[0];
    //   }

    //   this.barChartData = [
    //     {
    //       data: prevYearData,
    //       label: this.commonService.prevYear.toString(),
    //       barPercentage: 0.7,
    //     },
    //     {
    //       data: currentYearData,
    //       label: this.commonService.currentYear.toString(),
    //       barPercentage: 0.7,
    //     },
    //   ];
    //   this.tgpSoldLastYear = res.tgp_sold_last_year;
    //   this.tgpSoldCurrentYear = res.tgp_sold_current_year;
    //   this.tgpSoldPercent =
    //     (this.tgpSoldLastYear / this.tgpSoldCurrentYear) * 100;
    //     this.ntgpSoldLastYear = res.ntgp_sold_last_year;
    //   this.ntgpSoldCurrentYear = res.ntgp_sold_current_year;
    //   this.ntgpSoldPercent =
    //     (this.ntgpSoldLastYear / this.ntgpSoldCurrentYear) * 100;
    //     if (isNaN(this.ntgpSoldPercent)) this.ntgpSoldPercent = 0;
    //     if (isNaN(this.tgpSoldPercent)) this.tgpSoldPercent = 0;
    // });

    let resArray = this.commonService.commonApiCall(apiObj, (res) => {
      let prevYearData: any[12] = [];
      let currentYearData: any[12] = [];
      let growthPercentage: any[12] = [];
    
      for (let k = 0; k < 12; k++) {
        let monthDate = new Date(`2021-${Object.keys(res)[k]}-01`);
        let mname = this.datePipe.transform(monthDate, 'MMM');
    
        // get the index of the month in the bar chart labels
        let idx = this.barChartLabels.indexOf(mname);
    
        let monthObj = res[Object.keys(res)[k]];
        let yearKeys = Object.values(monthObj);
    
        // set the object at the month label index
        currentYearData[idx] = Object.values(yearKeys[0])[0];
        prevYearData[idx] = Object.values(yearKeys[1])[0];
        growthPercentage[idx] = Object.values(yearKeys[2])[0];
        this.currentfy = Object.keys(yearKeys[0])[0];
        this.previousfy = Object.keys(yearKeys[1])[0];
      }
    
      this.barChartData = [
        {
          data: prevYearData,
          label: this.commonService.prevYear.toString(),
          barPercentage: 0.7,
        },
        {
          data: currentYearData,
          label: this.commonService.currentYear.toString(),
          barPercentage: 0.7,
        },
      ];
    
      this.tgpSoldLastYear = res.tgp_sold_last_year;
      this.tgpSoldCurrentYear = res.tgp_sold_current_year;
      this.tgpSoldPercent = (this.tgpSoldLastYear / this.tgpSoldCurrentYear) * 100;
    
      this.ntgpSoldLastYear = res.ntgp_sold_last_year;
      this.ntgpSoldCurrentYear = res.ntgp_sold_current_year;
      this.ntgpSoldPercent = (this.ntgpSoldLastYear / this.ntgpSoldCurrentYear) * 100;
    
      if (isNaN(this.ntgpSoldPercent)) this.ntgpSoldPercent = 0;
      if (isNaN(this.tgpSoldPercent)) this.tgpSoldPercent = 0;
    });
    
    //this.isLoading = false;
  }
  
  showToastermessage:boolean=false;
  showfinalErrorMsg:string="";
  async downloadReport(){
      try{
        this.spinner.show();
        let today = new Date();
        let apiUrl =  this.appService.partSummaryMisReport;
        let to_date = this.datePipe.transform(new Date(), "yyyy-MM-dd");
        let from_date = this.datePipe.transform(new Date(today.getFullYear(), today.getMonth() - 1, today.getDate()), "yyyy-MM-dd");
        let reqObj = {
          "part_name":this.formcontrolValues && this.formcontrolValues.part_name?this.formcontrolValues.part_name:this.searchText,
          "is_tata_part": this.selectedBrand == "tgp",
          "from_date": this.formcontrolValues && this.formcontrolValues.fromDate?this.datePipe.transform(this.formcontrolValues.fromDate,"YYYY-MM-dd"):from_date,
          "to_date": this.formcontrolValues && this.formcontrolValues.toDate?this.datePipe.transform(this.formcontrolValues.toDate,"YYYY-MM-dd"):to_date,
          "guru_name": this.formcontrolValues && this.formcontrolValues.guru_name?this.formcontrolValues.guru_name:"",
          "guru_phno": this.formcontrolValues && this.formcontrolValues.guru_phno?this.formcontrolValues.guru_phno:"",
          "job_id": this.formcontrolValues && this.formcontrolValues.jobcard_id?this.formcontrolValues.jobcard_id:"",
          "jobcard_created_date": this.formcontrolValues && this.formcontrolValues.jobcard_created_date?this.datePipe.transform(this.formcontrolValues.jobcard_created_date,"YYYY-MM-dd"):"",
          "region": this.role === 'RPM'||this.role === 'DEALER' ? this.zone : (this.formcontrolValues && this.formcontrolValues.zone ? this.formcontrolValues.zone : ""),
          "case_type": this.formcontrolValues && this.formcontrolValues.case_type?this.formcontrolValues.case_type:"",
          "part_number": this.formcontrolValues && this.formcontrolValues.part_number?this.formcontrolValues.part_number:"",
          "offset":0,
          "limit":250,
          "lass_name":this.formcontrolValues && this.formcontrolValues.lass_name?this.formcontrolValues.lass_name:"",
          "lass_contact": this.formcontrolValues && this.formcontrolValues.lass_number?this.formcontrolValues.lass_number:"",
          "state": this.formcontrolValues && this.formcontrolValues.state?this.formcontrolValues.state:""
        };
        let totalResult=0;
        let data:any[] = [];
        let res = await this.commonService.postAsyncRequest(apiUrl,reqObj);
        if(res){
          totalResult = res.count;
          data = res.data;
        }
        let rou = Math.floor(totalResult/250);
        rou = (totalResult%250>0) && (rou > 0)?rou+1:rou; 
        if(rou > 1 ){
          for(var i=1;i<rou;i++){
            reqObj.offset = (i * 250);
            res = await this.commonService.postAsyncRequest(apiUrl,reqObj);
            if(res && res.success && res.data.length){
              data = [...data, ...res.data];
            }
          }
        }
        let finalData:PartFitment[] = [];
        for (let row of data) {
          const ListInput: PartFitment = {} as PartFitment;
          ListInput.Part_Number = row.part_number
          ListInput.Part_Name = row.name
          ListInput.Part_Price = row.price
          ListInput.Quantity = row.quantity
          ListInput.Total_Amount = (row.price * row.quantity).toString(); 
          ListInput.Part_Sacnned = row.is_scanned || row.is_scanned == 'True' ?'Yes':'No';
          ListInput.Lass_Name = row.lass_name
          ListInput.Lass_Number = row.lass_number
          ListInput.Garage_Area = row.garage_name
          ListInput.Guru_Name = row.guru_name
          ListInput.Guru_Number = row.guru_phone_number
          ListInput.Dealer_Name = row.dealer
          ListInput.SSO = row.sso
          ListInput.State = row.state_name
          ListInput.Region = row.zone
          ListInput.Pincode = row.pincode
          ListInput.Job_card_Id = row.jobcard_id
          ListInput.Job_Card_Opening_Date = this.datePipe.transform( row.jobcard_created_date,"dd/MM/yyyy")
          ListInput.Job_Card_Opening_Time =this.datePipe.transform( row.jobcard_created_date,"h:mm a")
          ListInput.Job_Category = row.case_type
          finalData.push(ListInput);
        }
        if(finalData.length > 0 ){
          this.excelService.exportCancellationAsExcelFile(finalData, 'parts_fitment_report');
        }else{
          this.showToastermessage = true;
          this.showfinalErrorMsg =
            "No Data For Download";
          setTimeout(() => {
            this.showfinalErrorMsg = "";
            this.showToastermessage = false;
          }, 3000);
        }
        console.log(finalData);
      }catch(error){
        this.showToastermessage = true;
        this.showfinalErrorMsg =
          "No Data For Download";
        setTimeout(() => {
          this.showfinalErrorMsg = "";
          this.showToastermessage = false;
        }, 3000);
        this.spinner.hide();
      }
      this.spinner.hide();
    
  }
  refreshList(){
    this.pageIndex = 0;
    this.formControlValue(undefined)
  }
  getReportData(event?) {
    try{
    this.spinner.show();
    this.isDataReady = false;
    this.orders = [];
    this.recordCount = 0;
    let apiUrl = this.appService.partSummaryMisReport;
    let queryParamList = [];

    //paging
    let pageParam = "";
    if (this.pageIndex != 0) {
      this.newpageind = this.pageIndex + 1;
      pageParam = `page=` + this.newpageind;
      queryParamList.push(pageParam);
    }

    let apiQueryParam = "";
    if (queryParamList.length > 0) {
      apiQueryParam = `?` + queryParamList.join("&");
    }

    let viewType = "quarterly";
    let dtObj = this.commonService.getDateRangObject(
      viewType,
      "",
      "YYYY-MM-dd"
    );

    let filtersApplied = false;
      for (let key in this.formcontrolValues) {
        if (this.formcontrolValues[key] && key !== 'fromDate' && key !== 'toDate') {
          filtersApplied = true;
          break;
        }
      }

    let today = new Date();
    let to_date =this.datePipe.transform(new Date(),"yyy-MM-dd");
    let from_date = this.datePipe.transform(new Date(today.getFullYear(), today.getMonth() - 1, today.getDate()), "yyy-MM-dd");
    let reqObj = {
      "part_name": this.formcontrolValues && this.formcontrolValues.part_name ? this.formcontrolValues.part_name : this.searchText,
      "is_tata_part": this.selectedBrand == "tgp",
      "from_date": filtersApplied ? null : (this.formcontrolValues && this.formcontrolValues.fromDate ? this.datePipe.transform(this.formcontrolValues.fromDate,"yyy-MM-dd") : from_date),
      "to_date": filtersApplied ? null : (this.formcontrolValues && this.formcontrolValues.toDate ? this.datePipe.transform(this.formcontrolValues.toDate,"yyy-MM-dd") : to_date),
      "guru_name": this.formcontrolValues && this.formcontrolValues.guru_name ? this.formcontrolValues.guru_name : "",
      "guru_phno": this.formcontrolValues && this.formcontrolValues.guru_phno ? this.formcontrolValues.guru_phno : "",
      "job_id": this.formcontrolValues && this.formcontrolValues.jobcard_id ? this.formcontrolValues.jobcard_id : "",
      "jobcard_created_date": this.formcontrolValues && this.formcontrolValues.jobcard_created_date ? this.datePipe.transform(this.formcontrolValues.jobcard_created_date, "yyyy-MM-dd") : "",
      "region": this.role === 'RPM'||this.role === 'DEALER' ? this.zone : (this.formcontrolValues && this.formcontrolValues.zone ? this.formcontrolValues.zone : ""),
      "case_type": this.formcontrolValues && this.formcontrolValues.case_type ? this.formcontrolValues.case_type : "",
      "part_number": this.formcontrolValues && this.formcontrolValues.part_number ? this.formcontrolValues.part_number : "",
      "lass_name": this.formcontrolValues && this.formcontrolValues.lass_name ? this.formcontrolValues.lass_name : "",
      "lass_contact": this.formcontrolValues && this.formcontrolValues.lass_number ? this.formcontrolValues.lass_number : "",
      "state": this.formcontrolValues && this.formcontrolValues.state ? this.formcontrolValues.state : "",
      "offset": this.pageIndex > 0 ? this.pageIndex * 10 : 0,
      "limit": 10
    };
    const url = `${apiUrl}`;
    //alert(url);
    const apiObj = {
      url: url,
      methodType: HttpMethod.POST,
      requestObj: reqObj,
    };

    this.commonService.commonApiCall(apiObj, (res) => {
      if ( res && res.success) {
        this.spinner.hide();
        this.recordCount = res.count;
        let ordersArr: any = [];
        res.data.forEach((item) => {
          let obj = {
            id: 1,
            lassName:`${item?.lass_name}`,
            lassNumber:`${item?.lass_number}`,
            guruId: `${item?.guru_id}`,
            garage: `${item?.garage_name}`,
            dealer_name: `${item?.dealer}`, 
            sso:`${item?.sso}`,
            guru: `${item?.guru_name}`,
            guruNumber:`${item?.guru_phone_number}`,
            state:`${item?.state_name }`,
            region:`${item?.zone }`,
            pincode:`${item?.pincode }`,
            jobID:`${item?.jobcard_id }`,
            jobOpeningDt:this.datePipe.transform(`${item?.jobcard_created_date }`,"dd/MM/YYYY"),
            jobOpeningTime:this.datePipe.transform(`${item?.jobcard_created_date }`,"h:mm a"),
            jobCategory:`${item?.case_type }`,
            partname: `${item?.part_number}`,
            partDescription:`${item?.name}`,
            partScanned:`${item.is_scanned?'Yes':'No'}`,
            quantity:`${item?.quantity}`,
            price:`${item?.price}`,
            total_part_amt:( (item?.price?item?.price:0) * (item?.quantity?item?.quantity:0 ) )
          };
          ordersArr.push(obj);
        });
       
        
        this.orders = ordersArr;
        

        
        
        this.isDataReady = true;
      } else {
        this.spinner.hide();
        this.isDataReady = true;
      }
    });
    }catch(error){
      this.spinner.hide();
    }
  }
  formControlValue(event){
    console.log(event);
    this.pageIndex = 0;
    this.formcontrolValues = event;
    this.getReportData(event);
  }
}

export interface PartFitment{
  Part_Name:string;
  Part_Number:string;
  Part_Price:string;
  Quantity:string;
  Total_Amount:string;
  Lass_Name:string;
  Lass_Number:string;
  Garage_Area:string;
  Guru_Name:string;
  Guru_Number:string;
  State:string;
  Region:string;
  Pincode:string;
  Job_card_Id:string;
  Job_Card_Opening_Date:string;
  Job_Card_Opening_Time:string;
  Job_Category:string;
  SSO:string;
  Dealer_Name:string;
  Part_Sacnned:string;
}