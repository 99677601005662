import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { Observable, throwError } from "rxjs";
import {
  startWith,
  map,
  debounceTime,
  distinctUntilChanged,
  filter,
  finalize,
  switchMap,
  tap,
  catchError,
} from "rxjs/operators";
import { HttpMethod } from "src/app/constants/http-handlers";
import { AppService } from "src/app/services/app.service";
import { CommonService } from "src/app/services/common.service";
declare let moment: any; //declare moment
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: "app-add-part-item",
  templateUrl: "./add-part-item.component.html",
  styleUrls: ["./add-part-item.component.scss"],
})
export class AddPartItemComponent implements OnInit {
  step: string = "promotional";
  partDetailForm: FormGroup;

  selected = null;
  addTrainingForm: FormGroup;
  categories: any;
  hashTagValue: any[] = [];
  roles: any[] = [];
  formData: FormData = new FormData();
  today = new Date();
  startDateValue: any = "";
  isSubmitted: boolean = false;
  minStartDateValue: any = new Date();
  showfinalSuccessMsg: string;
  showfinalErrorMsg: string;
  showToastermessage: boolean = false;
  partItemUpdate: boolean = false;

  isLoading: boolean = false;
  searchText: string = "";
  control = new FormControl("");
  streets: string[] = [];
  filteredStreets: Observable<string[]>;

  searchMoviesCtrl = new FormControl();
  filteredMovies: any;
  errorMsg!: string;
  minLengthTerm = 4;
  selectedMovie: any = "";
  datePipe: any;

  setItemType(type) {
    this.step = type;
  }
  constructor(
    private _formBuilder: FormBuilder,
    private appService: AppService,
    public dialog: MatDialog,
    private commonService: CommonService,
    private http: HttpClient,
    private spinner: NgxSpinnerService
  ) {
    this.partDetailForm = this._formBuilder.group({
      startDate: ["", Validators.required],
      endDate: ["", Validators.required],
      description: ["", [Validators.pattern(/^[a-zA-Z ]*$/)]],
    });
  }
  ngOnInit(): void {
    let mytoken = localStorage.getItem("access_token");
    const headers = { Authorization: "Bearer " + mytoken };

    this.searchMoviesCtrl.valueChanges
      .pipe(
        filter((res) => {
          return res !== null && res.length >= this.minLengthTerm;
        }),
        distinctUntilChanged(),
        debounceTime(1000),
        tap(() => {
          this.errorMsg = "";
          this.filteredMovies = [];
          this.isLoading = true;
        }),
        switchMap((value) =>
          this.http
            .post(this.appService.searchPart, { part_num: value }, { headers })
            .pipe(
              // this.http
              // .get("http://www.omdbapi.com/?apikey=" + API_KEY + "&s=" + value)
              //.pipe(

              finalize(() => {
                this.isLoading = false;
              })
            )
        )
      )
      // .subscribe((data: any) => {

      //   // if (data["results"] == undefined) {
      //   //   this.errorMsg = data["msg"];
      //   //   this.filteredMovies = [];
      //   // } else {
      //   //   this.errorMsg = "";
      //   //   this.filteredMovies = data["results"];
      //   // }
      //   // console.log(this.filteredMovies);
      // });
      .subscribe({
        next: (data: any) => {
          if (data["results"] == undefined) {
            this.errorMsg = data["msg"];
            this.filteredMovies = [];
          } else {
            this.errorMsg = "";
            debugger;
            if (data["results"].length > 0) {
              this.filteredMovies = data["results"];
            } else {
              this.filteredMovies = [
                { part_desc: "No Data Found", part_number: "" },
              ];
            }
          }
        },
        error: (error) => {
          this.errorMsg = error.error.msg;
          this.filteredMovies = [];
          this.selectedMovie = "";
        },
      });
  }
  getPartlData() {
    if (this.partDetailForm.invalid) {
      this.isSubmitted = true;
    } else {
      this.isSubmitted = false;
      let userObj = {
        part_name: this.selectedMovie.part_desc,
        part_description: this.selectedMovie.umrp,
        part_number: this.selectedMovie.part_number,
        image_url: this.selectedMovie.image,
        price: this.selectedMovie.umrp,
        points: +this.selectedMovie.part_points,
        product_dimensions: this.selectedMovie.product_dimensions,
        target: 0,
        part_type: this.step,
        // start_date: moment(this.partDetailForm.get("startDate").value).format(
        //   "YYYY-MM-DD"
        // ),
        // end_date: moment(this.partDetailForm.get("endDate").value).format(
        //   "YYYY-MM-DD"
        // ),
        start_date: this.datePipe.transform(this.partDetailForm.get("startDate").value, 'yyyy-MM-dd'),
        end_date: this.datePipe.transform(this.partDetailForm.get("endDate").value, 'yyyy-MM-dd'),
      };
      const url = this.appService.partAdded;
      const apiObj = {
        url: url,
        methodType: HttpMethod.POST,
        requestObj: userObj,
      };
      this.commonService.commonApiCall(apiObj, (res, error) => {

        this.partDetailForm.reset();
        this.selectedMovie = [];
        if (error != undefined) {
          this.showToastermessage = true;
          this.showfinalErrorMsg = error.error.msg;
          this.spinner.hide();
          setTimeout(() => {
            this.showfinalErrorMsg = "";
            this.showToastermessage = false;
          }, 3000);
        } else {
          this.showfinalSuccessMsg = res.msg;
          this.showToastermessage = true;
          this.spinner.hide();
          this.partItemUpdate = true;
          setTimeout(() => {
            this.showfinalSuccessMsg = "";
            this.showToastermessage = false;
          }, 3000);
        }
      });
    }
  }

  checkText(event: KeyboardEvent) {
    if (
      event.code == "Enter" ||
      (event.code == "Backspace" && this.searchText.trim() == "")
    ) {
      console.log("test");
    }
  }

  private _filter(value: string): string[] {
    const filterValue = this._normalizeValue(value);
    return this.streets.filter((street) =>
      this._normalizeValue(street).includes(filterValue)
    );
  }

  private _normalizeValue(value: string): string {
    return value.toLowerCase().replace(/\s/g, "");
  }
  onSelected() {
    this.selectedMovie = this.selectedMovie;
    console.log(this.selectedMovie);
  }

  displayWith(value: any) {
    return value?.Title;
  }
  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }
  clearSelection() {
    // this.selectedMovie = "";
    // this.filteredMovies = [];
  }
  keyPressNumbers(event) {
    var charCode = event.which ? event.which : event.keyCode;
    // Only Numbers 0-9
    if (charCode < 48 || charCode > 57) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }
  keyDateFormat(event) {
    var charCode = event.which ? event.which : event.keyCode;
    // Only Numbers 0-9
    if (charCode < 47 || charCode > 57) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }
}
