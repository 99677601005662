import { Component, Input, OnInit } from "@angular/core";
import { HttpMethod } from "../../constants/http-handlers";
import { AppService } from "src/app/services/app.service";
import { CommonService } from "src/app/services/common.service";

@Component({
  selector: "app-add-user",
  templateUrl: "./add-user.component.html",
  styleUrls: ["./add-user.component.scss"],
})
export class AddUserComponent implements OnInit {
  step: string;
  role: string;
  constructor(
    private commonService: CommonService,
    private appService: AppService
  ) {}

  
  
  ngOnInit(): void {
    this.step = "Lass";
    if (localStorage.getItem("userDetail")) {
      this.role = JSON.parse(localStorage.getItem("userDetail")).userdetails.role;
    }
  }
  addUserTab(role) {
    this.step = role;
    
  }
}
