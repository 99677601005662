import { Component, Input, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-add-user",
  templateUrl: "./reports.component.html",
  styleUrls: ["./reports.component.scss"],
})
export class ReportsComponent implements OnInit {
  step: string;
  constructor(private route: ActivatedRoute ) {}
  ngOnInit(): void {
    this.route.queryParams
      .subscribe(params => {
        if(params.type){
          this.step = params.type
        }else{
          this.step = "part-summary";
        } 
      });
  }
  changeTab(tabVal){
    this.step = tabVal;
  }
}
