import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild,TemplateRef, OnDestroy } from '@angular/core';
import { TableColumn } from "./TableColumn";
import { MatSort, Sort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { ModalDismissReasons, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { DashboardService } from 'src/app/services/dashboard.service';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'guru-table',
  templateUrl: './guru-table.component.html',
  styleUrls: ['./guru-table.component.scss']
})
export class GuruTableComponent implements OnInit, AfterViewInit, OnDestroy {

  public tableDataSource = new MatTableDataSource([]);
  public displayedColumns: string[];
  showNodata: boolean = true;
  @ViewChild(MatPaginator, { static: false }) matPaginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) matSort: MatSort;
  partSummary: any;
  claimSummary: any;
  fetchrate: any;
  selectedJobId: any;
  clickedJobId: any;
  @Input() componentName: string;
  @Input() isPageable = true;
  @Input() isSortable = false;
  @Input() isFilterable = false;
  @Input() secondHeader = false;
  @Input() tableColumns: TableColumn[];
  @Input() rowActionIcon: string;
  paginationSizes: number[] = [10];
  @Input() defaultPageSize = this.paginationSizes[0];
  sub: Subscription = new Subscription();
  @Output() sort: EventEmitter<Sort> = new EventEmitter();
  @Output() childPageChangeEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() rowAction: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('partSummaryPopup', { read: TemplateRef, static: false })
  partSummaryPopup!: TemplateRef<any>;
  partSummaryModal: any = '';
  @ViewChild('claimStatusPopup', { read: TemplateRef, static: false })
  claimStatusPopup!: TemplateRef<any>;
  claimStatusModal: any = '';
  role: string;
  @ViewChild('invoicePaymentListPopup', { read: TemplateRef, static: false })
  invoicePaymentListPopup!: TemplateRef<any>;
  invoicePaymentListModal: any = '';
  closeResult: string;
  // this property needs to have a setter, to dynamically get changes from parent component
  @Input() set tableData(data: any[]) {
    this.setTableDataSource(data);
  }
  @Input() recordCount: number = 0;
  @Input() currentPageIndex: number = 0;
isDataReady: boolean = false;
  EmitResult = {
    pageNumber: '',
    pageSize: ''
  };

  testPaginator = {
    length: 0,
    pageSize: 10,
    pageIndex: this.currentPageIndex
  };

  constructor(private modalService: NgbModal, private dashboard:DashboardService,private spinner: NgxSpinnerService,) {

  }
  pageChanged = ($event) => {
    this.EmitResult = {
      pageNumber: $event.pageIndex,
      pageSize: $event.pageSize
    };
    this.childPageChangeEvent.emit($event.pageIndex);
  }
  ngOnInit(): void {
    
    const columnNames = this.tableColumns.map((tableColumn: TableColumn) => tableColumn.name);
    if (this.rowActionIcon) {
      this.displayedColumns = [this.rowActionIcon, ...columnNames]
    } else {
      this.displayedColumns = columnNames;
    }
    if (localStorage.getItem("userDetail")) {
      this.role = JSON.parse(localStorage.getItem("userDetail")).userdetails.role;
    }
    
  }

  // we need this, in order to make pagination work with *ngIf
  ngAfterViewInit(): void {

    this.testPaginator = {
      length: this.recordCount,
      pageSize: 10,
      pageIndex: this.currentPageIndex
    };
    this.isDataReady = true;
    this.tableDataSource.paginator = this.matPaginator;
    this.tableDataSource.sort = this.matSort;
  }
  
  onClaimClick(jobID: any,  event: any){
    const ListInput: any = {} as any;
    ListInput.job_id = jobID;
    event.stopPropagation(); 
    this.spinner.show();
    let ngbModalOptions: NgbModalOptions = {
      backdrop: true,
      keyboard: true,
      size: "md"
    };
    try {
      this.sub.add(this.dashboard.fetchClaim(ListInput).subscribe(
        (response) => {
          this.spinner.hide();
          if (response.result) {
            this.claimSummary = response?.result;
            this.claimStatusModal = this.modalService.open(this.claimStatusPopup, ngbModalOptions);
            this.claimStatusModal.result.then((result) => {
              this.closeResult = `Closed with: ${result}`;
            }, (reason: any) => {
              this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            });
          } 
          else {
            
          }
        }
      ));
    } catch (error) {
      throw(error);
    }
  }

  onJobIdClick(jobID: any, event: any) {
    const ListInput: any = {} as any;
    ListInput.job_id = jobID;
    this.selectedJobId = jobID;
    event.stopPropagation(); 
    this.spinner.show();
    let ngbModalOptions: NgbModalOptions = {
      backdrop: true,
      keyboard: true,
      size: "lg"
    };
  
    try {
      this.sub.add(this.dashboard.jobDetails(ListInput).subscribe(
        (response) => {
          this.spinner.hide();
          if (response.success) {
            this.fetchRateList();
            this.partSummary = response?.data
            this.partSummaryModal = this.modalService.open(this.partSummaryPopup, ngbModalOptions);
            this.partSummaryModal.result.then((result) => {
              this.closeResult = `Closed with: ${result}`;
            }, (reason: any) => {
              this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            });
          } else {
            
          }
        }
      ));
    } catch (error) {
      throw(error);
    }
  }

  fetchRateList(){
    const ListInput1: any = {} as any;
    try {
      this.sub.add(this.dashboard.fetchRate(ListInput1).subscribe(
        (response) => {
          this.spinner.hide();
          if (response.success === true) {
            this.fetchrate = response.msg;
            this.setRateListName(this.partSummary?.rate_list);
          } else {       
            this.fetchrate = null;
          }
        }
      ));
    } catch (error) {
      throw(error);
    }
  }

  selectedRateListName: string | undefined;
  setRateListName(rateListId: string) {
  if (this.fetchrate && Array.isArray(this.fetchrate)) {
    const selectedRate = this.fetchrate.find(rate => rate.ROW_ID === rateListId);
    this.selectedRateListName = selectedRate ? selectedRate.NAME_s : undefined;
  } else {
    console.error("fetchrate is undefined or null");
  }
}

  onPaymentUpdate(jobID: any){
    const ListInput: any = {} as any;
    ListInput.job_id = jobID;
    this.clickedJobId = jobID;
    let ngbModalOptions: NgbModalOptions = {
      backdrop: true,
      keyboard: true,
      size: "lg"
    };
    this.invoicePaymentListModal = this.modalService.open(this.invoicePaymentListPopup, ngbModalOptions);
    this.invoicePaymentListModal.result.then((result) => {
    this.closeResult = `Closed with: ${result}`;
    }, (reason: any) => {
    this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    }); 
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  setTableDataSource(data: any) {
    this.testPaginator['length'] = this.recordCount;
    this.tableDataSource = new MatTableDataSource<any>(data);
    
    if(this.recordCount > 10){
      this.isPageable = true;
    }
    this.tableDataSource.paginator = this.matPaginator;
    this.tableDataSource.sort = this.matSort;
    if(this.tableDataSource.filteredData.length > 0){
      this.showNodata = false;
    }
 
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.tableDataSource.filter = filterValue.trim().toLowerCase();
  }

  sortTable(sortParameters: Sort) {
    //debugger;
    // defining name of data property, to sort by, instead of column name
    sortParameters.active = this.tableColumns.find(column => column.name === sortParameters.active).dataKey;
    this.sort.emit(sortParameters);
  }

  // emitRowAction(row: any) {
    emitRowAction(row: any) {
      this.rowAction.emit(row);
    }  

    ngOnDestroy() {
      this.modalService.dismissAll()
      this.sub.unsubscribe();
    }
}

