import { Component, EventEmitter, OnInit, Output, ViewChild, TemplateRef, OnDestroy } from '@angular/core';
import { DatePipe } from '@angular/common';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpMethod } from 'src/app/constants/http-handlers';
import { AppService } from 'src/app/services/app.service';
import { CommonService } from 'src/app/services/common.service';
import { ExcelService } from 'src/app/services/excel.service';
import { DoorStepBookingReport } from 'src/app/reports/door-step-booking-report/door-step-booking-report.component';
import { DoorStepBookingFilterComponent } from 'src/app/reports/door-step-booking-report/door-step-booking-filter/door-step-booking-filter.component';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ModalDismissReasons, NgbModal, NgbModalOptions, NgbDropdown, } from '@ng-bootstrap/ng-bootstrap';
import { DashboardService } from 'src/app/services/dashboard.service';

@Component({
  selector: 'app-leads',
  templateUrl: './leads.component.html',
  styleUrls: ['./leads.component.scss']
})
export class LeadsComponent implements OnInit, OnDestroy {
  dataSource = new MatTableDataSource<any>();
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild('childComponent', {static: false}) childComponent:DoorStepBookingFilterComponent;
  displayedColumns: string[] = [
    "optYId",
    "salesStage",
    "leadCreated",
    "createdDate",
    "updatedDate",
    "CustomerName",
    "CustomerMobile",
    "vehicleNumber",
    "lob",
    "ppl",
    "pl",
    "dealerName",
    // "dealerUserName",
    "division",
    "guruName",
    "GuruMobileNumber",
    "quantity",
    "state",
    "city",
    "district",
    "pincode",
];
  showNodata: boolean = false;
  isLoading:boolean = false;
  totalRows = 0;
  pageSize = 10;
  currentPage = 0;
  pgIndex: number = 0;
  showfinalSuccessMsg: string;
  showfinalErrorMsg: string;
  showToastermessage: boolean = false;
  formcontrolValues:any;
  @Output() formEventTrigger = new EventEmitter<any>();
  isOpenDropdown:boolean = false;
  doorStepFilterForm: FormGroup;
  doorstepfilter: any;
  filterObj: any;
  ppldata: any;
  lobData: any;
  states:any
  pldata: any;
  sub: Subscription = new Subscription();
  today = new Date();
  closeResult: any = ''
  optyDetailsModal: any = '';
  optyDetails: any;
  @ViewChild('optyDetailsPopup', { read: TemplateRef, static: false })
  optyDetailsPopup!: TemplateRef<any>;
  constructor( 
    private commonService:CommonService, 
    private appService:AppService,
    private datePipe:DatePipe,
    private excelService:ExcelService,
    private spinner: NgxSpinnerService,
    private fb:FormBuilder,
    private router:Router,
    private Dashboard:DashboardService,
    private modalService: NgbModal,
    ) { }
  ngOnInit(): void {
    this.buildFilterForm();
    this.doorStepbookinglist();
    this.optyplMaster();
  }
  buildFilterForm(){
    this.doorStepFilterForm = this.fb.group({
      MobileNumber:[''],
      GuruMobileNumber: [''],
      state: [''],
      ppl: [''],
      pl: [''],
      lob: [''],
      customerName:[''],
      delaerNumber:[''],
      zone:[''],
      stage_name: [''],
      GuruName: [''],
      fromDate: [''],
      toDate: [''],
    });
  }
  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }
  doorStepbookinglist(formVal?) {
    try{
     
      let apiUrl = this.appService.leadsList;
      const url = `${apiUrl}`;
      let date = new Date();
      let toDate = this.datePipe.transform(formVal?formVal.toDate:date, "yyyy-MM-dd");
      let fromDate = this.datePipe.transform(formVal?formVal.fromDate:date.setMonth(date.getMonth() - 3), "yyyy-MM-dd");
      let req = {
        "limit": this.pageSize,
        "offset": this.currentPage * this.pageSize,
        "state":formVal?formVal.state : '',
        "pincode":"",
        "cust_mobile_no":formVal?formVal.MobileNumber : '',
        "opty_id":formVal?formVal.opty_id : '',
        "lob":formVal?formVal.lob : '',
        "ppl":formVal?formVal.ppl : '',
        "pl":formVal?formVal.pl:'',
        "from_date":formVal?formVal.fromDate: '',
        "to_date":formVal?formVal.toDate: '',
        "stage_name": formVal?formVal.stage_name : '',
        "zone": formVal?formVal.zone : '',
        "guru_name": formVal?formVal.GuruName : "",
        "guru_mobile_number": formVal?formVal.GuruMobileNumber : "",
      }
      const apiObj = {
        requestObj:req,
        url: url,
        methodType: HttpMethod.POST,
      };
      this.spinner.show();
      this.commonService.commonApiCall(apiObj, (res) => {
        if (
          res &&
          res.hasOwnProperty("result") &&
          res.result
        ) {
          this.spinner.hide();
          if(res.success == true){
            console.log("res.result",res.result)
            this.totalRows = res.total_count
              let temarrOBj = []
            this.dataSource = new MatTableDataSource(res.result);
            this.dataSource.sort = this.sort;
            this.paginator.pageIndex = this.currentPage;
            this.isOpenDropdown = false;
            this.isLoading = false;
            this.showNodata = false;
          }else{
            this.showfinalErrorMsg = res.msg;
            this.showToastermessage = true;
            this.showNodata = true;
          }
        }else {
          this.showNodata = true;
          this.spinner.hide();
          this.showfinalErrorMsg = res.error.msg;
          this.isOpenDropdown = false;
          this.showToastermessage = true;
        }
      });
    }catch(error){
      this.spinner.hide();
      this.showfinalErrorMsg = error.error.msg;
      this.showToastermessage = true;
      this.showNodata = true;
    }
  }

  pageChanged(event) {
    this.currentPage = event.pageIndex;
    this.pageSize = event.pageSize;
    this.isLoading = true;
    this.doorStepbookinglist(this.doorStepFilterForm.value);
  }
  formControlValue(event){
    this.currentPage=0;
    this.formcontrolValues = event;
    this.doorStepbookinglist(event);
  }
  openDropdown(){
    this.isOpenDropdown = !this.isOpenDropdown;
  }
  closeDropdown(){
    this.isOpenDropdown = false;
  }
  reset(){
    this.doorStepFilterForm.reset();
    this.doorStepbookinglist();
  }
  isValidDiff() {
    let fromDate = new Date(this.doorStepFilterForm.controls['fromDate']?.value);
    let toDate = new Date(this.doorStepFilterForm.controls['toDate']?.value);
    let diff = Math.floor((Date.UTC(toDate.getFullYear(), toDate.getMonth(), toDate.getDate()) - Date.UTC(fromDate.getFullYear(), fromDate.getMonth(), fromDate.getDate())) / (1000 * 60 * 60 * 24))
    return (diff <= 95 && diff >= 0 ? true : false);
}

applyFilter() {
  console.log("form_value>>", this.doorStepFilterForm.value);
  this.isOpenDropdown = false;
  this.currentPage = 0;
  if (this.doorStepFilterForm.controls['fromDate'].value && this.doorStepFilterForm.controls['toDate'].value) {
    if (this.isValidDiff()) {
      this.doorStepbookinglist(this.doorStepFilterForm.value);
    } else {
      this.showToastermessage = true;
      this.showfinalErrorMsg = 'Please select a date range up to 95 days';
      setTimeout(() => {
        this.showfinalErrorMsg = "";
        this.showToastermessage = false;
      }, 3000);
    }
  } else {
    this.doorStepbookinglist(this.doorStepFilterForm.value);
  }
}



onRowClick(leadId: any): void {
  const ListInput: any = {} as any;
  ListInput.lead_id = leadId;
  
  try {
    
    this.spinner.show();
    this.sub.add(this.Dashboard.optyStatus(ListInput).subscribe(
      (response) => {
        this.spinner.hide();
        if (response.success) {
          let ngbModalOptions: NgbModalOptions = {
            backdrop: true,
            keyboard: true,
            size:"xl"
          };
        this.optyDetails = response?.result
        this.optyDetailsModal = this.modalService.open(this.optyDetailsPopup, ngbModalOptions);
        this.optyDetailsModal.result.then((result) => {
          this.closeResult = `Closed with: ${result}`;
        }, (reason: any) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
      } 
      }
    ));
  } catch (error) {
    this.spinner.hide();
    throw(error);
  }
}

private getDismissReason(reason: any): string {
  if (reason === ModalDismissReasons.ESC) {
    return 'by pressing ESC';
  } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
    return 'by clicking on a backdrop';
  } else {
    return `with: ${reason}`;
  }
}

optyplMaster() {
  try {
    let data = {};
    this.sub.add(this.Dashboard.optyplMaster(data).subscribe({
      next: (res: any) => {
        if (res && res.success === true) {
          this.lobData = res.result.lob;
          this.ppldata = res.result.ppl;
          this.pldata = res.result.pl;
          this.states= res.result.state
        }else{} 
      },
      error: (error) => {
        console.log(error);
      }
    }));
  } catch (error) {
    console.log(error);
  }
}
ngOnDestroy() {
  this.modalService.dismissAll()
  this.sub.unsubscribe();
}
}
