<div class="guru-list-top-container">
  <h2>gurus <small class="changes-subhead">(Current month)</small></h2>
  <div class="guru-list-container comn-box">
    <div class="guru-list-text-wrap">
      <div class="guru-list-half-container">
        <app-gurus-status></app-gurus-status>
      </div>
      <div class="guru-list-half-container">
        <app-top-transacting-gurus></app-top-transacting-gurus>
      </div>
    </div>
  </div>
</div>
