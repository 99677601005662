import { Component, OnInit, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-claim-status-details',
  templateUrl: './claim-status-details.component.html',
  styleUrls: ['./claim-status-details.component.scss']
})
export class ClaimStatusDetailsComponent implements OnInit {

  @Input() claimData: any
  constructor(private modalService: NgbModal) { }

  ngOnInit(): void {
  }
  close(){
    this.modalService.dismissAll();
  }
}
