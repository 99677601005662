import { Component, OnInit } from "@angular/core";
import { HttpMethod } from "src/app/constants/http-handlers";
import { AppService } from "src/app/services/app.service";
import { CommonService } from "src/app/services/common.service";

@Component({
  selector: "app-line-items",
  templateUrl: "./line-items.component.html",
  styleUrls: ["./line-items.component.scss"],
})
export class LineItemsComponent implements OnInit {
  promotionalItems: any[] = [];

  constructor(
    private commonService: CommonService,
    private appService: AppService
  ) {}

  ngOnInit(): void {
    const url = `${this.appService.dashBoardPartPromotionItems}`;
    const apiObj = {
      url: url,
      methodType: HttpMethod.GET,
    };
    this.commonService.commonApiCall(apiObj, (res) => {
      if (res && res.hasOwnProperty("results") && res.results) {
        for (var i = 0; i < res.results.length; i++) {
          this.promotionalItems.push(res.results[i]);
        }
      }
    });
  }
}
