import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-guru-referrel',
  templateUrl: './guru-referrel.component.html',
  styleUrls: ['./guru-referrel.component.scss']
})
export class GuruReferrelComponent implements OnInit {

  @Input() dataSource:any;
  displayedColumns: string[] = ["id","mechanic_name","garage_area","source","referrel_by_id","guru_name","guru_username","guru_state","guru_region","laas_name","laas_number","lass_email"];
  constructor() { }

  ngOnInit(): void {
  }

}
