<div class="tb-table-wrapper">
    <div class="guru-tabs-row d-flex" style="justify-content: end;">
        <div class="guru-tabs-items">
            <div class="tb-btn-group">
                <ul>
                    <li *ngIf="!isAddUser">
                        <!-- <app-user-filter (formEventTrigger)="formControlValue($event)"></app-user-filter> -->
                    </li>
                    <!-- <li>
                        <button mat-stroked-button class="primary-stroke-button exportBtn" (click)="downloadGuruListExcel()">
                            Export Excel
                        </button>
                    </li> -->
                    <li *ngIf="!isAddUser">
                        <button mat-stroked-button class="primary-stroke-button exportBtn">
                            Refresh
                        </button>
                    </li>
                    <li>
                        <div class="addUser-btn">
                            <button mat-flat-button matStepperNext class="primary-flat-button" 
                            (click)="isAddUser = !isAddUser">
                                 {{isAddUser ? 'Back' : 'Add Dealer'}}
                            </button>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>


<div class="form-wrap comn-box add-item-content" *ngIf="isAddUser">
    <!-- <form [formGroup]="dealerForm" (ngSubmit)="addUserFormData()" autocomplete="off">
        <div class="form-section">
            <div class="form-row">
                <div class="form-col">
                    <label for="contractor" class="form-label">Contractor <span class="requiredfield">*</span></label>
                    <mat-radio-group aria-label="Select an option" formControlName="is_contractor">
                        <mat-radio-button value="1">Yes</mat-radio-button>
                        &nbsp;
                        <mat-radio-button value="2"  [checked]="true">No</mat-radio-button>
                      </mat-radio-group>
                </div>
                <div class="form-col">
                    <label for="active" class="form-label">Active <span class="requiredfield">*</span></label>
                    <mat-slide-toggle formControlName="is_active"></mat-slide-toggle>
                  </div>
            </div>
            <div class="form-row">
                <div class="form-col">
                    <label for="firstName" class="form-label">First Name <span class="requiredfield">*</span></label>
                    <input type="text" class="form-control" id="firstName" formControlName="firstName" maxlength="30"
                        placeholder="Enter First Name" appOnlyAlphabet />
                    <div *ngIf="submitted && this.dealerForm.controls.firstName.errors" class="invalid-field">
                        <span *ngIf="this.dealerForm.controls.firstName.errors.required">First Name is required</span>
                        <span *ngIf="this.dealerForm.controls.firstName.errors.pattern">Please enter valid First
                            Name</span>
                        <span *ngIf="
                  !this.dealerForm.controls.firstName.errors.required &&
                  this.dealerForm.controls.firstName.errors.whitespace
                ">Please enter valid data</span>
                    </div>
                </div>
                <div class="form-col">
                    <label for="lastName" class="form-label">Last Name <span class="requiredfield">*</span></label>
                    <input type="text" class="form-control" id="lastName" formControlName="lastName" maxlength="30"
                        placeholder="Enter Last Name" appOnlyAlphabet />
                    <div *ngIf="submitted && this.dealerForm.controls.lastName.errors" class="invalid-field">
                        <span *ngIf="this.dealerForm.controls.lastName.errors.required">Last Name is required</span>
                        <span *ngIf="this.dealerForm.controls.lastName.errors.pattern">Please enter valid Last
                            Name</span>
                        <span *ngIf="
                  !this.dealerForm.controls.lastName.errors.required &&
                  this.dealerForm.controls.lastName.errors.whitespace
                ">Please enter valid data</span>
                    </div>
                </div>
            </div>
            <div class="form-row">
                <div class="form-col">
                    <label for="mobileNum" class="form-label">Mobile Number <span class="requiredfield">*</span></label>
                    <input type="text" class="form-control" id="mobileNum" (keypress)="keyPressNumbers($event)"
                        placeholder="Enter Mobile Number" formControlName="mobileNum" maxlength="10" />
                    <div *ngIf="submitted && this.dealerForm.controls.mobileNum.errors" class="invalid-field">
                        <span *ngIf="this.dealerForm.controls.mobileNum.errors.required">Mobile Number is
                            required</span>
                        <span *ngIf="
                  this.dealerForm.controls.mobileNum.errors.pattern ||
                  this.dealerForm.controls.mobileNum.errors.min
                ">Please enter valid Mobile Number</span>
                    </div>
                </div>
                <div class="form-col">
                    <label for="username" class="form-label">Username <span class="requiredfield">*</span></label>
                    <input type="text" class="form-control" id="username" formControlName="username"
                        (keypress)="removeSpace()" placeholder="Enter User Name" />
                    <div *ngIf="submitted && this.dealerForm.controls.username.errors" class="invalid-field">
                        <span *ngIf="this.dealerForm.controls.username.errors.required">User Name is required</span>
                        <span *ngIf="this.dealerForm.controls.username.errors.pattern">Please enter valid User
                            Name</span>
                        <span *ngIf="
                  !this.dealerForm.controls.username.errors.required &&
                  this.dealerForm.controls.username.errors.whitespace
                ">Please enter valid data</span>
                    </div>
                </div>
            </div>
            <div class="form-row">
                <div class="form-col">
                    <label for="state" class="form-label">State<span class="requiredfield">*</span></label>
                    <mat-form-field>
                        <mat-label label for="state" class="form-label"> Select State <span class="requiredfield">*</span></mat-label>
                        <mat-select formControlName="state" >
                            <mat-option [value]="state" *ngFor="let state of states ">{{ state }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <div *ngIf="submitted && dealerForm.get('state').invalid" class="invalid-field">
                        <span>Please select a State</span>
                    </div>
                    <div *ngIf="submitted && dealerForm.get('state').invalid && dealerForm.get('state').touched" class="invalid-field">
                        <span>Please select a State</span>
                    </div>
                </div>
                <div class="form-col">
                    <label for="region" class="form-label">Region<span class="requiredfield">*</span></label>
                    <mat-form-field>
                        <mat-label label for="state" class="form-label"> Select region <span class="requiredfield">*</span></mat-label>
                        <mat-select formControlName="region" >
                            <mat-option [value]="region" *ngFor="let region of regions ">{{ region }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <div *ngIf="submitted && dealerForm.get('region').invalid && dealerForm.get('region').touched" class="invalid-field">
                        <span>Please select a Region</span>
                    </div>
                    <div *ngIf="submitted && dealerForm.get('region').invalid" class="invalid-field">
                        <span>Please select a Region</span>
                    </div>
                </div>
            </div>
            

            <div class="form-row">
<div class="form-col">
    <label for="email" class="form-label">Email Id </label>
    <input type="text" class="form-control" id="email" formControlName="email" (keypress)="removeSpace()" />
    <div *ngIf="submitted && this.dealerForm.controls.email.errors" class="invalid-field">
       
    </div>
</div>

                
            </div>
        </div>
        <div class="addUser-btn">
            <button mat-flat-button matStepperNext class="primary-flat-button" type="submit">
                Add 
            </button>
        </div>
    </form> -->
</div>

<div class="action-toaster" *ngIf="showToastermessage">
    <div class="toaster-msg alert alert-success" *ngIf="showfinalSuccessMsg">
        {{ showfinalSuccessMsg }}
    </div>
    <div class="toaster-msg alert alert-danger" *ngIf="showfinalErrorMsg">
        {{ showfinalErrorMsg }}
    </div>
</div>