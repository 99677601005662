import { AfterViewInit, Component, OnDestroy, ViewChild } from "@angular/core";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatTable, MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";
import { HttpMethod } from "../../constants/http-handlers";
import { MSRListTableItem } from "src/app/models/apm";
import { AppService } from "src/app/services/app.service";
import { CommonService } from "src/app/services/common.service";
import { Router } from "@angular/router";
import { DatePipe } from "@angular/common";
import { NgxSpinnerService } from "ngx-spinner";
import { MatDialog } from "@angular/material/dialog";
import { ConfirmDialogComponent } from "../guru-detail/confirm-dialog/confirm-dialog.component";
import { ExcelService } from "src/app/services/excel.service";
import { GurulistFilterComponent } from "./gurulist-filter/gurulist-filter.component";
import { DashboardService } from "src/app/services/dashboard.service";
import Swal from "sweetalert2";
import { Subscription } from "rxjs";
import { EditGuruTypeDialogComponent } from "../edit-guru-type-dialog/edit-guru-type-dialog.component";

@Component({
  selector: "app-guru-list-table",
  templateUrl: "./guru-list-table.component.html",
  styleUrls: ["./guru-list-table.component.scss"],
  providers: [DatePipe],
})
export class GuruListTableComponent implements AfterViewInit, OnDestroy {
  step: string = "";
  showNodata: boolean = false;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatTable) table!: MatTable<MSRListTableItem>;
  dataSource = new MatTableDataSource<MSRListTableItem>();
  sub: Subscription = new Subscription();
  isLoading = false;
  totalRows = 0;
  pageSize = 10;
  currentPage = 0;
  pgIndex: number = 0;
  filterChipList: any[] = [];
  filterObject: any = [];
  showfinalSuccessMsg: string;
  showfinalErrorMsg: string;
  showToastermessage: boolean = false;

  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = [
    // "id",
    "account_id",
    "guru_name",
    "registration_date",
    "dealer_name",
    "garage_name",
    "garage_area",
    "garage_id",
    "ratings",
    
    "guru_phone_number",
    "jobcard_count",
    "part_fitted",
    "rejected_on",
    "rejection_reason",
    "associated_since",
    // "is_completed",
    "source",
    "dealer",
    "sso",
    "zone",
    "state_name",
    "district",
    "pincode",
    "membership",
    // "is_accepted",
    // "is_personal_details",
    // "id_number",
    // "profile_image",
    "lass_name",
    "lass_number",
    "lass_email",
    "msr_id",
    "msr_name",
    "msr_mobile_num",
    // "guru_type",
    // "star_guru_status",
  ];
  guruList: any = [];
  globalSearchText: string;
  role: string;
  formcontrolValues:any;
  @ViewChild('childComponent', {static: false}) childComponent:GurulistFilterComponent;
  constructor(
    private commonService: CommonService,
    private appService: AppService,
    private excelService:ExcelService,
    private router: Router,
    private datePipe: DatePipe,
    private spinner: NgxSpinnerService,
    private dialog: MatDialog,
    private dashboard:DashboardService,
  ) {
    if (this.router.isActive) {
      this.router.routeReuseStrategy.shouldReuseRoute = function () {
        return false;
      };
      this.router.onSameUrlNavigation = "reload";
    }
    if (localStorage.getItem("userDetail")) {
      this.role = JSON.parse(localStorage.getItem("userDetail")).userdetails.role;
    }
    this.displayedColumns = this.role === 'RPM' || this.role === 'SuperAdmin' 
  ? [...this.displayedColumns, 'guru_type'] 
  : this.displayedColumns;
  }

  ngOnInit(): void {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;

    //debugger;
    this.commonService.globalSearchMessage.subscribe(
      (message) => (this.globalSearchText = message)
    );
    this.commonService.globalguruListStatus.subscribe(
      (message) => (this.step = message)
    );
    this.getGurulist();
  }
  openEditDialog(row: any): void {
    const dialogRef = this.dialog.open(EditGuruTypeDialogComponent, {
      width: '350px',
      height: '310px', // specify the desired height here
      data: { guru_type: row.guru_type,account_id:row.account_id }
    });
    console.log(row.guru_type);
    dialogRef.componentInstance.guruTypeSelected.subscribe((selectedType: string) => {
      // Call updateGuruType with the selected guru type
      this.updateGuruType(row, selectedType);
      this.getGurulist();
    });
  }
  
  

  updateGuruType(row: any, newGuruType: string): void {
    const ListInput = [{
      "id": row.id,
      "account_type": newGuruType
    }];

    try {
      this.spinner.show();
      this.sub.add(this.dashboard.starGuruUpdate(ListInput).subscribe({
        next: (response) => {
          this.spinner.hide();
          if (response.success) {
            row.guru_type = newGuruType;
            Swal.fire({
              icon: 'success',
              text: 'The Guru Type has been updated.',
              timer: 2000,
              showConfirmButton: false
            });
          } else {
            this.showToastermessage = true;
            this.showfinalSuccessMsg = "";
            this.showfinalErrorMsg = "Error. Please try again after some time";
            setTimeout(() => {
              this.showToastermessage = false;
            }, 2000);
            
          }
        },
        error: (err) => {
          throw(err);
        }
      }));
    } catch (error) {
      throw(error);
    }
  }










  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }

  // getGurulist(formVal?) {
  //   try{
  //     let apiUrl = this.appService.guruList;
  //     const url = `${apiUrl}`;
  //     let date = new Date();
  //     let toDate = this.datePipe.transform(formVal?formVal.toDate:date, "yyyy-MM-dd");
  //     let fromDate = this.datePipe.transform(formVal?formVal.fromDate:date.setMonth(date.getMonth() - 3), "yyyy-MM-dd");
  //     let req = {
  //       "to_date":toDate?toDate:this.datePipe.transform(date,"yyyy-MM-dd"),
  //       "from_date":fromDate?fromDate:this.datePipe.transform(date.setMonth(date.getMonth() - 3),"yyyy-MM-dd"),
  //       "lass_name":this.formcontrolValues?this.formcontrolValues.lassnme:"",
  //       "lass_number":this.formcontrolValues?this.formcontrolValues.lassContactNo:"",
  //       "username": this.formcontrolValues?this.formcontrolValues.username:"",
  //       "status": this.step?this.step:"",
  //       "contact_number": this.formcontrolValues?this.formcontrolValues.contact_number:"",
  //       "region": this.formcontrolValues?this.formcontrolValues.zone:"",
  //       // "registration_date": "2023-01-02",
  //       "state": this.formcontrolValues?this.formcontrolValues.state:"",
  //       "sso": this.formcontrolValues?this.formcontrolValues.sso:"",
  //       "dealer": this.formcontrolValues?this.formcontrolValues.dealer:"",
  //       "offset": this.currentPage == 0 ?0:(this.currentPage * 10),
  //       "limit": 10,
  //     }
  //     const apiObj = {
  //       requestObj:req,
  //       url: url,
  //       methodType: HttpMethod.POST,
  //     };
  //     this.spinner.show();
  //     this.commonService.commonApiCall(apiObj, (res) => {
  //       if ( res && res.hasOwnProperty("data") &&
  //       res.data && res.data.length
  //       ) {
  //         this.spinner.hide();
  //         if(res.success == true){
  //           this.dataSource = new MatTableDataSource(res.data);
  //           this.dataSource.sort = this.sort;
  //           this.paginator.pageIndex = this.currentPage;
  //           this.paginator.length = res.count;
  //           // this.childComponent.closeDropdown();
  //           this.isLoading = false;
  //           this.showNodata = false;
  //         }else{
  //           this.showfinalErrorMsg = res.msg;
  //           this.showToastermessage = true;
  //           this.showNodata = true;
  //         }
  //       }else {
  //         this.showNodata = true;
  //         this.spinner.hide();
  //         this.showfinalErrorMsg = res.error.msg;
  //         this.childComponent.closeDropdown();
  //         this.showToastermessage = true;
  //       }
  //     });
  //   }catch(error){
  //     this.spinner.hide();
  //     this.showfinalErrorMsg = error.error.msg;
  //     this.showToastermessage = true;
  //     this.showNodata = true;
  //     console.log(error);
  //   }
  // }
  getGurulist(formVal?) {
    try {
      const apiUrl = this.appService.guruList;
      const url = `${apiUrl}`;
      let date = new Date();
      let toDate = this.datePipe.transform(formVal ? formVal.toDate : date, "yyyy-MM-dd");
      let fromDate = this.datePipe.transform(formVal ? formVal.fromDate : date.setMonth(date.getMonth() - 3), "yyyy-MM-dd");
  
      let req = {
        "to_date": toDate,
        "from_date": fromDate,
        "lass_name": this.formcontrolValues ? this.formcontrolValues.lassnme : "",
        "lass_number": this.formcontrolValues ? this.formcontrolValues.lassContactNo : "",
        "username": this.formcontrolValues ? this.formcontrolValues.username : "",
        "status": this.step ? this.step : "",
        "contact_number": this.formcontrolValues ? this.formcontrolValues.contact_number : "",
        "region": this.formcontrolValues ? this.formcontrolValues.zone : "",
        "state": this.formcontrolValues ? this.formcontrolValues.state : "",
        "sso": this.formcontrolValues ? this.formcontrolValues.sso : "",
        "dealer": this.formcontrolValues ? this.formcontrolValues.dealer : "",
        "offset": this.currentPage === 0 ? 0 : (this.currentPage * 10),
        "limit": 10
      };
  
      const apiObj = {
        requestObj: req,
        url: url,
        methodType: HttpMethod.POST
      };
  
      this.spinner.show();
      this.commonService.commonApiCall(apiObj, (res) => {
        if (res && res.hasOwnProperty("data") && res.data && res.data.length) {
          this.spinner.hide();
          if (res.success === true) {
            this.dataSource = new MatTableDataSource(res.data);
            this.dataSource.sort = this.sort;
            this.paginator.pageIndex = this.currentPage;
            this.paginator.length = res.count;
            this.isLoading = false;
            this.showNodata = false;
          } else {
            this.showfinalErrorMsg = res.msg;
            this.showToastermessage = true;
            this.showNodata = true;
          }
        } else {
          this.showNodata = true;
          this.spinner.hide();
          this.showfinalErrorMsg = res.error.msg;
          this.childComponent.closeDropdown();
          this.showToastermessage = true;
        }
      });
    } catch (error) {
      this.spinner.hide();
      this.showfinalErrorMsg = error.error.msg;
      this.showToastermessage = true;
      this.showNodata = true;
      console.error(error);
    }
  }
  
  async downloadGuruListExcel(){
    try{
      this.spinner.show();
      let apiUrl =  this.appService.guruList;
      let date = new Date();
      let toDate = this.datePipe.transform(this.formcontrolValues?this.formcontrolValues.toDate:date, "yyyy-MM-dd");
      let fromDate = this.datePipe.transform(this.formcontrolValues?this.formcontrolValues.fromDate:date.setMonth(date.getMonth() - 3), "yyyy-MM-dd");
      let req = {
        "to_date":toDate?toDate:this.datePipe.transform(date,"yyyy-MM-dd"),
        "from_date":fromDate?fromDate:this.datePipe.transform(date.setMonth(date.getMonth() - 3),"yyyy-MM-dd"),
        "lass_name":this.formcontrolValues?this.formcontrolValues.lassnme:"",
        "lass_number":this.formcontrolValues?this.formcontrolValues.lassContactNo:"",
        "username": this.formcontrolValues?this.formcontrolValues.username:"",
        "status": this.step?this.step:"",
        "contact_number": this.formcontrolValues?this.formcontrolValues.contact_number:"",
        "region": this.formcontrolValues?this.formcontrolValues.zone:"",
        // "registration_date": "2023-01-02",
        "state": this.formcontrolValues?this.formcontrolValues.state:"",
        "sso": this.formcontrolValues?this.formcontrolValues.sso:"",
        "dealer": this.formcontrolValues?this.formcontrolValues.dealer:"",
        "offset": 0,
        "limit": 250,
      }
      let totalResult=0;
      let data:any[] = [];
      let res = await this.commonService.postAsyncRequest(apiUrl,req);
      if(res){
        totalResult = res.count;
        data = res.data;
      }
      let rou = Math.floor(totalResult/250);
      rou = (totalResult%250>0) && (rou > 0)?rou+1:rou; 
      if(rou > 1 ){
        for(var i=1;i<rou;i++){
          req.offset = (i * 250);
          res = await this.commonService.postAsyncRequest(apiUrl,req);
          if(res  && res.hasOwnProperty("data") && res.data && res.data.length ){
            data = [...data, ...res.data];
          }
        }
      }
      let finalData:GuruList[] = [];
      for (let row of data) {
        const ListInput: GuruList = {} as GuruList;
        ListInput.Id =   row.id
        ListInput.Guru_Name = row.guru_name
        ListInput.Registration_Date = row.registration_date?this.datePipe.transform(row.registration_date, "dd-MM-yyyy"):"NA"
        ListInput.Garage_Name = row.garage_name?row.garage_name:"NA"
        ListInput.Garage_Area =   row.garage_area?row.garage_area:"NA"
        ListInput.Garage_Id = row.garage_id?row.garage_id:"NA"
        ListInput.Ratings =  row.ratings?row.ratings:"NA"
        ListInput.Guru_Phone_Number = row.guru_phone_number?row.guru_phone_number:"NA"
        ListInput.Total_Jobcards = row.jobcard_count?row.jobcard_count:"NA"
        ListInput.Total_Part_Fitted = row.part_fitted?row.part_fitted:"NA"
        ListInput.Rejected_On = row.rejected_on?this.datePipe.transform(row.rejected_on, "dd-MM-yyyy"):"NA"
        ListInput.Rejection_Reason = row.rejection_reason?row.rejection_reason:"NA"
        ListInput.Associated_Since = row.associated_since?this.datePipe.transform(row.associated_since, "dd-MM-yyyy"):"NA"
        ListInput.Source = row.source?row.source:"NA"
        ListInput.Dealer = row.dealer ?row.dealer:"NA"
        ListInput.Sso= row.sso?row.sso:"NA"
        ListInput.Zone=row.zone?row.zone:"NA"
        ListInput.State_Name = row.state_name?row.state_name:"NA"
        ListInput.District = row.district?row.district:"NA"
        ListInput.Pincode = row.pincode?row.pincode:"NA"
        ListInput.Membership = row.membership?row.membership:"NA"
        ListInput.Lass_Name = row.lass_details && row.lass_details.lass_name?row.lass_details.lass_name:"NA"
        ListInput.Lass_Number = row.lass_details && row.lass_details.lass_number?row.lass_details.lass_number:"NA"
        ListInput.Lass_Email = row.lass_details && row.lass_details.lass_email?row.lass_details.lass_email:"NA"
        ListInput.Msr_Id= row.msr_details && row.msr_details.msr_id?row.msr_details.msr_id:"NA"
        ListInput.Msr_Name=row.msr_details && row.msr_details.msr_name?row.msr_details.msr_name:"NA"
        ListInput.Msr_Mobile_Number=row.msr_details && row.msr_details.msr_mobile_num?row.msr_details.msr_mobile_num:"NA"
        finalData.push(ListInput);
      }
      if(finalData.length > 0 ){
        this.excelService.exportCancellationAsExcelFile(finalData, 'guru_list_report');
      }else{
        this.showToastermessage = true;
        this.showfinalErrorMsg =
          "No Data For Download";
        setTimeout(() => {
          this.showfinalErrorMsg = "";
          this.showToastermessage = false;
        }, 3000);
      }
      console.log(finalData);
    }catch(error){
      this.showToastermessage = true;
      this.showfinalSuccessMsg =
        "No Data For Download";
      setTimeout(() => {
        this.showfinalSuccessMsg = "";
        this.showToastermessage = false;
      }, 3000);
    }
    this.spinner.hide();
  }
  pageChanged(event) {
    this.pgIndex = event.pageIndex?event.pageIndex:0;
    this.pgIndex = this.pgIndex + 1;
    this.currentPage = event.pageIndex?event.pageIndex:0;
    this.isLoading = true;
    this.getGurulist();
  }
  formControlValue(event){
    this.currentPage=0;
    this.formcontrolValues = event;
    this.getGurulist(event);
  }


  setGuruListTab(status) {
    this.step = status;
    this.commonService.changeGurustep(status.trim());
    //localStorage.setItem("step", status);
    //reset page index
    this.pgIndex = 1;
    this.currentPage = 0;
    this.isLoading = true;
    this.getGurulist();
  }
  actionAPMBtn(action) {
    var checkedValue = [];
    const inputElements = document.getElementsByClassName(
      "messageCheckbox"
      ) as unknown as HTMLInputElement;
    for (var i = 0; inputElements[i]; ++i) {
      //debugger;
      if (inputElements[i].checked) {
        checkedValue.push(inputElements[i].id);
      }
    }
    let userObj;
    let url = "";
    if (action === "Approve") {
      userObj = {
        guru_id: checkedValue,
      };
      url = this.appService.pendingGuruApprove;
    } else {
      userObj = {
        guru_id: checkedValue,
        reason: "Rejected",
      };
      url = this.appService.pendingGuruReject;
    }
    const apiObj = {
      url: url,
      methodType: HttpMethod.POST,
      requestObj: userObj,
    };
    this.commonService.commonApiCall(apiObj, (res, error) => {
      if (error != undefined) {
        this.showToastermessage = true;
        this.showfinalSuccessMsg = "";
        this.showfinalErrorMsg = error.error.msg;
        setTimeout(() => {
          this.showToastermessage = false;
        }, 4000);
      } else {
        this.showfinalErrorMsg = "";
        this.showfinalSuccessMsg = res.msg;
        this.showToastermessage = true;
        setTimeout(() => {
          this.showToastermessage = false;
        }, 4000);
      }

      this.getGurulist();
    });
  }

  rejectProfile(action) {
    var checkedValue = [];
    const inputElements = document.getElementsByClassName(
      "messageCheckbox"
    ) as unknown as HTMLInputElement;
    for (var i = 0; inputElements[i]; ++i) {
      //debugger;
      if (inputElements[i].checked) {
        checkedValue.push(inputElements[i].id);
      }
    }
    if(checkedValue.length > 0){
      let dialogRef = this.dialog.open(ConfirmDialogComponent, {
        width: "607px",
        height: "398px",
        disableClose: true,
      });
      dialogRef.componentInstance.gurucheckedValue = checkedValue;
      dialogRef.componentInstance.action = action;
      dialogRef.componentInstance.guruFlagSource = "list";
    }else{
      this.showToastermessage = true;
        this.showfinalSuccessMsg = "";
        this.showfinalErrorMsg = "No guru is selected to be rejected, please select at least one guru";
        setTimeout(() => {
          this.showToastermessage = false;
        }, 3000);
    }
  }

  updateGuru(id) {
    this.router.navigate(["update-guru/" + id]);
  }
  acceptGuru(id) {
    let userObj = {
      added_by: JSON.parse(localStorage.getItem("loginUser")).id,
    };
    // Subscribeconsole.log(userObj);
    const url = `${this.appService.acceptGuru + id}/`;
    const apiObj = {
      url: url,
      methodType: HttpMethod.POST,
      requestObj: userObj,
    };
    this.commonService.commonApiCall(apiObj, (res, error) => {
      this.getGurulist();
    });
  }

  toggleStarGuruStatus(isChecked: boolean, row){
    const ListInput = [{
      "id": row.id,
      "is_star_guru": isChecked ? 'True' : 'False'
    }];
  
    try {
      this.spinner.show();
      this.sub.add(this.dashboard.starGuruUpdate(ListInput).subscribe({
        next: (response) => {
          this.spinner.hide();
          if (response.success) {
            row.is_star_guru = isChecked ? 'True' : 'False';
            Swal.fire({
              icon: 'success',
              text: 'The Star Guru status has been updated.',
              timer: 2000, 
              showConfirmButton: false
            });
          } else {
            this.showToastermessage = true;
            this.showfinalSuccessMsg = "";
            this.showfinalErrorMsg = "Error. Please try again after some time";
            setTimeout(() => {
            this.showToastermessage = false;
          }, 2000);
          }
        },
        error: (err) => {
          throw(err)
        }
      }))
    }
    catch(error){
      throw(error);
    }
  }
  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
export interface GuruList{
  Id:any
  Guru_Name:any
  Registration_Date:any
  Garage_Name:any
  Garage_Area:any
  Garage_Id:any
  Ratings:any
  Guru_Phone_Number:any
  Total_Part_Fitted:any
  Total_Jobcards:any
  Rejected_On:any
  Rejection_Reason:any
  Associated_Since:any
  Source:any
  Dealer:any
  Sso:any
  Zone:any
  State_Name:any
  District:any
  Pincode:any
  Membership:any
  Lass_Name:any
  Lass_Number:any
  Lass_Email:any
  Msr_Id:any
  Msr_Name:any
  Msr_Mobile_Number:any
}