<div class="item-container">
  <h2>NEWLY LAUNCHED ITEMS</h2>
  <mat-grid-list cols="4" gutterSize="32px" rowHeight="385" class="">
    <mat-grid-tile class="item-box new-flag-item" *ngFor="let item of newItems">
      <div class="card-item-inner-box">
        <div class="item-display">
          <div class="item-image">
            <img [src]="item.image_url" alt="Photo" />
          </div>
          <div class="item-text">
            <h2 class="item-heading">{{ item.part_name }}</h2>
            <p class="item-size">{{ item.part_number }}</p>
            <p class="item-size">{{ item.part_description }}</p>
          </div>
        </div>
        <div class="item-footer">
          <div class="item-footer-text">
            <span>MRP: Rs {{ item.price }}</span>
            <span>Points: {{ item.points }}</span>
          </div>
          <div class="item-footer-text item-bg-color">
            <span class="text-red-color">Sales: {{ item.sales }}</span>
            <!-- <span class="text-grey-color">Target: {{ item.target }}</span> -->
          </div>
        </div>
      </div>
    </mat-grid-tile>
  </mat-grid-list>
</div>