import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AppService } from "../services/app.service";

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  constructor(private http: HttpClient, private router: Router, private appService: AppService) {
}
  postHandler(url: string, data: any, headerContentType: any): Observable<any> {
    //console.log(type);
    return this.http.post(url, data, this.getHeaders(headerContentType));
    
  }
  /* Last Commit Changes*/ 
  getHandler(url: string): Observable<any> {
    return this.http.get(url, this.getHeaders());
  }

  putHandler(url: string, data: any): Observable<any> {
    return this.http.put(url, data, this.getHeaders());
  }

  deleteHandler(url: string): Observable<any> {
    return this.http.delete(url);
  }
  getHeaders(headerContentType: string = '') {
    const token = localStorage.getItem('access_token')
    if(headerContentType == 'multipart/form-data'){
      return  {
        headers: new HttpHeaders({
          //'Content-Type': 'multipart/form-data',
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + token
        }),
      };
    }
    else if (headerContentType == 'map'){
      return  {
        headers: new HttpHeaders({
          'Content-Type': 'application/json;charset=UTF-8',
          'Accept': 'application/json',
          'Authorization': 'Basic Y3ZwLXVzZXItMTpwbHV0b2lzbm90YXBsYW5ldA==',
        }),
      }
    }
    else{
      return  {
        headers: new HttpHeaders({
          'Content-Type': 'application/json;charset=UTF-8',
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + token,
        }),
      };
    }
  }

  
 

  uploadPurchasedPlan(apiUrl: string, fileObj: any): Observable<any> {
    const token = localStorage.getItem('access_token')
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'multipart/form-data',
        'Authorization': 'Bearer '+ token
      })
      // responseType: 'blob' as 'json'
    
    };
    
    return this.http.post<any>(`${apiUrl}`,fileObj, httpOptions);
      
    // return res;
  }



}