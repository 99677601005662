import { Component, Input, OnInit } from "@angular/core";
import { HttpMethod } from "src/app/constants/http-handlers";
import { AppService } from "src/app/services/app.service";
import { CommonService } from "src/app/services/common.service";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { DataService } from "src/app/services/data.service";

@Component({
  selector: "app-notifications",
  templateUrl: "./notifications.component.html",
  styleUrls: ["./notifications.component.scss"],
})
export class NotificationsComponent implements OnInit {
  showThreeNotifications: any[] = [];
  showNotifications: any[] = [];
  showAllItems: boolean = false;
  message: string;
  subscription: Subscription;
  @Input() hideNotifications: boolean = false;
  constructor(
    private commonService: CommonService,
    private appService: AppService,
    private router: Router,
    private dataService: DataService
  ) {}

  ngOnInit(): void {
    if (localStorage.getItem("userDetail")){
      //if (localStorage.getItem("notidata") != "true"){
        this.getNotifications();
      //}
      
    }
    
  }

  getNotifications() {
    const url = `${this.appService.notifications}`;
    const apiObj = {
      url: url,
      methodType: HttpMethod.GET,
    };
    this.commonService.commonApiCall(apiObj, (res) => {
      //localStorage.setItem("notidata", "true");
      this.showNotifications = res.results;
      for (var i = 0; i < res.results.length; i++) {
        this.showThreeNotifications.push(res.results[i]);
      }
    });
  }
  showAllNotification() {
    this.showAllItems = !this.showAllItems;
  }
  redirectToTraining(catItem) {
    //debugger;
    this.router.navigate(["/training", catItem]);
    this.hideNotifications = true;
  }
}
