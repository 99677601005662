<div class="guru-status-container">
  <div class="chart-container">
    <canvas baseChart [data]="doughnutChartData" [labels]="doughnutChartLabels" [chartType]="doughnutChartType"
      [options]="doughnutChartOptions" [colors]="chartColors">
    </canvas>
  </div>
  <div class="top-guru-transacting-sec">
    <div class="top-guru-transacting-row">
      <div class="top-guru-item">
        <div class="guru-active-border">
          <h2>{{ activeGurus }}</h2>
          <h5>Active</h5>
          <span [ngClass]="
              changesactiveGurus < 0 ? ' decreaseValue' : ' increaseValue'
            ">{{ changesactiveGurus | absnew | number: "1.0-0" }}%</span>
          <small>(Over last month) </small>
        </div>
      </div>
      <div class="top-guru-item">
        <div class="guru-transacting-border">
          <h2>{{ transactingGurus }}</h2>
          <h5>Transacting</h5>
          <span [ngClass]="
              changesTransactingGurus < 0 ? ' decreaseValue' : ' increaseValue'
            ">{{ changesTransactingGurus | absnew | number: "1.0-0" }}%</span>
          <small>(Over last month) </small>
        </div>
      </div>
      <div class="top-guru-item">
        <div class="guru-inactive-border">
          <h2>{{ inactiveGurus }}</h2>
          <h5>Inactive</h5>
          <span [ngClass]="
              changesInactiveGurus < 0 ? ' decreaseValue' : ' increaseValue'
            ">{{ changesInactiveGurus | absnew | number: "1.0-0" }}%</span>
          <small>(Over last month) </small>
        </div>
      </div>
    </div>
  </div>
  <div class="distribute-box second">
    <h3>Guru Tier Distribution:</h3>
    <div class="star-box">
      <!-- <div class="platinum-star">02</div>
      <b class="star-seprator"></b> -->
      <div class="gold-star">{{ goldStatus }}</div>
      <b class="star-seprator"></b>
      <div class="silver-star">{{ silverStatus }}</div>
      <b class="star-seprator"></b>
      <div class="bronze-star">{{ platinumStatus }}</div>
    </div>
  </div>
</div>