import { Component, OnInit } from "@angular/core";
import { HttpMethod } from "src/app/constants/http-handlers";
import { AppService } from "src/app/services/app.service";
import { CommonService } from "src/app/services/common.service";

@Component({
  selector: "app-target-achieved",
  templateUrl: "./target-achieved.component.html",
  styleUrls: ["./target-achieved.component.scss"],
})
export class TargetAchievedComponent implements OnInit {
  tgpPartConsumed: number;
  tgpPartpercentchange: number;
  nontgpPartConsumed: number;
  nontgpPartpercentchange: number;
  totalPartConsumed: number;
  totalPartpercentchange: number;
  last_month_parts_target: number;
  total_parts_last_month: number;
  current_month_parts_target: number;
  total_parts_current_month: number;
  fitmentValue: string;
  perctantage_target: any;
  style_perctantage: any;
  globalUserRoleId: any;

  constructor(
    private commonService: CommonService,
    private appService: AppService
  ) {}

  ngOnInit(): void {
    this.commonService.globalselectedUseroleId.subscribe(
      (message) => (this.globalUserRoleId = message)
    );
    let url;
    if (this.globalUserRoleId !== '') {
      url = `${this.appService.dashBoardPartperformance}?user_id=${this.globalUserRoleId}`;
    } else {
      url = `${this.appService.dashBoardPartperformance}`;
    }
    const apiObj = {
      url: url,
      methodType: HttpMethod.GET,
    };
    this.commonService.commonApiCall(apiObj, (res) => {
      if (res && res.hasOwnProperty("results") && res.results) {
        this.totalPartpercentchange = res.total_parts_percentage_change;
        this.nontgpPartpercentchange = res.non_tgp_parts_percentage_change;
        this.tgpPartpercentchange = res.tgp_parts_percentage_change;

        this.total_parts_current_month = res.total_parts_current_month;
        this.last_month_parts_target = res.last_month_parts_target;
        this.total_parts_last_month = res.total_parts_last_month;
        this.current_month_parts_target = res.current_month_parts_target;
        if (this.current_month_parts_target == 0)
          this.perctantage_target = "0%";
        else
          this.perctantage_target =
            Math.round(
              (this.total_parts_current_month /
                this.current_month_parts_target) *
                100
            ) + "%";
        this.style_perctantage = "width:" + this.perctantage_target;
        this.fitmentValue =
          this.total_parts_current_month + " - " + this.perctantage_target;
        
      }
    });
  }
}
