<div class="tb-table-wrapper">
  <ng-container *ngIf="globalSearchText !== ''">
    <h3>Search results for : {{ globalSearchText }}</h3>
  </ng-container>
  <ng-container *ngIf="globalSearchText == ''">
    <div class="guru-tabs-row">
      <div class="guru-tabs-items">
        <ul>
          <li [ngClass]="{ 'active-tab': step === 'all' }" (click)="setGuruListTab('all')">
            <a>All</a>
          </li>
          <li class="active bullet-state" [ngClass]="{ 'active-tab': step === 'active' }"
            (click)="setGuruListTab('active')">
            <a>Active</a>
          </li>
          <li class="transacting bullet-state" [ngClass]="{ 'active-tab': step === 'transacting' }"
            (click)="setGuruListTab('transacting')">
            <a>Transacting</a>
          </li>
          <li class="inactive bullet-state" [ngClass]="{ 'active-tab': step === 'inactive' }"
            (click)="setGuruListTab('inactive')">
            <a>In-Active</a>
          </li>
          <ng-container *ngIf="role === 'MSR' || role === 'Distributor'">
            <li [ngClass]="{ 'active-tab': step === 'pending' }" (click)="setGuruListTab('pending')">
              <a>Pending</a>
            </li>
            <li [ngClass]="{ 'active-tab': step === 'rejected' }" (click)="setGuruListTab('rejected')">
              <a>Rejected</a>
            </li>
          </ng-container>
        </ul>
      </div>
      <div class="tb-btn-group" *ngIf="step !== 'pending' && step !== 'rejected'">
        <ul>
          <li>
            <app-gurulist-filter (formEventTrigger)="formControlValue($event)"></app-gurulist-filter>
          </li>
          <li>
              <button mat-stroked-button class="primary-stroke-button exportBtn" (click)="downloadGuruListExcel()">
                  Export Excel
              </button>
          </li>
          <li>
              <button mat-stroked-button class="primary-stroke-button exportBtn" (click)="pageChanged(0)">
              Refresh
              </button>
          </li>
      </ul>
      </div>
    </div>
  </ng-container>
  <!-- <app-filter-chip [filterChip]="filterChipList"></app-filter-chip> -->

  <div class="guru-list-table" *ngIf="!showNodata">
    <!-- <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar> -->
    <table mat-table matTableExporter class="full-width-table tb-table" matSort aria-label="Elements"
      #exporter="matTableExporter" [dataSource]="dataSource">
      <ng-container matColumnDef="account_id" sticky>
        <th class="account_id-col" mat-header-cell *matHeaderCellDef style="background-color: white;">
          Account ID
        </th>
        <td class="account_id-col" mat-cell *matCellDef="let row" style="box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);">
          {{ row.account_id?row.account_id:"NA" }}
        </td>
      </ng-container>
      
      <ng-container matColumnDef="guru_type" stickyEnd *ngIf="this.role === 'RPM' || this.role === 'SuperAdmin'">
        <th class="guru_type-col" mat-header-cell *matHeaderCellDef style="background-color: white;"> 
          Guru Type
        </th>
        <td class="guru_type-col text-center" mat-cell *matCellDef="let row">
          <div class="guru-type-wrapper">
            <span class="guru-type">{{ row.guru_type ? row.guru_type : 'NA' }}</span>
            <span class="edit-icon fas fa-pen" (click)="openEditDialog(row)" style="cursor: pointer;"></span>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="guru_name">
        <th class="guru_name-col" mat-header-cell *matHeaderCellDef>
          Guru Name
        </th>
        <td class="guru_name-col" mat-cell *matCellDef="let row">
          {{ row.guru_name?row.guru_name:"NA" }}
        </td>
      </ng-container>
      <ng-container matColumnDef="registration_date">
        <th class="registration_date-col" mat-header-cell *matHeaderCellDef>
          Registration Date
        </th>
        <td class="registration_date-col text-center" mat-cell *matCellDef="let row">
          {{ row.registration_date?(row.registration_date | date:"dd-MM-yyyy"):"NA" }}
        </td>
      </ng-container>
      <ng-container matColumnDef="dealer_name">
        <th class="registration_date-col text-center" mat-header-cell *matHeaderCellDef>
          Dealer Name
        </th>
        <td class="dealer_name-col text-center " mat-cell *matCellDef="let row">
          {{ row.dealer_name?row.dealer_name:"NA" }}
        </td>
      </ng-container>
      <ng-container matColumnDef="garage_name">
        <th class="garage_name-col" mat-header-cell *matHeaderCellDef>
          Garage Name
        </th>
        <td class="garage_name-col" mat-cell *matCellDef="let row">
          {{ row.garage_name?row.garage_name:"NA" }}
        </td>
      </ng-container>
      <ng-container matColumnDef="garage_area">
        <th class="garage_area-col" mat-header-cell *matHeaderCellDef>
          Garage Area
        </th>
        <td class="garage_area-col text-center" mat-cell *matCellDef="let row">
          {{ row.garage_area?row.garage_area:"NA" }}
        </td>
      </ng-container>
      <ng-container matColumnDef="garage_id">
        <th class="garage_id-col" mat-header-cell *matHeaderCellDef>
          Garage Id
        </th>
        <td class="garage_id-col text-center" mat-cell *matCellDef="let row">
          {{ row.garage_id?row.garage_id:"NA" }}
        </td>
      </ng-container>
      <ng-container matColumnDef="ratings">
        <th class="ratings-col" mat-header-cell *matHeaderCellDef>
          Ratings
        </th>
        <td class="ratings-col text-center" mat-cell *matCellDef="let row">
          {{ row.ratings?row.ratings:"NA" }}
        </td>
      </ng-container>
      
      <ng-container matColumnDef="guru_phone_number">
        <th class="guru_phone_number-col" mat-header-cell *matHeaderCellDef>
          Guru Phone Number
        </th>
        <td class="guru_phone_number-col text-center" mat-cell *matCellDef="let row">
          {{ row.guru_phone_number?row.guru_phone_number:"NA" }}
        </td>
      </ng-container>
      <ng-container matColumnDef="jobcard_count">
        <th class="jobcard_count-col" mat-header-cell *matHeaderCellDef>
          Total Jobcards 
        </th>
        <td class="jobcard_count-col text-center" mat-cell *matCellDef="let row">
          {{ row.jobcard_count?row.jobcard_count:"NA" }}
        </td>
      </ng-container>
      <ng-container matColumnDef="part_fitted">
        <th class="part_fitted-col" mat-header-cell *matHeaderCellDef>
          Total Parts Fitted
        </th>
        <td class="part_fitted-col text-center" mat-cell *matCellDef="let row">
          {{ row.part_fitted?row.part_fitted :"NA" }}
        </td>
      </ng-container>
      <ng-container matColumnDef="rejected_on">
        <th class="rejected_on-col" mat-header-cell *matHeaderCellDef>
          Rejected Date
        </th>
        <td class="rejected_on-col text-center" mat-cell *matCellDef="let row">
          {{ row.rejected_on?(row.rejected_on | date:"dd-MM-yyyy" ):"NA" }}
              </td>
      </ng-container>
      <ng-container matColumnDef="rejection_reason">
        <th class="rejection_reason-col" mat-header-cell *matHeaderCellDef>
          Rejection Reason
        </th>
        <td class="rejection_reason-col text-center" mat-cell *matCellDef="let row">
          {{ row.rejection_reason?row.rejection_reason:"NA" }}
        </td>
      </ng-container>
      <ng-container matColumnDef="associated_since">
        <th class="associated_since-col" mat-header-cell *matHeaderCellDef>
          <ng-container *ngIf="step !== 'pending' && step !== 'rejected'">
            Associated Since
          </ng-container>
          <ng-container *ngIf="step === 'pending'">
            Pending Since
          </ng-container>
          <ng-container *ngIf="step === 'rejected'">
            Received Date
          </ng-container>
        </th>
        <td class="associated_since-col text-center" mat-cell *matCellDef="let row">
          {{ row.associated_since?(row.associated_since | date:"dd-MM-yyyy" ):"NA" }}
        </td>
      </ng-container>
      <ng-container matColumnDef="source">
        <th class="source-col" mat-header-cell *matHeaderCellDef>
          Source
        </th>
        <td class="source-col" mat-cell *matCellDef="let row">
          {{ row.source?row.source:"NA" }}
        </td>
      </ng-container>
      <ng-container matColumnDef="dealer">
        <th class="dealer-col" mat-header-cell *matHeaderCellDef>
          Dealer
        </th>
        <td class="dealer-col" mat-cell *matCellDef="let row">
          {{ row.dealer?row.dealer:"NA" }}
        </td>
      </ng-container>
      <ng-container matColumnDef="sso">
        <th class="sso-col" mat-header-cell *matHeaderCellDef>
          SSO
        </th>
        <td class="sso-col" mat-cell *matCellDef="let row">
          {{ row.sso?row.sso:"NA" }}
        </td>
      </ng-container>
      <ng-container matColumnDef="zone">
        <th class="zone-col" mat-header-cell *matHeaderCellDef>
          Zone
        </th>
        <td class="zone-col" mat-cell *matCellDef="let row">
          {{ row.zone?row.zone:"NA" }}
        </td>
      </ng-container>
      <ng-container matColumnDef="state_name">
        <th class="state_name-col" mat-header-cell *matHeaderCellDef>
          State
        </th>
        <td class="state_name-col" mat-cell *matCellDef="let row">
          {{ row.state_name?row.state_name:"NA" }}
        </td>
      </ng-container>
      <ng-container matColumnDef="district">
        <th class="district-col" mat-header-cell *matHeaderCellDef>
          District
        </th>
        <td class="district-col" mat-cell *matCellDef="let row">
          {{ row.district?row.district:"NA" }}
        </td>
      </ng-container>
      <ng-container matColumnDef="pincode">
        <th class="pincode-col" mat-header-cell *matHeaderCellDef>
          Pincode
        </th>
        <td class="pincode-col" mat-cell *matCellDef="let row">
          {{ row.pincode?row.pincode:"NA" }}
              </td>
      </ng-container>
      <ng-container matColumnDef="membership">
        <th class="membership-col" mat-header-cell *matHeaderCellDef>
          Membership
        </th>
        <td class="membership-col text-center" mat-cell *matCellDef="let row">
          {{ row.membership?row.membership:"NA" }}
              </td>
      </ng-container>
      <ng-container matColumnDef="lass_name">
        <th class="lass_name-col" mat-header-cell *matHeaderCellDef>
          Lass Name
        </th>
        <td class="lass_name-col" mat-cell *matCellDef="let row">
          {{ row.lass_details && row.lass_details.lass_name ?row.lass_details.lass_name:"NA" }}
              </td>
      </ng-container>
      <ng-container matColumnDef="lass_number">
        <th class="lass_number-col" mat-header-cell *matHeaderCellDef>
          Lass Number
        </th>
        <td class="lass_number-col" mat-cell *matCellDef="let row">
          {{ row.lass_details && row.lass_details.lass_number ?row.lass_details.lass_number:"NA" }}
              </td>
      </ng-container>
      <ng-container matColumnDef="lass_email">
        <th class="lass_email-col" mat-header-cell *matHeaderCellDef>
          Lass Email
        </th>
        <td class="lass_email-col" mat-cell *matCellDef="let row">
          {{ row.lass_details && row.lass_details.lass_email ?row.lass_details.lass_email:"NA" }}
              </td>
      </ng-container>
      <ng-container matColumnDef="msr_id">
        <th class="msr_id-col" mat-header-cell *matHeaderCellDef>
          Msr ID
        </th>
        <td class="msr_id-col" mat-cell *matCellDef="let row">
          {{ row.msr_details?row.msr_details.msr_id:"NA" }}
              </td>
      </ng-container>
      <ng-container matColumnDef="msr_name">
        <th class="msr_name-col" mat-header-cell *matHeaderCellDef>
          Msr Name
        </th>
        <td class="msr_name-col" mat-cell *matCellDef="let row">
          {{ row.msr_details && row.msr_details.msr_name ?row.msr_details.msr_name:"NA" }}
              </td>
      </ng-container>
      <ng-container matColumnDef="msr_mobile_num">
        <th class="msr_mobile_num-col" mat-header-cell *matHeaderCellDef>
          Msr Mobile Number
        </th>
        <td class="msr_mobile_num-col text-center" mat-cell *matCellDef="let row">
          {{ row.msr_details && row.msr_details.msr_mobile_num?row.msr_details.msr_mobile_num:"NA" }}
              </td>
      </ng-container>
      <!-- <ng-container matColumnDef="guru_username">
        <th class="guru-detailed-col" mat-header-cell *matHeaderCellDef mat-sort-header>
          Guru
        </th>
        <td class="guru-first-col guru-detailed-col" mat-cell *matCellDef="let row">
          <div class="guru-img-cont">
            <ng-container *ngIf="step === 'pending' && role === 'Distributor'"><input type="checkbox"
                class="pending-chkbox messageCheckbox" name="s" id="{{ row.id }}" />
            </ng-container>
            <div class="guru-image">
              <ng-container *ngIf="row.profile_image">
                <img [src]="row.profile_image" />
              </ng-container>
              <ng-container *ngIf="!row.profile_image">
                <img src="./assets/images/demo-img.png" />
              </ng-container>
            </div>
            <div class="guru-detail">
              <ng-container *ngIf="row.is_accepted">
                <a class="guru-name" [routerLink]="['guru-detail', row.id]">{{
                  row.first_name + " " + row.last_name
                }}</a>
              </ng-container>
              <ng-container *ngIf="!row.is_accepted">
                <a class="guru-name">{{
                  row.first_name + " " + row.last_name
                }}</a>
              </ng-container>
              <span class="guru-id small-font">{{ row.guru_user_id }}</span>
            </div>
          </div>
        </td>
      </ng-container> -->
      <ng-container matColumnDef="update_guru">
        <th [ngClass]="{ hidden: step !== 'pending' || role !== 'MSR' }" class="update-col" mat-header-cell
          *matHeaderCellDef>
          Update
        </th>
        <td [ngClass]="{ hidden: step !== 'pending' || role !== 'MSR' }" class="update-col" mat-cell
          *matCellDef="let row">
          <ng-container *ngIf="row.is_completed === false && row.is_accepted === true">
            <button mat-stroked-button class="primary-flat-button" (click)="updateGuru(row.id)">
              Update
            </button>
          </ng-container>
          <ng-container *ngIf="
              row.is_personal_details === false &&
              row.source === 'application' &&
              row.is_accepted === false
            ">
            <button mat-stroked-button class="primary-flat-button" (click)="acceptGuru(row.id)">
              Accept
            </button>
          </ng-container>
        </td>
      </ng-container>
      <tr class="tb-head-row" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr class="tb-item-row active-guru {{ row.membership | lowercase }}" mat-row
        *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>

  <ng-container *ngIf="showNodata">
    <div class="no-item-found">No record found</div>
  </ng-container>

  <mat-paginator appPagination showFirstLastButtons [length]="totalRows" [pageIndex]="currentPage" [pageSize]="pageSize"
    [hidden]="showNodata" (page)="pageChanged($event)" class="custom-paginator" [hidePageSize]="true">
  </mat-paginator>
</div>

<div class="actionBtnGroup" *ngIf="step === 'pending' && role === 'Distributor'">
  <ul>
    <li>
      <button mat-stroked-button class="primary-flat-button" (click)="actionAPMBtn('Approve')">
        Approve
      </button>
    </li>
    <li>
      <button mat-stroked-button class="primary-flat-button" (click)="rejectProfile('Reject')">
        Reject
      </button>
    </li>
  </ul>
</div>
<div class="action-toaster" *ngIf="showToastermessage">
  <div class="toaster-msg alert alert-success" *ngIf="showfinalSuccessMsg">
    {{ showfinalSuccessMsg }}
  </div>
  <div class="toaster-msg alert alert-danger" *ngIf="showfinalErrorMsg">
    {{ showfinalErrorMsg }}
  </div>
</div>

<ngx-spinner></ngx-spinner>