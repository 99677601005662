import { Component, OnInit } from "@angular/core";
import { HttpMethod } from "src/app/constants/http-handlers";
import { AppService } from "src/app/services/app.service";
import { CommonService } from "src/app/services/common.service";

@Component({
  selector: "app-parts-performance",
  templateUrl: "./parts-performance.component.html",
  styleUrls: ["./parts-performance.component.scss"],
})
export class PartsPerformanceComponent {
  tgpPartConsumed: number;
  tgpPartpercentchange: number;
  nontgpPartConsumed: number;
  nontgpPartpercentchange: number;
  totalPartConsumed: number;
  totalPartpercentchange: number;
  globalUserRoleId: any;

  constructor(
    private commonService: CommonService,
    private appService: AppService
  ) {}

  ngOnInit(): void {
    this.commonService.globalselectedUseroleId.subscribe(
      (message) => (this.globalUserRoleId = message)
    );
    let url;
    if (this.globalUserRoleId !== '') {
      url = `${this.appService.dashBoardPartperformance}?user_id=${this.globalUserRoleId}`;
    } else {
      url = `${this.appService.dashBoardPartperformance}`;
    }
    const apiObj = {
      url: url,
      methodType: HttpMethod.GET,
    };
    this.commonService.commonApiCall(apiObj, (res) => {
      if (res && res.hasOwnProperty("results") && res.results) {
        this.totalPartpercentchange = res.total_parts_percentage_change;
        this.nontgpPartpercentchange = res.non_tgp_parts_percentage_change;
        this.tgpPartpercentchange = res.tgp_parts_percentage_change;
        this.totalPartConsumed = res.total_parts_current_month;

        if (res.results.length && res.results) {
          //debugger;
          this.nontgpPartConsumed = 0;
          this.tgpPartConsumed = 0;
          if (res.results.length === 1) {
            if (res.results[0].is_tgp == false) {
              this.nontgpPartConsumed = res.results[0].total_parts_consumed;
            } else {
              this.tgpPartConsumed = res.results[0].total_parts_consumed;
            }
          }

          if (res.results.length === 2) {
            if (res.results[0].is_tgp == false) {
              this.nontgpPartConsumed = res.results[0].total_parts_consumed;
            } else {
              this.tgpPartConsumed = res.results[0].total_parts_consumed;
            }
            if (res.results[1].is_tgp == false) {
              this.nontgpPartConsumed = res.results[1].total_parts_consumed;
            } else {
              this.tgpPartConsumed = res.results[1].total_parts_consumed;
            }
          }
        } else {
          this.nontgpPartConsumed = 0;
          this.tgpPartConsumed = 0;
        }
      }
    });
  }
}
