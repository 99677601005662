import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators, FormControl } from "@angular/forms";
import { MatDialogRef } from "@angular/material/dialog";
import { HttpMethod } from "../../../constants/http-handlers";
import { AppService } from "src/app/services/app.service";
import { CommonService } from "src/app/services/common.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-confirm-dialog",
  templateUrl: "./confirm-dialog.component.html",
  styleUrls: ["./confirm-dialog.component.scss"],
})
export class ConfirmDialogComponent implements OnInit {
  recId: number;
  action: string;
  guruFlagSource: string;
  gurucheckedValue: any[];
  frmDelete: FormGroup;
  isFormShow: boolean = true;

  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    private formBuilder: FormBuilder,
    private commonService: CommonService,
    private appService: AppService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.frmDelete = this.formBuilder.group({
      reason: ["", [Validators.required, Validators.pattern(/^[a-zA-Z ]*$/), this.noWhitespaceValidator]],
    });
    if (this.action == "Approve") {
      this.isFormShow = false;
      let userObj;
      let url = "";
      userObj = {
        guru_id: [this.recId],
      };
      url = this.appService.pendingGuruApprove;

      const apiObj = {
        url: url,
        methodType: HttpMethod.POST,
        requestObj: userObj,
      };
      this.commonService.commonApiCall(apiObj, (res, error) => {
        setTimeout(() => {
          this.dialogRef.close();
          this.router.navigate(["guru-list"]);
        }, 2000);
      });
    } else {
      this.isFormShow = true;
    }
  }
  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }
  closeModal(): void {
    this.dialogRef.close();
  }
  onSubmit() {
    if (this.frmDelete.invalid) {
      return;
    } else {
      if (this.action == "Delete") {
        const url = this.appService.msrGuruDelete + this.recId + "/";
        const apiObj = {
          url: url,
          methodType: HttpMethod.PUT,
          requestObj: { reason: this.frmDelete.value.reason },
        };
        this.commonService.commonApiCall(apiObj, (res) => {
          if (res) {
            this.isFormShow = false;
            setTimeout(() => {
              this.dialogRef.close();
              this.router.navigate(["guru-list"]);
            }, 2000);
          }
        });
      } else {
        let userObj;
        let url = "";
        if (this.guruFlagSource == "detail") {
          userObj = {
            guru_id: [this.recId],
            reason: this.frmDelete.value.reason,
          };
        } else {
          userObj = {
            guru_id: this.gurucheckedValue,
            reason: this.frmDelete.value.reason,
          };
        }
        url = this.appService.pendingGuruReject;

        const apiObj = {
          url: url,
          methodType: HttpMethod.POST,
          requestObj: userObj,
        };
        this.commonService.commonApiCall(apiObj, (res, error) => {
          this.isFormShow = false;
          setTimeout(() => {
            this.dialogRef.close();
            this.router.navigate(["guru-list"]);
          }, 2000);
        });
      }
    }
  }
}
