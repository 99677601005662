import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpMethod } from 'src/app/constants/http-handlers';
import { AppService } from 'src/app/services/app.service';
import { CommonService } from 'src/app/services/common.service';
import { ExcelService } from 'src/app/services/excel.service';
import { DoorStepBookingFilterComponent } from './door-step-booking-filter/door-step-booking-filter.component';

@Component({
  selector: 'app-door-step-booking-report',
  templateUrl: './door-step-booking-report.component.html',
  styleUrls: ['./door-step-booking-report.component.scss']
})
export class DoorStepBookingReportComponent implements OnInit {
  dataSource = new MatTableDataSource<any>();
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild('childComponent', {static: false}) childComponent:DoorStepBookingFilterComponent;
  displayedColumns: string[] = [
    "bookingId",
    "caseType",
    "bookingSurce",
    "createdDate",
    "bookingDate",
    "timeslot",
    "vehicleNumber",
    "ownerName",
    "ownerContactNo",
    "guruId",
    "guruName",
    "guruContactNo",
    "guruState",
    "guruRegion",
    "lassName",
    "lassNumber",
    "lastStatus",
    "sso",
    "lastStatusDate",
    "jobcardCreatedDate",
    "jobCardStatus",
];
  showNodata: boolean = false;
  isLoading:boolean = false;
  totalRows = 0;
  pageSize = 10;
  currentPage = 0;
  pgIndex: number = 0;
  showfinalSuccessMsg: string;
  showfinalErrorMsg: string;
  showToastermessage: boolean = false;
  formcontrolValues:any;
  role: string;
  zone: any;
  constructor( 
    private commonService:CommonService, 
    private appService:AppService,
    private datePipe:DatePipe,
    private excelService:ExcelService,
    private spinner: NgxSpinnerService,
    ) { }
  ngOnInit(): void {
    this.role = JSON.parse(localStorage.getItem("userDetail")).userdetails.role;
if (this.role === 'RPM'||this.role === 'DEALER') {
  this.zone = JSON.parse(localStorage.getItem('userDetail')).userdetails.region;
}
    this.doorStepbookinglist();
  }
  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }
  doorStepbookinglist(formVal?) {
    try{
      let apiUrl = this.appService.doorStepBookingReport;
      const url = `${apiUrl}`;
      let date = new Date();
      let toDate = this.datePipe.transform(formVal?formVal.toDate:date, "yyyy-MM-dd");
      let fromDate = this.datePipe.transform(formVal?formVal.fromDate:date.setMonth(date.getMonth() - 1), "yyyy-MM-dd");
      let req = {
        "to_date": toDate,
        "from_date": fromDate,
        "vehicle_number": this.formcontrolValues ? this.formcontrolValues.vehicleRegNo : "",
        "guru_contact_number": this.formcontrolValues ? this.formcontrolValues.mobileNo : "",
        "lass_name": this.formcontrolValues ? this.formcontrolValues.lassnme : "",
        "lass_number": this.formcontrolValues ? this.formcontrolValues.lassContactNo : "",
        "guru_state": this.formcontrolValues ? this.formcontrolValues.state : "",
        "guru_region": this.role === 'RPM'||this.role === 'DEALER' ? this.zone : (this.formcontrolValues ? this.formcontrolValues.zone : ""),
        "offset": this.currentPage == 0 ? 0 : (this.currentPage * 10),
        "case_type": "Doorstep Booking",
        "limit": 10
      }
      const apiObj = {
        requestObj:req,
        url: url,
        methodType: HttpMethod.POST,
      };
      this.spinner.show();
      this.commonService.commonApiCall(apiObj, (res) => {
        if (
          res &&
          res.hasOwnProperty("result") &&
          res.result &&
          res.result.booking_details.length
        ) {
          this.spinner.hide();
          if(res.success == true){
            this.dataSource = new MatTableDataSource(res.result.booking_details);
            this.dataSource.sort = this.sort;
            this.paginator.pageIndex = this.currentPage;
            this.paginator.length = res.result.count;
            this.childComponent.closeDropdown();
            this.isLoading = false;
            this.showNodata = false;
          }else{
            this.showfinalErrorMsg = res.msg;
            this.showToastermessage = true;
            this.showNodata = true;
          }
        }else {
          this.showNodata = true;
          this.spinner.hide();
          this.showfinalErrorMsg = res.error.msg;
          this.childComponent.closeDropdown();
          this.showToastermessage = true;
        }
      });
    }catch(error){
      this.spinner.hide();
      this.showfinalErrorMsg = error.error.msg;
      this.showToastermessage = true;
      this.showNodata = true;
      console.log(error);
    }
  }
  async downloadDoorStepBookingListExcel(){
    try{
      this.spinner.show();
      let apiUrl =  this.appService.doorStepBookingReport;
      let date = new Date();
      let toDate = this.datePipe.transform(this.formcontrolValues?this.formcontrolValues.toDate:date, "yyyy-MM-dd");
      let fromDate = this.datePipe.transform(this.formcontrolValues?this.formcontrolValues.fromDate:date.setMonth(date.getMonth() - 1), "yyyy-MM-dd");
      let req = {
        "to_date":toDate?toDate:this.datePipe.transform(date,"yyyy-MM-dd"),
        "from_date":fromDate?fromDate:this.datePipe.transform(date.setMonth(date.getMonth() - 3),"yyyy-MM-dd"),
        "vehicle_number": this.formcontrolValues?this.formcontrolValues.vehicleRegNo:"",
        "guru_contact_number":this.formcontrolValues?this.formcontrolValues.mobileNo:"",
        "lass_name":this.formcontrolValues?this.formcontrolValues.lassnme:"",
        "lass_number":this.formcontrolValues?this.formcontrolValues.lassContactNo:"",
        "guru_state":this.formcontrolValues?this.formcontrolValues.state:"",
        "guru_region":this.role === 'RPM'||this.role === 'DEALER' ? this.zone : (this.formcontrolValues ? this.formcontrolValues.zone : ""),
        "case_type": "Doorstep Booking",
        "offset": 0,
        "limit": 10
      }
      let totalResult=0;
      let data:any[] = [];
      let res = await this.commonService.postAsyncRequest(apiUrl,req);
      if(res){
        totalResult = res.result.count;
        data = res.result.booking_details;
      }
      let rou = Math.floor(totalResult/10);
      rou = (totalResult%10>0) && (rou > 0)?rou+1:rou; 
      if(rou > 1 ){
        for(var i=1;i<rou;i++){
          req.offset = (i * 10);
          res = await this.commonService.postAsyncRequest(apiUrl,req);
          if(res && res.result.booking_details && res.result.booking_details.length){
            data = [...data, ...res.result.booking_details];
          }
        }
      }
      let finalData:DoorStepBookingReport[] = [];
      for (let row of data) {
        const ListInput: DoorStepBookingReport = {} as DoorStepBookingReport;
        ListInput.Booking_id =   row.id
        ListInput.Case_Type = row.case_type
        ListInput.Booking_source = row.case_type == 'OTC Doorstep Booking'?'BANDHU':(row.case_type == 'Web Doorstep Booking' || row.case_type == 'Web Vehicle Breakdown')?'CUSTOMER CARE':( row.case_type == 'Doorstep Booking' || row.case_type == 'Vehicle Breakdown' )?'FLEETEDGE':'NA'
        ListInput.Created_date = this.datePipe.transform(row.created_date, "dd-MM-yyyy")
        ListInput.Booking_date = this.datePipe.transform(row.date, "dd-MM-yyyy")
        ListInput.Time_slot = row.start_time +" - "+row.end_time;
        ListInput.Vehicle_number =   row.registration_number
        ListInput.Owner_name = row.fleet.first_name+" "+row.fleet.last_name
        ListInput.Owner_contact_no = row.fleet.username
        ListInput.Guru_id = row.guru?row.guru.id:'NA'
        ListInput.Guru_Name = row.guru? ( row.guru.guru_detail.first_name+" "+row.guru.guru_detail.last_name ) :'NA'
        ListInput.Guru_contact_no = row.guru?row.guru.guru_detail.username :'NA'
        ListInput.Guru_State = row.guru?row.guru.state_name:"NA"
        ListInput.Guru_Region = row.guru?row.guru.zone:"NA"
        ListInput.SSO = row.guru?row.guru.sso:"NA"
        ListInput.Lass_Name= row.lass_detail?row.lass_detail.lass_name:"NA"
        ListInput.Lass_Number=row.lass_detail?row.lass_detail.lass_number:"NA"
        ListInput.Last_status = row.milestone_status?row.milestone_status.status:row.case_status
        ListInput.Last_status_date = this.datePipe.transform(row.updated_date, "dd-MM-yyyy")
        ListInput.Job_card_created_date = row.jobcard_details?( this.datePipe.transform(row.jobcard_details.created_date , "dd-MM-yyyy")) :'NA'
        ListInput.Job_card_status =  row.jobcard_details?(row.jobcard_details.work_completion >=25 && row.jobcard_details.work_completion <=75?'Job Card Ongoing':(row.jobcard_details.work_completion >=75 && row.jobcard_details.work_completion <=100)?'Job card completed':'NA'):'NA'
        finalData.push(ListInput);
      }
      if(finalData.length > 0 ){
        this.excelService.exportCancellationAsExcelFile(finalData, 'door_step_booking_report');
      }else{
        this.showToastermessage = true;
        this.showfinalErrorMsg =
          "No Data For Download";
        setTimeout(() => {
          this.showfinalErrorMsg = "";
          this.showToastermessage = false;
        }, 3000);
      }
      console.log(finalData);
    }catch(error){
      this.showToastermessage = true;
      this.showfinalErrorMsg =
        "No Data For Download";
      setTimeout(() => {
        this.showfinalErrorMsg = "";
        this.showToastermessage = false;
      }, 3000);
    }
    this.spinner.hide();
  }
  pageChanged(event) {
    this.pgIndex = event.pageIndex?event.pageIndex:0;
    this.pgIndex = this.pgIndex + 1;
    this.currentPage = event.pageIndex?event.pageIndex:0;
    this.isLoading = true;
    this.doorStepbookinglist(this.formcontrolValues);
  }
  formControlValue(event){
    console.log(event);
    this.currentPage=0;
    this.formcontrolValues = event;
    this.doorStepbookinglist(event);
  }
}

export interface DoorStepBookingReport{

  Booking_id:string
  Case_Type:string

  Booking_source:string
  
  Created_date:string
  
  Booking_date:string
  
  Time_slot:string
  
  Vehicle_number:string
  
  Owner_name:string
  
  Owner_contact_no:string
  
  Guru_id:string
  Guru_Name:string
  
  Guru_contact_no:string
  
  Guru_location:string
  
  Last_status:string
  
  Last_status_date:string
  
  Job_card_created_date:string
  
  Job_card_status:string
  Lass_Name:string;
  Lass_Number:string;
  Guru_State:string;
  Guru_Region:string;
  SSO:string;
}