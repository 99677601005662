import { HttpClient } from "@angular/common/http";
import { OnChanges, SimpleChanges } from "@angular/core";
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { FormControl, FormBuilder } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTable, MatTableDataSource } from "@angular/material/table";
import { NgxSpinnerService } from "ngx-spinner";
import { Observable } from "rxjs";
import { HttpMethod } from "src/app/constants/http-handlers";
import { AppService } from "src/app/services/app.service";
import { CommonService } from "src/app/services/common.service";

@Component({
  selector: "app-added-part-list",
  templateUrl: "./added-part-list.component.html",
  styleUrls: ["./added-part-list.component.scss"],
})
export class AddedPartListComponent implements OnInit, OnChanges {
  submittedTrainingForm: boolean = false;
  showfinalSuccessMsg: string;
  showfinalErrorMsg: string;
  showToastermessage: boolean = false;
  trainingPictureFile: any = "";
  trainingItemFile: any = "";
  notificationAlert: boolean = false;
  deleteItemModal: boolean = false;
  deleteProductId: number;
  displayedColumns: string[] = [
    "partType",
    "partNameNum",
    "partPrice",
    "StartEndDate",
    "action",
  ];
  //dataSource = ELEMENT_DATA;

  showNodata: boolean = false;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatTable) table!: MatTable<any>;
  dataSource = new MatTableDataSource<any>();
  @Input() refreshTableItems: boolean = false;
  @Input() partType: string = "promotional";

  isLoading = false;
  totalRows = 0;
  pageSize = 10;
  currentPage = 0;
  pgIndex: number = 0;

  constructor(
    private appService: AppService,
    private commonService: CommonService,
    public dialog: MatDialog,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.productList();
  }
  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }
  ngOnChanges(changes: SimpleChanges) {
    for (let property in changes) {
      if (property === "refreshTableItems") {
        if (this.refreshTableItems) {
          this.productList();
          this.refreshTableItems = false;
        }
      }
      if (property === "partType") {
        this.pgIndex = 0;
        this.totalRows = 0;
        this.pageSize = 10;
        this.currentPage = 0;
        this.showNodata = false;
        this.productList();
      }
    }
  }
  productList() {
    let apiUrl = this.appService.getPartList;
    let queryParamList = [];

    //paging
    let pageParam = "";
    if (this.pgIndex != 0 && this.pgIndex != 1) {
      pageParam = `page=` + this.pgIndex;
      queryParamList.push(pageParam);
    }

    let apiQueryParam = "";
    if (queryParamList.length > 0) {
      apiQueryParam = queryParamList.join("&");
    }

    const url = `${apiUrl}` + apiQueryParam + "&type=" + this.partType;
    const apiObj = {
      url: url,
      methodType: HttpMethod.GET,
    };
    this.commonService.commonApiCall(apiObj, (res) => {
      if (
        res &&
        res.hasOwnProperty("results") &&
        res.results &&
        res.results.length
      ) {
        this.dataSource = new MatTableDataSource(res.results);
        this.dataSource.sort = this.sort;
        this.paginator.pageIndex = this.currentPage;
        this.paginator.length = res.count;
        this.isLoading = false;
      }
      if (res.count === 0) {
        this.showNodata = true;
      } else {
        this.showNodata = false;
      }
    });
    //this.isLoading = false;
  }
  pageChanged(event: PageEvent) {
    this.pgIndex = event.pageIndex;
    this.pgIndex = this.pgIndex + 1;
    this.currentPage = event.pageIndex;
    this.isLoading = true;
    this.productList();
  }
  deleteProdItem(itemId) {
    this.deleteItemModal = true;
    this.deleteProductId = itemId;
  }
  cancelDeleteItemModal() {
    this.deleteItemModal = false;
  }
  confirmDeleteItem() {
    let userObj = {
      is_deleted: true,
    };
    const apiObj = {
      url: this.appService.deletePartItem + this.deleteProductId,
      methodType: HttpMethod.PUT,
      requestObj: userObj,
    };

    this.spinner.show();

    this.commonService.commonApiCall(apiObj, (res: any, error) => {
      if (error != undefined) {
        this.showToastermessage = true;
        this.showfinalErrorMsg = error.error.detail;
        this.deleteItemModal = false;
        this.spinner.hide();
        setTimeout(() => {
          this.showfinalErrorMsg = "";
          this.showToastermessage = false;
        }, 3000);
      } else {
        this.showfinalSuccessMsg = res.message;
        this.showToastermessage = true;
        this.deleteItemModal = false;
        this.spinner.hide();
        this.productList();

        setTimeout(() => {
          this.showfinalSuccessMsg = "";
          this.showToastermessage = false;
        }, 3000);
      }
    });
  }
}
