<div class="vehicleData-wrapper col-full">
    <div class="col-70">
        <div class="vehicle-summary-col comn-box">
            <h3>Vechicle Summary</h3>
            <div class="summary-table">
                <div class="summarytab-head">
                    <div class="summarytab-row">
                        <div class="summarytab-col">Part Name</div>
                        <div class="summarytab-col">All Brand Fitments</div>
                        <div class="summarytab-col summary-job-cards">TGP Fitments</div>
                    </div>
                </div>
                
                <div class="summarytab-body">
                    <div class="summarytab-row" *ngFor="let item of vechicleSummarydata">
                        <div class="summarytab-col summary-img-title">
                            <i class="summary-img"><img [src]="item.brandImg" alt=""></i> 
                            <div>{{item.part}}
                                <span class="small-font">{{item.partno}}</span></div>
                        </div>
                        <div class="summarytab-col">{{item.allfitment}}</div>
                        <div class="summarytab-col summary-job-cards">{{item.tgpfitment}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="vehicle-compare-col col-30">
        <div class="comn-box">
            <h3>Comparison</h3>
            <div class="summary-row">
                <div class="summary-full-col">
                    <h4>Job Cards</h4>
                    <div class="summary-col">
                        <div class="summary-box decrease-col">
                            <h2>3290 <small> 2021</small></h2>
                            <div class="coupan-val">
                                <span class="decreaseValue">8%</span>
                                <small>(Over last month)</small>
                            </div>
                        </div>
                        <div class="summary-box fourth">
                            <h2>2457 <small> 2020</small></h2>
                        </div>
                    </div>
                </div>
            </div>
            <div class="summary-row">
                <div class="summary-full-col">
                    <h4>TGP Fitted</h4>
                    <div class="summary-col">
                        <div class="summary-box decrease-col">
                            <h2>2459 <small> 2021</small></h2>
                            <div class="coupan-val">
                                <span class="decreaseValue">8%</span>
                                <small>(Over last month)</small>
                            </div>
                        </div>
                        <div class="summary-box fourth">
                            <h2>4000 <small> 2020</small></h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="tb-table-wrapper" >
    <div class="guru-tabs-row">
      <div class="guru-tabs-items">
        <h2>Competitor Parts Fitment Details</h2>
      </div>
      <div class="tb-btn-group">
        <ul>
            <li>
                <div class="tb-searchbox">
                    <button class="tb-button guru-flex">
                      <div class="tb__search_icon"></div>
                    </button>
                    <input
                      type="text"
                      class="tb-search-text"
                      placeholder="Search by Name or Guru ID or Contact"
                    />
                  </div>
            </li>
          <li>
            <button mat-stroked-button class="primary-stroke-button exportBtn">
              Export
            </button>
          </li>
        </ul>
      </div>
    </div>
    <guru-table [tableData]="orders" [tableColumns]="ordersTableColumns" [isFilterable]="false" [isPageable]="true"
      [defaultPageSize]="15" (sort)="sortData($event)" class="targetcompetitor-table">
    </guru-table>
  </div>