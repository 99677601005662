import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { catchError } from 'rxjs/operators';
import { of, throwError } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class UserService {
  token = localStorage.getItem('access_token');
  header = {
    headers: {
      "Content-Type": "application/json",
      "Authorization": 'Bearer ' + this.token
    }
  }

  baseUrl = `${environment.apiUrl}api/emandi/portal/`;
  userRegistrationURL = `${this.baseUrl}registration/`;
  roleMasterURL = `${this.baseUrl}addrole/`;
  getRoleMasterURL = `${this.baseUrl}GetRoles/`;
  getUserMasterURL = `${this.baseUrl}GetUserList/`;
  constructor(private http: HttpClient) { }
  getHeaders(headerContentType: string = '') {
    const token = localStorage.getItem('access_token')
    // if (headerContentType == 'multipart/form-data') {
      return {
        headers: new HttpHeaders({
          'Content-Type': 'application/json;charset=UTF-8',
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + token,
        }),
      };
    // }
    // else {
    //   return null;
    // }
  }
  userRegistration(data: any) {
    return this.http.post(this.userRegistrationURL, data, this.getHeaders()).pipe(catchError(this.handlError))
  }

  createRole(data: any) {
    return this.http.post(this.roleMasterURL, data, this.getHeaders()).pipe(catchError(this.handlError))
  }
  getRoles() {
    return this.http.post(this.getRoleMasterURL, '', this.getHeaders()).pipe(catchError(this.handlError))
  }
  getUsers(data: any) {
    return this.http.post(this.getUserMasterURL, data, this.getHeaders()).pipe(catchError(this.handlError))
  }

  handlError(error: HttpErrorResponse) {
    if (error.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, body was: `, error.error);
    }
    // Return an observable with a user-facing error message.
    // return throwError(() => new Error('Something bad happened; please try again later.'));
    return throwError(error);
  }
}
