<div class="otp-dialogue">
  <div class="otpInprogress" *ngIf="true">
    <h2>OTP Verification</h2>
    <div class="dialogue-body">
      <p>Enter the 4-digit OTP toy received to</p>
      <div class="contact-num">+91 9876543210</div>
      <form [formGroup]="getOTPform" (ngSubmit)="ValidateOTP()">
        <div class="otp-box">
          <input
            type="text"
            formControlName="otp1"
            (keyup)="onKeyUp($event)"
            maxlength="1"
          />
          <input
            type="text"
            formControlName="otp2"
            (keyup)="onKeyUp($event)"
            maxlength="1"
          />
          <input
            type="text"
            formControlName="otp3"
            (keyup)="onKeyUp($event)"
            maxlength="1"
          />
          <input
            type="text"
            formControlName="otp4"
            (keyup)="onKeyUp($event)"
            maxlength="1"
          />
        </div>
      </form>
      <p>Didn’t receive OTP?</p>
      <div class="resend-otp"><a class="link-button">Resend OTP</a></div>
    </div>
    <div class="dialogueBtn">
      <button mat-flat-button class="primary-stroke-button">Cancel</button>
      <button mat-flat-button class="primary-flat-button" [disabled]="fillOtp">
        Confirm
      </button>
    </div>
  </div>
  <div class="otpUpdated" *ngIf="false">
    <div class="success-icon"></div>
    <h2>Phone Number Updated Successfully</h2>
  </div>
</div>
