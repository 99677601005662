<div class="tb-table-wrapper">
  <h2>Distributor List</h2>
  <div class="guru-tabs-row">
    <div class="bandhu-tabs">
      <mat-button-toggle-group
        name="fontStyle"
        value="All"
        aria-label="Font Style"
      >
        <mat-button-toggle value="All" (click)="setMSRlistTab('all')"
          >All</mat-button-toggle
        >
        <mat-button-toggle value="Low" (click)="setMSRlistTab('low')"
          >Low</mat-button-toggle
        >
        <mat-button-toggle value="Average" (click)="setMSRlistTab('average')"
          >Average</mat-button-toggle
        >
        <mat-button-toggle value="High" (click)="setMSRlistTab('high')"
          >High</mat-button-toggle
        >
      </mat-button-toggle-group>
    </div>
  </div>
  <!-- <div class="tb-searchbox search">
      <button class="tb-button guru-flex" (click)="searchRecords()">
        <div class="tb__search_icon"></div>
      </button>
      <input
        type="text"
        class="tb-search-text"
        placeholder="Search by Name or Contact"
        [(ngModel)]="searchText"
        (keyup)="checkText($event)"
      />
    </div> -->

  <div class="guru-list-table" *ngIf="!showNodata">
    <!-- <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar> -->
    <table
      mat-table
      matTableExporter
      class="full-width-table tb-table"
      matSort
      aria-label="Elements"
      [dataSource]="dataSource"
    >
      <ng-container matColumnDef="full_name">
        <th
          class="guru-detailed-col"
          mat-header-cell
          mat-sort-header
          *matHeaderCellDef
        >
          Distributors
        </th>
        <td class="guru-detailed-col" mat-cell *matCellDef="let element">
          <div class="comn-img-cont">
            <div class="comn-item-img">
              <img
                [src]="
                  element.profile_image
                    ? element.profile_image
                    : './assets/images/demo-img.png'
                "
                alt=""
              />
            </div>
            <div class="comn-item-detail">
              <a
                class="comn-item-name"
                [routerLink]="['distributor-detail', element.id]"
                >{{ element.full_name }}</a
              >
              <span class="small-font area-truncate" [title]="element.area">{{
                element.area
              }}</span>
            </div>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="total_msr">
        <th colspan="4" mat-header-cell *matHeaderCellDef class="msr-col">
          MSRs
        </th>
        <td
          colspan="4"
          mat-cell
          *matCellDef="let element"
          class="msr-col msr-col-td"
        >
          <div class="msr-total-col">
            <span> {{ element.distributor_msr_total_count }}</span>
            <span> {{ element.distributor_msr_high_perf_total_count }}</span>
            <span> {{ element.distributor_msr_avg_perf_total_count }}</span>
            <span> {{ element.distributor_msr_low_perf_total_count }}</span>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="apm_msr_part_fitment_count">
        <th mat-header-cell *matHeaderCellDef>Part Fitment Count</th>
        <td mat-cell *matCellDef="let element">
          {{ element.distributor_msr_part_fitment_count }}
        </td>
      </ng-container>

      <ng-container matColumnDef="contact">
        <th class="contact-col" mat-header-cell *matHeaderCellDef>Contact</th>
        <td
          class="contact-col guru-last-col"
          mat-cell
          *matCellDef="let element"
        >
          {{ element.phone_number }}
        </td>
      </ng-container>
      <ng-container matColumnDef="firstEmpty">
        <th mat-header-cell *matHeaderCellDef colspan="1"></th>
      </ng-container>
      <ng-container matColumnDef="apm_msr_total_count">
        <th mat-header-cell *matHeaderCellDef mat-sort-header colspan="1">
          Total
        </th>
      </ng-container>
      <ng-container matColumnDef="apm_msr_high_perf_total_count">
        <th mat-header-cell *matHeaderCellDef mat-sort-header colspan="1">
          High
        </th>
      </ng-container>
      <ng-container matColumnDef="apm_msr_avg_perf_total_count">
        <th mat-header-cell *matHeaderCellDef mat-sort-header colspan="1">
          Average
        </th>
      </ng-container>
      <ng-container matColumnDef="apm_msr_low_perf_total_count">
        <th mat-header-cell *matHeaderCellDef mat-sort-header colspan="1">
          Low
        </th>
      </ng-container>
      <ng-container matColumnDef="lastEmpty">
        <th mat-header-cell *matHeaderCellDef colspan="2"></th>
      </ng-container>

      <tr
        class="tb-head-row"
        mat-header-row
        *matHeaderRowDef="displayedColumns"
      ></tr>
      <tr
        class="tb-head-row tb-sechead-row"
        mat-header-row
        *matHeaderRowDef="[
          'firstEmpty',
          'apm_msr_total_count',
          'apm_msr_high_perf_total_count',
          'apm_msr_avg_perf_total_count',
          'apm_msr_low_perf_total_count',
          'lastEmpty'
        ]"
      ></tr>
      <tr
        class="tb-item-row"
        mat-row
        *matRowDef="let row; columns: displayedColumns"
      ></tr>
    </table>
  </div>

  <div class="no-item-found" *ngIf="showNodata">No record founds</div>

  <mat-paginator
    appPagination
    showFirstLastButtons
    [length]="totalRows"
    [pageIndex]="currentPage"
    [pageSize]="pageSize"
    (page)="pageChanged($event)"
    [hidden]="showNodata"
    class="custom-paginator"
    [hidePageSize]="true"
  >
  </mat-paginator>
</div>

<ngx-spinner></ngx-spinner>
