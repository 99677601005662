<div *ngIf="router.url !== '/login'">
  <app-header></app-header>
  <div class="guru-container">
    <router-outlet></router-outlet>
  </div>
</div>

<ng-container *ngIf="router.url == '/login'">
  <app-login></app-login>
</ng-container>
