<div class="mb-3">
    <div class="guru-tabs-row">
        <div></div>
        <div class="tb-btn-group">
            <ul>
                <li>
                    <div class="tb-filter-wrapper">
                        <button mat-stroked-button class="primary-stroke-button filterBtn" (click)="openDropdown()" [ngClass]="{ open: isOpenDropdown }">
                        Filter
                        </button>
                        <div class="custom-filter-sec" *ngIf="isOpenDropdown">
                        <h3>
                            Filter <button class="filterCloseBtn" (click)="closeDropdown()"></button>
                        </h3>
                        <form [formGroup]="doorStepFilterForm" name="doorStepFilterForm" class="form">
                                <div class="custom-filter-body" style="justify-content: space-evenly;">
                                </div>
                                <div class="custom-filter-body" style="justify-content: space-evenly;">
                                    <div class="custom-filter-col">
                                        <mat-form-field>
                                            <input matInput [matDatepicker]="toDatePick" placeholder="From Date" formControlName="fromDate" [max]="today">
                                            <mat-datepicker-toggle matSuffix [for]="toDatePick"></mat-datepicker-toggle>
                                            <mat-datepicker #toDatePick></mat-datepicker>
                                        </mat-form-field>
                                    </div>
                                    <div class="custom-filter-col">
                                        <mat-form-field>
                                            <input matInput [matDatepicker]="fromDatePick" placeholder="To Date" formControlName="toDate" [max]="today">
                                            <mat-datepicker-toggle matSuffix [for]="fromDatePick"></mat-datepicker-toggle>
                                            <mat-datepicker #fromDatePick></mat-datepicker>
                                        </mat-form-field>
                                    </div>
                                    <div class="custom-filter-col">
                                        <mat-form-field appearance="fill">
                                            <mat-label>Guru Name</mat-label>
                                            <input formControlName="GuruName" matInput (keydown.enter)="applyFilter()">
                                        </mat-form-field>
                                    </div>
                                    <div class="custom-filter-col">
                                        <mat-form-field appearance="fill">
                                            <mat-label>Guru Mobile Number</mat-label>
                                            <input formControlName="GuruMobileNumber" matInput (keydown.enter)="applyFilter()">
                                        </mat-form-field>
                                    </div>
                                    
                                    <div class="custom-filter-col" style="display: flex; flex-direction: column; justify-content: center;">
                                        <mat-form-field appearance="fill">
                                            <mat-label>Customer Mobile Number</mat-label>
                                            <input formControlName="MobileNumber" matInput (keydown.enter)="applyFilter()">
                                        </mat-form-field>
                                    </div>
                                    <div class="custom-filter-col" style="display: flex; flex-direction: column; justify-content: center;">
                                        <mat-form-field class="mt-10">
                                            <mat-label label for="state" class="form-label"> Select State <span
                                                class="requiredfield">*</span></mat-label>
                                            <mat-select formControlName="state" multiple>
                                                <mat-option [value]="state" *ngFor="let state of states ">{{ state }}</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div class="custom-filter-col">
                                        <mat-form-field>
                                            <mat-label label for="zone" class="form-label"> Select Region <span class="requiredfield">*</span></mat-label>
                                            <mat-select formControlName="zone" multiple>
                                                <mat-option value="North">North</mat-option>
                                                <mat-option value="East">East</mat-option>
                                                <mat-option value="West">West</mat-option>
                                                <mat-option value="South">South</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div class="custom-filter-col">
                                        <mat-form-field>
                                            <mat-label label for="lob" class="form-label"> Select Lob <span
                                                class="requiredfield">*</span></mat-label>
                                            <mat-select formControlName="lob" multiple>
                                              <mat-option [value]="lob" *ngFor="let lob of lobData">{{
                                                lob }}</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div class="custom-filter-col">
                                        <mat-form-field>
                                            <mat-label label for="PPl" class="form-label"> Select PPL <span
                                                class="requiredfield">*</span></mat-label>
                                            <mat-select formControlName="ppl" multiple>
                                              <mat-option [value]="ppl" *ngFor="let ppl of ppldata">{{
                                                ppl }}</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div class="custom-filter-col">
                                        <mat-form-field>
                                            <mat-label label for="pl" class="form-label"> Select Pl <span
                                                class="requiredfield">*</span></mat-label>
                                            <mat-select formControlName="pl" multiple>
                                              <mat-option [value]="pl" *ngFor="let pl of pldata">{{
                                                pl }}</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div class="custom-filter-col">
                                        <mat-form-field>
                                            <mat-label label for="stage_name" class="form-label"> Select Sales Stage <span
                                                class="requiredfield">*</span></mat-label>
                                            <mat-select formControlName="stage_name">
                                                <mat-option value="C0 (Prospecting)">C0 (Prospecting)</mat-option>
                                                <mat-option value="C1A (Papers submitted)">C1A (Papers submitted)</mat-option>
                                                <mat-option value="C1 (Quote Tendered)">C1 (Quote Tendered)</mat-option>
                                                <mat-option value="C2 (Adv. Received)">C2 (Adv. Received)</mat-option>
                                                <mat-option value="C3 (Vehicle Delivered)">C3 (Vehicle Delivered)</mat-option>
                                                <mat-option value="CANCELLED">CANCELLED</mat-option>
                                                <mat-option value="Closed Lost at C0">Closed Lost at C0</mat-option>
                                                <mat-option value="Closed Lost at C1">Closed Lost at C1</mat-option>
                                                <mat-option value="Closed Lost at C1A">Closed Lost at C1A</mat-option>
                                                <mat-option value="Closed Lost at C2">Closed Lost at C2</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    
                                    
                                    <div class="custom-filter-col"></div>
                                </div>
                                <div class="btn-group filterBtn-group">
                                    <button mat-stroked-button class="primary-stroke-button"(click)="reset()" >Reset</button>
                                    <button mat-flat-button class="primary-flat-button" (click)="applyFilter()">Apply</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    
    </div>

    <div class="table-wrapper">
        <div class="guru-list-table rpm-table" *ngIf="!showNodata">
        <table mat-table matTableExporter 
            class="full-width-table tb-table" matSort aria-label="Elements" 
            [dataSource]="dataSource">
            <ng-container matColumnDef="optYId" sticky>
                <th mat-header-cell *matHeaderCellDef class="optId-col" style="background-color: white">OPTY ID</th>
                <td mat-cell (click)="element?.lead_id !== 'NA' ? onRowClick(element?.lead_id) : null" *matCellDef="let element" class="optId-col  {{ element.lead_id }} text-nowrap" 
                style="box-shadow: 0 3px 10px rgb(0 0 0 / 0.2); cursor: pointer; color:rgb(39, 106, 252);  
                " >
                {{ element.lead_id }}
                </td>
            </ng-container>
            <ng-container matColumnDef="salesStage">
                <th mat-header-cell *matHeaderCellDef class="salesStage-col">
                Sales Stage
                </th>
                <td mat-cell *matCellDef="let element" class="salesStage-col text-nowrap">
                {{ element.STAGE_NAME_s }}
                </td>
            </ng-container>
            <ng-container matColumnDef="vehicleNumber">
                <th mat-header-cell *matHeaderCellDef class="vehicleNumber-col">
                Vehicle Number
                </th>
                <td mat-cell *matCellDef="let element" class="vehicleNumber-col text-nowrap">
                {{ element.vc_number }}
                </td>
            </ng-container>
            
            <ng-container matColumnDef="CustomerName">
                <th mat-header-cell *matHeaderCellDef class="CustomerName-col">
            Customer Name
                </th>
                <td mat-cell *matCellDef="let element" class="CustomerName-col">
                {{ element.prospect_name }}
                </td>
            </ng-container>
            <ng-container matColumnDef="CustomerMobile">
                <th mat-header-cell *matHeaderCellDef class="CustomerMobile text-nowrap">
                Customer Mobile
                </th>
                <td mat-cell *matCellDef="let element" class="CustomerMobile text-nowrap">
                {{ element.prospect_phone }}
                </td>
            </ng-container>
            <ng-container matColumnDef="leadCreated">
                <th mat-header-cell *matHeaderCellDef class="leadCreated-col text-nowrap">
                Lead Created
                </th>
                <td mat-cell style="text-transform: none !important;" *matCellDef="let element" class="leadCreated-col text-nowrap">
                {{ element.OPTY_CREATED_THROUGH_s | uppercase }}
                </td>
            </ng-container>
            <ng-container matColumnDef="updatedDate">
                <th mat-header-cell *matHeaderCellDef class="updatedDate-col text-nowrap">
                Updated date
                </th>
                <td mat-cell *matCellDef="let element" class="updatedDate-col text-nowrap">
                {{ element.LAST_UPD_dt | date:"dd-MMM-yyyy" }}
                </td>
            </ng-container>
            <ng-container matColumnDef="createdDate">
                <th mat-header-cell *matHeaderCellDef class="createdDate-col text-nowrap">
                Created date
                </th>
                <td mat-cell *matCellDef="let element" class="createdDate-col text-nowrap">
                {{ element.CREATED_dt | date:"dd-MMM-yyyy" }}
                </td>
            </ng-container>
            <ng-container matColumnDef="lob">
                <th mat-header-cell *matHeaderCellDef class="lob-col text-nowrap">
                LOB
                </th>
                <td mat-cell *matCellDef="let element" class="lob-col text-nowrap">
                {{ element.lob }}
                </td>
            </ng-container>
            <ng-container matColumnDef="ppl">
                <th mat-header-cell *matHeaderCellDef class="ppl-col text-nowrap">
                PPL
                </th>
                <td mat-cell *matCellDef="let element" class="ppl-col text-nowrap">
                {{ element.ppl }}
                </td>
            </ng-container>
            <ng-container matColumnDef="pl">
                <th mat-header-cell *matHeaderCellDef class="pl-col text-nowrap">
                PL
                </th>
                <td mat-cell *matCellDef="let element" class="pl-col text-nowrap">
                {{ element.pl}}
                </td>
            </ng-container>
            <ng-container matColumnDef="guruName">
                <th mat-header-cell *matHeaderCellDef class="guruName-col text-nowrap">
                Guru Name
                </th>
                <td mat-cell *matCellDef="let element" class="guruName-col text-nowrap">
                {{ element.guru_name}} 
                </td>
            </ng-container>
            <ng-container matColumnDef="dealerName">
                <th mat-header-cell *matHeaderCellDef class="dealerName-col text-nowrap">
                    Dealer Name
                </th>
                <td mat-cell *matCellDef="let element" class="dealerName-col text-nowrap">
                {{ (element.guru__dealer_name) || "-"}} 
                </td>
            </ng-container>
            <!-- <ng-container matColumnDef="dealerUserName">
                <th mat-header-cell *matHeaderCellDef class="dealerUserName-col text-nowrap">
                    Dealer User Name
                </th>
                <td mat-cell *matCellDef="let element" class="dealerUserName-col text-nowrap text-center">
                {{ (element.LOGIN_s) || "-"}} 
                </td>
            </ng-container> -->
            <ng-container matColumnDef="GuruMobileNumber">
                <th mat-header-cell *matHeaderCellDef class="GuruMobileNumber-col text-nowrap">
                Guru Mobile
                </th>
                <td mat-cell *matCellDef="let element" class="GuruMobileNumber-col text-nowrap">
                {{ element.guru_mobile_number}} 
                </td>
            </ng-container>
            <ng-container matColumnDef="division">
                <th mat-header-cell *matHeaderCellDef class="division-col text-nowrap text-center">
                Division Name
                </th>
                <td mat-cell *matCellDef="let element" class="division-col text-nowrap text-center">
                {{ element.DIV_NAME_s}} 
                </td>
            </ng-container>
            <ng-container matColumnDef="quantity">
                <th mat-header-cell *matHeaderCellDef class="quantity-col text-nowrap">
                Quantity 
                </th>
                <td mat-cell *matCellDef="let element" class="quantity-col text-nowrap" style="text-align: center;">
                    {{ element.quantity }} 
            </td>
            </ng-container>
            <ng-container matColumnDef="state">
                <th mat-header-cell *matHeaderCellDef class="state-col text-nowrap">
                State
                </th>
                <td mat-cell *matCellDef="let element" class="state-col text-nowrap">
                {{ element.prospect_state}} 
                </td>
            </ng-container>
            <ng-container matColumnDef="city">
                <th mat-header-cell *matHeaderCellDef class="city-col text-nowrap">
                City
                </th>
                <td mat-cell *matCellDef="let element" class="city-col text-nowrap">
                {{ element.prospect_city}} 
                </td>
            </ng-container>
            <ng-container matColumnDef="district">
                <th mat-header-cell *matHeaderCellDef class="district-col text-nowrap">
                District
                </th>
                <td mat-cell *matCellDef="let element" class="district-col text-nowrap">
                {{ element.prospect_district}} 
                </td>
            </ng-container>
            <ng-container matColumnDef="pincode">
                <th mat-header-cell *matHeaderCellDef class="district-col text-nowrap">
                Pincode
                </th>
                <td  mat-cell *matCellDef="let element" class="district-col guru-last-co-leads text-nowrap">
                {{ element.prospect_pincode}}
                </td>
            </ng-container>
            <tr class="tb-head-row" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr class="tb-item-row" mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
        </div>
    
        <ng-container *ngIf="showNodata">
        <div class="no-item-found">No record found</div>
        </ng-container>
    
        <mat-paginator
        appPagination
        showFirstLastButtons
        [length]="totalRows"
        [pageIndex]="currentPage"
        [pageSize]="pageSize"
        [hidden]="showNodata"
        (page)="pageChanged($event)"
        class="custom-paginator"
        [hidePageSize]="true"
        >
        </mat-paginator>
    </div>
</div>
<ng-template #optyDetailsPopup let-d="dismiss">
    <app-opty-detail [optyData]="optyDetails"></app-opty-detail>
</ng-template>  
<div class="action-toaster" *ngIf="showToastermessage" style="z-index: 9999;">
    <div class="toaster-msg alert alert-danger" *ngIf="showfinalErrorMsg">
        {{ showfinalErrorMsg }}
    </div>
</div>
<ngx-spinner></ngx-spinner>