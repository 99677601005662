<div class="PartsPerformanceComponent">
  <h2>
    parts performance
    <small class="changes-subhead">(Percentage change over last month)</small>
  </h2>
  <div class="partsPerformancetable comn-box">
    <div class="partsinfo-head">
      <div class="partsinfo-row">
        <div class="parts-col">Details</div>
        <div class="parts-col tata-genuine"></div>
        <div class="parts-col nonTGP">
          NON <br />
          TGP
        </div>
        <!-- <div class="parts-col durafit"></div> -->
        <!-- <div class="parts-col tata-profile"></div> -->
        <div class="parts-col">Total</div>
      </div>
    </div>
    <div class="partsinfo-body">
      <!-- <div class="partsinfo-row">
        <div class="parts-col">Points Earned</div>
        <div class="parts-col">
          267 <span class="performance-point increaseValue">3%</span>
        </div>
        <div class="parts-col">
          231 <span class="performance-point decreaseValue">6%</span>
        </div>
        <div class="parts-col">
          512 <span class="performance-point increaseValue">9%</span>
        </div>
         <div class="parts-col">
          1010 <span class="performance-point increaseValue">2%</span>
        </div> 
      </div> -->
      <div class="partsinfo-row">
        <div class="parts-col">Part Items Sold</div>
        <div class="parts-col">
          {{ tgpPartConsumed }}
          <span class="performance-point" [ngClass]="
              tgpPartpercentchange < 0 ? ' decreaseValue' : ' increaseValue'
            ">{{ tgpPartpercentchange | absnew | number: "1.0-0" }}%</span>
        </div>
        <div class="parts-col">
          {{ nontgpPartConsumed }}
          <span class="performance-point" [ngClass]="
              nontgpPartpercentchange < 0 ? ' decreaseValue' : ' increaseValue'
            ">{{ nontgpPartpercentchange | absnew | number: "1.0-0" }}%</span>
        </div>
        <div class="parts-col">
          {{ totalPartConsumed }}
          <span class="performance-point" [ngClass]="
              totalPartpercentchange < 0 ? ' decreaseValue' : ' increaseValue'
            ">
            {{ totalPartpercentchange | absnew | number: "1.0-0" }}%</span>
        </div>
        <!-- <div class="parts-col">
          900 <span class="performance-point decreaseValue">8%</span>
        </div> -->
      </div>
      <!-- <div class="partsinfo-row">
        <div class="parts-col">Coupons Scanned</div>
        <div class="parts-col">
          9872 <span class="performance-point decreaseValue">2%</span>
        </div>
        <div class="parts-col">
          982 <span class="performance-point increaseValue">4%</span>
        </div>
        <div class="parts-col">
          1248 <span class="performance-point increaseValue">9%</span>
        </div>
     <div class="parts-col">
          12102 <span class="performance-point increaseValue">7%</span>
        </div>
      </div> -->
    </div>
  </div>
</div>