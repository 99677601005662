<div class="item-container">
  <h2>Line Items Summary</h2>
  <div class="guru-tabs-row">
    <div class="guru-tabs-items">
      <ul>
        <li
          [ngClass]="{ 'active-tab': selectedTabItem === 'slow' }"
          (click)="selectedTab('slow')"
        >
          <a>Slow Moving Parts</a>
        </li>
        <li
          [ngClass]="{ 'active-tab': selectedTabItem === 'fast' }"
          (click)="selectedTab('fast')"
        >
          <a>Fast Moving Parts</a>
        </li>
      </ul>
    </div>
    <div class="tb-right-col">
      <div class="tb-searchbox">
        <button class="tb-button guru-flex" (click)="searchData()">
          <div class="tb__search_icon"></div>
        </button>
        <input
          type="text"
          class="tb-search-text"
          appOnlyAlphaNumeric
          placeholder="Search by Enter Part No. or Name"
          [(ngModel)]="searchText"
          (keyup)="checkText($event)"
        />
      </div>
      <div class="prev-next-col">
        <a
          class="link-button"
          (click)="prevnextMovingItems('prev')"
          [ngClass]="{ disabled: this.disablePrevPaginations }"
          >Previous</a
        >
        <a
          class="link-button"
          (click)="prevnextMovingItems('next')"
          [ngClass]="{ disabled: this.disableNextPaginations }"
          >Next</a
        >
      </div>
    </div>
  </div>

  <div class="no-item-found" *ngIf="!promotionalItems.length">
    No record found
  </div>

  <mat-grid-list
    cols="4"
    gutterSize="32px"
    rowHeight="363"
    class=""
    *ngIf="promotionalItems.length > 0"
  >
    <mat-grid-tile class="item-box" *ngFor="let item of promotionalItems">
      <div class="card-item-inner-box">
        <div class="item-display">
          <div class="item-image">
            <img [src]="item.part_image" alt="part" />
          </div>
          <div class="item-text">
            <h2 class="item-heading">
              {{ item.name }}
            </h2>
            <p class="item-size">{{ item.part_number }}</p>
          </div>
        </div>
        <div class="item-footer" style="max-height: 36px">
          <div class="item-footer-text item-bg-color">
            <span>MRP: Rs {{ item.price }}</span>
            <!-- <span>Points: {{ item.point }}</span> -->
            <span>Sales: {{ item.sales }}</span>
          </div>
          <!-- <div class="item-footer-text item-bg-color">
            <span class="text-red-color">Sales: {{ item.sales }}</span>
            <span class="text-grey-color">Target: {{ item.target }}</span>
          </div> -->
        </div>
      </div>
    </mat-grid-tile>
  </mat-grid-list>
</div>
<ngx-spinner></ngx-spinner>
