<div class="tb-filter-wrapper">
    <button mat-stroked-button class="primary-stroke-button filterBtn" (click)="openDropdown()" [ngClass]="{ open: isOpenDropdown }">
      Filter
    </button>
    <div class="custom-filter-sec" *ngIf="isOpenDropdown">
      <h3>
        Filter <button class="filterCloseBtn" (click)="closeDropdown()"></button>
      </h3>
      <form [formGroup]="referrelReportForm" name="doorStepFilterForm" class="form">
            <div class="custom-filter-body" style="justify-content: space-evenly;">
                <div class="custom-filter-col">
                    <mat-form-field>
                        <input matInput [matDatepicker]="toDatePick" placeholder="From Date" formControlName="fromDate" [max]="today">
                        <mat-datepicker-toggle matSuffix [for]="toDatePick"></mat-datepicker-toggle>
                        <mat-datepicker #toDatePick></mat-datepicker>
                    </mat-form-field>
                </div>
                <div class="custom-filter-col">
                    <mat-form-field>
                        <input matInput [matDatepicker]="fromDatePick" placeholder="To Date" formControlName="toDate" [max]="today" >
                        <mat-datepicker-toggle matSuffix [for]="fromDatePick"></mat-datepicker-toggle>
                        <mat-datepicker #fromDatePick></mat-datepicker>
                    </mat-form-field>
                </div>
            </div>
            <div class="custom-filter-body" style="justify-content: space-evenly;">
                <!-- <div class="custom-filter-col">
                  <mat-form-field appearance="fill">
                      <mat-label>Guru Name</mat-label>
                      <input formControlName="guru_name" matInput>
                    </mat-form-field>
                </div> -->
                <div class="custom-filter-col">
                    <mat-form-field appearance="fill">
                        <mat-label>Guru Phone Number</mat-label>
                        <input formControlName="guru_number" matInput>
                      </mat-form-field>
                </div>
                <div class="custom-filter-col">
                  <mat-form-field appearance="fill">
                      <mat-label>Job Card Id</mat-label>
                      <input formControlName="jobcardid" matInput>
                    </mat-form-field>
                </div>
                <div class="custom-filter-col" style="margin-top: 12px;">
                  <mat-form-field>
                    <mat-label>Job Card Created Date</mat-label>
                    <input matInput [matDatepicker]="jobCardOpeningDate" formControlName="jobCardOpeningDate">
                    <mat-datepicker-toggle matSuffix [for]="jobCardOpeningDate"></mat-datepicker-toggle>
                    <mat-datepicker #jobCardOpeningDate></mat-datepicker>
                  </mat-form-field>
                </div>
                <div class="custom-filter-col">
                  <mat-form-field appearance="fill">
                      <mat-label>Lass Name</mat-label>
                      <input formControlName="lassnme" matInput>
                    </mat-form-field>
                </div>
                <div class="custom-filter-col">
                    <mat-form-field appearance="fill">
                        <mat-label>Lass Phone Number</mat-label>
                        <input formControlName="lassContactNo" matInput>
                      </mat-form-field>
                </div>
                <div class="custom-filter-col">
                    <mat-form-field appearance="fill">
                        <mat-label>Chassis Number</mat-label>
                        <input formControlName="chasis_no" matInput>
                      </mat-form-field>
                </div>
                <!-- <div class="custom-filter-col">
                  <mat-form-field appearance="fill">
                      <mat-label>Emission Norms</mat-label>
                      <input formControlName="emissionNorms" matInput>
                    </mat-form-field>
                </div> -->
                <div class="custom-filter-col">
                  <mat-form-field appearance="fill">
                      <mat-label>Lob</mat-label>
                      <input formControlName="lob" matInput>
                    </mat-form-field>
                </div>
                <div class="custom-filter-col">
                    <mat-form-field appearance="fill">
                        <mat-label>PL</mat-label>
                        <input formControlName="pl" matInput>
                      </mat-form-field>
                </div>
                <div class="custom-filter-col" style="margin-top: 12px;">
                    <mat-form-field appearance="fill">
                        <mat-label>PPL</mat-label>
                        <input formControlName="ppl" matInput>
                      </mat-form-field>
                </div>
                <div class="custom-filter-col">
                  <label for="region" class="form-label">Select Case Type <span class="requiredfield">*</span></label>
                  <select class="form-control" formControlName="case_type" (change)="selectRegion()">
                    <option value="">Please select case type</option>
                    <option value="Doorstep Booking"> Doorstep Booking </option>
                    <!-- <option value="Web Doorstep Booking"> Web Doorstep Booking </option>
                    <option value="OTC Doorstep Booking"> OTC Doorstep Booking </option>
                    <option value="Web Vehicle Breakdown"> Web Vehicle Breakdown </option> -->
                    <option value="Vehicle Breakdown"> Vehicle Breakdown </option>
                  </select>
                </div>

                <div class="custom-filter-col" *ngIf="role=='SuperAdmin'">
                    <label for="region" class="form-label">Select Region <span class="requiredfield">*</span></label>
                    <select class="form-control" formControlName="zone" (change)="selectRegion()">
                      <option value="">Please select Region</option>
                      <option [value]="region" *ngFor="let region of regions">
                        {{ region }}
                      </option>
                    </select>
                </div>
                <div class="custom-filter-col">
                    <label for="region" class="form-label">Select State <span class="requiredfield">*</span></label>
                    <select class="form-control" formControlName="state" (change)="selectRegion()">
                      <option value="">Please select State</option>
                      <option [value]="state.state_name" *ngFor="let state of states">
                        {{ state.state_name }}
                      </option>
                    </select>
                </div>
                <div class="custom-filter-col">
                  <label for="sr" class="form-label">SR Type <span class="requiredfield">*</span></label>
                  <select class="form-control" formControlName="sr">
                    <option value="">Please select SR type</option>
                    <option value="First Free Service">First Free Service</option>
                    <option value="Second Free Service">Second Free Service</option>
                    <option value="Third Free Service">Third Free Service</option>
                    <option value="Paid Service">Paid Service</option>
                    <option value="Break-down">Break-down</option>
                  </select>
              </div>

              <div class="custom-filter-col" *ngIf="role == 'SuperAdmin'" style="margin-top: 12px;">
                <mat-form-field appearance="fill">
                  <mat-label>Dealer</mat-label>
                  <input formControlName="dealer" matInput>
                </mat-form-field>
            </div>
                <div class="custom-filter-col"></div>
            </div>
            <div class="btn-group filterBtn-group">
                <button mat-stroked-button class="primary-stroke-button"(click)="reset()" >Reset</button>
                <button mat-flat-button class="primary-flat-button" (click)="applyFilter()">Apply</button>
            </div>
        </form>
    </div>
</div>

<div class="action-toaster" *ngIf="showToastermessage">
    <div class="toaster-msg alert alert-danger" *ngIf="showfinalErrorMsg">
      {{ showfinalErrorMsg }}
    </div>
</div>
