<div class="mb-3">
    <div class="guru-tabs-row">
        <div></div>
        <div class="tb-btn-group">
            <ul>
                <li>
                    <app-problem-sub-problem-filter #childComponent (formEventTrigger)="formControlValue($event)"></app-problem-sub-problem-filter>
                </li>
                <li>
                    <button mat-stroked-button class="primary-stroke-button exportBtn" (click)="downloadProblemSubProblemListListExcel()">
                        Export Excel
                    </button>
                </li>
                <li>
                    <button mat-stroked-button class="primary-stroke-button exportBtn" (click)="pageChanged(0)">
                    Refresh
                    </button>
                </li>
            </ul>
        </div>
    </div>
    <div class="table-wrapper">
        <div class="guru-list-table rpm-table" *ngIf="!showNodata">
        <table mat-table matTableExporter 
            class="full-width-table tb-table" matSort aria-label="Elements" 
            [dataSource]="dataSource">
            <ng-container matColumnDef="problemId" sticky>
                <th mat-header-cell *matHeaderCellDef class="problemId-col" style="background-color: white;">ID</th>
                <td mat-cell *matCellDef="let element" class="problemId-col  {{ element.id }} text-nowrap" style="box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
                ">
                {{ element.id }}
                </td>
            </ng-container>
            <ng-container matColumnDef="problemCreatedDate">
                <th mat-header-cell *matHeaderCellDef class="problemCreatedDate-col">
                Created Date
                </th>
                <td mat-cell *matCellDef="let element" class="problemCreatedDate-col">
                {{ element.created_date | date: "dd/MM/yyyy" }}
                </td>
            </ng-container>
            <ng-container matColumnDef="problem">
                <th mat-header-cell *matHeaderCellDef class="problem-col">
                Problem
                </th>
                <td mat-cell *matCellDef="let element" class="problem-col text-nowrap">
                {{ element.problem.problem_english }}
                </td>
            </ng-container>
            <ng-container matColumnDef="subProblem">
                <th mat-header-cell *matHeaderCellDef class="subProblem-col">
                Sub Problem
                </th>
                <td mat-cell *matCellDef="let element" class="subProblem-col text-nowrap">
                    <select *ngIf="element.sub_problem && element.sub_problem.length > 1" class="form-control sub-problem-list">
                        <option *ngFor="let sb_prbl of element.sub_problem">
                            {{ sb_prbl }}
                        </option>
                    </select>
                    <span style="padding-left: 10px;" *ngIf="element.sub_problem && element.sub_problem.length == 1">{{element.sub_problem[0]}}</span>
                    <span style="padding-left: 10px;" *ngIf="!element.sub_problem.length">NA</span>
                </td>
            </ng-container>
            <ng-container matColumnDef="jobCardId">
                <th mat-header-cell *matHeaderCellDef class="jobCardId text-nowrap">
                Job card Id
                </th>
                <td mat-cell *matCellDef="let element" class="jobCardId text-nowrap text-center ">
                {{ element.jobcard?element.jobcard.jobcard_id:"NA" }}
                </td>
            </ng-container>
            <ng-container matColumnDef="jobCardCaseId">
                <th mat-header-cell *matHeaderCellDef class="jobCardCaseId-col text-nowrap">
                Job Card Case Id
                </th>
                <td mat-cell style="text-transform: none !important;" *matCellDef="let element" class="jobCardCaseId-col text-nowrap text-center">
                {{ element.jobcard?element.jobcard.case_id:"NA" }}
                </td>
            </ng-container>
            <ng-container matColumnDef="jobCardCreatedDate">
                <th mat-header-cell *matHeaderCellDef class="jobCardCreatedDate-col text-nowrap">
                Job Created Date
                </th>
                <td mat-cell *matCellDef="let element" class="jobCardCreatedDate-col text-nowrap text-center">
                {{ element.jobcard?(element.jobcard.jc_created_date| date: "dd/MM/yyyy"):"NA"  }}
                </td>
            </ng-container>
            <ng-container matColumnDef="jobCardUpdatedDate">
                <th mat-header-cell *matHeaderCellDef class="jobCardUpdatedDate-col text-nowrap">
                Job Card Updated Date
                </th>
                <td mat-cell *matCellDef="let element" class="jobCardUpdatedDate-col text-nowrap text-center">
                {{ element.jobcard?(element.jobcard.jc_updated_date | date: "dd/MM/yyyy"):"NA"  }}
                </td>
            </ng-container>
            <ng-container matColumnDef="userID">
                <th mat-header-cell *matHeaderCellDef class="userID-col text-nowrap">
                User Id
                </th>
                <td mat-cell *matCellDef="let element" class="userID-col text-nowrap">
                {{ element.jobcard?element.jobcard.user_id:"NA" }}
                </td>
            </ng-container>
            <ng-container matColumnDef="garageName">
                <th mat-header-cell *matHeaderCellDef class="garageName-col text-nowrap">
                Garage Name
                </th>
                <td mat-cell *matCellDef="let element" class="garageName-col text-nowrap">
                {{ element.jobcard?element.jobcard.garage_name:"NA" }}
                </td>
            </ng-container>
            <ng-container matColumnDef="guruId">
                <th mat-header-cell *matHeaderCellDef class="guruId-col text-nowrap">
                Guru Id
                </th>
                <td mat-cell *matCellDef="let element" class="guruId-col text-nowrap">
                {{ element.jobcard?element.jobcard.guru_id:"NA" }}
                </td>
            </ng-container>
            <ng-container matColumnDef="guruName">
                <th mat-header-cell *matHeaderCellDef class="guruName-col text-nowrap">
                Guru Name
                </th>
                <td mat-cell *matCellDef="let element" class="guruName-col text-nowrap">
                {{ element.jobcard?element.jobcard.guru_name:"NA" }}
                </td>
            </ng-container>
            <ng-container matColumnDef="guruContactNo">
                <th mat-header-cell *matHeaderCellDef class="guruContactNo-col text-nowrap">
                Guru Contact Number
                </th>
                <td mat-cell *matCellDef="let element" class="guruContactNo-col text-nowrap text-center">
                {{ element.jobcard?element.jobcard.guru_mobile_no:"NA" }} 
                </td>
            </ng-container>
            <ng-container matColumnDef="guruState">
                <th mat-header-cell *matHeaderCellDef class="guruState-col text-nowrap">
                Guru State
                </th>
                <td mat-cell *matCellDef="let element" class="guruState-col text-nowrap">
                {{ element.jobcard && element.jobcard.state_name?element.jobcard.state_name:"NA" }} 
                </td>
            </ng-container>
            <ng-container matColumnDef="guruRegion">
                <th mat-header-cell *matHeaderCellDef class="guruRegion-col text-nowrap">
                Guru Region
                </th>
                <td mat-cell *matCellDef="let element" class="guruRegion-col text-nowrap text-center">
                {{ element.jobcard?element.jobcard.region:"NA" }} 
                </td>
            </ng-container>
            <ng-container matColumnDef="lassName">
                <th mat-header-cell *matHeaderCellDef class="lassName-col text-nowrap">
                Lass Name
                </th>
                <td mat-cell *matCellDef="let element" class="lassName-col text-nowrap">
                {{ element.jobcard?element.jobcard.lass_name:"NA" }} 
                </td>
            </ng-container>
            <ng-container matColumnDef="lassNumber">
                <th mat-header-cell *matHeaderCellDef class="lassNumber-col text-nowrap">
                Lass Number
                </th>
                <td mat-cell *matCellDef="let element" class="lassNumber-col-prbsubprb text-nowrap">
                {{ element.jobcard?element.jobcard.lass_number:"NA" }} 
                </td>
            </ng-container>
            <tr class="tb-head-row" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr class="tb-item-row" mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
        </div>
    
        <ng-container *ngIf="showNodata">
        <div class="no-item-found">No record found</div>
        </ng-container>
    
        <mat-paginator
        appPagination
        showFirstLastButtons
        [length]="totalRows"
        [pageIndex]="currentPage"
        [pageSize]="pageSize"
        [hidden]="showNodata"
        (page)="pageChanged($event)"
        class="custom-paginator"
        [hidePageSize]="true"
        >
        </mat-paginator>
    </div>
</div>
<div class="action-toaster" *ngIf="showToastermessage">
    <div class="toaster-msg alert alert-danger" *ngIf="showfinalSuccessMsg">
        {{ showfinalSuccessMsg }}
    </div>
</div>
<ngx-spinner></ngx-spinner>