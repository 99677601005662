<div class="head-dropdown">
  <h2>
    Reports
    <span class="subhead"
      >Part summary -
      <span *ngIf="step === 'partsfitment'">Part Fitments</span>
      <span *ngIf="step === 'targetachieved'">Parts fitted</span>
      <span *ngIf="step === 'targetcompetitor'">Target Vs Competitor</span>
    </span>
  </h2>
</div>

<div class="bandhu-tabs report-summary-tabs">
  <div>
    <mat-button-toggle-group
      name="fontStyle"
      [value]="brandType"
      aria-label="Font Style"
    >
      <mat-button-toggle value="tgp" (change)="onBrandTypeChange($event.value)"
        >TGP</mat-button-toggle
      >
      <mat-button-toggle value="ntgp" (change)="onBrandTypeChange($event.value)"
        >NON TGP</mat-button-toggle
      >

      <!-- <mat-button-toggle value="Durafit">Durafit</mat-button-toggle>
      <mat-button-toggle value="Proflife">Proflife</mat-button-toggle> -->
    </mat-button-toggle-group>
  </div>

  <div class="tb-btn-group" *ngIf="step === 'targetcompetitor'">
    <ul>
      <li>
        <div class="tb-searchbox">
          <button class="tb-button guru-flex">
            <div class="tb__search_icon"></div>
          </button>
          <input
            type="text"
            class="tb-search-text"
            placeholder="Search by Guru ID"
          />
        </div>
      </li>
    </ul>
  </div>
</div>
<div class="guru-tabs-row">
  <div class="guru-tabs-items">
    <ul>
      <li
        [ngClass]="{ 'active-tab': step === 'partsfitment' }"
        (click)="step = 'partsfitment'"
      >
        <a>Part Fitments</a>
      </li>
      <li
        [ngClass]="{ 'active-tab': step === 'targetachieved' }"
        (click)="step = 'targetachieved'"
      >
        <a>Top Parts Fitted</a>
      </li>
      <!-- <li
        [ngClass]="{ 'active-tab': step === 'targetcompetitor' }"
        (click)="step = 'targetcompetitor'"
      >
        <a>Target Vs Competitor</a>
      </li> -->
    </ul>
  </div>
  <!-- <div class="tb-btn-group">
    <ul>
      <li *ngIf="step !== 'partsfitment'">
        <mat-form-field
          appearance="outline"
          floatLabel="always"
          class="form-date"
        >
          <mat-date-range-input
            [formGroup]="campaignOne"
            [rangePicker]="campaignOnePicker"
            [comparisonStart]="campaignTwo.value.start"
            [comparisonEnd]="campaignTwo.value.end"
          >
            <input
              matStartDate
              placeholder="Start date"
              formControlName="start"
            />
            <input matEndDate placeholder="End date" formControlName="end" />
          </mat-date-range-input>
          <mat-datepicker-toggle
            matSuffix
            [for]="campaignOnePicker"
          ></mat-datepicker-toggle>
          <mat-date-range-picker #campaignOnePicker></mat-date-range-picker>
        </mat-form-field>
      </li>
      <li *ngIf="step === 'partsfitment'">
        <div class="tb-select-dropdown">
          <div class="tb-selectedVal" (click)="selectValfun()">
            {{ selectedItem }}
          </div>
          <ul class="tb-dropdown-options" *ngIf="showDropdown">
            <li
            *ngFor="let item of commonService.viewTypeOptions; let i = index;"
            (click)="select(i)"
            [ngClass]="{ selected: isActive(item.paramName) }"
          >
            {{ item.title }}
          </li>

          </ul>
        </div>
      </li>

      <li class="hidden">
        <app-gurulist-filter></app-gurulist-filter>
      </li>
    </ul>
  </div> -->
</div>

<div class="report-subcomponent-wrapper">
  <app-parts-fitment
    *ngIf="step === 'partsfitment'"
    [selectedBrand]="brandType"
    [selectedView]="selected"
  >
  </app-parts-fitment>
  <app-target-vs-achieved
    *ngIf="step === 'targetachieved'"
    [selectedBrand]="brandType"
    [selectedView]="selected"
  >
  </app-target-vs-achieved>
  <app-target-vs-competitor
    *ngIf="step === 'targetcompetitor'"
  ></app-target-vs-competitor>
</div>
