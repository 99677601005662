<div class="modal-content">
    <div class="modal-header">
        <h5 class="modal-title">Service Details</h5>
        <button type="button" class="btn-close" (click)="close()"></button>
    </div>
    <div class="modal-body">
        <!-- Customer Details -->
        <div class="accordion" id="accordionDetails">
            <div class="accordion-item">
                <h2 class="accordion-header" id="headingCustomer">
                    <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseCustomer" aria-expanded="true" aria-controls="collapseCustomer">
                        <strong>Customer Details</strong> 
                    </button>
                </h2>
                <div id="collapseCustomer" class="accordion-collapse collapse show" aria-labelledby="headingCustomer">
                    <div class="accordion-body">
                        <div class="row">
                            <div class="col">
                                <strong>Registration Number:</strong> <h3>{{partData.customer.registration_number}}</h3>
                            </div>
                            <div class="col">
                                <strong>Chassis Number:</strong> <h3>{{partData.customer.chassis_number}}</h3>
                            </div>
                            <div class="col">
                                <strong>Customer Name:</strong> <h3>{{partData.customer.customer_name}}</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Guru Details -->
        <div class="accordion" id="accordionDetails">
            <div class="accordion-item">
                <h2 class="accordion-header" id="headingGuru">
                    <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseGuru" aria-expanded="true" aria-controls="collapseGuru">
                        <strong>Guru Details</strong> 
                    </button>
                </h2>
                <div id="collapseGuru" class="accordion-collapse collapse show" aria-labelledby="headingGuru">
                    <div class="accordion-body">
                        <div class="row">
                            <div class="col-lg-3">
                                <strong>Guru Mobile:</strong> <h3>{{partData.guru_details.guru_mobile_number}}</h3>
                            </div>
                            <div class="col-lg-3">
                                <strong>Guru Name:</strong> <h3>{{partData.guru_details.mechanic_name}}</h3>
                            </div>
                            <div class="col-lg-3">
                                <strong>Garage Name:</strong> <h3>{{partData.guru_details.garage_name}}</h3> 
                            </div>
                            <div class="col-lg-3">
                                <strong>Zone:</strong> <h3>{{partData.guru_details.zone}}</h3> 
                            </div>
                        </div>
                            
                        <div class="row">
                            <div class="col-lg-3"> 
                                <strong>State:</strong> <h3>{{partData.guru_details.state_name}}</h3>
                            </div>
                            <div class="col-lg-3">
                                <strong>District:</strong> <h3>{{partData.guru_details.district}}</h3>
                            </div>
                            <div class="col-lg-3">
                                <strong>City:</strong> <h3>{{partData.guru_details.city_name}}</h3>
                            </div>
                            <div class="col-lg-3">
                                <!-- <strong>Zone:</strong> <h3>{{partData.guru_details.zone}}</h3>  -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Job Details -->
            <div class="accordion-item">
                <h2 class="accordion-header" id="headingJob">
                    <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseJob" aria-expanded="true" aria-controls="collapseJob">
                        <strong>Job Details</strong> 
                    </button>
                </h2>
                <div id="collapseJob" class="accordion-collapse collapse show" aria-labelledby="headingJob">
                    <div class="accordion-body">
                        <div class="row">
                            <div class="col-lg-3" *ngIf="jobId">
                                <strong>Job Id:</strong> <h3>{{jobId}}</h3> 
                            </div>
                            <div class="col-lg-3">
                            <strong>SR Type:</strong> 
                            <div class="d-flex align-items-center">
                                <h3 *ngIf="!editingSRType" style="min-width: 130px;">{{(partData.job_type) || "---"}}</h3>
                                <!-- <div *ngIf="partData.jc_status !== 'Rejected' && partData.jc_status !== 'Approved' && jobRejected !== 'Rejected' && jobRejected !== 'Approved'">
                                <input *ngIf="editingSRType" [(ngModel)]="partData.job_type" type="text" style="width: 130px; min-width: 130px;">
                                <button *ngIf="!editingSRType" class="btn btn-primary custom-button1 ml-3 mb-3" (click)="editingSRType = true"><i class="fa fa-edit"></i></button>
                                <button *ngIf="editingSRType" class="btn btn-success custom-button1 ml-3" 
                                (click)="onSave(partData.job_type, partData.part_details.quantity, partData.part_details.part_number, partData.odometer_reading)"><i class="fa fa-save"></i></button>
                                </div> -->
                            </div>
                            
                            </div>
                            <div class="col-lg-3">
                                <strong>Job Code:</strong> <h3>{{partData.job_code}}</h3> 
                            </div>
                           
                            <div class="col-lg-3">
                                <strong>Complaint: </strong> <h3>Schedule Service (SSC)</h3>
                            </div>
                            
                        </div>
                    
                        <div class="row">
                            <div class="col-lg-3">
                                <strong>Labour Cost:</strong> <h3>{{partData.labour_charges}}</h3> 
                            </div>
                            <div class="col-lg-4">
                                <strong>Odometer Reading:</strong> 
                                <div class="d-flex align-items-center">
                                    <h3 *ngIf="!editingOdometerReading" style="min-width: 60px;">{{partData.odometer_reading}}</h3>
                                    <div *ngIf="partData.jc_status !== 'Rejected' && partData.jc_status !== 'Approved' && jobRejected !== 'Rejected' && jobRejected !== 'Approved'">
                                        <input 
                                            maxlength="6" 
                                            *ngIf="editingOdometerReading" 
                                            [(ngModel)]="tempOdometerReading" 
                                            type="number" 
                                            style="width: 70px; min-width: 70px;"
                                            (keypress)="validateInput($event)"
                                        >
                                        <button *ngIf="editingOdometerReading" class="btn btn-danger custom-button1 ml-3" (click)="cancelEdit()">
                                            <i class="fa fa-close"></i>
                                        </button>
                                            
                                        <button *ngIf="!editingOdometerReading" class="btn btn-primary custom-button1 ml-3 mb-3" (click)="startEdit()">
                                            <i class="fa fa-pen"></i>
                                        </button>
                                        <button *ngIf="editingOdometerReading" class="btn btn-success custom-button1 ml-3" 
                                            (click)="onSave(partData.job_type, partData.part_details.quantity, partData.part_details.part_number, tempOdometerReading)">
                                            <i class="fa fa-save"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-5">
                                <strong>Rate List: <span style="color: red;" *ngIf="partData.jc_status !== 'Rejected' && partData.jc_status !== 'Approved' && jobRejected !== 'Rejected' && jobRejected !== 'Approved'">*</span></strong>
                                
                                <div *ngIf="selectedRateListAPI"><strong>{{ selectedRateListAPI }}</strong></div>
                                
                                <ng-select 
                                    *ngIf="partData.jc_status !== 'Rejected' && partData.jc_status !== 'Approved' && jobRejected !== 'Rejected' && jobRejected !== 'Approved'"
                                    class="custom-dropdown" 
                                    [(ngModel)]="selectedRateListId" 
                                    [items]="fetchData" 
                                    bindLabel="NAME_s" 
                                    bindValue="ROW_ID" 
                                    placeholder="Select Rate List">
                                    
                                    <ng-option *ngFor="let labourRate of fetchData; let i = index" [value]="labourRate.ROW_ID">
                                        {{ i + 1 }}. {{ labourRate.NAME_s }}
                                    </ng-option>
                                </ng-select>
                            
                                
                            </div>
                        </div>  
                        
                    </div>
                </div>
            </div>
            <!-- Problem Details -->
            <div class="accordion-item" >
                <h2 class="accordion-header" id="headingProblem">
                    <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseProblem" aria-expanded="true" aria-controls="collapseProblem">
                        <strong>Problem Details</strong> 
                    </button>
                </h2>
                <div id="collapseProblem" class="accordion-collapse collapse show" aria-labelledby="headingProblem">
                    <div class="accordion-body">
                        <div class="row">
                            <div class="col"><strong>Sr no:</strong></div>
                            <div class="col"><strong>Problem:</strong></div>
                            <div class="col"><strong>Sub Problem:</strong></div>
                        </div>
                        <div *ngFor="let problemDetail of partData.problem_details; let i = index">
                            <div class="row">
                                <div class="col"><h3>{{i + 1}}</h3></div>
                                <div class="col"><h3>{{problemDetail.problem.problem_english}}</h3></div>
                                <div class="col">
                                    <ul>
                                        <li *ngFor="let subProblem of problemDetail.sub_problem; let j = index"> <h3>{{j + 1}}. {{subProblem}}</h3></li>
                                    </ul>
                                    <br> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        <!-- Part Details -->
        <div class="accordion-item" >
            <h2 class="accordion-header" id="headingPart">
                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapsePart" aria-expanded="true" aria-controls="collapsePart">
                    <strong>Part Details</strong> 
                </button>
            </h2>
            <div id="collapsePart" class="accordion-collapse collapse show" aria-labelledby="headingPart">
                <div class="accordion-body" >
                    <table class="table">
                        <thead>
                            <tr>
                                <th scope="col">Part Number</th>
                                <th scope="col">Price</th>
                                <th scope="col">Quantity</th>
                                <th scope="col">Total Price</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let partDetail of partData.part_details; let i = index">
                                <td>{{partDetail.part_number}}</td>
                                <td>{{(partDetail.price).toFixed(2)}}</td>
                                <td>
                                    {{partDetail.quantity}}
                                </td>
                                <td>
                                    {{ (partDetail.price * partDetail.quantity).toFixed(2) }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <!-- Insurance Details -->
        <div class="accordion-item">
            <h2 class="accordion-header" id="headingInsurance">
                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseInsurance" aria-expanded="true" aria-controls="collapseInsurance">
                    <strong>Insurance Details</strong> 
                </button>
            </h2>
            <div id="collapseInsurance" class="accordion-collapse collapse show" aria-labelledby="headingInsurance">
                <div class="accordion-body">
                    <div class="row">
                        <div class="col">
                            <strong>Company Name:
                                <!-- <span style="color: red;" *ngIf="partData.jc_status !== 'Rejected' && partData.jc_status !== 'Approved' && jobRejected !== 'Rejected' && jobRejected !== 'Approved'">*</span> -->
                            </strong>
                            
                            <!-- Input field for editing company name -->
                                <div *ngIf="isEditing" class="mt-3">
                                    <div class="row">
                                        <div class="col-lg-8">
                                            <input type="text" class="form-control" 
                                                [(ngModel)]="insuranceCompanyName" 
                                                placeholder="Enter Company Name"  
                                                style="height: 35px;">
                                        </div>
                                        <div class="col-lg-4 mt-1" style="font-size: 20px; cursor: pointer;">
                                            <div class="row">
                                                <div class="col">
                                                    <i class="fa fa-close" style="color: red;" (click)="isEditing = false"></i>
                                                </div>
                                                <div class="col">
                                                    <div 
                                                    [ngClass]="{'text-muted': !insuranceCompanyName, 'text-success': insuranceCompanyName}" 
                                                    (click)="insuranceCompanyName ? saveCompanyName() : null">
                                                    <i class="fa fa-save"></i>
                                                </div>
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                            <!-- Display existing company name -->
                             <div class="row">
                             <div class="col-lg-8">
                                <div *ngIf="!isEditing && partData?.INSURENCE_COMPONY_NAME_s" class="text-muted mt-3">
                                    {{ partData?.INSURENCE_COMPONY_NAME_s || "--"}}
                                </div>
                             </div>
                             <div class="col-lg-4" *ngIf="!isEditing && partData?.INSURENCE_COMPONY_NAME_s">
                                <div  *ngIf="partData.jc_status !== 'Rejected' && partData.jc_status !== 'Approved' && jobRejected !== 'Rejected' && jobRejected !== 'Approved'"  style="margin-top: 12px; cursor: pointer;"  style="margin-top: 12px; cursor: pointer;" (click)="editCompanyName()">
                                    <i class="fa fa-pen" style="color:blue;"></i>
                                </div>
                             </div>
                            </div>
                            
                        
                            <!-- Placeholder if no company name is set -->
                            <div *ngIf="!isEditing && !partData?.INSURENCE_COMPONY_NAME_s && partData.jc_status !== 'Rejected' && partData.jc_status !== 'Approved' && jobRejected !== 'Rejected' && jobRejected !== 'Approved'" class="mt-3">
                                <input type="text" class="form-control" 
                                       [(ngModel)]="insuranceCompanyName" 
                                       placeholder="Enter Company Name" >
                            </div>
                        </div>
                        <div class="col">
                            <strong>Insurance Type:
                                <!-- <span style="color: red;" *ngIf="partData.jc_status !== 'Rejected' && partData.jc_status !== 'Approved' && jobRejected !== 'Rejected' && jobRejected !== 'Approved'">*</span> -->
                            </strong>
                            
                            <!-- Show ng-select when ASSET_INSURANCE_TYPE_s is not set -->
                            <div *ngIf="partData.jc_status !== 'Rejected' && partData.jc_status !== 'Approved' && jobRejected !== 'Rejected' && jobRejected !== 'Approved' && !partData?.ASSET_INSURANCE_TYPE_s" class="mt-3">
                                <ng-select #insuranceSelect  
                                           [(ngModel)]="selectedInsuranceType" 
                                           [items]="insuranceTypes" 
                                           bindLabel="label" 
                                           bindValue="value" 
                                           placeholder="Select Insurance Type">
                                    <ng-option *ngFor="let type of insuranceTypes" [value]="type.value">
                                        {{ type.label }}
                                    </ng-option>
                                </ng-select>
                            </div>
                            
                            <!-- Show ng-select in edit mode -->
                            <div *ngIf="partData.jc_status !== 'Rejected' && partData.jc_status !== 'Approved' && jobRejected !== 'Rejected' && jobRejected !== 'Approved' && isEditMode" class="mt-3">
                                <div class="row">
                                    <div class="col-lg-8">
                                        <ng-select  
                                                [(ngModel)]="selectedInsuranceType" 
                                                [items]="insuranceTypes" 
                                                bindLabel="label" 
                                                bindValue="value" 
                                                placeholder="Select Insurance Type">
                                            <ng-option *ngFor="let type of insuranceTypes" [value]="type.value">
                                                {{ type.label }}
                                            </ng-option>
                                        </ng-select>
                                    </div>
                                    <div class="col-lg-4 mt-1" style="font-size: 20px; cursor: pointer;">
                                        <div class="row">
                                            <div class="col">
                                                <i class="fa fa-close" style="color: red;" (click)="isEditMode = false;"></i>
                                            </div>
                                            <div class="col">
                                                <i class="fa fa-save"  style="color: green;"
                                                [ngClass]="{'text-muted': !selectedInsuranceType, 'text-success': selectedInsuranceType}" 
                                                (click)="selectedInsuranceType ? saveInsuranceType() : null"></i>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            
                            <!-- Display existing insurance type -->
                            <div class="row" *ngIf="!isEditMode && partData?.ASSET_INSURANCE_TYPE_s">
                                <div class="col-6">
                                    <div class="text-muted mt-3">
                                        {{ (partData?.ASSET_INSURANCE_TYPE_s) || "--" }}
                                    </div>
                                </div>
                                <div class="col-lg-6" (click)="toggleEditMode()">
                                    <div *ngIf="partData.jc_status !== 'Rejected' && partData.jc_status !== 'Approved' && jobRejected !== 'Rejected' && jobRejected !== 'Approved'" style="margin-top: 12px; cursor: pointer;">
                                        <i class="fa fa-pen" style="color:blue;"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    <div class="row mt-3">
                        <div class="col">
                            <div class="d-flex justify-content-between align-items-center">
                                <strong>Valid Till:
                                    <!-- <span style="color: red;" *ngIf="partData.jc_status !== 'Rejected' && partData.jc_status !== 'Approved' && jobRejected !== 'Rejected' && jobRejected !== 'Approved'">*</span> -->
                                </strong>
                            </div>
                        
                            <!-- Date picker input when no date is set -->
                            <div class="input-group mt-3" *ngIf="!partData?.ASSET_INSURANCE_VALID_DT_dt && partData.jc_status !== 'Rejected' && partData.jc_status !== 'Approved' && jobRejected !== 'Rejected' && jobRejected !== 'Approved'">
                                <input class="form-control" placeholder="Select valid till date" name="dp" 
                                       [ngModel]="transactionDate" (ngModelChange)="transactionDate = $event" 
                                       ngbDatepicker #d="ngbDatepicker" >
                                <button class="input-group-addon" (click)="d.toggle()" type="button">
                                    <i class="fa fa-calendar"></i>
                                </button>
                            </div>
                            
                            <!-- Input field for editing date -->
                            <div class="input-group mt-3" *ngIf="isEditingDate && partData.jc_status !== 'Rejected' && partData.jc_status !== 'Approved' && jobRejected !== 'Rejected' && jobRejected !== 'Approved'">
                                <div class="col-lg-8">
                                    <div class="row">
                                        <div class="col-lg-6">
                                            <input class="form-control" placeholder="Select valid till date" name="dp" 
                                                [ngModel]="transactionDate" (ngModelChange)="transactionDate = $event" 
                                                ngbDatepicker #d="ngbDatepicker" 
                                                style="width: 226px; margin-left: -10px;height: unset;" >
                                        </div>
                                        <div class="col-lg-2" style="z-index: 9999; margin-left: 58px;">
                                            <button class="input-group-addon" (click)="d.toggle()" type="button">
                                                <i class="fa fa-calendar"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div class="row">
                                        <div class="col">
                                            <i class="fa fa-close" 
                                            style="color: red; font-size: 20px; cursor: pointer; margin: 6px 0 0 4px;"
                                            (click)="this.isEditingDate = false"></i>
                                        </div>
                                        <div class="col">
                                            <div 
                                            [ngClass]="{'text-muted': !transactionDate, 'text-success': transactionDate}" 
                                            style="font-size: 20px; cursor: pointer; margin: 6px 0 0 4px;" 
                                            (click)="transactionDate ? saveDate() : null">
                                            <i class="fa fa-save"></i>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                            
                            <!-- Display existing date -->
                            <div class="row" *ngIf="!isEditingDate">
                                <div class="col-lg-6">
                                    <div *ngIf="partData?.ASSET_INSURANCE_VALID_DT_dt" class="text-muted mt-3">
                                        {{ (partData?.ASSET_INSURANCE_VALID_DT_dt).substr(0,10) }}
                                    </div>
                                </div>
                                <div class="col-lg-6" *ngIf="partData?.ASSET_INSURANCE_VALID_DT_dt">
                                    <div *ngIf="partData.jc_status !== 'Rejected' && partData.jc_status !== 'Approved' && jobRejected !== 'Rejected' && jobRejected !== 'Approved'" style="margin: 12px 0 0 62px; cursor: pointer;" (click)="editDate()">
                                        <i class="fa fa-pen" style="color:blue;"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <strong>Insurance Image:</strong>
                            <div class="row">
                                <div class="col mt-4" *ngIf="partData?.insurance_copy"> 
                                   <h4 style="color: blue;"> Image is available</h4>
                                </div>
                                <div class="col mt-3">
                                    <ng-container *ngIf="partData?.insurance_copy; else noImage">
                                        <img (click)="openImageModal()" src="{{ partData.insurance_copy }}" alt="Insurance Image" height="50" width="50" style="cursor: pointer; border: solid 1px black;">
                                    </ng-container>
                                    <ng-template #noImage>
                                        <span style="color: red;">Image not Available</span>
                                    </ng-template>
                                </div>
                            </div>
                        </div>
                    </div>                
                </div>
            </div>
        </div>
        <div *ngIf="errorMessage" class="alert alert-danger">
            <div class="text-center">
                {{ errorMessage }}
            </div>
        </div>
        <!-- Modal footer -->
        <div class="modal-footer" *ngIf="partData.jc_status !== 'Rejected' && partData.jc_status !== 'Approved' && jobRejected !== 'Rejected' && jobRejected !== 'Approved'">
            <button type="button" class="btn btn-success" (click)="onApprove(partData.job_type, partData.customer.registration_number, partData.customer.chassis_number, partData.odometer_reading, partData.INSURENCE_COMPONY_NAME_s, partData.ASSET_INSURANCE_TYPE_s, formatTransactionDate(transactionDate))">Approve</button>
            <button type="button" class="btn btn-danger"  (click)="reject()">Reject</button>
        </div>
        <div *ngIf="partData.jc_status === 'Rejected' || jobRejected === 'Rejected'" class="alert alert-danger text-center" role="alert">
            Job Card is Rejected
        </div>
        <div *ngIf="partData.jc_status === 'Approved' || jobRejected === 'Approved'" class="alert alert-success text-center" role="alert">
            Job Card Approved successfully and it is in Pending state.
        </div>          
</div>
<!-- Insurance Image Modal -->
<div class="modal fade" id="imageModal" tabindex="-1" aria-labelledby="imageModalLabel" aria-hidden="true" 
    [ngClass]="{'show': isImageModalOpen}" 
    [style.display]="isImageModalOpen ? 'block' : 'none'">
    <div class="modal-dialog modal-lg modal-bottom">
        <div class="modal-content" style="margin-bottom: 50px;">
            <div class="modal-header">
                <h5 class="modal-title" id="imageModalLabel">Insurance Image</h5>
                <button type="button" class="btn-close" (click)="closeImageModal()" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="img-body">
                    <img src="{{partData?.insurance_copy}}" alt="Insurance Image" class="img-fluid fixed-image">
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Body Overlay -->
<div class="body-overlay fade" [ngClass]="{'show': isImageModalOpen}" *ngIf="isImageModalOpen"></div>