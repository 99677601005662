import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-prolife-engine-referrel',
  templateUrl: './prolife-engine-referrel.component.html',
  styleUrls: ['./prolife-engine-referrel.component.scss']
})
export class ProlifeEngineReferrelComponent implements OnInit {
  @Input() dataSource:any;
  displayedColumns: string[] = ["id","customer_name","customer_created_date","customer_phone","customer_state","customer_pincode","model_intereste","regulatory_norms","customer_registration_number","customer_expected_purchase_date","mechanic_name","garage_area","gstin_number","upi_id","guru_name","guru_username","guru_state","guru_region","lass_name","lass_number","lass_email"];
  constructor() { }

  ngOnInit(): void {
  }

}
