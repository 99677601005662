import {
  Component,
  ElementRef,
  OnInit,
  VERSION,
  ViewChild,
} from "@angular/core";
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
  UntypedFormArray,
  UntypedFormControl,
  FormsModule,
} from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { HttpMethod } from "../constants/http-handlers";
import { SelectLocationComponent } from "./select-location/select-location.component";
import { AppService } from "../services/app.service";
import { CommonService } from "../services/common.service";
import { MatCheckboxChange } from "@angular/material/checkbox";
declare let moment: any; //declare moment
import { ActivatedRoute, Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ConfirmDialogueComponent } from "./confirm-dialogue/confirm-dialogue.component";

@Component({
  selector: "app-add-guru",
  templateUrl: "./add-guru.component.html",
  styleUrls: ["./add-guru.component.scss"],
})
export class AddGuruComponent implements OnInit {
  personalDetailForm: UntypedFormGroup;
  workDetailForm: UntypedFormGroup;
  //dependantForm: FormGroup;
  garageLatitude: number;
  garageLongitude: number;
  dependantForm: any;
  kidsForm: any;
  otherDetailForm: UntypedFormGroup;
  uploadDocForm: UntypedFormGroup;
  isEditable: boolean = true;
  submitted: boolean = false;
  allComplete: boolean = false;
  disableWeddingDate: boolean = false;
  educations = [];
  idproofs = [];
  maritalStatus = [];
  vehicleOverheads = [];
  vehicleModelTypeServices = [];
  majorAreas = [];
  workshopImage = [];
  firstnameval: any;
  lastnameval: any;
  mobileval: any;
  pincodeval: any;
  cityname: any;
  districtname: any;
  statename: any;
  regionname: any;
  countryname: any;
  gcityname: any;
  gdistrictname: any;
  gstatename: any;
  gregionname: any;
  gcountryname: any;
  phvalue: any;
  updatelng: number;
  updatelat: number;
  userExitStatus: string;
  getWokrDatasubmitted: boolean = false;
  getdependentsubmitted: boolean = false;
  Gender: string[] = ["Male", "Female"];
  yearOfservices: number[] = [2018, 2019, 2020, 2021];
  numOfkidsItem: number[] = [1, 2];
  numOfDependants: number[] = [1, 2, 3];
  version = VERSION;
  base64File: string = "";
  filename: string = "";
  filename2: string = "";
  filename3: string = "";
  filename4: string = "";
  showfinalSuccessMsg: string;
  showfinalErrorMsg: string;
  showToastermessage: boolean = false;
  today = new Date();
  dobdate = new Date();
  depedendentItem1: boolean = false;
  depedendentItem2: boolean = false;
  depedendentItem3: boolean = false;
  kid2Detail: boolean = false;
  idPatternValid: any;
  guruPictureFile: any = "";
  guruIDFile: string = "";
  guruRegistrationForm: string = "";
  guruOtherDocument: string = "";
  selectedDependants: any;
  selectedKids: any;
  getOthersubmitted: boolean = false;
  recId: number = 0;
  gurutype: string = "Add guru";
  updateGuruForm: boolean = false;
  profile_imageURL: string = "";
  uploadDocuments = [];
  updatefilename: string;
  pincodeErrorMsg: string;
  garragepincodeErrorMsg: string;
  personalDetailChange: boolean = true;
  workDetailChange: boolean = true;
  dependantDetailChange: boolean = true;
  otherDetailChange: boolean = true;
  addGuruId: number = 0;
  addWorkId: number = 0;
  addDependantId: number = 0;
  addOtherId: number = 0;
  addUserId: number = 0;
  formData: FormData = new FormData();
  newarraytemp = [];
  objtest = {};

  @ViewChild("selectDependant", { read: ElementRef })
  public selectDependant: ElementRef<any>;
  datePipe: any;

  constructor(
    private _formBuilder: UntypedFormBuilder,
    public dialog: MatDialog,
    private commonService: CommonService,
    private appService: AppService,
    private router: Router,
    private actRoute: ActivatedRoute,
    private spinner: NgxSpinnerService
  ) {
    // this.updatelng = 77.1025;
    // this.updatelat = 28.7041;
    
    //this.selectedDependants = 1;
    this.selectedKids = 1;
    if (this.actRoute.snapshot.params.id) {
      this.recId = this.actRoute.snapshot.params.id;
    } else {
      this.recId = 0;
    }

    //  condition for number /  variable flag disable edit mode flag false
  }
  getGuruDetails() {
    let apiUrl = this.appService.msrGuruDetail + this.recId + "/";
    const url = `${apiUrl}`;
    const apiObj = {
      url: url,
      methodType: HttpMethod.GET,
    };
    this.commonService.commonApiCall(apiObj, (res) => {
      if (res != null) {
        this.updateGuruForm = true;
        this.addGuruId = res.id;
        this.addUserId = res.user_id;
        this.addWorkId = res.id;
        this.addDependantId = res.id;
        this.addOtherId = res.id;
        this.personalDetailForm
          .get("registerationdate")
          .setValue(res.personal_details.registration_date);
        this.personalDetailForm.get("firstName").setValue(res.firstname);
        this.personalDetailForm
          .get("middlename")
          .setValue(res.personal_details.middle_name);
        this.personalDetailForm.get("lastName").setValue(res.lastname);
        this.personalDetailForm
          .get("guruDOB")
          .setValue(res.personal_details.dob);
        this.personalDetailForm
          .get("homeaddress")
          .setValue(res.personal_details.house_address);
        this.personalDetailForm
          .get("pinCode")
          .setValue(res.personal_details.pincode);
        this.personalDetailForm.get("city").setValue(res.personal_details.city);
        this.personalDetailForm
          .get("district")
          .setValue(res.personal_details.district);
        this.personalDetailForm
          .get("state")
          .setValue(res.personal_details.state);
        this.personalDetailForm
          .get("region")
          .setValue(res.personal_details.region);
        this.personalDetailForm
          .get("country")
          .setValue(res.personal_details.country);
        //this.getpincode();
        this.personalDetailForm
          .get("primarycontact")
          .setValue(res.guru_contact_number);
        this.personalDetailForm
          .get("secondarycontact")
          .setValue(res.personal_details.contact_2);
        this.personalDetailForm
          .get("emailId")
          .setValue(res.personal_details.email_id);
        this.personalDetailForm
          .get("maritalState")
          .setValue(res.personal_details.marital_status);
        this.setMaritalState(res.personal_details.marital_status);
        this.personalDetailForm
          .get("weddingdate")
          .setValue(res.personal_details.wedding_date);
        this.personalDetailForm
          .get("education")
          .setValue(res.personal_details.education);
        this.personalDetailForm
          .get("idproof")
          .setValue(res.personal_details.identity_proof);
        this.personalDetailForm
          .get("idnumber")
          .setValue(res.personal_details.id_number);
        this.workDetailForm
          .get("garageName")
          .setValue(res.work_details.garage_name);
        this.workDetailForm
          .get("garageOwnerName")
          .setValue(res.work_details.garage_owner_name);
        this.workDetailForm
          .get("businessIncorporationDate")
          .setValue(res.work_details.business_incorporation_date);
        this.workDetailForm
          .get("gstNumber")
          .setValue(res.work_details.gst_number);
        this.workDetailForm
          .get("garageaddress")
          .setValue(res.work_details.garage_address);
        this.workDetailForm
          .get("garragepinCode")
          .setValue(res.work_details.pincode);
        this.getgaragepincode();
        this.garageLatitude = res.work_details.garage_lat;
        this.garageLongitude = res.work_details.garage_long;
        this.vehicleModelTypeServices = this.vehicleModelTypeServices.map(
          (obj) => {
            return {
              ...obj,
              checked: res.work_details.vehicle_model.includes(obj.value),
            };
          }
        );
        this.updatevModelChange(res.work_details.vehicle_model);
        this.vehicleOverheads = this.vehicleOverheads.map((obj) => {
          return {
            ...obj,
            checked: res.work_details.vehicle_make.includes(obj.value),
          };
        });
        this.updatevMakeChange(res.work_details.vehicle_make);
        this.majorAreas = this.majorAreas.map((obj) => {
          return {
            ...obj,
            checked: res.work_details.major_area_work.includes(obj.value),
          };
        });
        this.updatevMajorChange(res.work_details.major_area_work);
        this.workDetailForm
          .get("otherSpecificArea")
          .setValue(res.work_details.other_area_work);
        this.dependantForm
          .get("numOfDependants")
          .setValue(res.dependant_details.no_of_dependent);
        this.addDependant();
        if (res.dependant_details.no_of_dependent === 1) {
          this.dependantForm
            .get("name1")
            .setValue(res.dependant_details.dependants_details[0].name);
          this.dependantForm
            .get("service_year1")
            .setValue(res.dependant_details.dependants_details[0].service_year);
          this.dependantForm
            .get("dob1")
            .setValue(res.dependant_details.dependants_details[0].dob);
        }
        if (res.dependant_details.no_of_dependent === 2) {
          this.dependantForm
            .get("name1")
            .setValue(res.dependant_details.dependants_details[0].name);
          this.dependantForm
            .get("service_year1")
            .setValue(res.dependant_details.dependants_details[0].service_year);
          this.dependantForm
            .get("dob1")
            .setValue(res.dependant_details.dependants_details[0].dob);
          this.dependantForm
            .get("name2")
            .setValue(res.dependant_details.dependants_details[1].name);
          this.dependantForm
            .get("service_year2")
            .setValue(res.dependant_details.dependants_details[1].service_year);
          this.dependantForm
            .get("dob2")
            .setValue(res.dependant_details.dependants_details[1].dob);
        }
        if (res.dependant_details.no_of_dependent === 3) {
          this.dependantForm
            .get("name1")
            .setValue(res.dependant_details.dependants_details[0].name);
          this.dependantForm
            .get("service_year1")
            .setValue(res.dependant_details.dependants_details[0].service_year);
          this.dependantForm
            .get("dob1")
            .setValue(res.dependant_details.dependants_details[0].dob);
          this.dependantForm
            .get("name2")
            .setValue(res.dependant_details.dependants_details[1].name);
          this.dependantForm
            .get("service_year2")
            .setValue(res.dependant_details.dependants_details[1].service_year);
          this.dependantForm
            .get("dob2")
            .setValue(res.dependant_details.dependants_details[1].dob);
          this.dependantForm
            .get("name3")
            .setValue(res.dependant_details.dependants_details[2].name);
          this.dependantForm
            .get("service_year3")
            .setValue(res.dependant_details.dependants_details[2].service_year);
          this.dependantForm
            .get("dob3")
            .setValue(res.dependant_details.dependants_details[2].dob);
        }
        this.otherDetailForm
          .get("faceid")
          .setValue(res.other_details.facebook_id);
        this.otherDetailForm.get("hobbies").setValue(res.other_details.hobbies);
        this.otherDetailForm
          .get("interestPreference")
          .setValue(res.other_details.interests);
        this.otherDetailForm
          .get("aspirations")
          .setValue(res.other_details.aspirations);
        this.otherDetailForm
          .get("spouseName")
          .setValue(res.other_details.spouse_name);
        this.otherDetailForm
          .get("SpouseDOB")
          .setValue(res.other_details.spouse_dob);
        this.otherDetailForm
          .get("spouseContact")
          .setValue(res.other_details.spouse_contact);
        this.otherDetailForm
          .get("spouseEmail")
          .setValue(res.other_details.spouse_email);
        this.otherDetailForm
          .get("kidsNum")
          .setValue(res.other_details.no_of_kids);
        this.addKid();
        if (res.other_details.no_of_kids === 1) {
          this.otherDetailForm
            .get("kidname1")
            .setValue(res.other_details.kids_details[0].name);
          this.otherDetailForm
            .get("kidGender1")
            .setValue(res.other_details.kids_details[0].gender);
          this.otherDetailForm
            .get("kidseducation1")
            .setValue(res.other_details.kids_details[0].education);
        }
        if (res.other_details.no_of_kids === 2) {
          this.otherDetailForm
            .get("kidname1")
            .setValue(res.other_details.kids_details[0].name);
          this.otherDetailForm
            .get("kidGender1")
            .setValue(res.other_details.kids_details[0].gender);
          this.otherDetailForm
            .get("kidseducation1")
            .setValue(res.other_details.kids_details[0].education);
          this.otherDetailForm
            .get("kidname2")
            .setValue(res.other_details.kids_details[1].name);
          this.otherDetailForm
            .get("kidGender2")
            .setValue(res.other_details.kids_details[1].gender);
          this.otherDetailForm
            .get("kidseducation2")
            .setValue(res.other_details.kids_details[1].education);
        }
      }
    });
  }
  getCoords(event) {
    this.garageLatitude = event.lat;
    this.garageLongitude = event.lng;
  }
  education() {
    const url = `${this.appService.addGuruEducation}`;
    const apiObj = {
      url: url,
      methodType: HttpMethod.GET,
    };
    this.commonService.commonApiCall(apiObj, (res) => {
      for (var i = 0; i < res.length; i++) {
        this.educations.push(res[i].value);
      }
    });
  }
  idproof() {
    const url = `${this.appService.addGuruIdentityproof}`;
    const apiObj = {
      url: url,
      methodType: HttpMethod.GET,
    };
    this.commonService.commonApiCall(apiObj, (res) => {
      for (var i = 0; i < res.length; i++) {
        this.idproofs.push(res[i].value);
      }
    });
  }
  marital() {
    const url = `${this.appService.addGuruMaritalstatus}`;
    const apiObj = {
      url: url,
      methodType: HttpMethod.GET,
    };
    this.commonService.commonApiCall(apiObj, (res) => {
      for (var i = 0; i < res.length; i++) {
        this.maritalStatus.push(res[i].value);
      }
    });
  }
  updatevMakeChange(values: []) {
    const vehicalMakeSel: UntypedFormArray = this.workDetailForm.get(
      "vehicalMakeSel"
    ) as UntypedFormArray;
    for (var i = 0; i < values.length; i++) {
      vehicalMakeSel.push(new UntypedFormControl(values[i]));
    }
  }
  onvMakeChange(value: object, isChecked: boolean) {
    const vehicalMakeSel: UntypedFormArray = this.workDetailForm.get(
      "vehicalMakeSel"
    ) as UntypedFormArray;
    //debugger;
    if (isChecked) {
      vehicalMakeSel.push(new UntypedFormControl(value));
    } else {
      let i: number = 0;
      vehicalMakeSel.controls.forEach((item: UntypedFormControl) => {
        if (item.value == value) {
          vehicalMakeSel.removeAt(i);
          return;
        }
        i++;
      });
    }
  }
  updatevModelChange(values: []) {
    const vehicalModelSel: UntypedFormArray = this.workDetailForm.get(
      "vehicalModelSel"
    ) as UntypedFormArray;
    for (var i = 0; i < values.length; i++) {
      vehicalModelSel.push(new UntypedFormControl(values[i]));
    }
  }
  onvModelChange(value: string, isChecked: boolean) {
    const vehicalModelSel: UntypedFormArray = this.workDetailForm.get(
      "vehicalModelSel"
    ) as UntypedFormArray;
    //debugger;
    if (isChecked) {
      //debugger;
      vehicalModelSel.push(new UntypedFormControl(value));
    } else {
      let i: number = 0;
      vehicalModelSel.controls.forEach((item: UntypedFormControl) => {
        if (item.value == value) {
          vehicalModelSel.removeAt(i);
          return;
        }
        i++;
      });
    }
  }
  updatevMajorChange(values: []) {
    const majorAreaSel: UntypedFormArray = this.workDetailForm.get(
      "majorAreaSel"
    ) as UntypedFormArray;
    for (var i = 0; i < values.length; i++) {
      majorAreaSel.push(new UntypedFormControl(values[i]));
    }
  }
  onvMajorChange(value: string, isChecked: boolean) {
    const majorAreaSel: UntypedFormArray = this.workDetailForm.get(
      "majorAreaSel"
    ) as UntypedFormArray;
    //debugger;
    if (isChecked) {
      //debugger;
      majorAreaSel.push(new UntypedFormControl(value));
    } else {
      let i: number = 0;
      majorAreaSel.controls.forEach((item: UntypedFormControl) => {
        if (item.value == value) {
          majorAreaSel.removeAt(i);
          return;
        }
        i++;
      });
    }
  }
  vehicleOver() {
    const url = `${this.appService.addGuruVehicalmake}`;
    const apiObj = {
      url: url,
      methodType: HttpMethod.GET,
    };
    this.commonService.commonApiCall(apiObj, (res) => {
      this.vehicleOverheads = res.map((obj) => {
        return { ...obj, checked: false };
      });
    });
  }
  vehicleModel() {
    const url = `${this.appService.addGuruVehicalmodel}`;
    const apiObj = {
      url: url,
      methodType: HttpMethod.GET,
    };
    this.commonService.commonApiCall(apiObj, (res) => {
      this.vehicleModelTypeServices = res.map((obj) => {
        return { ...obj, checked: false };
      });
    });
  }
  vehicleMajor() {
    const url = `${this.appService.addGuruMajorareawork}`;
    const apiObj = {
      url: url,
      methodType: HttpMethod.GET,
    };
    this.commonService.commonApiCall(apiObj, (res) => {
      this.majorAreas = res.map((obj) => {
        return { ...obj, checked: false };
      });
    });
  }
  addKid() {
    if (this.otherDetailForm.value.kidsNum == "1") {
      this.kid2Detail = false;
      this.otherDetailForm.value.kidname2 = "";
      this.otherDetailForm.value.kidGender2 = "";
      this.otherDetailForm.value.kidseducation2 = "";
    }
    if (this.otherDetailForm.value.kidsNum == "2") {
      this.kid2Detail = true;
    }
  }
  addDependant() {
    //debugger;
    if (this.dependantForm.value.numOfDependants == "") {
      this.dependantForm = this._formBuilder.group({
        numOfDependants: [this.dependantForm.value.numOfDependants],
        name1: [""],
        service_year1: [""],
        dob1: [""],
        name2: [""],
        service_year2: [""],
        dob2: [""],
        name3: [""],
        service_year3: [""],
        dob3: [""],
      });
      this.depedendentItem1 = false;
      this.depedendentItem2 = false;
      this.depedendentItem3 = false;
      this.dependantForm.value.name1 = "";
      this.dependantForm.value.name2 = "";
      this.dependantForm.value.name3 = "";
      this.dependantForm.value.dob1 = "";
      this.dependantForm.value.dob2 = "";
      this.dependantForm.value.dob3 = "";
      this.dependantForm.value.service_year1 = "";
      this.dependantForm.value.service_year2 = "";
      this.dependantForm.value.service_year3 = "";
    }
    if (this.dependantForm.value.numOfDependants == "1") {
      this.dependantForm = this._formBuilder.group({
        numOfDependants: [
          this.dependantForm.value.numOfDependants,
          // Validators.required,
        ],
        name1: [
          this.dependantForm.value.name1,
          [Validators.required, this.noWhitespaceValidator],
        ],
        service_year1: [
          this.dependantForm.value.service_year1,
          Validators.required,
        ],
        dob1: [this.dependantForm.value.dob1, Validators.required],
        name2: [""],
        service_year2: [""],
        dob2: [""],
        name3: [""],
        service_year3: [""],
        dob3: [""],
      });
      this.depedendentItem1 = true;
      this.depedendentItem2 = false;
      this.depedendentItem3 = false;
      this.dependantForm.value.name2 = "";
      this.dependantForm.value.name3 = "";
      this.dependantForm.value.dob2 = "";
      this.dependantForm.value.dob3 = "";
      this.dependantForm.value.service_year2 = "";
      this.dependantForm.value.service_year3 = "";
    }

    if (this.dependantForm.value.numOfDependants == "2") {
      this.dependantForm = this._formBuilder.group({
        numOfDependants: [
          this.dependantForm.value.numOfDependants,
          // Validators.required,
        ],
        name1: [
          this.dependantForm.value.name1,
          [Validators.required, this.noWhitespaceValidator],
        ],
        service_year1: [
          this.dependantForm.value.service_year1,
          Validators.required,
        ],
        dob1: [this.dependantForm.value.dob1, Validators.required],
        name2: [
          this.dependantForm.value.name2,
          [Validators.required, this.noWhitespaceValidator],
        ],
        service_year2: [
          this.dependantForm.value.service_year2,
          Validators.required,
        ],
        dob2: [this.dependantForm.value.dob3, Validators.required],
        name3: [""],
        service_year3: [""],
        dob3: [""],
      });
      this.depedendentItem1 = true;
      this.depedendentItem2 = true;
      this.depedendentItem3 = false;
      this.dependantForm.value.name3 = "";
      this.dependantForm.value.service_year3 = "";
      this.dependantForm.value.dob3 = "";
    }

    if (this.dependantForm.value.numOfDependants == "3") {
      this.dependantForm = this._formBuilder.group({
        numOfDependants: [
          this.dependantForm.value.numOfDependants,
          // Validators.required,
        ],
        name1: [
          this.dependantForm.value.name1,
          [Validators.required, this.noWhitespaceValidator],
        ],
        service_year1: [
          this.dependantForm.value.service_year1,
          Validators.required,
        ],
        dob1: [this.dependantForm.value.dob1, Validators.required],
        name2: [
          this.dependantForm.value.name2,
          [Validators.required, this.noWhitespaceValidator],
        ],
        service_year2: [
          this.dependantForm.value.service_year2,
          Validators.required,
        ],
        dob2: [this.dependantForm.value.dob2, Validators.required],
        name3: [
          this.dependantForm.value.name3,
          [Validators.required, this.noWhitespaceValidator],
        ],
        service_year3: [
          this.dependantForm.value.service_year3,
          Validators.required,
        ],
        dob3: [this.dependantForm.value.dob3, Validators.required],
      });
      this.depedendentItem1 = true;
      this.depedendentItem3 = true;
      this.depedendentItem2 = true;
      this.dependantForm.value.name3 = "";
      this.dependantForm.value.yearOfservice3 = "";
      this.dependantForm.value.dependantDOB3 = "";
    }
  }
  checkuser(event: any) {
    let checkUserObj = {
      contact_1: this.personalDetailForm.value.primarycontact,
    };
    const url = this.appService.guruExists;
    const apiObj = {
      url: url,
      methodType: HttpMethod.POST,
      requestObj: checkUserObj,
    };
    this.commonService.commonApiCall(apiObj, (res, error) => {
      if (error !== undefined) {
        if (error.status === 412) {
          this.personalDetailForm.get("primarycontact").setValue("");
          //this.personalDetailForm.value.primarycontact = "";
          this.userExitStatus = error.error.msg;
        }
      } else {
        this.userExitStatus = "";
        return;
      }
    });
  }
  ngOnInit() {
    this.dobdate.setFullYear(this.dobdate.getFullYear() - 18);
    if (this.selectDependant) {
      this.selectDependant.nativeElement.value = "";
    }
    this.education();
    this.idproof();
    this.marital();
    this.vehicleOver();
    this.vehicleModel();
    this.vehicleMajor();
    this.workDetailForm = this._formBuilder.group({
      useremail: this._formBuilder.array([]),
    });

    this.workDetailForm = this._formBuilder.group({
      garageName: [
        "",
        [
          Validators.required,
          Validators.pattern(/^[a-zA-Z ]*$/),
          this.noWhitespaceValidator,
        ],
      ],

      garageOwnerName: [
        "",
        [
          Validators.required,
          Validators.pattern(/^[a-zA-Z ]*$/),
          this.noWhitespaceValidator,
        ],
      ],
      businessIncorporationDate: [""],
      gstNumber: [
        "",
        [
          Validators.pattern(
            /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}/
          ),
        ],
      ],
      garageaddress: ["", [Validators.required, this.noWhitespaceValidator]],
      garragepinCode: [
        "",
        [Validators.required, Validators.pattern("^((\\-?)|0)?[0-9]{6}$")],
      ],
      garragecity: ["", Validators.required],
      garragedistrict: [""],
      garragestate: [""],
      garrageregion: [""],
      garragecountry: [""],
      vehicalMakeSel: this._formBuilder.array([], [Validators.required]),
      vehicalModelSel: this._formBuilder.array([], [Validators.required]),
      majorAreaSel: this._formBuilder.array([], [Validators.required]),
      otherSpecificArea: [""],
      majorArea: this._formBuilder.array(this.majorAreas.map((x) => !1)),
    });

    this.dependantForm = this._formBuilder.group({
      numOfDependants: [""],
      name1: [""],
      service_year1: [""],
      dob1: [""],
      name2: [""],
      service_year2: [""],
      dob2: [""],
      name3: [""],
      service_year3: [""],
      dob3: [""],
    });

    this.otherDetailForm = this._formBuilder.group({
      faceid: [""],
      hobbies: [""],
      interestPreference: [""],
      aspirations: [""],
      spouseName: ["", Validators.pattern(/^[a-zA-Z ]*$/)],
      SpouseDOB: [""],
      spouseContact: [
        "",
        [
          Validators.pattern("^((\\-?)|0)?[0-9]{10}$"),
          Validators.min(6000000000),
          Validators.maxLength(10),
        ],
      ],
      spouseEmail: [
        "",
        [Validators.pattern(/^\w.+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/)],
      ],
      kidsNum: [""],
      kidname1: ["", Validators.pattern(/^[a-zA-Z ]*$/)],
      kidGender1: [""],
      kidseducation1: [""],
      kidname2: ["", Validators.pattern(/^[a-zA-Z ]*$/)],
      kidGender2: [""],
      kidseducation2: [""],
    });

    if (this.recId !== 0) {
      this.gurutype = "Update Guru";
      this.personalDetailForm = this._formBuilder.group({
        registerationdate: ["", Validators.required],
        firstName: [
          "",
          [Validators.pattern(/^[a-zA-Z ]*$/), this.noWhitespaceValidator],
        ],
        middlename: ["", Validators.pattern(/^[a-zA-Z ]*$/)],
        lastName: [
          "",
          [Validators.pattern(/^[a-zA-Z ]*$/), this.noWhitespaceValidator],
        ],
        guruDOB: ["", Validators.required],
        homeaddress: ["", [Validators.required, this.noWhitespaceValidator]],
        pinCode: [
          "",
          [Validators.required, Validators.pattern("^((\\-?)|0)?[0-9]{6}$")],
        ],
        city: ["", Validators.required],
        district: [""],
        state: [""],
        region: [""],
        country: [""],
        primarycontact: [""],
        secondarycontact: ["", Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")],
        emailId: [
          "",
          [Validators.pattern(/^\w.+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/)],
        ],
        education: ["", Validators.required],
        idproof: ["", Validators.required],
        idnumber: ["", Validators.required],
        maritalState: ["", Validators.required],
        weddingdate: ["", Validators.required],
      });
      this.getGuruDetails();
    } else {
      this.gurutype = "Add Guru";
      this.personalDetailForm = this._formBuilder.group({
        registerationdate: ["", Validators.required],
        firstName: [
          "",
          [
            Validators.required,
            Validators.pattern(/^[a-zA-Z ]*$/),
            this.noWhitespaceValidator,
          ],
        ],
        middlename: ["", Validators.pattern(/^[a-zA-Z ]*$/)],
        lastName: [
          "",
          [
            Validators.required,
            Validators.pattern(/^[a-zA-Z ]*$/),
            this.noWhitespaceValidator,
          ],
        ],
        guruDOB: ["", Validators.required],
        homeaddress: ["", [Validators.required, this.noWhitespaceValidator]],
        pinCode: [
          "",
          [Validators.required, Validators.pattern("^((\\-?)|0)?[0-9]{6}$")],
        ],
        city: ["", Validators.required],
        district: [""],
        state: [""],
        region: [""],
        country: [""],
        primarycontact: [
          "",
          [
            Validators.required,
            Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$"),
            Validators.min(6000000000),
            Validators.maxLength(10),
          ],
        ],
        secondarycontact: [
          "",
          [
            Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$"),
            Validators.min(6000000000),
            Validators.maxLength(10),
          ],
        ],
        emailId: [
          "",
          [Validators.pattern(/^\w.+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/)],
        ],
        education: ["", Validators.required],
        idproof: ["", Validators.required],
        idnumber: ["", Validators.required],
        maritalState: ["", Validators.required],
        weddingdate: ["", Validators.required],
      });
    }
  }
  uploadAllFile(event, type, newevent) {
    const target = newevent.target as HTMLInputElement;
    if (
      event[0].type == "image/png" ||
      event[0].type == "image/jpg" ||
      event[0].type == "image/jpeg"
    ) {
      if (event[0].size < 5242880) {
        if (type === 1) {
          this.formData.append("profile_image", event[0]);
          this.guruPictureFile = event[0].name;
          target.value = "";
        }
        if (type === 2) {
          this.formData.append("guru_id_card", event[0]);
          this.guruIDFile = event[0].name;
          target.value = "";
        }
        if (type === 3) {
          this.formData.append("registration_form", event[0]);
          this.guruRegistrationForm = event[0].name;
          target.value = "";
        }
        if (type === 4) {
          this.formData.append("other_documents", event[0]);
          this.guruOtherDocument = event[0].name;
          target.value = "";
        }
        if (type === 5) {
          if (event.length > 5) {
            this.showToastermessage = true;
            this.showfinalErrorMsg =
              "You are only allowed to upload a maximum of 5 files";
            setTimeout(() => {
              this.showfinalErrorMsg = "";
              this.showToastermessage = false;
            }, 3000);
          } else {
            if (this.workshopImage.length + event.length > 5) {
              this.showToastermessage = true;
              this.showfinalErrorMsg =
                "You are only allowed to upload a maximum of 5 files";
              setTimeout(() => {
                this.showfinalErrorMsg = "";
                this.showToastermessage = false;
              }, 3000);
            } else {
              for (let i = 0; i < event.length; i++) {
                //debugger;
                this.workshopImage.push(event[i]);
                //this.formData.append("workshop_image", event[i]);
                //this.guruOtherDocument = event[0].name;
              }
              target.value = "";
            }
          }
        }
      } else {
        this.showToastermessage = true;
        this.showfinalErrorMsg = "File size can’t be over 5MB.";
        setTimeout(() => {
          this.showfinalErrorMsg = "";
          this.showToastermessage = false;
        }, 3000);
      }
    } else {
      this.showToastermessage = true;
      this.showfinalErrorMsg = "jpg, jpeg, and png file formats are allowed.";
      setTimeout(() => {
        this.showfinalErrorMsg = "";
        this.showToastermessage = false;
      }, 3000);
    }
  }
  removeUploads(type) {
    if (type == "profile_image") {
      this.guruPictureFile = "";
    } else if (type == "guru_id_card") {
      this.guruIDFile = "";
    } else if (type == "registration_form") {
      this.guruRegistrationForm = "";
    } else if (type == "other_documents") {
      this.guruOtherDocument = "";
    } else {
      this.workshopImage.splice(type, 1);
    }
    this.formData.delete(type);
  }
  openDialog(action) {
    let dialogRef = this.dialog.open(ConfirmDialogueComponent, {
      disableClose: true,
    });
    dialogRef.componentInstance.action = "delete";
    dialogRef.afterClosed().subscribe(res => {
      if(res.data == "Yes"){
        this.removeUploads(action)
      }
    })
  }

  openDialogUploadUpdate(event, type, newevent) {
    if( (type==1 && this.guruPictureFile !="") 
    || ( type==2 &&  this.guruIDFile !="" ) 
    || ( type==3 &&  this.guruRegistrationForm !="" )  
    || ( type==4 &&  this.guruOtherDocument !="" ) 
    || ( type==5 &&  this.workshopImage.length == 5 )){
      let dialogRef = this.dialog.open(ConfirmDialogueComponent, {
        disableClose: true,
      });
      dialogRef.componentInstance.action = "update";
      dialogRef.afterClosed().subscribe(res => {
        if(res.data == "Yes"){
          this.uploadAllFile(event, type, newevent);
        }
      })
    }else{
      this.uploadAllFile(event, type, newevent);
    }
  }

  get personalinvalid() {
    return this.personalDetailForm.controls;
  }
  get workinvalid() {
    return this.workDetailForm.controls;
  }
  get dependendinvalid() {
    return this.dependantForm.controls;
  }
  get otherinvalid() {
    return this.otherDetailForm.controls;
  }
  getPersonalData() {
    this.submitted = true;
    if (this.personalDetailForm.valid) {
      this.personalDetailForm.valueChanges.subscribe((x) => {
        this.personalDetailChange = true;
      });
      if (this.personalDetailChange) {
        var marriedstatus;
        if (this.personalDetailForm.value.maritalState === "Single") {
          marriedstatus = false;
        } else {
          marriedstatus = true;
        }
        var marrieddate;
        if (this.personalDetailForm.value.weddingdate == "") {
          marrieddate = "";
        } else {
          // marrieddate = moment(
          //   this.personalDetailForm.value.weddingdate
          // ).format("YYYY-MM-DD");
          marrieddate = this.datePipe.transform(this.personalDetailForm.value.weddingdate, 'yyyy-MM-dd');

        }

        let userObj = {
          section: "personal_details",
          personal_details: {
            // registration_date: this.personalDetailForm.value.registerationdate,
            registration_date: this.datePipe.transform(this.personalDetailForm.value.registerationdate, 'yyyy-MM-dd'),

            first_name: this.personalDetailForm.value.firstName,
            middle_name: this.personalDetailForm.value.middlename,
            last_name: this.personalDetailForm.value.lastName,
            // dob: moment(this.personalDetailForm.value.guruDOB).format(
            //   "YYYY-MM-DD"
            // ),
            dob: this.datePipe.transform(this.personalDetailForm.value.guruDOB, 'yyyy-MM-dd'),

            house_address: this.personalDetailForm.value.homeaddress,
            pincode: +this.personalDetailForm.value.pinCode,
            city: (<HTMLInputElement>document.getElementById("city")).value,
            state: (<HTMLInputElement>document.getElementById("state")).value,
            district: (<HTMLInputElement>document.getElementById("district"))
              .value,
            region: (<HTMLInputElement>document.getElementById("region")).value,
            country: (<HTMLInputElement>document.getElementById("country"))
              .value,
            contact_1: this.personalDetailForm.value.primarycontact,
            contact_2: this.personalDetailForm.value.secondarycontact,
            email_id: this.personalDetailForm.value.emailId,
            marital_status: this.personalDetailForm.value.maritalState,
            wedding_date: marrieddate,
            education: this.personalDetailForm.value.education,
            identity_proof: this.personalDetailForm.value.idproof,
            id_number: this.personalDetailForm.value.idnumber,
          },
        };
        if (marrieddate === "") {
          delete userObj.personal_details.wedding_date;
        }
        this.spinner.show();
        let url = "";
        if (this.addGuruId === 0) {
          url = this.appService.addGuru;
        } else {
          url = this.appService.updateGuru + this.addGuruId;
        }
        const apiObj = {
          url: url,
          methodType: HttpMethod.POST,
          requestObj: userObj,
        };
        this.commonService.commonApiCall(apiObj, (res, error) => {
          if (error != undefined) {
            this.spinner.hide();
          } else {
            this.spinner.hide();
            //debugger;
            this.personalDetailChange = false;
            this.addGuruId = res.guru_id;
            this.addUserId = res.user_id;
          }
        });
      }
    }
  }
  getWokrData() {
    this.getWokrDatasubmitted = true;
    if (this.workDetailForm.valid) {
      this.workDetailForm.valueChanges.subscribe((x) => {
        this.workDetailChange = true;
      });
      if (this.workDetailChange) {
        if (this.garageLatitude === undefined) {
          this.garageLatitude = 28.7014;
        }
        if (this.garageLongitude === undefined) {
          this.garageLongitude = 77.1025;
        }
        var businessdata;
        if (
          this.workDetailForm.value.businessIncorporationDate == "" ||
          this.workDetailForm.value.businessIncorporationDate == undefined
        ) {
          businessdata = "";
        } else {
          // businessdata = moment(
          //   this.workDetailForm.value.businessIncorporationDate
          // ).format("YYYY-MM-DD");
          businessdata = this.datePipe.transform(this.workDetailForm.value.businessIncorporationDate, 'yyyy-MM-dd');

        }
        //debugger;
        this.spinner.show();
        let userObj = {
          section: "work_details",
          guru_id: this.addGuruId,
          user_id: this.addUserId,
          work_details: {
            garage_name: this.workDetailForm.value.garageName,
            garage_owner_name: this.workDetailForm.value.garageOwnerName,
            business_incorporation_date: businessdata,
            gst_number: this.workDetailForm.value.gstNumber,
            garage_address: this.workDetailForm.value.garageaddress,
            pincode: this.workDetailForm.value.garragepinCode,
            city: (<HTMLInputElement>document.getElementById("garragecity"))
              .value,
            state: (<HTMLInputElement>(
              document.getElementById("garragedistrict")
            )).value,
            district: (<HTMLInputElement>(
              document.getElementById("garragestate")
            )).value,
            region: (<HTMLInputElement>document.getElementById("garrageregion"))
              .value,
            country: (<HTMLInputElement>(
              document.getElementById("garragecountry")
            )).value,
            garage_lat: this.garageLatitude,
            garage_long: this.garageLongitude,
            vehicle_make: this.workDetailForm.get("vehicalMakeSel").value,
            vehicle_model: this.workDetailForm.get("vehicalModelSel").value,
            major_area_work: this.workDetailForm.get("majorAreaSel").value,
            other_area_work: this.workDetailForm.value.otherSpecificArea,
          },
        };
        if (businessdata === "") {
          delete userObj.work_details.business_incorporation_date;
        }
        let url = "";
        if (this.addWorkId === 0) {
          url = this.appService.addGuru;
        } else {
          url = this.appService.updateGuru + this.addWorkId;
        }
        const apiObj = {
          url: url,
          methodType: HttpMethod.POST,
          requestObj: userObj,
        };
        this.commonService.commonApiCall(apiObj, (res, error) => {
          if (error != undefined) {
            this.spinner.hide();
          } else {
            //debugger;
            this.spinner.hide();
            this.workDetailChange = false;
            this.addWorkId = res.guru_id;
          }
        });
      }
    }
  }
  dependantFormData() {
    //debugger;
    this.getdependentsubmitted = true;
    if (this.dependantForm.valid) {
      this.dependantForm.valueChanges.subscribe((x) => {
        this.dependantDetailChange = true;
      });
      if (this.dependantDetailChange) {
        let dependentDetailsArr = [];
        if (this.dependantForm.value.numOfDependants == "1") {
          dependentDetailsArr = [];
          dependentDetailsArr.push({
            name: this.dependantForm.value.name1,
            service_year: this.dependantForm.value.service_year1,
            // dob: moment(this.dependantForm.value.dob1).format("YYYY-MM-DD"),
            dob :this.datePipe.transform(this.dependantForm.value.dob1).format("YYYY-MM-DD"),

          });
        }
        if (this.dependantForm.value.numOfDependants == "2") {
          dependentDetailsArr = [];
          dependentDetailsArr.push(
            {
              name: this.dependantForm.value.name1,
              service_year: this.dependantForm.value.service_year1,
              // dob: moment(this.dependantForm.value.dob1).format("YYYY-MM-DD"),
              dob :this.datePipe.transform(this.dependantForm.value.dob1).format("YYYY-MM-DD"),

            },
            {
              name: this.dependantForm.value.name2,
              service_year: this.dependantForm.value.service_year2,
              // dob: moment(this.dependantForm.value.dob2).format("YYYY-MM-DD"),
              dob :this.datePipe.transform(this.dependantForm.value.dob2).format("YYYY-MM-DD"),

            }
          );
        }
        if (this.dependantForm.value.numOfDependants == "3") {
          dependentDetailsArr = [];
          dependentDetailsArr.push(
            {
              name: this.dependantForm.value.name1,
              service_year: this.dependantForm.value.service_year1,
              // dob: moment(this.dependantForm.value.dob1).format("YYYY-MM-DD"),
              dob :this.datePipe.transform(this.dependantForm.value.dob1).format("YYYY-MM-DD"),

            },
            {
              name: this.dependantForm.value.name2,
              service_year: this.dependantForm.value.service_year2,
              // dob: moment(this.dependantForm.value.dob2).format("YYYY-MM-DD"),
              dob :this.datePipe.transform(this.dependantForm.value.dob2).format("YYYY-MM-DD"),

            },
            {
              name: this.dependantForm.value.name3,
              service_year: this.dependantForm.value.service_year3,
              // dob: moment(this.dependantForm.value.dob3).format("YYYY-MM-DD"),
              dob :this.datePipe.transform(this.dependantForm.value.dob3).format("YYYY-MM-DD"),

            }
          );
        }
        this.spinner.show();
        let userObj = {
          section: "dependants_details",
          guru_id: this.addGuruId,
          dependant_details: {
            no_of_dependent: +this.dependantForm.value.numOfDependants,
            dependants_details: dependentDetailsArr,
          },
        };
        let url = "";
        if (this.addDependantId === 0) {
          url = this.appService.addGuru;
        } else {
          url = this.appService.updateGuru + this.addDependantId;
        }
        const apiObj = {
          url: url,
          methodType: HttpMethod.POST,
          requestObj: userObj,
        };
        this.commonService.commonApiCall(apiObj, (res, error) => {
          if (error != undefined) {
            this.spinner.hide();
          } else {
            //debugger;
            this.spinner.hide();
            this.dependantDetailChange = false;
            this.addDependantId = res.guru_id;
          }
        });
      }
    }
  }
  otherDetailFormData() {
    this.getOthersubmitted = true;
    if (this.otherDetailForm.valid) {
      this.otherDetailForm.valueChanges.subscribe((x) => {
        this.otherDetailChange = true;
      });
      if (this.otherDetailChange) {
        var spousedobdate;
        if (this.otherDetailForm.value.SpouseDOB == "") {
          spousedobdate = "";
        } else {
          // spousedobdate = moment(this.otherDetailForm.value.SpouseDOB).format(
          //   "YYYY-MM-DD"
          // );
          spousedobdate =this.datePipe.transform(this.otherDetailForm.value.SpouseDOB).format("YYYY-MM-DD");

        }
        let kidDetailsArr = [];
        if (this.personalDetailForm.value.maritalState === "Single") {
          kidDetailsArr = [];
        } else {
          if (this.otherDetailForm.value.kidsNum == "1") {
            kidDetailsArr = [];
            kidDetailsArr.push({
              name: this.otherDetailForm.value.kidname1,
              gender: this.otherDetailForm.value.kidGender1,
              education: this.otherDetailForm.value.kidseducation1,
            });
          }
          if (this.otherDetailForm.value.kidsNum == "2") {
            kidDetailsArr = [];
            kidDetailsArr.push(
              {
                name: this.otherDetailForm.value.kidname1,
                gender: this.otherDetailForm.value.kidGender1,
                education: this.otherDetailForm.value.kidseducation1,
              },
              {
                name: this.otherDetailForm.value.kidname2,
                gender: this.otherDetailForm.value.kidGender2,
                education: this.otherDetailForm.value.kidseducation2,
              }
            );
          }
        }
        this.spinner.show();
        let userObj = {
          section: "other_details",
          guru_id: this.addGuruId,
          other_details: {
            facebook_id: this.otherDetailForm.value.faceid,
            hobbies: this.otherDetailForm.value.hobbies,
            interests: this.otherDetailForm.value.interestPreference,
            aspirations: this.otherDetailForm.value.aspirations,
            spouse_name: this.otherDetailForm.value.spouseName,
            spouse_dob: spousedobdate,
            spouse_contact: this.otherDetailForm.value.spouseContact,
            spouse_email: this.otherDetailForm.value.spouseEmail,
            no_of_kids: +this.otherDetailForm.value.kidsNum,
            kids_details: kidDetailsArr,
          },
        };
        if (spousedobdate === "") {
          delete userObj.other_details.spouse_dob;
        }
        let url = "";
        if (this.addOtherId === 0) {
          url = this.appService.addGuru;
        } else {
          url = this.appService.updateGuru + this.addOtherId;
        }
        const apiObj = {
          url: url,
          methodType: HttpMethod.POST,
          requestObj: userObj,
        };
        this.commonService.commonApiCall(apiObj, (res, error) => {
          if (error != undefined) {
            this.spinner.hide();
          } else {
            //debugger;
            this.spinner.hide();
            this.otherDetailChange = false;
            this.addOtherId = res.guru_id;
          }
        });
      }
    }
  }
  openMapdialogue() {
    this.dialog.open(SelectLocationComponent, {
      width: "1063px",
    });
  }
  setMaritalState(status) {
    if (status == "Single") {
      this.disableWeddingDate = true;
      (<HTMLInputElement>document.getElementById("weddingdate")).value = "";
      this.personalDetailForm.get("weddingdate").setValue("");
      this.personalDetailForm.get("weddingdate").clearValidators();
    } else {
      this.disableWeddingDate = false;
      this.personalDetailForm
        .get("weddingdate")
        .setValidators([Validators.required]);
    }
    this.personalDetailForm.get("weddingdate").updateValueAndValidity();
  }
  setIdentityProof(id) {
    //    this.personalDetailForm.get('employed').valueChanges.subscribe(response => {
    //   console.log('check response is ', response);
    if (id == "Passport") {
      this.personalDetailForm
        .get("idnumber")
        .setValidators([
          Validators.required,
          Validators.pattern(/^[A-Z][0-9]{7,8}$/),
        ]);
    } else if (id == "Aadhar Card") {
      this.personalDetailForm
        .get("idnumber")
        .setValidators([
          Validators.required,
          Validators.pattern(/^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/),
        ]);
    } else {
      this.personalDetailForm
        .get("idnumber")
        .setValidators([Validators.required]);
    }
    this.personalDetailForm.get("idnumber").updateValueAndValidity();

    //  })
  }
  checkText(event: KeyboardEvent) {
    if (event.code == "Enter") {
      this.getpincode();
    }
    if (this.personalDetailForm.value.pinCode.trim() == "") {
      // this.cityname = "";
      // this.districtname = "";
      // this.statename = "";
      // this.regionname = "";
      // this.countryname = "";
      this.personalDetailForm.get("city").setValue("");
      this.personalDetailForm.get("district").setValue("");
      this.personalDetailForm.get("state").setValue("");
      this.personalDetailForm.get("region").setValue("");
      this.personalDetailForm.get("country").setValue("");
    }
  }
  garragecheckText(event: KeyboardEvent) {
    if (event.code == "Enter") {
      this.getgaragepincode();
    }
    if (this.workDetailForm.value.garragepinCode.trim() == "") {
      // this.gcityname = "";
      // this.gdistrictname = "";
      // this.gstatename = "";
      // this.gregionname = "";
      // this.gcountryname = "";
      this.workDetailForm.get("garragecity").setValue("");
      this.workDetailForm.get("garragedistrict").setValue("");
      this.workDetailForm.get("garragestate").setValue("");
      this.workDetailForm.get("garrageregion").setValue("");
      this.workDetailForm.get("garragecountry").setValue("");
    }
  }
  getpincode() {
    const urlpin = `${
      this.appService.domain +
      "master/location?pincode=" +
      this.personalDetailForm.value.pinCode
    }`;
    const apiObj = {
      url: urlpin,
      methodType: HttpMethod.GET,
    };
    if (this.personalDetailForm.value.pinCode !== "") {
      this.commonService.commonApiCall(apiObj, (res, error) => {
        if ("error" in res) {
          //console.log(true);
          this.pincodeErrorMsg = error.error.msg;
          this.personalDetailForm.get("city").setValue("");
          this.personalDetailForm.get("district").setValue("");
          this.personalDetailForm.get("state").setValue("");
          this.personalDetailForm.get("region").setValue("");
          this.personalDetailForm.get("country").setValue("");
        } else {
          this.pincodeErrorMsg = "";
          this.personalDetailForm.get("city").setValue(res.city);
          this.personalDetailForm.get("district").setValue(res.district);
          this.personalDetailForm.get("state").setValue(res.state);
          this.personalDetailForm.get("region").setValue(res.region);
          this.personalDetailForm.get("country").setValue(res.country);
        }
      });
    }
  }
  getgaragepincode() {
    const url = `${
      this.appService.domain +
      "master/location?pincode=" +
      this.workDetailForm.value.garragepinCode
    }`;
    const apiObj = {
      url: url,
      methodType: HttpMethod.GET,
    };
    if (this.workDetailForm.value.garragepinCode !== "") {
      this.commonService.commonApiCall(apiObj, (res, error) => {
        if ("error" in res) {
          //console.log(true);
          this.garragepincodeErrorMsg = error.error.msg;
          this.workDetailForm.get("garragecity").setValue("");
          this.workDetailForm.get("garragedistrict").setValue("");
          this.workDetailForm.get("garragestate").setValue("");
          this.workDetailForm.get("garrageregion").setValue("");
          this.workDetailForm.get("garragecountry").setValue("");
        } else {
          this.getlocation(this.workDetailForm.value.garragepinCode);
          this.garragepincodeErrorMsg = "";
          this.workDetailForm.get("garragecity").setValue(res.city);
          this.workDetailForm.get("garragedistrict").setValue(res.district);
          this.workDetailForm.get("garragestate").setValue(res.state);
          this.workDetailForm.get("garrageregion").setValue(res.region);
          this.workDetailForm.get("garragecountry").setValue(res.country);
        }
      });
    }
  }
  getlocation(pincodeval) {
    let userObj = {
      latitude: "",
      longitude: "",
      pincode: pincodeval,
    };
    const url = this.appService.getlatlong;
    const apiObj = {
      url: url,
      methodType: HttpMethod.POST,
      requestObj: userObj,
    };
    this.commonService.commonApiCall(apiObj, (res, error) => {
      if (error != undefined) {
        this.garageLatitude = 28.7014;
        this.garageLongitude = 77.1025;
      }
      else{
        this.updatelat = res.latitude;
        this.updatelng = res.longitude;
        this.garageLatitude = res.latitude;
        this.garageLongitude = res.longitude;
      }
    },"map");
    
  }
  changeDependantdate() {
    for (var i in this.dependantForm.value.dependantData) {
      if (this.dependantForm.value.dependantData[i].name == "") {
        alert("name blank");
        return;
      }
      // this.dependantForm.value.dependantData[i].dob = moment(
      //   this.dependantForm.value.dependantData[i].dob
      // ).format("YYYY-MM-DD");
      this.dependantForm.value.dependantData[i].dob = this.datePipe.transform( this.dependantForm.value.dependantData[i].dob).format("YYYY-MM-DD");

    }
  }
  keyPressNumbers(event) {
    var charCode = event.which ? event.which : event.keyCode;
    // Only Numbers 0-9
    if (charCode < 48 || charCode > 57) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }
  keyPressSpclCharacter(event) {
    var k = event.which ? event.which : event.keyCode;
    return (
      (k > 64 && k < 91) ||
      (k > 96 && k < 123) ||
      k == 8 ||
      k == 32 ||
      (k >= 48 && k <= 57)
    );
  }
  keyDateFormat(event) {
    var charCode = event.which ? event.which : event.keyCode;
    // Only Numbers 0-9
    if (charCode < 47 || charCode > 57) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }
  onFinalSubmit() {
    for (let i = 0; i < this.workshopImage.length; i++) {
      this.formData.append("workshop_image", this.workshopImage[i]);
    }
    this.spinner.show();
    let apiUrl = this.appService.addGurudocs + this.addGuruId;
    const apiObj = {
      url: apiUrl,
      methodType: HttpMethod.POST,
      requestObj: this.formData,
    };
    this.commonService.commonApiCall(
      apiObj,
      (res: any, error) => {
        if (error != undefined) {
          this.showToastermessage = true;
          this.showfinalErrorMsg = error.error.msg;
          setTimeout(() => {
            this.showfinalErrorMsg = "";
            this.showToastermessage = false;
          }, 3000);
          this.spinner.hide();
        } else {
          this.showfinalSuccessMsg = "Guru submitted successfully";
          this.showToastermessage = true;
          setTimeout(() => {
            this.showfinalSuccessMsg = "";
            this.showToastermessage = false;
            this.router.navigate(["guru-list"]);
          }, 3000);
          this.spinner.hide();
        }
      },
      "multipart/form-data"
    );
  }
  onCheckboxChange(e) {
    //debugger;
    const checkArray: UntypedFormArray = this.workDetailForm.get(
      "checkArray"
    ) as UntypedFormArray;

    if (e.target.checked) {
      checkArray.push(new UntypedFormControl(e.target.value));
    } else {
      let i: number = 0;
      checkArray.controls.forEach((item: UntypedFormControl) => {
        if (item.value == e.target.value) {
          checkArray.removeAt(i);
          return;
        }
        i++;
      });
    }
  }
  public noWhitespaceValidator(control: UntypedFormControl) {
      const isWhitespace = (control.value || '').trim().length === 0;
      const isValid = !isWhitespace;
      return isValid ? null : { 'whitespace': true };
  }
}
