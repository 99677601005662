<h2>
  Reports
  <span class="subhead"
    >Job summary -
    <span *ngIf="step === 'jobCard'">job card vs tgp fitment</span>
    <span *ngIf="step === 'vehicleData'">TML Vehicle Data</span>
    <!-- <span *ngIf="step === 'otherbrands'">TML Vs Other Brands</span> -->
  </span>
</h2>
<div class="bandhu-tabs report-summary-tabs hidden">
  <mat-button-toggle-group
    name="fontStyle"
    value="All Areas"
    aria-label="Font Style"
  >
    <mat-button-toggle value="All Areas">All Areas</mat-button-toggle>
    <mat-button-toggle value="Balewadi">Balewadi</mat-button-toggle>
    <mat-button-toggle value="Vishrantwadi">Vishrantwadi</mat-button-toggle>
    <mat-button-toggle value="Aundh">Aundh</mat-button-toggle>
    <mat-button-toggle value="Baner">Baner</mat-button-toggle>
  </mat-button-toggle-group>
</div>
<div class="guru-tabs-row">
  <div class="guru-tabs-items">
    <ul>
      <li
        [ngClass]="{ 'active-tab': step === 'jobCard' }"
        (click)="step = 'jobCard'"
      >
        <a>Job Cards Vs TGP Fitment</a>
      </li>
      <li
        [ngClass]="{ 'active-tab': step === 'vehicleData' }"
        (click)="step = 'vehicleData'"
      >
        <a>TML Vehicle Data</a>
      </li>
      <!-- <li
        [ngClass]="{ 'active-tab': step === 'otherbrands' }"
        (click)="step = 'otherbrands'"
      >
        <a>TML Vs Other Brands</a>
      </li> -->
    </ul>
  </div>
  <div class="tb-btn-group">
    <ul>
      <li>
        <div class="tb-select-dropdown">
          <div class="tb-selectedVal" (click)="selectValfun()">
            {{ selectedItem }}
          </div>
          <ul class="tb-dropdown-options" *ngIf="showDropdown">
            <li
              *ngFor="let item of commonService.viewTypeOptions; let i = index"
              (click)="select(i)"
              [ngClass]="{ selected: isActive(item.paramName) }"
            >
              {{ item.title }}
            </li>
          </ul>
        </div>
      </li>

      <!-- <li *ngIf="step === 'vehicleData'">
        <app-gurulist-filter></app-gurulist-filter>
      </li> -->
    </ul>
  </div>
</div>

<div class="report-subcomponent-wrapper">
  <app-job-card-tgp-fitment
    *ngIf="step === 'jobCard'"
    [selectedView]="selected"
  ></app-job-card-tgp-fitment>
  <app-tml-vehicle-data
    *ngIf="step === 'vehicleData'"
    [selectedView]="selected"
  ></app-tml-vehicle-data>
  <!-- <app-tml-vs-other-brands
    *ngIf="step === 'otherbrands'"
  ></app-tml-vs-other-brands> -->
</div>
