<table mat-table matTableExporter 
    class="table full-width-table tb-table" matSort aria-label="Elements" 
    [dataSource]="dataSource">
    <ng-container matColumnDef="id" sticky>
        <th mat-header-cell *matHeaderCellDef class="id-col" style="background-color: white;">ID</th>
        <td mat-cell *matCellDef="let element" class="id-col  {{ element.id }} text-nowrap" style="box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
        ">
        {{ element.id }}
        </td>
    </ng-container>
    <ng-container matColumnDef="mechanic_name">
        <th mat-header-cell *matHeaderCellDef class="mechanic_name-col text-nowrap">
        Mechanic Name
        </th>
        <td mat-cell *matCellDef="let element" class="mechanic_name-col text-nowrap">
        {{ element.mechanic_name}}
        </td>
    </ng-container>
    <ng-container matColumnDef="garage_area">
        <th mat-header-cell *matHeaderCellDef class="garage_area-col text-nowrap">
        Garage Area
        </th>
        <td mat-cell *matCellDef="let element" class="garage_area-col text-nowrap">
        {{ element.garage_area}}
        </td>
    </ng-container>
    <!-- <ng-container matColumnDef="loyality_points">
        <th mat-header-cell *matHeaderCellDef class="loyality_points-col text-nowrap">
        Loyalty Points
        </th>
        <td mat-cell *matCellDef="let element" class="loyality_points-col text-nowrap">
            {{ element.loyalty_points}}
        </td>
    </ng-container> -->
    <ng-container matColumnDef="source">
        <th mat-header-cell *matHeaderCellDef class="source-col text-nowrap">
        Source
        </th>
        <td mat-cell *matCellDef="let element" class="source-col text-nowrap">
            {{ element.source}}
        </td>
    </ng-container>
    <ng-container matColumnDef="referrel_by_id">
        <th mat-header-cell *matHeaderCellDef class="referrel_by_id-col text-nowrap">
        Refer By
        </th>
        <td mat-cell *matCellDef="let element" class="referrel_by_id-col text-nowrap">
            {{ element.referred_by_id}}
        </td>
    </ng-container>
    <!-- <ng-container matColumnDef="latitude">
        <th mat-header-cell *matHeaderCellDef class="latitude-col text-nowrap">
        Latitude
        </th>
        <td mat-cell *matCellDef="let element" class="latitude-col text-nowrap">
            {{ element.latitude?element.latitude:"NA" }}
        </td>
    </ng-container>
    <ng-container matColumnDef="longitude">
        <th mat-header-cell *matHeaderCellDef class="longitude-col text-nowrap">
        Longitude
        </th>
        <td mat-cell *matCellDef="let element" class="longitude-col text-nowrap">
            {{ element.longitude?element.longitude:"NA" }}
        </td>
    </ng-container>
    <ng-container matColumnDef="created_at">
        <th mat-header-cell *matHeaderCellDef class="created_at-col text-nowrap">
        Created At
        </th>
        <td mat-cell *matCellDef="let element" class="created_at-col text-nowrap">
            {{ element.created_date | date: "dd/MM/yyyy" }}
        </td>
    </ng-container>
    <ng-container matColumnDef="updated_at">
        <th mat-header-cell *matHeaderCellDef class="updated_at-col text-nowrap">
        Updated At
        </th>
        <td mat-cell *matCellDef="let element" class="updated_at-col text-nowrap">
            {{ element.updated_date | date: "dd/MM/yyyy" }}
        </td>
    </ng-container>
    <ng-container matColumnDef="guru_id">
        <th mat-header-cell *matHeaderCellDef class="guru_id-col text-nowrap">
        Guru Id
        </th>
        <td mat-cell *matCellDef="let element" class="guru_id-col text-nowrap">
            {{ element.guru_user_data? element.guru_user_data.user_id:'NA' }}
        </td>
    </ng-container> -->
    <ng-container matColumnDef="guru_name">
        <th mat-header-cell *matHeaderCellDef class="guru_name-col text-nowrap">
        Guru Name
        </th>
        <td mat-cell *matCellDef="let element" class="guru_name-col text-nowrap">
            {{ element.referred_by_guru_details? (element.referred_by_guru_details.referred_by_guru_name):'NA' }}
        </td>
    </ng-container>
    <ng-container matColumnDef="guru_username">
        <th mat-header-cell *matHeaderCellDef class="guru_username-col text-nowrap">
        Guru Phone Number
        </th>
        <td mat-cell *matCellDef="let element" class="guru_username-col text-nowrap">
            {{ element.referred_by_guru_details?element.referred_by_guru_details.referred_by_guru_phone_number:'NA' }}
        </td>
    </ng-container>

    <ng-container matColumnDef="guru_state">
        <th mat-header-cell *matHeaderCellDef class="guru_state-col text-nowrap">
        Guru State
        </th>
        <td mat-cell *matCellDef="let element" class="guru_state-col text-nowrap">
            {{ element.referred_by_guru_details?element.referred_by_guru_details.referred_by_guru_state_name:'NA' }}
        </td>
    </ng-container>
    <ng-container matColumnDef="guru_region">
        <th mat-header-cell *matHeaderCellDef class="guru_region-col text-nowrap">
        Guru Region
        </th>
        <td mat-cell *matCellDef="let element" class="guru_region-col text-nowrap">
            {{ element.referred_by_guru_details?element.referred_by_guru_details.referred_by_guru_zone:'NA' }}
        </td>
    </ng-container>
    <ng-container matColumnDef="laas_name">
        <th mat-header-cell *matHeaderCellDef class="laas_name-col text-nowrap">
        Lass Name
        </th>
        <td mat-cell *matCellDef="let element" class="laas_name-col text-nowrap">
            {{ element.lass_detail?element.lass_detail.lass_name:'NA' }}
        </td>
    </ng-container>
    <ng-container matColumnDef="laas_number">
        <th mat-header-cell *matHeaderCellDef class="laas_number-col text-nowrap">
        Lass Number
        </th>
        <td mat-cell *matCellDef="let element" class="laas_number-col text-nowrap">
            {{ element.lass_detail?element.lass_detail.lass_number:'NA' }}
        </td>
    </ng-container>
    <ng-container matColumnDef="lass_email">
        <th mat-header-cell *matHeaderCellDef class="lass_email-col text-nowrap">
        Lass Email
        </th>
        <td mat-cell *matCellDef="let element" class="lass_email-col text-nowrap">
            {{ element.lass_detail?element.lass_detail.lass_email:'NA' }}
        </td>
    </ng-container>
    <tr class="tb-head-row" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr class="tb-item-row" mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>