<div class="guru-item-wrap">
  <div class="guru-row col-full mb-50">
    <div class="col-one-third gurus-status-col">
      <h2>Gurus <span class="changes-subhead">(Current Month)</span></h2>
      <div class="comn-box">
        <app-gurus-status></app-gurus-status>
      </div>
    </div>
    <div class="col-half">
      <!-- <app-coupon-summary></app-coupon-summary> -->
      <app-parts-performance></app-parts-performance>
      <app-target-achieved></app-target-achieved>
    </div>
    <!-- <div class="col-quarter redemptions-col">
         <app-redemptions></app-redemptions> 
      </div> -->
  </div>

  <div class="guru-items-wrap guru-full-grey-bg">
    <app-line-item-summary></app-line-item-summary>
  </div>

  <!--<div class="guru-row col-full mb-50">
      <div class="guru-left-col col-half">
        <app-parts-performance></app-parts-performance>
      </div>
       <div class="guru-right-col col-half">
         <app-points-trend></app-points-trend> 
      </div>
    </div> 
   
      <div class="campaign-full">
        <app-campaign-guru-diwas></app-campaign-guru-diwas>
      </div>
    </div>
    <div class="guru-row mb-50 dist-heatmap-sec">
      <h2>
        Distributor Performance Heatmap
        <small>(Click on an area to view its performance data)</small>
      </h2>
      <div class="distributer-heatmap col-full">
        <div class="heatmap col-half">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d227748.38256235013!2d75.65046966592854!3d26.88544791801586!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x396c4adf4c57e281%3A0xce1c63a0cf22e09!2sJaipur%2C%20Rajasthan!5e0!3m2!1sen!2sin!4v1628494487526!5m2!1sen!2sin"
            width="100%"
            height="600"
            style="border: 0"
            allowfullscreen="false"
            loading="lazy"
          ></iframe>
        </div>
        <div class="top-msr col-half">
          <h4>Total 5 MSRs</h4>
          <div class="top-msr-items">
            <div class="msr-item">
              <div class="msr-item-head">
                <div class="msr-profile">
                  <div class="msr-img summary-img">
                    <img src="./assets/images/user__pic.png" alt="" />
                  </div>
                  <div class="msr-name-cont">
                    <h4>Girish Sulakhe, <span>Solapur</span></h4>
                    <p>9876543210</p>
                  </div>
                </div>
                <div class="msr-plan-list">
                  <ul class="d-flex">
                    <li>
                      <a class="link-button" href="">View Visit Plan</a>
                    </li>
                    <li>
                      <a class="link-button" href="">View Guru List</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="msr-status">
                <div class="msr-chart"></div>
                <div class="msr-status-body">
                  <div class="msr-status-row">
                    <div class="summary-box active">
                      <h2>128 <small>Active</small></h2>
                    </div>
                    <div class="summary-box transacting">
                      <h2>128 <small>Transacting</small></h2>
                    </div>
                    <div class="summary-box inactive">
                      <h2>128 <small>Inactive</small></h2>
                    </div>
                  </div>
                  <div class="msr-targets-acheived comn-box col-full">
                    <div class="msr-targets-col col-one-third">
                      <h6><span> Target vs Achieved:</span> 130/200</h6>
                    </div>
                    <div class="msr-targets-col col-one-third">
                      <h6><span> Parts Fitment:</span> 14020/15420</h6>
                    </div>
                    <div class="msr-targets-col col-one-third">
                      <h6><span> Redeemed Points:</span> 130/200</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="msr-item">
              <div class="msr-item-head">
                <div class="msr-profile">
                  <div class="msr-img summary-img">
                    <img src="./assets/images/user__pic.png" alt="" />
                  </div>
                  <div class="msr-name-cont">
                    <h4>Girish Sulakhe, <span>Solapur</span></h4>
                    <p>9876543210</p>
                  </div>
                </div>
                <div class="msr-plan-list">
                  <ul class="d-flex">
                    <li>
                      <a class="link-button" href="">View Visit Plan</a>
                    </li>
                    <li>
                      <a class="link-button" href="">View Guru List</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="msr-status">
                <div class="msr-chart"></div>
                <div class="msr-status-body">
                  <div class="msr-status-row">
                    <div class="summary-box active">
                      <h2>128 <small>Active</small></h2>
                    </div>
                    <div class="summary-box transacting">
                      <h2>128 <small>Transacting</small></h2>
                    </div>
                    <div class="summary-box inactive">
                      <h2>128 <small>Inactive</small></h2>
                    </div>
                  </div>
                  <div class="msr-targets-acheived comn-box col-full">
                    <div class="msr-targets-col col-one-third">
                      <h6><span> Target vs Achieved:</span> 130/200</h6>
                    </div>
                    <div class="msr-targets-col col-one-third">
                      <h6><span> Parts Fitment:</span> 14020/15420</h6>
                    </div>
                    <div class="msr-targets-col col-one-third">
                      <h6><span> Redeemed Points:</span> 130/200</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
</div>
