<div class="target-achieved-wrapper">
  <div class="target-col">
    <div class="target-box">
      <h3>Top Parts Fitted</h3>
      <div class="summary-table">
        <div class="summarytab-body">
          <div class="summarytab-row" *ngFor="let item of targetDetails?.results; let i = index">
            <div class="summarytab-col summarytab-col-75 summary-img-title">
              <i class="summary-img"><img src="./assets/images/parts/crownwheel.svg" alt="" /></i>
              <div class="summary-detail">
                {{ item.part.name }}
                <span class="small-font">{{ item.part.part_number }}</span>
              </div>
            </div>
            <div class="summarytab-col summarytab-col-25 summary-img-title">
              {{ item.parts_fitted }}
            </div>

            <!-- <div class="summarytab-col hidden">
              <div class="summarytab-modcol">
                <div class="progress">
                  <div
                    class="progress-bar"
                    role="progressbar"
                    aria-valuenow="1560"
                    aria-valuemin="10"
                    aria-valuemax="1700"
                    data-value="750 - 75%"
                    style="width: 70%"
                  >
                    <span
                      class="popOver"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="70%"
                    ></span>
                  </div>
                </div>
                <div class="progress-text"><span>0</span><span>600</span></div>
              </div>
            </div>
            <div
              class="summarytab-col summarytab-col-25 summary-job-cards hidden"
            >
              <div class="summary-percentage">
                <span class="increaseValue">5%</span>
                <span class="small-font">Last Year - 368/600</span>
              </div>
            </div> -->
          </div>
        </div>
        <div *ngIf="!targetDetails?.results.length" class="no-item-found">
          No record found
        </div>
      </div>
    </div>
  </div>
  <div class="Comparison-col">
    <div class="comn-box">
      <h3>Comparison with last year: Parts Fitted</h3>
      <div class="summary-row">
        <div class="summary-left-col">
          <div class="summary-col">
            <div class="summary-box">
              <h4>
                Total Parts Fitted -
                {{ targetDetails?.current_year_parts_fitment }}
              </h4>
              <div class="coupan-val">
                <span>FY-{{ commonService.currentYear }}</span>

                <span [class]="
                    targetDetails?.parts_fitment_percentage_change > 0
                      ? 'increaseValue'
                      : 'decreaseValue'
                  ">{{
                    targetDetails?.parts_fitment_percentage_change | abs
                      | number: "1.0-2"
                  }}%</span>
              </div>
              <!-- <div class="target-text-wrap">
                <div class="progress">
                  <div
                    class="progress-bar"
                    role="progressbar"
                    aria-valuenow="1560"
                    aria-valuemin="10"
                    aria-valuemax="1700"
                    data-value="750 - 75%"
                    style="width: 70%"
                  >
                    <span
                      class="popOver"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="70%"
                    ></span>
                  </div>
                </div>
              </div>
              <div class="progress-text"><span>0</span><span>1700</span></div> -->
            </div>
            <div class="summary-box">
              <h4>
                Total Parts Fitted -
                {{ targetDetails?.last_year_parts_fitment }}
              </h4>
              <div class="coupan-val">
                <span>FY-{{ commonService.prevYear }}</span>
                <span></span>
              </div>
              <!-- <div class="target-text-wrap">
                <div class="progress">
                  <div
                    class="progress-bar"
                    role="progressbar"
                    aria-valuenow="1560"
                    aria-valuemin="10"
                    aria-valuemax="1700"
                    data-value="750 - 75%"
                    style="width: 70%"
                  >
                    <span
                      class="popOver"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="70%"
                    ></span>
                  </div>
                </div>
              </div>
              <div class="progress-text"><span>0</span><span>1700</span></div> -->
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="summary-row">
        <div class="summary-left-col">
          <div class="summary-col">
            <div class="summary-box">
              <h4>Total Points Earned</h4>
              <div class="coupan-val">
                <span>2020</span>
                <span class="increaseValue">8%</span>
              </div>
              <div class="target-text-wrap">
                <div class="progress">
                  <div
                    class="progress-bar"
                    role="progressbar"
                    aria-valuenow="1560"
                    aria-valuemin="10"
                    aria-valuemax="1700"
                    data-value="750 - 75%"
                    style="width: 70%"
                  >
                    <span
                      class="popOver"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="70%"
                    ></span>
                  </div>
                </div>
              </div>
              <div class="progress-text"><span>0</span><span>4000</span></div>
            </div>
            <div class="summary-box">
              <h4>Total Points Claimed</h4>
              <div class="coupan-val">
                <span>2019</span>
                <span></span>
              </div>
              <div class="target-text-wrap">
                <div class="progress">
                  <div
                    class="progress-bar"
                    role="progressbar"
                    data-value="750 - 75%"
                    style="width: 70%"
                  >
                    <span
                      class="popOver"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="70%"
                    ></span>
                  </div>
                </div>
              </div>
              <div class="progress-text"><span>0</span><span>4000</span></div>
            </div>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</div>
<!-- <div class="tb-table-wrapper">
  <div class="guru-tabs-row">
    <div class="guru-tabs-items">
      <h2>Individual Part Details</h2>
    </div>
    <div class="tb-btn-group">
      <ul>
        <li>
          <div class="tb-searchbox">
            <button class="tb-button guru-flex">
              <div class="tb__search_icon"></div>
            </button>
            <input
              type="text"
              class="tb-search-text"
              placeholder="Search by Title"
            />
          </div>
        </li>
        <li>
          <button mat-stroked-button class="primary-stroke-button exportBtn">
            Export
          </button>
        </li>
      </ul>
    </div>
  </div>
  <guru-table
    [tableData]="orders"
    [tableColumns]="ordersTableColumns"
    [isFilterable]="false"
    [isPageable]="true"
    [defaultPageSize]="15"
    (sort)="sortData($event)"
  >
  </guru-table>
</div> -->