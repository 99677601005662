<table mat-table matTableExporter 
    class="full-width-table tb-table" matSort aria-label="Elements" 
    [dataSource]="dataSource">
    <ng-container matColumnDef="id" sticky>
        <th mat-header-cell *matHeaderCellDef class="id-col" style="background-color: white;">ID</th>
        <td mat-cell *matCellDef="let element" class="id-col  {{ element.id }} text-nowrap" style="box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
        ">
        {{ element.id?element.id:'NA' }}
        </td>
    </ng-container>
    <ng-container matColumnDef="prospect_name">
        <th mat-header-cell *matHeaderCellDef class="prospect_name-col text-nowrap">
        Prospect Name
        </th>
        <td mat-cell *matCellDef="let element" class="prospect_name-col text-nowrap text-center">
        {{ element.prospect_name?element.prospect_name:'NA' }}
        </td>
    </ng-container>
    <ng-container matColumnDef="prospect_created_date">
        <th mat-header-cell *matHeaderCellDef class="prospect_created_date-col text-nowrap">
        Prospect Created Date
        </th>
        <td mat-cell *matCellDef="let element" class="prospect_created_date-col text-nowrap text-center">
        {{ element.created_date? ( element.created_date  | date: "dd/MM/yyyy" ):'NA' }}
        </td>
    </ng-container>
    <ng-container matColumnDef="prospect_phone">
        <th mat-header-cell *matHeaderCellDef class="prospect_phone-col text-nowrap">
        Prospect Phone Number
        </th>
        <td mat-cell *matCellDef="let element" class="prospect_phone-col text-nowrap text-center">
        {{ element.prospect_phone?element.prospect_phone:'NA' }}
        </td>
    </ng-container>
    <ng-container matColumnDef="prospect_state">
        <th mat-header-cell *matHeaderCellDef class="prospect_state-col text-nowrap">
        Location of Purchase
        </th>
        <td mat-cell *matCellDef="let element" class="prospect_state-col text-nowrap text-center">
        {{ element.prospect_state?element.prospect_state:'NA' }}
        </td>
    </ng-container>
    <ng-container matColumnDef="prospect_pincode">
        <th mat-header-cell *matHeaderCellDef class="prospect_pincode-col text-nowrap">
        Prospect Pincode
        </th>
        <td mat-cell *matCellDef="let element" class="prospect_pincode-col text-nowrap text-center">
        {{ element.prospect_pincode?element.prospect_pincode:'NA' }}
        </td>
    </ng-container>
    <ng-container matColumnDef="model_intereste">
        <th mat-header-cell *matHeaderCellDef class="model_intereste-col text-nowrap">
        Model Interested
        </th>
        <td mat-cell *matCellDef="let element" class="model_intereste-col text-nowrap text-center">
        {{ element.model_interested?element.model_interested:'NA' }}
        </td>
    </ng-container>
    <ng-container matColumnDef="vehicle_final_by">
        <th mat-header-cell *matHeaderCellDef class="vehicle_final_by-col text-nowrap">
        Vehicle Final By
        </th>
        <td mat-cell *matCellDef="let element" class="vehicle_final_by-col text-nowrap text-center">
        {{ element.vehicle_final_by?element.vehicle_final_by:'NA' }}
        </td>
    </ng-container>
    <ng-container matColumnDef="remainder_date">
        <th mat-header-cell *matHeaderCellDef class="remainder_date-col text-nowrap">
            Reminder Date
        </th>
        <td mat-cell *matCellDef="let element" class="remainder_date-col text-nowrap text-center">
        {{ element.reminder_date?( element.reminder_date  | date: "dd/MM/yyyy" ):'NA' }}
        </td>
    </ng-container>
    <ng-container matColumnDef="mechanic_name">
        <th mat-header-cell *matHeaderCellDef class="mechanic_name-col text-nowrap">
        Mechanic Name
        </th>
        <td mat-cell *matCellDef="let element" class="mechanic_name-col text-nowrap text-center">
        {{ element.guru?element.guru.mechanic_name:'NA' }}
        </td>
    </ng-container>
    <ng-container matColumnDef="garage_area">
        <th mat-header-cell *matHeaderCellDef class="garage_area-col text-nowrap">
        Garage Name
        </th>
        <td mat-cell *matCellDef="let element" class="garage_area-col text-nowrap text-center">
        {{ element.guru?element.guru.garage_name:'NA' }}
        </td>
    </ng-container>
    <ng-container matColumnDef="gstin_number">
        <th mat-header-cell *matHeaderCellDef class="gstin_number-col text-nowrap">
        GSTIN Number
        </th>
        <td mat-cell *matCellDef="let element" class="gstin_number-col text-nowrap text-center">
        {{ element.guru?element.guru.gstin_number :'NA' }}
        </td>
    </ng-container>
    <ng-container matColumnDef="upi_id">
        <th mat-header-cell *matHeaderCellDef class="upi_id-col text-nowrap">
        UPI ID
        </th>
        <td mat-cell *matCellDef="let element" class="upi_id-col text-nowrap">
            {{ element.guru?element.guru.upi_id:'NA' }}
        </td>
    </ng-container>
    <ng-container matColumnDef="last_invoice_number">
        <th mat-header-cell *matHeaderCellDef class="last_invoice_number-col text-nowrap">
        Last Invoice Number
        </th>
        <td mat-cell *matCellDef="let element" class="last_invoice_number-col text-nowrap text-center">
            {{ element.guru?element.guru.last_invoice_number:'NA' }}
        </td>
    </ng-container>
    <ng-container matColumnDef="guru_name">
        <th mat-header-cell *matHeaderCellDef class="guru_name-col text-nowrap">
        Guru Name
        </th>
        <td mat-cell *matCellDef="let element" class="guru_name-col text-nowrap text-center">
            {{ element.guru?(element.guru.guru_user_data.first_name+' '+element.guru.guru_user_data.last_name ):'NA' }}
        </td>
    </ng-container>
    <ng-container matColumnDef="guru_username">
        <th mat-header-cell *matHeaderCellDef class="guru_username-col text-nowrap">
        Guru Phone Number
        </th>
        <td mat-cell *matCellDef="let element" class="guru_username-col text-nowrap text-center">
            {{ element.guru?element.guru.guru_user_data.username:'NA' }}
        </td>
    </ng-container>
    <ng-container matColumnDef="guru_state">
        <th mat-header-cell *matHeaderCellDef class="guru_state-col text-nowrap">
        Guru State
        </th>
        <td mat-cell *matCellDef="let element" class="guru_state-col text-nowrap text-center">
            {{ element.guru?element.guru.state_name:'NA' }}
        </td>
    </ng-container>
    <ng-container matColumnDef="guru_region">
        <th mat-header-cell *matHeaderCellDef class="guru_region-col text-nowrap">
        Guru Region
        </th>
        <td mat-cell *matCellDef="let element" class="guru_region-col text-nowrap text-center" >
            {{ element.guru?element.guru.zone:'NA' }}
        </td>
    </ng-container>
    
    <ng-container matColumnDef="laas_name">
        <th mat-header-cell *matHeaderCellDef class="laas_name-col text-nowrap">
        Lass Name
        </th>
        <td mat-cell *matCellDef="let element" class="laas_name-col text-nowrap text-center">
            {{ element.guru?element.guru.lass_detail.lass_name:'NA' }}
        </td>
    </ng-container>
    <ng-container matColumnDef="laas_number">
        <th mat-header-cell *matHeaderCellDef class="laas_number-col text-nowrap">
        Lass Number
        </th>
        <td mat-cell *matCellDef="let element" class="laas_number-col text-nowrap text-center">
            {{ element.guru?element.guru.lass_detail.lass_number:'NA' }}
        </td>
    </ng-container>
    <ng-container matColumnDef="laas_email">
        <th mat-header-cell *matHeaderCellDef class="laas_email-col text-nowrap">
        Lass Email
        </th>
        <td mat-cell *matCellDef="let element" class="laas_email-col text-nowrap text-center">
            {{ element.guru?element.guru.lass_detail.lass_email:'NA' }}
        </td>
    </ng-container>

    <tr class="tb-head-row" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr class="tb-item-row" mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>

