import { Component, OnInit, Input, OnChanges } from "@angular/core";
import { AppService } from "src/app/services/app.service";
import { CommonService } from "src/app/services/common.service";
import { Sort } from "@angular/material/sort";
import { TableColumn } from "src/app/shared/guru-table/TableColumn";
import { HttpMethod } from "src/app/constants/http-handlers";
import { TmlVehicleData } from "./tms-vehicle-data";
import { DatePipe } from "@angular/common";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: "app-tml-vehicle-data",
  templateUrl: "./tml-vehicle-data.component.html",
  styleUrls: ["./tml-vehicle-data.component.scss"],
  providers: [DatePipe],
})
export class TmlVehicleDataComponent implements OnInit, OnChanges {
  @Input() selectedView: any;

  vehicleData: any[] = [];
  vehicleComparison: any;

  orders: TmlVehicleData[];
  ordersTableColumns: TableColumn[];

  isDataReady: boolean = false;
  currentYear = new Date().getFullYear();
  prevYear = this.currentYear - 1;
  searchText: string = "";
  recordCount: number = 0;
  pageIndex = 0;

  constructor(
    private appService: AppService,
    private commonService: CommonService,
    private datePipe: DatePipe,
    private spinner: NgxSpinnerService
  ) {
    this.initializeColumns();
  }

  ngOnInit(): void {
    this.initializeColumns();
    this.isDataReady = false;
    this.getOrders();
  }

  ngOnChanges(): void {
    this.getVehicleData();
    this.getVehicleComparison();
    //this.getOrders();
  }

  receivedPageHandler(valueEmitted: any) {
    this.pageIndex = valueEmitted;
    this.isDataReady = false;
    this.getOrders();
  }

  getVehicleData() {
    this.spinner.show();
    let viewType = this.commonService.getViewType(this.selectedView);

    //this.orders = [];
    let apiUrl = this.appService.vehicleData;
    let queryParamList = [];
    this.vehicleData = [];

    //view type param
    let viewTypeParam = "";
    if (viewType != "") {
      viewTypeParam = "date_range=" + viewType;
      queryParamList.push(viewTypeParam);
    }

    let apiQueryParam = "";
    if (queryParamList.length > 0) {
      apiQueryParam = `?` + queryParamList.join("&");
    }

    const url = `${apiUrl}` + apiQueryParam;

    const apiObj = {
      url: url,
      methodType: HttpMethod.GET,
    };
    this.commonService.commonApiCall(apiObj, (res) => {
      if (res && res.hasOwnProperty("results") && res.results) {
        this.spinner.show();
        res.results.forEach((ele) => {
          let obj: any = {
            model: ele.model,
            vechiclesRepaired: ele.vehicles_repaired,
            fittedLineItems: ele.most_fitted_line_items.join(","),
            jobCards: ele.jobcards,
            modelImg: "",
          };
          this.vehicleData.push(obj);
        });
      } else {
        this.spinner.hide();
      }
      this.spinner.hide();
    });
  }

  getVehicleComparison() {
    let viewType = this.commonService.getViewType(this.selectedView);
    //this.orders = [];
    let apiUrl = this.appService.vehicleComparison;
    let queryParamList = [];
    this.vehicleComparison = {};

    //view type param
    let viewTypeParam = "";
    if (viewType != "") {
      viewTypeParam = "date_range=" + viewType;
      queryParamList.push(viewTypeParam);
    }

    let apiQueryParam = "";
    if (queryParamList.length > 0) {
      apiQueryParam = `?` + queryParamList.join("&");
    }

    const url = `${apiUrl}` + apiQueryParam;

    const apiObj = {
      url: url,
      methodType: HttpMethod.GET,
    };
    this.commonService.commonApiCall(apiObj, (res) => {
      if (res) {
        this.vehicleComparison = res;
      }
      // console.log("** ",this.vehicleComparison);
    });
  }

  sortData(sortParameters: Sort) {
    const keyName = sortParameters.active;
    if (sortParameters.direction === "asc") {
      this.orders = this.orders.sort((a: TmlVehicleData, b: TmlVehicleData) =>
        a[keyName].localeCompare(b[keyName])
      );
      return "";
    } else if (sortParameters.direction === "desc") {
      this.orders = this.orders.sort((a: TmlVehicleData, b: TmlVehicleData) =>
        b[keyName].localeCompare(a[keyName])
      );
      return "";
    } else {
      return "";
      //return (this.orders);
    }
  }

  searchRecords() {
    this.isDataReady = false;
    this.getOrders();
  }

  checkText(event: KeyboardEvent) {
    if (event.code == "Enter" || this.searchText.trim() == "") {
      this.searchRecords();
    }
  }
  initializeColumns(): void {
    this.ordersTableColumns = [
      {
        name: "Vehicle",
        dataKey: "vehicleName",
        position: "center",
        isSortable: true,
      },
      {
        name: "Guru Name",
        dataKey: "guruName",
        position: "center",
        isSortable: false,
      },
      {
        name: "Area",
        dataKey: "area",
        position: "center",
        isSortable: true,
      },
      {
        name: "Job Card Id",
        dataKey: "jobCardId",
        position: "center",
        isSortable: false,
      },
      {
        name: "TGP",
        dataKey: "tgpPartsFitted",
        position: "center",
        isSortable: true,
      },
      {
        name: "NON TGP",
        dataKey: "nontgpPartsFitted",
        position: "center",
        isSortable: true,
      },
      {
        name: "Repair Date",
        dataKey: "repariDate",
        position: "center",
        isSortable: true,
      },
    ];
  }

  getOrders() {
    this.isDataReady = false;
    this.recordCount = 0;
    this.orders = [];

    let apiUrl = this.appService.vehicleRepairDetails;
    let queryParamList = [];
    let viewType = "quarterly"; // this.commonService.getViewType(this.selectedView)

    //paging
    let pageParam = "";
    if (this.pageIndex > 0) {
      pageParam = `page=` + (this.pageIndex + 1);
      queryParamList.push(pageParam);
    }
    let viewTypeParam = "";
    if (viewType != "") {
      viewTypeParam = "view_by=" + viewType;
      queryParamList.push(viewTypeParam);
    }

    let dtObj = this.commonService.getDateRangObject(
      viewType,
      "",
      "YYYY-MM-dd"
    );

    let requestObj = {};
    //searchText

    if (this.searchText == "") {
      requestObj = {
        from_date: dtObj.from,
        to_date: dtObj.to,
      };
    } else {
      if (/\D/g.test(this.searchText) == false) {
        requestObj = {
          from_date: dtObj.from,
          to_date: dtObj.to,
          user_id: this.searchText,
        };
      } else {
        requestObj = {
          from_date: dtObj.from,
          to_date: dtObj.to,
          name: this.searchText,
        };
      }
    }
    let apiQueryParam = "";
    if (queryParamList.length > 0) {
      apiQueryParam = `?` + queryParamList.join("&");
    }
    const url = `${apiUrl}` + apiQueryParam;

    const apiObj = {
      url: url,
      methodType: HttpMethod.POST,
      requestObj: requestObj,
    };
    this.commonService.commonApiCall(apiObj, (res) => {
      if (
        res &&
        res.hasOwnProperty("results") &&
        res.results &&
        res.results.length
      ) {
        this.recordCount = res.count;
        res.results.forEach((ele) => {
          let obj: any = {
            vehicleName: `<div class="vehicle-img"><img src="./assets/images/truck-img.svg" alt=""></div><div class="">${ele.vehicle_detail.registration_number?ele.vehicle_detail.registration_number:""} <span class="small-font">${ele.vehicle_detail.model?ele.vehicle_detail.model:""}</span></div>`,
            guruName: `<a class="guru-name">${ele.guru_details.guru_name?ele.guru_details.guru_name:""}</a><span class="small-font">${ele.guru_details.garage_name?ele.guru_details.garage_name:""}</span>`,
            jobCardId: ele.id?ele.id:"",
            area: ele.guru_details.area?ele.guru_details.area:"",
            tgpPartsFitted: "" + ele.tgp_parts_fitted?ele.tgp_parts_fitted:"",
            nontgpPartsFitted: "" + ele.ntgp_parts_fitted?ele.ntgp_parts_fitted:"",
            repariDate: ele.created_date?this.datePipe.transform(ele.created_date, "dd/MM/YYYY"):"",
          };
          this.orders.push(obj);
        });
        this.isDataReady = true;
      } else {
        this.isDataReady = true;
      }
    });
  }
}
