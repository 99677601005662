import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpMethod } from 'src/app/constants/http-handlers';
import { AppService } from 'src/app/services/app.service';
import { CommonService } from 'src/app/services/common.service';
import { ExcelService } from 'src/app/services/excel.service';



@Component({
  selector: 'app-otp-generator',
  templateUrl: './otp-generator.component.html',
  styleUrls: ['./otp-generator.component.scss']
})
export class OtpGeneratorComponent implements OnInit {

  dataSource = new MatTableDataSource<any>();
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  displayedColumns: string[] = [
    "otp_number",
    "is_active",
    "phone_number",
    "expiry_time",
    "create_time",
];
  showNodata: boolean = false;
  isLoading:boolean = false;
  totalRows = 0;
  pageSize = 10;
  currentPage = 0;
  pgIndex: number = 0;
  
  constructor( 
    private commonService:CommonService, 
    private appService:AppService,
    private datePipe:DatePipe,
    private excelService:ExcelService,
    private spinner: NgxSpinnerService,
    ) { }
  ngOnInit(): void {
    this.doorStepbookinglist();
  }
  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }
  doorStepbookinglist(formVal?) {
    try{
      let apiUrl =  this.appService.otpGeneratorApi;
      let req = {}
      const apiObj = {
        requestObj:req,
        url: apiUrl,
        methodType: HttpMethod.POST,
      };
      this.commonService.commonApiCall(apiObj, (res) => {
        if (
          res &&
          res.hasOwnProperty("result") &&
          res.result &&
          res.result.phone_otp.length
        ) {
          console.log(res.result.phone_otp);
          this.dataSource = new MatTableDataSource(res.result.phone_otp);
          this.dataSource.sort = this.sort;
          this.paginator.pageIndex = this.currentPage;
          this.paginator.length = res.result.count;
          this.isLoading = false;
        }else{
          this.showNodata = false;
          this.showToastermessage = true;
          this.showfinalErrorMsg = "No Otp Found";
        }
      });
    }catch(error){
      console.log(error);
    }
    //this.isLoading = false;
  }
  showfinalSuccessMsg: string;
  showfinalErrorMsg: string;
  showToastermessage: boolean = false;
 
  pageChanged(event) {
    this.pgIndex = event.pageIndex?event.pageIndex:0;
    this.pgIndex = this.pgIndex + 1;
    this.currentPage = event.pageIndex?event.pageIndex:0;
    this.isLoading = true;
    this.doorStepbookinglist();
  }
}