import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-apm-dashboard',
  templateUrl: './apm-dashboard.component.html',
  styleUrls: ['./apm-dashboard.component.scss']
})
export class ApmDashboardComponent implements OnInit {
  constructor() { }

  ngOnInit(): void {
  }
}
