<div class="tb-filter-wrapper">
    <button mat-stroked-button class="primary-stroke-button filterBtn" (click)="openDropdown()" [ngClass]="{ open: isOpenDropdown }">
      Filter
    </button>
    <div class="custom-filter-sec" *ngIf="isOpenDropdown">
      <h3>
        Filter <button class="filterCloseBtn" (click)="closeDropdown()"></button>
      </h3>
      <form [formGroup]="problemSubProblemFilterForm" name="doorStepFilterForm" class="form">
            <div class="custom-filter-body" style="justify-content: space-evenly;">
                <div class="custom-filter-col">
                    <mat-form-field>
                        <input matInput [matDatepicker]="toDatePick" placeholder="From Date" formControlName="fromDate" [max]="today">
                        <mat-datepicker-toggle matSuffix [for]="toDatePick"></mat-datepicker-toggle>
                        <mat-datepicker #toDatePick></mat-datepicker>
                    </mat-form-field>
                </div>
                <div class="custom-filter-col">
                    <mat-form-field>
                        <input matInput [matDatepicker]="fromDatePick" placeholder="To Date" formControlName="toDate" [max]="today">
                        <mat-datepicker-toggle matSuffix [for]="fromDatePick"></mat-datepicker-toggle>
                        <mat-datepicker #fromDatePick></mat-datepicker>
                    </mat-form-field>
                </div>
            </div>
            <div class="custom-filter-body" style="justify-content: space-evenly;">
                <div class="custom-filter-col">
                    <mat-form-field appearance="fill">
                        <mat-label>Jobcard Problem</mat-label>
                        <input formControlName="problem" matInput>
                    </mat-form-field>
                </div>
                <!-- <div class="custom-filter-col">
                    <mat-form-field appearance="fill">
                        <mat-label>Jobcard Sub Problem</mat-label>
                        <input formControlName="jobcard_sub_problem" matInput>
                    </mat-form-field>
                </div> -->
                <div class="custom-filter-col">
                    <mat-form-field appearance="fill">
                        <mat-label>Guru Name</mat-label>
                        <input formControlName="guruName" matInput>
                    </mat-form-field>
                </div>
                <div class="custom-filter-col">
                    <mat-form-field appearance="fill">
                        <mat-label>Guru Mobile Number</mat-label>
                        <input formControlName="mobileNo" matInput>
                    </mat-form-field>
                </div>
                <div class="custom-filter-col">
                    <mat-form-field appearance="fill">
                        <mat-label>Lass Name</mat-label>
                        <input formControlName="lassnme" matInput>
                    </mat-form-field>
                </div>
                <div class="custom-filter-col">
                    <mat-form-field appearance="fill">
                        <mat-label>Lass Phone Number</mat-label>
                        <input formControlName="lassContactNo" matInput>
                    </mat-form-field>
                </div>
                <div class="custom-filter-col"  >
                    <label for="region" class="form-label"*ngIf="role=='SuperAdmin'" >Select Region <span class="requiredfield">*</span></label>
                    <select class="form-control" formControlName="zone" *ngIf="role=='SuperAdmin'">
                        <option value="">Please select Region</option>
                        <option [value]="region" *ngFor="let region of regions">
                        {{ region }}
                        </option>
                    </select>
                </div>
                <!-- <div class="custom-filter-col">
                    <label for="region" class="form-label">Select Guru State <span class="requiredfield">*</span></label>
                    <select class="form-control" formControlName="state">
                        <option value="">Please select State</option>
                        <option [value]="state.state_name" *ngFor="let state of states">
                        {{ state.state_name }}
                        </option>
                    </select>
                </div> -->
            </div>
            <div class="btn-group filterBtn-group">
                <button mat-stroked-button class="primary-stroke-button"(click)="reset()" >Reset</button>
                <button mat-flat-button class="primary-flat-button" (click)="applyFilter()">Apply</button>
            </div>
        </form>
    </div>
</div>

<div class="action-toaster" *ngIf="showToastermessage">
    <div class="toaster-msg alert alert-danger" *ngIf="showfinalErrorMsg">
      {{ showfinalErrorMsg }}
    </div>
</div>