<ng-container>
  <!-- Filter -->
  <ng-container *ngIf="isFilterable" [matColumnDef]="this.rowActionIcon">
    <mat-form-field>
      <mat-label>Filter</mat-label>
      <input matInput (keyup)="applyFilter($event)" placeholder="filter" />
    </mat-form-field>
  </ng-container>
  <div class="guru-list-table">
    <!-- Table -->
    <table
      mat-table
      [dataSource]="tableDataSource"
      class="full-width-table tb-table"
      matSort
      (matSortChange)="sortTable($event)"
    >
      <!-- action column -->
      <ng-container
        *ngIf="rowActionIcon?.length"
        [matColumnDef]="rowActionIcon"
      >
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element" [id]="rowActionIcon">
          <button mat-button>
            <mat-icon>{{ rowActionIcon }}</mat-icon>
          </button>
        </td>
      </ng-container>
      <ng-container
        *ngFor="let tableColumn of tableColumns"
        [matColumnDef]="tableColumn.name"
      >
        <!-- if sortable column header -->
        <ng-container *ngIf="tableColumn.isSortable; else notSortable">
          <th
            mat-header-cell
            *matHeaderCellDef
            [mat-sort-header]="tableColumn.name"
            [arrowPosition]="
              tableColumn.position === 'right' ? 'before' : 'after'
            "
          >
            {{ tableColumn.name }}
          </th>
        </ng-container>
        <!-- else not sortable -->
        <ng-template #notSortable>
          <th
            mat-header-cell
            *matHeaderCellDef
            [class.text-right]="tableColumn.position == 'right'"
          >
            {{ tableColumn.name }}
          </th>
        </ng-template>
        <!--****************   Without popups code starts here ******************* -->
        <!-- column data -->
        <!-- <td
        mat-cell
        *matCellDef="let element"
        [class.text-right]="tableColumn.position == 'right'"


        > -->
        <!-- <div
          class="tb-column-item"
          [innerHTML]="element | dataPropertyGetter: tableColumn.dataKey"
        ></div>
        </td>
        </ng-container>
        <tr
        mat-header-row
        *matHeaderRowDef="displayedColumns"
        class="tb-head-row"
        ></tr> -->
        <!-- <tr
        class="tb-item-row active-guru"
        mat-row
        *matRowDef="let row; columns: displayedColumns"
        ></tr>
        </table>
        </div> -->

        <!--****************   Without popups code end here ******************* -->

        <!--****************   For payment update and job card detail popups starts here ******************* -->

        <!-- column data -->
        <td
          mat-cell
          *matCellDef="let element"
          [class.text-right]="tableColumn.position == 'right'"

          (click)="(tableColumn.name === 'Claim Status' && componentName === 'job-card-tgp-fitment' && role === 'DEALER' && element.claimstatus === 'Settled' ) ? onClaimClick(element.jobID, $event) : null"
          (click)="tableColumn.name === 'Job card Id' && componentName === 'job-card-tgp-fitment' && role === 'DEALER' && element.srType !== 'Paid Service' && element.srType !== 'Breakdown' ? onJobIdClick(element.jobID, $event): null"

          [ngStyle]="{
            'color':
              (tableColumn.name === 'Job card Id' && componentName === 'job-card-tgp-fitment' && (element.srType === 'Paid Service' || element.srType === 'Breakdown' || element.srType === '')) ? 'Default' :
              (tableColumn.name === 'Job card Id' && componentName === 'job-card-tgp-fitment' && role === 'DEALER') ? 'blue' :
              (tableColumn.name === 'Claim Status' && componentName === 'job-card-tgp-fitment' && role === 'DEALER' && element.claimstatus === 'Settled') ? 'blue' :
              (element | dataPropertyGetter: tableColumn.dataKey) === 'True' ? 'Default' : 
              (element | dataPropertyGetter: tableColumn.dataKey) === 'False' ? 'blue' : '',
            'cursor':
              (tableColumn.name === 'Job card Id' && componentName === 'job-card-tgp-fitment' && (element.srType === 'Paid Service' || element.srType === 'Breakdown' || element.srType === '')) ? 'default' :
              (tableColumn.name === 'Job card Id' || tableColumn.name === 'Claim Status' || element.claimstatus === 'Settled') && role === 'DEALER' ? 'pointer' : 'default'
          }"
        >
          <div
            class="tb-column-item"
            [innerHTML]="tableColumn.dataKey === 'action' ? '' : (element | dataPropertyGetter: tableColumn.dataKey)"
          ></div>
          <ng-container *ngIf="tableColumn.dataKey === 'action' && tableColumn.name === 'Action' && componentName === 'job-card-tgp-fitment' && role === 'DEALER'">
            <span *ngIf="(element | dataPropertyGetter: tableColumn.dataKey) === 'True'; else paymentUpdate" style="pointer-events: none; cursor: not-allowed;">
              Paid
            </span>
            <ng-template #paymentUpdate>
              <span *ngIf="(element | dataPropertyGetter: tableColumn.dataKey) === ''; else paymentUpdateClick" style="text-align: center;">
                -
              </span>
              <ng-template #paymentUpdateClick>
                <span *ngIf="element.claimstatus === 'Settled'; else notSettled" (click)="onPaymentUpdate(element.jobID)" style="cursor: pointer;">
                  Update Payment
                </span>
                <ng-template #notSettled>
                  <span style="text-align: center; color: black;">-</span>
                </ng-template>
              </ng-template>
            </ng-template>
          </ng-container>
          <ng-container *ngIf="tableColumn.dataKey === 'action' && tableColumn.name === 'Action' && componentName === 'job-card-tgp-fitment' && role === 'SuperAdmin'">
            <span *ngIf="(element | dataPropertyGetter: tableColumn.dataKey) === 'True'; else paymentUpdate" style="pointer-events: none; cursor: not-allowed;">
              Paid
            </span>
            <ng-template #paymentUpdate>
              <span *ngIf="(element | dataPropertyGetter: tableColumn.dataKey) === ''; else paymentUpdateClicks" style="text-align: center;">
                -
              </span>
              <ng-template #paymentUpdateClicks>
                <span style="color: maroon; pointer-events: none; cursor: not-allowed;">
                  Unpaid
                </span>
              </ng-template>
            </ng-template>
          </ng-container>
        </td>
      </ng-container>
      <tr
        mat-header-row
        *matHeaderRowDef="displayedColumns"
        class="tb-head-row"
      ></tr>
      <tr
        class="tb-item-row active-guru"
        mat-row
        *matRowDef="let row; columns: displayedColumns"
      ></tr>
    </table>
  </div>

<!--****************   For payment update and job card detail popups ends here ******************* -->


  <ng-container *ngIf="showNodata">
    <div class="no-item-found">No record found</div>
  </ng-container>
  <!-- Pagination -->
  <!-- <mat-paginator *ngIf="isPageable" [pageSizeOptions]="paginationSizes" [pageSize]="defaultPageSize"
        showFirstLastButtons class="custom-paginator">
    </mat-paginator> -->
  <ng-container *ngIf="isDataReady">
    <mat-paginator
      appPagination
      *ngIf="isPageable"
      showFirstLastButtons
      [length]="testPaginator.length"
      [pageSize]="testPaginator.pageSize"
      [pageIndex]="testPaginator.pageIndex"
      [hidePageSize]="true"
      (page)="pageChanged($event)"
      class="custom-paginator"
    >
    </mat-paginator>
  </ng-container>
</ng-container>
<ng-template #partSummaryPopup let-d="dismiss">
  <app-job-card-details 
      [partData]="partSummary" 
      [jobId]="selectedJobId" 
      [fetchData]="fetchrate"
      [selectedRateListAPI]="selectedRateListName">
  </app-job-card-details>
</ng-template>
<ng-template #invoicePaymentListPopup let-d="dismiss">
  <app-invoice-payment-list [jobIds]="clickedJobId"></app-invoice-payment-list>
</ng-template>
<ng-template #claimStatusPopup let-d="dismiss">
  <app-claim-status-details [claimData]="claimSummary"></app-claim-status-details>
</ng-template>
<ngx-spinner></ngx-spinner>