<div class="guru-items-wrap guru-full-grey-bg">
  <div class="item-container">
    <div class="part-fitment-col item-box">
      <h4>Parts Fitment Trend</h4>
      <canvas
        baseChart
        [datasets]="lineChartData"
        [labels]="lineChartLabels"
        [options]="lineChartOptions"
        [colors]="lineChartColors"
        [legend]="lineChartLegend"
        [chartType]="lineChartType"
      ></canvas>
    </div>
    <mat-grid-list
      cols="4"
      gutterSize="32px"
      rowHeight="363"
      class="msr-promotionalItems"
      *ngIf="promotionalItems.length > 0"
    >
      <mat-grid-tile class="item-box" *ngFor="let item of promotionalItems">
        <div class="card-item-inner-box">
          <div class="item-display">
            <div class="item-image">
              <img [src]="item.image_url" alt="Photo" />
            </div>
            <div class="item-text">
              <h2 class="item-heading">
                {{ item.part_name }}
              </h2>
              <p class="item-size">{{ item.part_description }}</p>
            </div>
          </div>
          <div class="item-footer">
            <div class="item-footer-text">
              <span>MRP: Rs {{ item.price }}</span>
              <span>Points: {{ item.points }}</span>
            </div>
            <div class="item-footer-text item-bg-color">
              <span class="text-red-color"
                >Sales: {{ item.sales > 0 ? item.sales : 0 }}</span
              >

              <span class="text-grey-color">Target: {{ item.target }}</span>
            </div>
          </div>
        </div>
      </mat-grid-tile>
    </mat-grid-list>
  </div>
</div>
