<div class="tb-filter-wrapper">
    <button mat-stroked-button class="primary-stroke-button filterBtn" (click)="openDropdown()" [ngClass]="{ open: isOpenDropdown }">
      Filter
    </button>
    <div class="custom-filter-sec" *ngIf="isOpenDropdown">
      <h3>
        Filter <button class="filterCloseBtn" (click)="closeDropdown()"></button>
      </h3>
      <form [formGroup]="referrelReportForm" name="doorStepFilterForm" class="form">
            <div class="custom-filter-body" style="justify-content: space-evenly;">
                <div class="custom-filter-col">
                    <mat-form-field>
                        <input matInput [matDatepicker]="toDatePick" placeholder="From Date" formControlName="fromDate" [max]="today">
                        <mat-datepicker-toggle matSuffix [for]="toDatePick"></mat-datepicker-toggle>
                        <mat-datepicker #toDatePick></mat-datepicker>
                    </mat-form-field>
                </div>
                <div class="custom-filter-col">
                    <mat-form-field>
                        <input matInput [matDatepicker]="fromDatePick" placeholder="To Date" formControlName="toDate" [max]="today">
                        <mat-datepicker-toggle matSuffix [for]="fromDatePick"></mat-datepicker-toggle>
                        <mat-datepicker #fromDatePick></mat-datepicker>
                    </mat-form-field>
                </div>
            </div>
            <div class="custom-filter-body" style="justify-content: space-evenly;">
                <div class="custom-filter-col">
                    <mat-form-field appearance="fill">
                        <mat-label>Guru Phone Number</mat-label>
                        <input formControlName="mobileNo" matInput>
                      </mat-form-field>
                </div>
                <div *ngIf="reportType!='guru' && reportType!='prospect'" class="custom-filter-col">
                    <mat-form-field appearance="fill">
                        <mat-label>Customer Phone Number</mat-label>
                        <input formControlName="custmobileNo" matInput>
                      </mat-form-field>
                </div>
                <div *ngIf="reportType!='guru' && reportType!='prolife engine'" class="custom-filter-col">
                    <mat-form-field appearance="fill">
                        <mat-label>Prospect Phone Number</mat-label>
                        <input formControlName="prospectmobileNo" matInput>
                      </mat-form-field>
                </div>
                <div class="custom-filter-col">
                    <mat-form-field appearance="fill">
                        <mat-label>Lass Name</mat-label>
                        <input formControlName="lassName" matInput>
                      </mat-form-field>
                </div>
                <div class="custom-filter-col">
                    <mat-form-field appearance="fill">
                        <mat-label>Lass Phone Number</mat-label>
                        <input formControlName="lassNumber" matInput>
                      </mat-form-field>
                </div>
                <!-- <div class="custom-filter-col">
                    <mat-form-field appearance="fill">
                        <mat-label>SSO</mat-label>
                        <input formControlName="sso" matInput>
                      </mat-form-field>
                </div> -->
                <div class="custom-filter-col">
                    <label for="region" class="form-label">Select State</label>
                    <select class="form-control" formControlName="state" (change)="selectRegion()">
                      <option value="">Please select State</option>
                      <option [value]="state.state_name" *ngFor="let state of states">
                        {{ state.state_name }}
                      </option>
                    </select>
                </div>
                <div class="custom-filter-col">
                    <label for="region" class="form-label"  *ngIf="role=='SuperAdmin'">Select Region</label>
                    <select class="form-control" formControlName="region"  *ngIf="role=='SuperAdmin'">
                      <option value="">Please select region</option>
                      <option [value]="region" *ngFor="let region of regions">
                        {{ region }}
                      </option>
                    </select>
                </div>
            </div>
            <div class="btn-group filterBtn-group">
                <button mat-stroked-button class="primary-stroke-button"(click)="reset()" >Reset</button>
                <button mat-flat-button class="primary-flat-button" (click)="applyFilter()">Apply</button>
            </div>
        </form>
    </div>
</div>

<div class="action-toaster" *ngIf="showToastermessage">
    <div class="toaster-msg alert alert-danger" *ngIf="showfinalErrorMsg">
      {{ showfinalErrorMsg }}
    </div>
</div>