<div class="mb-3">
    <div class="guru-tabs-row">
        <div class="tb-left-col">
            <div class="form-col select-item">
                <a mat-stroked-button class="primary-flat-button msrGuruTargetTemplateBtn" (click)="pageChanged(0)">
                    Refresh
                </a>
                <!-- <small class="changes-subhead">(Last 3 Months)</small> -->
            </div>
            <div class="form-col select-item"></div>
            <div class="form-col select-item"></div>
        </div>
    </div>
  
<div class="table-wrapper">
    <div class="guru-list-table rpm-table" *ngIf="!showNodata">
      <table mat-table matTableExporter 
        class="full-width-table tb-table" matSort aria-label="Elements" 
        [dataSource]="dataSource">
        <ng-container matColumnDef="phone_number" sticky>
            <th mat-header-cell *matHeaderCellDef class="phone_no-col" style="background-color: white;">Phone Number</th>
            <td mat-cell *matCellDef="let element" class="phone_no-col  {{ element.phone_number }} text-nowrap" style="box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
            ">
            {{ element.phone_number }}
            </td>
        </ng-container>
        <ng-container matColumnDef="otp_number">
            <th mat-header-cell *matHeaderCellDef class="otp_no-col">
            Otp Number
            </th>
            <td mat-cell *matCellDef="let element" class="otp_no-col text-nowrap">
            {{ element.otp_number }}
            </td>
        </ng-container>
        <ng-container matColumnDef="create_time">
            <th mat-header-cell *matHeaderCellDef class="create_time-col">
                Create Time
            </th>
            <td mat-cell *matCellDef="let element" class="create_time-col text-nowrap">
            {{ element.create_time | date: "dd/MM/yyyy" }}
            </td>
        </ng-container>
        <ng-container matColumnDef="expiry_time">
            <th mat-header-cell *matHeaderCellDef class="expiry_time-col">
                Expiry Time
            </th>
            <td mat-cell *matCellDef="let element" class="expiry_time-col">
            {{ element.expiry_time | date: "dd/MM/yyyy" }}
            </td>
        </ng-container>
        <ng-container matColumnDef="is_active">
            <th mat-header-cell *matHeaderCellDef class="isActive text-nowrap">
             Status
            </th>
            <td mat-cell *matCellDef="let element" class="isActive text-nowrap">
            {{ element.is_active }}
            </td>
        </ng-container>
        <tr class="tb-head-row" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr class="tb-item-row" mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
  
    <ng-container *ngIf="showNodata">
      <div class="no-item-found">No record found</div>
    </ng-container>
  
    <mat-paginator
      appPagination
      showFirstLastButtons
      [length]="totalRows"
      [pageIndex]="currentPage"
      [pageSize]="pageSize"
      [hidden]="showNodata"
      (page)="pageChanged($event)"
      class="custom-paginator"
      [hidePageSize]="true"
    >
    </mat-paginator>
  </div>

  <div class="action-toaster" *ngIf="showToastermessage">
    <div class="toaster-msg alert alert-success" *ngIf="showfinalSuccessMsg">
      {{ showfinalSuccessMsg }}
    </div>
  </div>