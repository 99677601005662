<div class="training-video-header">
  <h2>TRaining videos</h2>
  <div class="searchdrop-column">
    <div class="tb-searchbox">
      <button class="tb-button guru-flex" (click)="searchData()">
        <div class="tb__search_icon"></div>
      </button>
      <input
        type="text"
        appOnlyAlphaNumeric
        class="tb-search-text"
        placeholder="Search Training Videos"
        [(ngModel)]="searchText"
        (keyup)="checkText($event)"
      />
    </div>
    <div class="tb-select-dropdown">
      <div class="tb-selectedVal" (click)="selectValfun()">
        {{ selectedItem }}
      </div>
      <ul class="tb-dropdown-options" *ngIf="showDropdown">
        <li
          *ngFor="
            let item of commonService.viewTypeOptionsTraining;
            let i = index
          "
          (click)="select(i)"
          [ngClass]="{ selected: isActive(item.paramName) }"
        >
          {{ item.title }}
        </li>
      </ul>
    </div>
  </div>
</div>

<div class="chip-filter position-relative">
  <span class="back__arrow" (click)="scrollLeft()">
    <mat-icon>keyboard_arrow_left</mat-icon>
  </span>
  <ul #chips>
    <!-- <li class="all-videos selected">All Videos</li> -->
    <li
      *ngFor="let chipfilterOption of chipfilterOptions"
      (click)="selectedCatItemfun(chipfilterOption.id)"
      [ngClass]="{ selected: isSelected(chipfilterOption.id) }"
    >
      <span class="hide cat-id">{{ chipfilterOption.id }}</span>
      {{ chipfilterOption.value }}
    </li>
  </ul>
  <span class="forword__arrow" (click)="scrollRight()">
    <mat-icon>keyboard_arrow_right</mat-icon>
  </span>
</div>
<div class="no-item-found" *ngIf="!videosList.length">No record found</div>
<div class="no-item-found" *ngIf="deletedVideo != ''">{{ deletedVideo }}</div>
<div style="min-height: 300px">
  <div
    class="highlight-video"
    id="highlight-video"
    *ngIf="videosList.length > 0 && deletedVideo == ''"
  >
    <div class="highlight-video-img">
      <video width="100%" #videoFileContainer controls>
        <source [src]="selectedVideo?.video_url" type="video/mp4" />
        Your browser does not support HTML video.
      </video>
    </div>

    <div class="highlight-video-content">
      <div class="highlight-video-top">
        <div class="highlight-content">
          <h1>{{ selectedVideo?.title }}</h1>
          <p>
            {{ selectedVideo?.short_desc }}
          </p>
          <!-- <div class="hashtags">
          <span>#TataBs6Engine2020</span>
        </div> -->
          <div class="more-content" *ngIf="showmoreContent">
            <p>
              {{ selectedVideo?.long_desc }}
            </p>
          </div>
        </div>
        <!-- <button mat-stroked-button class="primary-stroke-button share-video-btn">
        <i class="share-icon"></i> Share
      </button> -->
      </div>
      <div class="link-button" (click)="showHideContent()">
        <span *ngIf="!showmoreContent">Show More</span>
        <span *ngIf="showmoreContent">Show Less</span>
      </div>

      <div class="highlight-video-btm">
        <!-- <span class="video-views"> {{ selectedVideo?.view_count }} Views </span>
        •  -->
        Uploaded on -
        <span class="upload-date">
          {{ selectedVideo?.upload_date | date: "dd/MM/yyyy" }}</span
        >
      </div>
    </div>
  </div>
  <div class="videosList guru-full-grey-bg" *ngIf="videosList.length > 0">
    <div class="pagination-footer">
      <h2></h2>
      <div class="prev-next-col">
        <a
          class="link-button"
          (click)="prevnextMovingItems('prev')"
          [ngClass]="{ disabled: this.disablePrevPaginations }"
          >Previous</a
        >
        <a
          class="link-button"
          (click)="prevnextMovingItems('next')"
          [ngClass]="{ disabled: this.disableNextPaginations }"
          >Next</a
        >
      </div>
    </div>
    <ul>
      <li *ngFor="let videoItem of videosList; let i = index">
        <div class="video-item" (click)="playVideo(i)">
          <div class="video-img">
            <img [src]="videoItem.thumbnail_url" alt="" />
          </div>
          <div class="video-content">
            <h4>{{ videoItem.title }}</h4>
            <p>
              <!-- <span class="video-views">{{ videoItem.view_count }} Views </span>
              •  -->
              Uploaded on -
              <span class="upload-date">{{
                videoItem.upload_date | date: "dd/MM/yyyy"
              }}</span>
            </p>
            <!-- <div class="share-video"></div> -->
          </div>
        </div>
      </li>
    </ul>
  </div>
</div>

<ngx-spinner></ngx-spinner>
