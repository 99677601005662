<app-msr-info (sendMSRName)="msrName = $event"></app-msr-info>
<div class="tb-table-wrapper">
  <div class="guru-tabs-row">
    <div class="guru-tabs-items">
      <ul>
        <li
          [ngClass]="{ 'active-tab': selectedTab === 'targetachieved' }"
          (click)="selectedTab = 'targetachieved'"
        >
          <a>Target Vs Achieved</a>
        </li>
        <!-- <li
          [ngClass]="{ 'active-tab': selectedTab === 'visits' }"
          (click)="selectedTab = 'visits'"
        >
          <a>Gurus</a>
        </li>
        <li
          [ngClass]="{ 'active-tab': selectedTab === 'visits' }"
          (click)="selectedTab = 'visits'"
        >
          <a>Visits</a>
        </li> -->
      </ul>
    </div>
  </div>
  <div class="msr-subcomponent-wrapper">
    <app-target-acheived
      *ngIf="selectedTab === 'targetachieved'"
    ></app-target-acheived>
  </div>
</div>
