import { Component, OnInit,Input } from '@angular/core';

@Component({
  selector: 'app-prospect-referrel',
  templateUrl: './prospect-referrel.component.html',
  styleUrls: ['./prospect-referrel.component.scss']
})
export class ProspectReferrelComponent implements OnInit {
  
  @Input() dataSource:any;
  displayedColumns: string[] = ["id","prospect_name","prospect_created_date","prospect_phone","prospect_state","prospect_pincode","model_intereste","vehicle_final_by","remainder_date","mechanic_name","garage_area","gstin_number","upi_id","last_invoice_number","guru_name","guru_username","guru_region","guru_state","laas_name","laas_number","laas_email"];
  constructor() { }

  ngOnInit(): void {
  }

}
