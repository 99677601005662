<!-- <google-map height="500px" width="100%" [zoom]="zoom" [center]="center" [options]="options"
  (mapClick)="registerMarker($event)">
  <map-marker #markerElem *ngFor="let marker of markers" [position]="marker.position" [options]="marker.options">
  </map-marker>
  (mapClick)="registerMarker($event)"
  <map-info-window>{{ infoContent }}</map-info-window>
</google-map> -->

<agm-map [zoom]="zoom" [restriction]="countryRestriction" [latitude]="newLat" [longitude]="newLng">
  <agm-marker [latitude]="newLat" [longitude]="newLng" (markerClick)="clickedMarker(infoWindow)">
      <agm-info-window [disableAutoPan]="false" #infoWindow>
        <div>
            <a><strong>Latitude:</strong>{{newLat}} </a><br />
            <a><strong>Longitude:</strong>{{newLng}} </a><br />
        </div>
      </agm-info-window>
  </agm-marker>
</agm-map>

<!-- 
<button (click)="zoomIn()">Zoom in</button>
<button (click)="zoomOut()">Zoom out</button>
<button (click)="logCenter()">Log center</button>
<button (click)="addMarker()">Add marker</button>
"Lat "{{newLat}} - "Lng "{{newLng}} -->