<h2>Add Training Video</h2>
<form [formGroup]="addTrainingForm" autocomplete="off">
  <div class="training-row col-full">
    <div class="training-left-col">
      <div class="form-row">
        <div class="form-col">
          <label for="trainingTitle" class="form-label"
            >Training Title <span class="requiredfield">*</span></label
          >
          <input
            type="text"
            class="form-control"
            id="trainingTitle"
            formControlName="trainingTitle"
            maxlength="100"
          />
          <div
            class="invalid-field"
            *ngIf="
              submittedTrainingForm &&
              this.addTrainingForm.controls.trainingTitle.errors
            "
          >
            <span
              *ngIf="
                this.addTrainingForm.controls.trainingTitle.errors.required
              "
              >Training Title is required</span
            >
            <span
              *ngIf="this.addTrainingForm.controls.trainingTitle.errors.pattern"
              >Please enter valid Training Title
            </span>
            <span
              *ngIf="
                !this.addTrainingForm.controls.trainingTitle.errors.pattern &&
                !this.addTrainingForm.controls.trainingTitle.errors
                  .whitespace &&
                this.addTrainingForm.controls.trainingTitle.errors.maxlength
              "
            >
              Max 50 characters allowed
            </span>
            <span
              *ngIf="
                !this.addTrainingForm.controls.trainingTitle.errors.required &&
                this.addTrainingForm.controls.trainingTitle.errors.whitespace
              "
              >Please enter valid data</span
            >
          </div>
        </div>
        <div class="form-col">
          <label for="shortDesc" class="form-label"
            >Short Description<span class="requiredfield">*</span></label
          >
          <input
            type="text"
            class="form-control"
            id="shortDesc"
            formControlName="shortDesc"
            maxlength="100"
          />
          <div
            class="invalid-field"
            *ngIf="
              submittedTrainingForm &&
              this.addTrainingForm.controls.shortDesc.errors
            "
          >
            <span
              *ngIf="this.addTrainingForm.controls.shortDesc.errors.required"
              >Short Description is required</span
            >
            <span *ngIf="this.addTrainingForm.controls.shortDesc.errors.pattern"
              >Please enter valid Short Description</span
            >
            <span
              *ngIf="
                !this.addTrainingForm.controls.shortDesc.errors.required &&
                this.addTrainingForm.controls.shortDesc.errors.whitespace
              "
              >Please enter valid data</span
            >
          </div>
        </div>
        <div class="form-col">
          <label for="longDesc" class="form-label"
            >Long Description<span class="requiredfield">*</span></label
          >
          <textarea
            type="text"
            class="form-control"
            id="longDesc"
            formControlName="longDesc"
          ></textarea>
          <div
            class="invalid-field"
            *ngIf="
              submittedTrainingForm &&
              this.addTrainingForm.controls.longDesc.errors
            "
          >
            <span *ngIf="this.addTrainingForm.controls.longDesc.errors.required"
              >Long Description is required</span
            >
            <span *ngIf="this.addTrainingForm.controls.longDesc.errors.pattern"
              >Please enter valid Long Descriptione</span
            >
            <span
              *ngIf="
                !this.addTrainingForm.controls.longDesc.errors.required &&
                this.addTrainingForm.controls.longDesc.errors.whitespace
              "
              >Please enter valid data</span
            >
          </div>
        </div>

        <div class="form-col">
          <label for="selectCategory" class="form-label"
            >Select Category <span class="requiredfield">*</span></label
          >
          <select class="form-control" formControlName="selectCategory">
            <option value="">Please select Category</option>
            <option [value]="category.id" *ngFor="let category of categories">
              {{ category.value }}
            </option>
          </select>
          <div
            class="invalid-field"
            *ngIf="
              submittedTrainingForm &&
              this.addTrainingForm.controls.selectCategory.errors
            "
          >
            <span
              *ngIf="
                this.addTrainingForm.controls.selectCategory.errors.required
              "
            >
              Category is required</span
            >
          </div>
        </div>

        <div class="form-col">
          <label for="hashtag" class="form-label">Add Hashtag </label>
          <div class="form-hashtag-row">
            <input
              type="text"
              class="form-control"
              id="hashtag"
              formControlName="hashtag"
            />
            <button class="btn" (click)="addHashtagFun()">add</button>
          </div>
          <div class="row hashTagValue-items" [id]="hashTagValue.length > 3?'hashListScroll':''">
            <div class="hashtagVal" *ngFor="let item of hashTagValue; let i = index">
              {{ item }}
              <i class="fa fa-close close-font-awsome" (click)="removeHasTag(i)">X</i>
            </div>
          </div>
          <!-- <ul class="hashTagValue-items">
            <li *ngFor="let item of hashTagValue; let i = index">
              {{ item }}
              <i class="fa fa-close close-font-awsome" (click)="removeHasTag(i)">X</i>
            </li>
          </ul> -->
          <div
            class="invalid-field"
            *ngIf="
              submittedTrainingForm &&
              this.addTrainingForm.controls.hashtag.errors
            "
          >
            <span *ngIf="this.addTrainingForm.controls.hashtag.errors.pattern">
              Please enter valid Hashtag</span
            >
          </div>
        </div>

        <div class="form-col">
          <label for="userRole" class="form-label"
            >Select User Role <span class="requiredfield">*</span></label
          >
          <mat-form-field
            appearance="outline"
            floatLabel="always"
            class="form-select"
          >
            <mat-select
              [formControl]="websiteMultiCtrl"
              placeholder="Please select User Role"
              [multiple]="true"
              #multiSelect
              [(ngModel)]="selected"
              matTooltip="{{ getToolTipData(selected) }}"
            >
              <mat-option>
                <ngx-mat-select-search
                  [formControl]="websiteMultiFilterCtrl"
                  placeholderLabel="Search Role"
                  noEntriesFoundLabel="No record found"
                ></ngx-mat-select-search>
              </mat-option>

              <mat-option
                *ngFor="let website of filteredWebsitesMulti | async"
                [value]="website"
              >
                {{ website }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <div
            class="invalid-field"
            *ngIf="submittedTrainingForm && this.websiteMultiCtrl.invalid"
          >
            <span> User Role is required</span>
          </div>
        </div>
      </div>
    </div>
    <div class="training-right-col">
      <div class="form-col">
        <label for="trainingTitle" class="form-label"
          >Training Thumbnails
          <span class="formfield-hint">
            (Upload jpg or png. File size can’t be over 5MB.) </span
          ><span class="requiredfield">*</span></label
        >
        <div class="file-input video-upload form-control">
          <input
            [hidden]="true"
            type="file"
            accept="image/png, image/jpeg, image/jpg"
            #fileSelect
            (change)="uploadthumbnailFile($event)"
            formControlName="uploadthumbnailFile"
          />
          <span class="fileName">
            <ng-container *ngIf="trainingPictureFile.length">{{
              trainingPictureFile
            }}</ng-container>
            <ng-container *ngIf="!trainingPictureFile.length"
              >Please select a file</ng-container
            >
          </span>
          <button class="choose-file" mat-button (click)="fileSelect.click()">
            Choose File
          </button>
        </div>
        <div
          class="invalid-field"
          *ngIf="
            submittedTrainingForm &&
            this.addTrainingForm.controls.uploadthumbnailFile.errors
          "
        >
          <span> Training Thumbnails is required</span>
        </div>
      </div>

      <div class="form-col">
        <label for="trainingTitle" class="form-label"
          >Training Video
          <span class="formfield-hint">
            (Upload video/mp4. File size can’t be over 50MB.) </span
          ><span class="requiredfield">*</span></label
        >
        <div class="uploadReport-container">
          <div
            class="dropzone upload-file-sec"
            appDrag
            (onFileDropped)="uploadFileDrop($event)"
          >
            <div class="upload-file-item">
              <img src="./assets/images/upload-icon.svg" alt="" />
              <div class="centered">Drag and Drop to upload Video!</div>
            </div>
            <!-- <div class="upload-img-item" *ngFor="let file of files">
            <img *ngIf="file" [src]="file.url" />
          </div> -->
          </div>

          <!--droped image preview-->

          <div class="or-wrap">Or</div>
          <div class="file-input">
            <input
              type="file"
              name="file-input"
              accept="video/*"
              id="file-input"
              class="file-input__input"
              (change)="uploadFile($event)"
              formControlName="uploadVideoFile"
            />
            <label class="file-input__label" for="file-input"
              ><span>Choose Video to Upload</span></label
            >
          </div>
          <span class="fileName trainingVideoFile">
            <ng-container *ngIf="trainingVideoFile.length">{{
              trainingVideoFile
            }}</ng-container>
            <ng-container *ngIf="!trainingVideoFile.length"></ng-container>
          </span>
        </div>
        <div
          class="invalid-field"
          *ngIf="
            submittedTrainingForm &&
            this.addTrainingForm.controls.uploadVideoFile.errors
          "
        >
          <span> Training Video is required</span>
        </div>
      </div>
      <div class="training-btn text-right">
        <button
          mat-flat-button
          class="primary-flat-button"
          type="submit"
          (click)="addTrainingFormData()"
        >
          Submit
        </button>
      </div>
    </div>
  </div>
</form>
<div class="action-toaster" *ngIf="showToastermessage">
  <div class="toaster-msg alert alert-success" *ngIf="showfinalSuccessMsg">
    {{ showfinalSuccessMsg }}
  </div>
  <div class="toaster-msg alert alert-danger" *ngIf="showfinalErrorMsg">
    {{ showfinalErrorMsg }}
  </div>
</div>

<div class="training-videos-sec">
  <h2>Training Videos</h2>
  <div class="training-videos-items guru-list-table">
    <table
      mat-table
      [dataSource]="dataSource"
      class="full-width-table tb-table"
    >
      <!--- Note that these columns can be defined in any order.
            The actual rendered columns are set as a property on the row definition" -->

      <!-- title Column -->
      <ng-container matColumnDef="title">
        <th mat-header-cell *matHeaderCellDef class="title-col">Title</th>
        <td mat-cell *matCellDef="let element" class="title-col">
          <div class="guru-img-cont">
            <!-- <div class="guru-image">
              <img [src]="element.thumbnail_url" alt="" />
            </div> -->
            <div class="guru-detail">
              {{ element.title }}
            </div>
          </div>
        </td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="short_desc">
        <th mat-header-cell *matHeaderCellDef class="shortdesc-col">
          Short Description
        </th>
        <td mat-cell *matCellDef="let element" class="shortdesc-col">
          {{ element.short_desc }}
        </td>
      </ng-container>

      <!-- Weight Column -->
      <ng-container matColumnDef="long_desc">
        <th mat-header-cell *matHeaderCellDef class="longdesc-col">
          Long Description
        </th>
        <td mat-cell *matCellDef="let element" class="longdesc-col">
          {{ element.long_desc }}
        </td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="upload_date">
        <th mat-header-cell *matHeaderCellDef>Uploaded Date</th>
        <td mat-cell *matCellDef="let element">
          {{ element.upload_date | date: "dd/MM/yyyy" }}
        </td>
      </ng-container>

      <!-- Symbol Column -->
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef class="contact-col text-center">
          Action
        </th>
        <td mat-cell *matCellDef="let element" class="contact-col text-center">
          <button
            mat-flat-button
            type="submit"
            (click)="deleteVideoItem(element.id)"
          >
            <img src="assets/images/delete_black_24dp.svg" alt="" />
          </button>
          <!-- {{ element.action }} -->
        </td>
      </ng-container>

      <tr
        class="tb-head-row"
        mat-header-row
        *matHeaderRowDef="displayedColumns"
      ></tr>
      <tr
        class="tb-item-row"
        mat-row
        *matRowDef="let row; columns: displayedColumns"
      ></tr>
    </table>
  </div>
  <ng-container *ngIf="showNodata">
    <div class="no-item-found">No record found</div>
  </ng-container>

  <mat-paginator
    appPagination
    showFirstLastButtons
    [length]="totalRows"
    [pageIndex]="currentPage"
    [pageSize]="pageSize"
    [hidden]="showNodata"
    (page)="pageChanged($event)"
    class="custom-paginator"
    [hidePageSize]="true"
  >
  </mat-paginator>
</div>

<div class="confirm-notification" *ngIf="notificationAlert">
  <div class="container-dialog">
    <h2>
      Are you sure, you want to send notification to
      <span>{{ websiteMultiCtrl.value }}</span>
    </h2>
    <div class="btn-group dialogBtn-group">
      <button
        mat-stroked-button
        class="primary-stroke-button"
        (click)="cancelNotification()"
      >
        Cancel
      </button>
      <button
        mat-flat-button
        class="primary-flat-button"
        (click)="submitWithNotification()"
      >
        Confirm
      </button>
    </div>
  </div>
</div>

<div class="confirm-notification" *ngIf="deleteVideoModal">
  <div class="container-dialog">
    <h2>Are you sure, you want to delete video?</h2>
    <div class="btn-group dialogBtn-group">
      <button
        mat-stroked-button
        class="primary-stroke-button"
        (click)="cancelDeleteVideoModal()"
      >
        No
      </button>
      <button
        mat-flat-button
        class="primary-flat-button"
        (click)="confirmDeleteVideo()"
      >
        Yes
      </button>
    </div>
  </div>
</div>

<ngx-spinner></ngx-spinner>
