<ng-container *ngIf="role === 'MSR'">
  <app-msr-dashboard></app-msr-dashboard>
</ng-container>
<ng-container *ngIf="role === 'APM'">
  <app-apm-dashboard></app-apm-dashboard>
</ng-container>
<ng-container *ngIf="role === 'Distributor'">
  <app-distributor-dashboard></app-distributor-dashboard>
</ng-container>
<ng-container *ngIf="role === 'RPM' || role === 'DEALER'">
  <app-sub-dashboard></app-sub-dashboard>
  <!-- <app-rpm-dashboard></app-rpm-dashboard>
  <app-rpm-dashboard-detail></app-rpm-dashboard-detail> -->
</ng-container>
<ng-container *ngIf="role === 'SuperAdmin'">
  <!-- <app-super-admin-dashboard></app-super-admin-dashboard> -->
  <app-sub-dashboard></app-sub-dashboard>
</ng-container>

