import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class AppService {
  constructor() {}

  domain = `${environment.apiUrl}api/emandi/portal/`;
  login = `${this.domain}login/sendotp/`;
  loginV2 = `${this.domain}admin/login/`
  verifyotp = `${this.domain}login/verifyotp/`;
  guruStatus = `${this.domain}dashboard/guru_status`;
  msrGuruList = `${this.domain}msr/guru/list/`;
  guruList = `${this.domain}msr/guru/v2/list/`
  msrStatusGuruList = `${this.domain}msr/guru/list/?status=`;
  msrGuruDetail = `${this.domain}msr/guru/detail/`;
  msrGuruDelete = `${this.domain}msr/guru/`;
  msrGuruPartFitment = `${this.domain}msr/guru/part-fitments/`;
  msrGuruJobCard = `${this.domain}msr/guru/job-card/history/`;
  msrGuruCompetitorFitments = `${this.domain}msr/guru/job-card/competitor-fitments/`;
  addGuruEducation = `${this.domain}master/data?type=education`;
  addGuruIdentityproof = `${this.domain}master/data?type=identity_proof`;
  addGuruVehicalmake = `${this.domain}master/data?type=vehicle_make`;
  addGuruVehicalmodel = `${this.domain}master/data?type=vehicle_model`;
  addGuruMajorareawork = `${this.domain}master/data?type=major_area_work`;
  addGuruMaritalstatus = `${this.domain}master/data?type=marital_status`;
  addGuru = `${this.domain}msr/guru/add/`;
  updateGuru = `${this.domain}msr/guru/update/?guru-id=`;
  addGurudocs = `${this.domain}msr/guru/upload/documents/?guru-id=`;
  guruExists = `${this.domain}msr/guru/add/validate/username/  `;
  topGurus = `${this.domain}dashboard/top_gurus`;
  showMSRlist = `${this.domain}msr/user/list/?view=MSR`;
  showAPMlist = `${this.domain}apm/user/list/`;
  MSRDetail = `${this.domain}msr/detail/`;
  msrDelete = `${this.domain}msr/`;
  APMDetail = `${this.domain}apm/detail/`;
  msrPartPromo = `${this.domain}msr/part-promotion/?msr_id=`;
  dashBoardPartperformance = `${this.domain}dashboard/parts_performance`;
  dashBoardPartPromotionItems = `${this.domain}dashboard/dashboard_parts?type=promotional`;
  dashBoardPartNewItems = `${this.domain}dashboard/dashboard_parts?type=new`;
  doorStepBookingReport = `${this.domain}reports/doorstep_booking/report/`;
  problemSubProblemReport = `${this.domain}reports/jobcard_problems/list/`;
  downloadManual = `${this.domain}super_admin/upload/manual_file_pdf`
  trainingVideoItems = `${this.domain}general/training/videos/`;
  trainingVideoSingle = `${this.domain}general/training/videos/details/`;
  trainingVideoCategoryItems = `${this.domain}master/data?type=training_video&sub_type=category`;
  deleteTrainingVideo = `${this.domain}super_admin/delete/training_video/`;
  partFitmentReportChartData = `${this.domain}reports/parts/summary/parts-fitment/`;
  partFitmentSummaryReport = `${this.domain}reports/parts/summary/parts-fitment/details/`;
  partSummaryMisReport = `${this.domain}reports/parts/summary/v2/parts-fitment/details/`
  partTargetVsAchieved = `${this.domain}reports/parts/summary/parts-fitted/`;
  otpGeneratorApi = `${this.domain}api/emandi/v2/fetch_otp_by_phone/`;
  rpmUserList = `${this.domain}super_admin/get/user/list/?user_type=RPM&id=all`;
  apmUserList = `${this.domain}super_admin/get/user/list/?user_type=APM&id=all`;
  distUserList = `${this.domain}super_admin/get/user/list/?user_type=Distributor&id=all`;
  msrUserList = `${this.domain}super_admin/get/user/list/?user_type=MSR&id=all`;
  jobSummary = `${this.domain}reports/job/summary/jobcard-summary/`;
  jobSummaryReport = `${this.domain}reports/job/summary/jobcard-history/`;
  jobSummaryMisReport = `${this.domain}reports/job/summary/v2/jobcard-history/`;
  vehicleData = `${this.domain}reports/vehicle-data/summary/`;
  vehicleComparison = `${this.domain}reports/vehicle-comparison/summary/`;
  vehicleRepairDetails = `${this.domain}reports/job/summary/vehicle-repair-history/`;
  msrPartFitmentTrend = `${this.domain}msr/part-fitment/?msr_id=`;
  msrGuruTargetTemplate = `${this.domain}msr/download_msr_guru_target_template/`;
  slowmovingsParts = `${this.domain}dashboard/dashboard_parts_summary/slow`;
  fastmovingsParts = `${this.domain}dashboard/dashboard_parts_summary/fast`;
  targetUpload = `${this.domain}msr/upload_msr_guru_target_template/`;
  targetList = `${this.domain}msr/fetch_msr_guru_target_data/`;
  pendingGuruApprove = `${this.domain}msr/guru/approve/`;
  pendingGuruReject = `${this.domain}msr/guru/reject/`;
  addUser = `${this.domain}super_admin/add_user/`;
  getAllRegion = `${this.domain}master/get_regions/`;
  getAllRPM = `${this.domain}super_admin/get/user/list/?user_type=RPM`;
  getAllAPM = `${this.domain}super_admin/get/user/list/?user_type=APM&id=`;
  getAllDistributor = `${this.domain}super_admin/get/user/list/?user_type=Distributor&id=`;
  getAllMSR = `${this.domain}super_admin/get/user/list/?user_type=MSR&id=`;
  getAllArea = `${this.domain}master/get_areas_apm/?`;
  acceptGuru = `${this.domain}msr/guru/accept/`;
  logout = `${this.domain}logout/`;
  clearPrevSession = `${this.domain}expire-session/`;
  getlatlong = `https://cvp-qa.api.tatamotors/api/geolocation-general/get-address-by-lat-long`;
  userRoles = `${this.domain}master/data?type=user_type`;
  addTrainingVideo = `${this.domain}super_admin/upload/training_video/`;
  notifications = `${this.domain}general/notifications`;
  profile = `${this.domain}dashboard/portal_profile/`;
  searchPart = `${environment.apiUrl}api/emandi/v2/search/part/`;
  getPartList = `${this.domain}dashboard/dashboard_parts?`;
  deletePartItem = `${this.domain}super_admin/update/parts/`;
  partAdded = `${this.domain}dashboard/dashboard_parts`;
  roleBasedUser = `${this.domain}dashboard/get_role_based_users/`;
  problemList = `${this.domain}api/emandi/v2/problem_statement/list/`;
  addProblemItem = `${this.domain}api/emandi/v2/problem_statement/add/`;
  distributorList = `${this.domain}distributor/user/list/?view=DISTRIBUTOR`;
  distributorDetails = `${this.domain}distributor/detail/`;
  referrelReport= `${this.domain}reports/referral/reports/`;
  leadsList=`${this.domain}api/emandi/get/opty_info/`
}
