<mat-toolbar class="tb-header guru-justify-space-between">
  <div class="tb-header-left">
    <span class="tb-logo"> </span>
    <ng-container *ngIf="role === 'APM' || role === 'Distributor'">
      <form [formGroup]="roleBasedUserForm" class="tb-select-item">
        <ng-container *ngIf="role === 'APM'">
          <label for="selectRoleUser" class="form-label">Distributor </label>
        </ng-container>
        <ng-container *ngIf="role === 'Distributor'">
          <label for="selectRoleUser" class="form-label">MSR </label>
        </ng-container>
        <select
          class="form-control"
          id="selectRoleUser"
          formControlName="roleBasedUserItem"
          (change)="getroleUserItem()"
        >
          <option value="">All</option>
          <ng-container *ngFor="let role of roleBasedUserList">
            <option [value]="role.user_id">
              {{ role.first_name + " " + role.last_name }}
            </option>
          </ng-container>
        </select>
      </form>
    </ng-container>
  </div>

  <div class="guru-flex">
    <div class="tb-searchbox" *ngIf="role !== 'SuperAdmin'">
      <button class="tb-button guru-flex" (click)="searchData()">
        <div class="tb__search_icon"></div>
      </button>
      <input
        type="text"
        class="tb-search-text"
        (keyup)="checkText($event)"
        [(ngModel)]="globalSearchText"
        placeholder="Search by Guru Name or ID or Contact"
        appStopSC
      />
    </div>
    <div class="tb-notification-wrapper" appDropdown>
      <div class="tb-notification guru-flex"></div>
      <app-notifications class="tb-notification-container"></app-notifications>
    </div>
    <div class="tb-userprofile guru-flex" [matMenuTriggerFor]="profile">
      <span class="tb-user-image">
        <ng-container *ngIf="userimage">
          <img src="{{ userimage }}" />
        </ng-container>
        <ng-container *ngIf="!userimage">
          <img src="./assets/images/demo-img.png" />
        </ng-container>
      </span>
      <span class="tb-user-name">
        Hi {{ loginUser ? loginUser.first_name : "" }}
      </span>
      <span class="dropdown-icon"> </span>
    </div>
    <mat-menu #profile="matMenu">
      <!-- <button mat-menu-item [routerLink]="'my-targets'" routerLinkActive="active">
        <span>My Targets</span>
      </button>
      <button mat-menu-item [routerLink]="'my-visit'" routerLinkActive="active">
        <span>My Visits</span>
      </button> -->
      <!-- [routerLink]="'my-profile'" routerLinkActive="active"
      [routerLink]="'change-password'" routerLinkActive="active" -->
      <button mat-menu-item (click)="redirectToprofile()">
        <span>My Profile</span>
      </button>
      <!--  <button mat-menu-item>
        <span>Change Password</span>
      </button> -->
      <button mat-menu-item (click)="logOutUser()">
        <span>Log Out</span>
      </button>
    </mat-menu>
  </div>
</mat-toolbar>
<app-subheader></app-subheader>

<div class="custom-login-error custom-popup" *ngIf="commonService.isMultiLogin">
  Your session has expired. Please relogin
</div>
