import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-distributor-dashboard',
  templateUrl: './distributor-dashboard.component.html',
  styleUrls: ['./distributor-dashboard.component.scss']
})
export class DistributorDashboardComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
