<div class="vehicleData-wrapper col-full">
  <div class="col-half">
    <div class="vehicle-summary-col comn-box">
      <h3>Vehicle Summary</h3>
      <!-- <div class="summary-table">
        <table>
          <thead>
            <tr>
              <th width="25%">Model</th>
              <th width="20%">Vehicles Repaired</th>
              <th>Most Fitted Line Items</th>
              <th width="12%">Job Cards</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of vehicleData">
              <td>{{ item.model }}</td>
              <td>{{ item.vechiclesRepaired }}</td>
              <td>{{ item.fittedLineItems }}</td>
              <td>{{ item.jobCards }}</td>
            </tr>
          </tbody>
          
        </table>
      </div> -->
      <div class="summary-table">
        <div class="summarytab-head">
          <div class="summarytab-row">
            <div class="summarytab-col model-col">Model</div>
            <div class="summarytab-col vechicles-repaired-col">
              Vehicles Repaired
            </div>
            <div class="summarytab-col">Most Fitted Part Items</div>
            <div class="summarytab-col summary-job-cards">Job Cards</div>
          </div>
        </div>

        <div class="summarytab-body">
          <div class="summarytab-row" *ngFor="let item of vehicleData">
            <div class="summarytab-col summary-img-title">
              <i class="summary-img">
                <img [src]="item.modelImg" alt="" />
              </i>
              {{ item.model }}
            </div>
            <div class="summarytab-col vechicles-repaired-col">
              {{ item.vechiclesRepaired }}
            </div>
            <div class="summarytab-col">{{ item.fittedLineItems }}</div>
            <div class="summarytab-col summary-job-cards">
              {{ item.jobCards }}
            </div>
          </div>
          <ng-container *ngIf="vehicleData.length == 0">
            <div class="no-item-found no-border">No record found</div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  <div class="vehicle-compare-col col-half">
    <div class="comn-box">
      <h3>Comparison</h3>
      <div class="summary-row">
        <div class="summary-left-col">
          <h4>Vehicles</h4>
          <div class="summary-col">
            <div
              [class]="
                vehicleComparison['vehicles']['status'] === 'down'
                  ? 'summary-box decrease-col'
                  : 'summary-box increase-col'
              "
            >
              <h2>
                {{ vehicleComparison["vehicles"][currentYear] }}
                <small> FY-{{ currentYear }}</small>
              </h2>
              <div class="coupan-val">
                <span
                  [class]="
                    vehicleComparison['vehicles']['status'] === 'down'
                      ? ' decreaseValue'
                      : 'increaseValue'
                  "
                  >{{
                    vehicleComparison["vehicles"]["percentage"]
                      | number: "1.0-2"
                  }}%</span
                >
                <small>(Over last month)</small>
              </div>
            </div>
            <div class="summary-box fourth">
              <h2>
                {{ vehicleComparison["vehicles"][prevYear] }}
                <small>FY-{{ prevYear }}</small>
              </h2>
            </div>
          </div>
        </div>
        <div class="summary-right-col">
          <h4>Job Cards</h4>
          <div class="summary-col">
            <div
              [class]="
                vehicleComparison['job-cards']['status'] === 'down'
                  ? 'summary-box decrease-col'
                  : 'summary-box increase-col'
              "
            >
              <h2>
                {{ vehicleComparison["job-cards"][currentYear] }}
                <small>FY-{{ currentYear }}</small>
              </h2>
              <div class="coupan-val">
                <span
                  [class]="
                    vehicleComparison['job-cards']['status'] === 'down'
                      ? 'decreaseValue'
                      : 'increaseValue'
                  "
                >
                  {{
                    vehicleComparison["job-cards"]["percentage"]
                      | number: "1.0-2"
                  }}%</span
                >
                <small>(Over last month)</small>
              </div>
            </div>
            <div class="summary-box second">
              <h2>
                {{ vehicleComparison["job-cards"][prevYear] }}
                <small>FY-{{ prevYear }}</small>
              </h2>
            </div>
          </div>
        </div>
      </div>
      <div class="summary-row">
        <div class="summary-left-col">
          <h4>TGP Fitted</h4>
          <div class="summary-col">
            <div
              [class]="
                vehicleComparison['tgp-fitted']['status'] === 'down'
                  ? 'summary-box decrease-col'
                  : 'summary-box increase-col'
              "
            >
              <h2>
                {{ vehicleComparison["tgp-fitted"][currentYear] }}
                <small>FY-{{ currentYear }}</small>
              </h2>
              <div class="coupan-val">
                <span
                  [class]="
                    vehicleComparison['tgp-fitted']['status'] === 'down'
                      ? 'decreaseValue'
                      : 'increaseValue'
                  "
                  >{{
                    vehicleComparison["tgp-fitted"]["percentage"]
                      | number: "1.0-2"
                  }}%</span
                >
                <small>(Over last month)</small>
              </div>
            </div>
            <div class="summary-box fourth">
              <h2>
                {{ vehicleComparison["tgp-fitted"][prevYear] }}
                <small> FY-{{ prevYear }}</small>
              </h2>
            </div>
          </div>
        </div>
        <div class="summary-right-col">
          <h4>Competitor Parts Fitted</h4>
          <div class="summary-col">
            <div
              [class]="
                vehicleComparison['competitor-part-fitted']['status'] === 'down'
                  ? 'summary-box decrease-col'
                  : 'summary-box increase-col'
              "
            >
              <h2>
                {{ vehicleComparison["competitor-part-fitted"][currentYear] }}
                <small>FY-{{ currentYear }}</small>
              </h2>
              <div class="coupan-val">
                <span
                  [class]="
                    vehicleComparison['competitor-part-fitted']['status'] ===
                    'down'
                      ? 'decreaseValue'
                      : 'increaseValue'
                  "
                  >{{
                    vehicleComparison["competitor-part-fitted"]["percentage"]
                      | number: "1.0-2"
                  }}%</span
                >
                <small>(Over last month)</small>
              </div>
            </div>
            <div class="summary-box second">
              <h2>
                {{ vehicleComparison["competitor-part-fitted"][prevYear] }}
                <small>FY-{{ prevYear }}</small>
              </h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="tb-table-wrapper">
  <div class="guru-tabs-row">
    <div class="guru-tabs-items">
      <h2>
        Vehicle Repair Detail
        <small class="changes-subhead">(Last 3 Months)</small>
      </h2>
    </div>
    <div class="tb-btn-group">
      <ul>
        <li>
          <div class="tb-searchbox">
            <button class="tb-button guru-flex" (click)="searchRecords()">
              <div class="tb__search_icon"></div>
            </button>
            <input
              type="text"
              class="tb-search-text"
              placeholder="Search by Guru Name"
              [(ngModel)]="searchText"
              (keyup)="checkText($event)"
              appStopSC
            />
          </div>
        </li>
        <!-- <li>
            <button mat-stroked-button class="primary-stroke-button exportBtn">
              Export
            </button>
          </li> -->
      </ul>
    </div>
  </div>

  <ng-container *ngIf="isDataReady">
    <guru-table
      [tableData]="orders"
      [tableColumns]="ordersTableColumns"
      (sort)="sortData($event)"
      [isFilterable]="false"
      [isPageable]="true"
      [defaultPageSize]="10"
      [recordCount]="recordCount"
      [currentPageIndex]="pageIndex"
      (childPageChangeEvent)="receivedPageHandler($event)"
    >
    </guru-table>
  </ng-container>
</div>

<ngx-spinner></ngx-spinner>
