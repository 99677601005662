import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-distributor-detail",
  templateUrl: "./distributor-detail.component.html",
  styleUrls: ["./distributor-detail.component.scss"],
})
export class DistributorDetailComponent implements OnInit {
  recId: number;
  distributorName: string;
  selectedTab: string;
  constructor(private activateRoute: ActivatedRoute) {
    this.recId = this.activateRoute.snapshot.params.id;
    this.selectedTab = "targetachieved";
  }

  ngOnInit(): void {}
}
