import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";
import { AppMaterialModule } from "../app-material/app-material.module";
import { SharedModule } from "../shared/shared.module";
import { MsrListComponent } from "./msr-list/msr-list.component";
import { MsrDetailComponent } from "./msr-detail/msr-detail.component";
import { MsrInfoComponent } from "./msr-detail/msr-info/msr-info.component";
import { RouterModule } from "@angular/router";
import { ConfirmDialogComponent } from "./msr-detail/confirm-dialog/confirm-dialog.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { TargetAcheivedComponent } from "./msr-detail/target-acheived/target-acheived.component";
import { TargetsComponent } from "./targets/targets.component";

import { ChartsModule } from "ng2-charts";
import { NgxSpinnerModule } from "ngx-spinner";
import { ApmListComponent } from "./apm-list/apm-list.component";
import { ApmDetailComponent } from "./apm-detail/apm-detail.component";
import { ApmInfoComponent } from "./apm-detail/apm-info/apm-info.component";
import { DistributorListComponent } from "./distributor-list/distributor-list.component";
import { DistributorInfoComponent } from "./distributor-detail/distributor-info/distributor-info.component";
import { DistributorDetailComponent } from "./distributor-detail/distributor-detail.component";

@NgModule({
  declarations: [
    MsrListComponent,
    MsrDetailComponent,
    MsrInfoComponent,
    ConfirmDialogComponent,
    TargetAcheivedComponent,
    TargetsComponent,
    ApmListComponent,
    ApmDetailComponent,
    ApmInfoComponent,
    DistributorInfoComponent,
    DistributorListComponent,
    DistributorDetailComponent,
  ],
  imports: [
    BrowserModule,
    CommonModule,
    SharedModule,
    AppMaterialModule,
    ReactiveFormsModule,
    RouterModule,
    ChartsModule,
    NgxSpinnerModule,
    FormsModule,
  ],
})
export class CommonListModule {}
