<div class="guru-wrap">
  <div class="guru-row">
    <div class="guru-left-col col-half">
      <app-gurus-info-list></app-gurus-info-list>
    </div>
    <div class="guru-right-col col-half">
      <!-- <app-coupon-summary></app-coupon-summary> -->
      <app-parts-performance></app-parts-performance>
      <app-target-achieved></app-target-achieved>
    </div>
  </div>

  <!-- <div class="guru-row">
    <div class="guru-left-col col-half">
     
    </div>
    <div class="guru-right-col col-half">
      <app-points-trend></app-points-trend> 
    </div>
  </div> -->
  <div class="guru-row">
    <div class="campaign-full">
      <!-- <app-campaign-guru-diwas></app-campaign-guru-diwas> -->
    </div>
  </div>
</div>
<div class="guru-items-wrap guru-full-grey-bg">
  <app-line-items></app-line-items>
  <app-newly-items></app-newly-items>
</div>
