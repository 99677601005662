import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ChartsModule } from "ng2-charts";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { CampaignGuruDiwasComponent } from "./campaign-guru-diwas/campaign-guru-diwas.component";
import { CampaignCarouselComponent } from "./campaign-guru-diwas/campaign-carousel/campaign-carousel.component";
import { PaginatorDirective } from "../directives/pagination.directive";
import { MatTableExporterModule } from "mat-table-exporter";
import { AppMaterialModule } from "../app-material/app-material.module";
import { HttpClientModule } from "@angular/common/http";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppRoutingModule } from "../app-routing.module";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { ChipFilterComponent } from "./training/chip-filter/chip-filter.component";
import { TrainingComponent } from "./training/training.component";
import { TopTrainningVideosComponent } from "./training/top-trainning-videos/top-trainning-videos.component";
import { NgxSpinnerModule } from "ngx-spinner";
import { abs } from "../custom-element/pipe/abs.pipe";
import { OnlyAlphabetDirective } from "../directives/only-alphabet.directive";
import { OnlyAlphaNumericDirective } from "../directives/only-alpha-numeric.directive";
import { UploadDirective } from "../directives/dragDrop.directive";
import { OptyDetailComponent } from './opty-detail/opty-detail.component';
import { JobCardDetailsComponent } from './job-card-details/job-card-details.component';
import { EditGuruTypeDialogComponent } from './edit-guru-type-dialog/edit-guru-type-dialog.component';
import { InvoicePaymentListComponent } from './invoice-payment-list/invoice-payment-list.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { StarGuruDetailsDialogComponent } from './star-guru-details-dialog/star-guru-details-dialog.component';
import { ClaimStatusDetailsComponent } from './claim-status-details/claim-status-details.component';
const sharedComponents = [
  CampaignGuruDiwasComponent,
    CampaignCarouselComponent,
    TrainingComponent,
    ChipFilterComponent,
    TopTrainningVideosComponent,
    PaginatorDirective,
    OnlyAlphabetDirective,
    OnlyAlphaNumericDirective,
    UploadDirective,
      
];
@NgModule({
  declarations: [
    ...sharedComponents,
    OptyDetailComponent,
    JobCardDetailsComponent,
    EditGuruTypeDialogComponent,
    InvoicePaymentListComponent,
    StarGuruDetailsDialogComponent,
    ClaimStatusDetailsComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    CommonModule,
    BrowserAnimationsModule,
    AppMaterialModule,
    ReactiveFormsModule,
    FormsModule,
    NgbModule,
    MatTableExporterModule,
    ChartsModule,
    NgxSpinnerModule,
    NgSelectModule
  ],
  exports: [
    ...sharedComponents,
    OptyDetailComponent,
    JobCardDetailsComponent,
    InvoicePaymentListComponent,
    ClaimStatusDetailsComponent,
  ],
})
export class SharedModule {}
