import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DashboardService } from 'src/app/services/dashboard.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-star-guru-details-dialog',
  templateUrl: './star-guru-details-dialog.component.html',
  styleUrls: ['./star-guru-details-dialog.component.scss']
})
export class StarGuruDetailsDialogComponent {
  // service: string;
  // dealer: string;
  // pincode: string;
  // state: string;
  // district: string;
  // city: string;
  // servicedealer: string;
  // servicedealerdivision: string;
  // salesedealer: string;
  // salesdealerdivision: string;
  pincodeBasedAllLocationsData: any;
  states:any = [];
	districts:any = [];
	talukas:any = [];
	cities:any = [];
	dealers:any = [];
	dealerDivisions:any = [];

  servicedealerDivisions:any = [];
	distributors:any = [];
	distributorDivisions:any = [];
    servicedealers: any[];
  stateSelected: any;
  selectedServiceDealerName: any;
  account_id: any;
  updatedata: any;
  selectedDealerInfo: any;
  guru_type: any;
  // dealerDivisions: any[];
  // dealers: any[];

  constructor(public dialogRef: MatDialogRef<StarGuruDetailsDialogComponent>,@Inject(MAT_DIALOG_DATA) public data: any, private fb: FormBuilder, private dashboardapi: DashboardService) { 
    this.account_id = data.action_id;
    this.guru_type=data.guru_type
  }
  starGuruform: FormGroup;

  ngOnInit(): void {
    this.buildFilterForm()
    // this.getPincode()
  }
 

  buildFilterForm() {
    this.starGuruform = this.fb.group({
      pincode: ["", [Validators.required, Validators.minLength(6), Validators.maxLength(6), Validators.pattern(/^-?([0-9]\d*)?$/)]],
      state: ["", [Validators.required]],
      district: ["",],
      city: ["",],
      taluka: ["",],
      dealer: ["", [Validators.required]],
      dealer_division: ["", [Validators.required]],
      servicedealer: ["", [Validators.required]],
      servicedealer_division: ["", [Validators.required]],
      active_user_position_id: ["", [Validators.required]],
			name_s: ["", [Validators.required]],
      service_position_id: ["", [Validators.required]],
			service_name_s: ["", [Validators.required]]
			// active_user_dealer_name: ["", [Validators.required]],

    });
  }


  getPincode(): boolean {
    let pincode = this.starGuruform.controls.pincode.value;
    let requestPayload = { "pincode": pincode, "app_name": "bandhu", "device_id": "24872681273" };
    let isSuccessful = false;

    this.dashboardapi.pinCode(requestPayload).subscribe({
      next: (response) => {
        if (response) {
          this.pincodeBasedAllLocationsData = response;
          console.log("this.pincodeBasedAllLocationsData", this.pincodeBasedAllLocationsData);

          for (let x = 0; x < response.length; x++) {
            let loc = response[x];
            if (this.states) {
              let state_codes = this.states.map((sc) => sc["code"]);
              if (!(state_codes && state_codes.includes(loc["state"]["code"]))) {
                this.states.push({ "code": loc["state"]["code"], "name": loc["state"]["name"] });
                console.log("this.statesuuiuiuu=======================================================", this.states);
              }
            } else {
              this.states.push({ "code": loc["state"]["code"], "name": loc["state"]["name"] });
            }
          }
          isSuccessful = true;
        } else {
          console.warn("Pincode not found.");
          isSuccessful = false;
        }
      },
      error: (error) => {
        console.error("API error occurred:", error);
        isSuccessful = false;
      }
    });

    return isSuccessful;
  }
  pincodeChanged(event: any) {
    // console.log("pincodeChanged", event);
    // this.numberString(event);
    this.starGuruform.updateValueAndValidity();
    this.getPincode();
  }

  getFilteredDistrict(): void {
    let state_selected = this.states.filter((x: any, j: number) => {
      if (this.starGuruform.value.state) {
        if (x["code"] == this.starGuruform.value.state) {
          this.stateSelected = x;
        }
        return (x["code"] == this.starGuruform.value.state);
      } else {
        return true;
      }
    });

    var state_selected_codes = state_selected.map((x: any) => { return x["code"] });
    let filtered_districts = this.pincodeBasedAllLocationsData.filter((item: any, i: number) => {
      return (typeof item["state"] != "undefined" && typeof item["state"]["code"] != "undefined" && state_selected_codes.includes(item["state"]["code"]));
    });

    // this.districts = []; // Clear previous districts
    for (let i = 0; i < filtered_districts.length; i++) {
      const loc = filtered_districts[i];
      if (this.districts && this.districts.length) {
        let district_codes = this.districts.map((x) => x["code"]);
        if (!(district_codes && district_codes.includes(loc["district"]))) {
          this.districts.push({ "code": loc["district"], "name": loc["district"] });
        }
      } else {
        this.districts.push({ "code": loc["district"], "name": loc["district"] });
      }
    }



    console.log("DISTRICT LIST", this.districts);
  }


  getFilteredTalukas(): void {
    let district_selected = this.districts.filter((x: any, j: number) => {
      if (this.starGuruform.value.district) {
        return (x["code"] == this.starGuruform.value.district);
      } else {
        return true;
      }
    });

    var district_selected_codes = district_selected.map((x: any) => { return x["code"] });
    console.log("DISTRICT SELECTED", district_selected, district_selected_codes);

    let filtered_talukas = this.pincodeBasedAllLocationsData.filter((item: any, i: number) => {
      return (typeof item["district"] != "undefined" && district_selected_codes.includes(item["district"]));
    });

    // this.talukas = []; // Clear previous talukas
    for (let i = 0; i < filtered_talukas.length; i++) {
      const loc = filtered_talukas[i];
      if (this.talukas && this.talukas.length) {
        let code_list = this.talukas.map((x) => x["code"]);
        if (!(code_list && code_list.includes(loc["taluka"]))) {
          this.talukas.push({ "code": loc["taluka"], "name": loc["taluka"], "data": loc });
        }
      } else {
        this.talukas.push({ "code": loc["taluka"], "name": loc["taluka"], "data": loc });
      }
    }



    console.log("TALUKA LIST", this.talukas);
  }

  getFilteredCities(): void {
    let taluka_selected = this.talukas.filter((x: any, j: number) => {
      if (this.starGuruform.value.taluka) {
        return (x["code"] == this.starGuruform.value.taluka);
      } else {
        return true;
      }
    });
    // console.log("CITY SELECTED", taluka_selected);
    var taluka_selected_codes = taluka_selected.map((x: any) => { return x["code"] });
    let filtered_cities = this.pincodeBasedAllLocationsData.filter((item: any, i: number) => {
      return (typeof item["taluka"] != "undefined" && taluka_selected_codes.includes(item["taluka"]));
    });
    for (let i = 0; i < filtered_cities.length; i++) {
      const loc = filtered_cities[i];
      if (this.cities && this.cities.length) {
        let code_list = this.cities.map((x) => x["code"]);
        if (!(code_list && code_list.includes(loc["city"]))) {
          this.cities.push({ "code": loc["city"], "name": loc["city"] });
        }
      } else {
        this.cities.push({ "code": loc["city"], "name": loc["city"] });
      }
    }
    console.log("CITY LIST", this.cities);
  }

  filterStateDistrictTalukaCity(boolIsStateChanged: boolean = false): any {
    console.log("this.guruInformationForm.value", this.starGuruform.value);

    console.log("STATES LIST", this.states);
    this.getFilteredDistrict();
    this.getFilteredTalukas();
    this.getFilteredCities();


  }

  stateChanged(): void {
    this.cities = [];
    this.talukas = [];
    this.districts = [];
    this.starGuruform.controls.district.reset();
    this.starGuruform.controls.taluka.reset();
    this.starGuruform.controls.city.reset();
    this.filterStateDistrictTalukaCity(false);
    // this.getRegion();
    this.getServiceDealers()
  }
  districtChanged(): void {
    this.cities = [];
    this.talukas = [];
    this.starGuruform.controls.taluka.reset();
    this.starGuruform.controls.city.reset();
    this.filterStateDistrictTalukaCity(false);
    this.getSalesDealers();
    // this.getDistributors();

  }

  talukaChanged(): void {
    this.cities = [];
    this.starGuruform = null;
    this.starGuruform.controls.city.reset();
    this.filterStateDistrictTalukaCity(false);
    // this.getDistributors();
  }



  getSalesDealers(): void {
    this.dealers = [];
    this.dealerDivisions = [];
    let selectedDistrict = this.starGuruform.controls.district.value;
    if (selectedDistrict) {
      // this.spinnerService.show();
      let requestPayload = { "district": selectedDistrict, offset: 0, limit: 50 };

      this.dashboardapi.salesDealer(requestPayload).subscribe({
        next: (response) => {
          this.dealers = [];
          this.dealerDivisions = [];
          if (response && typeof response["result"] != "undefined") {
            let apiResponseData = Object.values(response["result"]);
            for (let i = 0; i < apiResponseData.length; i++) {
              const dealer = apiResponseData[i];
              this.dealers.push({ "code": dealer["row_id"], "name": dealer["dealer"] });
            }
            console.log("Dealers", this.dealers);



          } else {
            // this.handleSuccessfulAPIResponse(apiResponse, "'Dealer is not found.");
          }
        }
      })
    }

  }

  getsalesDealerDivisions(): void {
    this.dealerDivisions = [];
    let selectedDealer = this.starGuruform.controls.dealer.value;
    if (selectedDealer) {
      // this.spinnerService.show();
      this.selectedDealerInfo = this.dealers.find(dealer => dealer.code === selectedDealer);
      console.log('this.selectedDealerInfo',this.selectedDealerInfo);
      
      let requestPayload = { "row_id": selectedDealer, offset: 0, limit: 100 };

      this.dashboardapi.dealearDivision(requestPayload).subscribe({
        next: (response) => {
          this.dealerDivisions = [];
          if (response && typeof response["results"] != "undefined" && typeof response["results"]["data"] != "undefined") {
            let apiResponseData = response["results"]["data"];
            for (let i = 0; i < apiResponseData.length; i++) {
              const division = apiResponseData[i];
              console.log("division", division);
              this.dealerDivisions.push({ "code": division["ROW_ID"], "name": division["DIV_NAME_s"] });
            }
            // this.guruInformationForm.controls.dealer_division.setValue(this.dealerDivisions[0]["code"]);
          } else {
            // this.handleSuccessfulAPIResponse(apiResponse, "'Dealer-Division is not found.");
          }
        }
      })

    }

  }
  getUserInfo():void {
    let selectedDealerDivision = this.starGuruform.controls.dealer_division.value;
    if(selectedDealerDivision && selectedDealerDivision.length) {
      // this.spinnerService.show();no
      this.starGuruform.controls.active_user_position_id.setValue("");
      // this.starGuruform.controls.active_user_dealer_code.setValue("");
      // this.starGuruform.controls.active_user_dealer_name.setValue("");
      let requestPayload = { "row_id": selectedDealerDivision };
      
      this.dashboardapi.userInfo(requestPayload).subscribe({
        next:(response)=>{
  
  
          if(response && typeof response["results"]!="undefined" && typeof response["results"][0]!="undefined"){
            let apiResponseData = response["results"][0];
            this.starGuruform.controls.active_user_position_id.setValue(apiResponseData["PR_POSTN_ID_s"]);
            this.starGuruform.controls.name_s.setValue(apiResponseData["NAME_s"]);
            // this.starGuruform.controls.active_user_dealer_name.setValue(apiResponseData["DIV_ORG_NAME_s"]);
          } else {
            // this.handleSuccessfulAPIResponse(apiResponse, "Active User Division not found");
          }
  
        }
      })
     
        
       
    }
  }
  

  getServiceDealers(): void {
    this.servicedealers = [];
    this.servicedealerDivisions=[]
    let selectedstate = this.starGuruform.controls.state.value;
    if (selectedstate) {
      // this.spinnerService.show();
      let requestPayload = {
        "state_name": selectedstate,
        "search_filter": "",
        offset: 0,
        limit: 50
      };

      this.dashboardapi.serviceDealer(requestPayload).subscribe({
        next: (response) => {
          if (response && Array.isArray(response.results)) {
            let apiResponseData = response.results;
            for (let i = 0; i < apiResponseData.length; i++) {
              const dealer = apiResponseData[i];
              this.servicedealers.push(dealer);
            }
            console.log("Dealers", this.servicedealers);
          } else {
            // this.handleSuccessfulAPIResponse(apiResponse, "'Service Dealer is not found.");
          }
        }
      })
    }


  }

  getServiceDealerDivisions(): void {
    // this.dealerDivisions=[]
    let selectedServiceDealer = this.starGuruform.controls.servicedealer.value;
    console.log('selectedServiceDealer',selectedServiceDealer);

    const selectedDealer = this.servicedealers.find(dealer => dealer.PAR_BU_ID_s === selectedServiceDealer);
    if (selectedDealer) {
      this.selectedServiceDealerName = selectedDealer.ORG_NAME_s; // Store the selected dealer's name
      console.log("Selected Service Dealer Name:", this.selectedServiceDealerName);
    }
    
    if (selectedServiceDealer) {
      // this.spinnerService.show();
      let requestPayload = {
        "PAR_BU_ID_s": selectedServiceDealer,
        offset: 0,
        limit: 100
      };

      this.dashboardapi.serviceDealerDivision(requestPayload).subscribe({
        next: (response) => {
          if (response && Array.isArray(response.msg)) {
            let apiResponseData = response.msg;
            for (let i = 0; i < apiResponseData.length; i++) {
              const division = apiResponseData[i];
              this.servicedealerDivisions.push(division);
            }
            console.log("Divisions", this.dealerDivisions);
          } else {
            // this.handleSuccessfulAPIResponse(response, "'Dealer-Division is not found.");
          }
          // this.spinnerService.hide();
        }
      })
    }
  }

  getServiceUserInfo():void {
    let selectedserviceDealerDivision = this.starGuruform.controls.servicedealer_division.value;
    if(selectedserviceDealerDivision && selectedserviceDealerDivision.length) {
      // this.spinnerService.show();no
      this.starGuruform.controls.service_position_id.setValue("");
      this.starGuruform.controls.service_name_s.setValue("");
      // this.starGuruform.controls.active_user_dealer_name.setValue("");
      let requestPayload = { "DIV_ID_s": selectedserviceDealerDivision };
      
      this.dashboardapi.serviceUserInfo(requestPayload).subscribe({
        next:(response)=>{
  
  
          if(response && typeof response["msg"]!="undefined" && typeof response["msg"][0]!="undefined"){
            let apiResponseData = response["msg"][0];
            this.starGuruform.controls.service_position_id.setValue(apiResponseData["POSTN_ID_s"]);
            this.starGuruform.controls.service_name_s.setValue(apiResponseData["NAME_s"]);
            // this.starGuruform.controls.active_user_dealer_name.setValue(apiResponseData["DIV_ORG_NAME_s"]);
          } else {
            // this.handleSuccessfulAPIResponse(apiResponse, "Active User Division not found");
          }
  
        }
      })
     
        
       
    }
  }
  
 

onSave(): void {
  let payload = [{
    dealer_name: this.starGuruform.controls.name_s.value,
    pr_position_id: this.starGuruform.controls.active_user_position_id.value,
    service_position_name: this.starGuruform.controls.service_name_s.value,
    service_position_id: this.starGuruform.controls.service_position_id.value,
    account_type: this.guru_type,
    contact_category: "",
    action_type: "update",
    account_id: this.account_id
  }];

  this.dashboardapi.updateApi(payload).subscribe({
    next: (res) => {
      this.updatedata = res;
      if (res && res.msg) {
        // Show success message using SweetAlert2
        Swal.fire({
          title: 'Success!',
          text: res.msg,
          icon: 'success',
          confirmButtonText: 'OK'
        }).then(() => {
          // Close the dialog after the alert is confirmed
          this.dialogRef.close();
        });
      }
    },
    error: (err) => {
      // Handle error if needed
      console.error('Error:', err);
      Swal.fire({
        title: 'Error!',
        text: 'There was an error updating the account.',
        icon: 'error',
        confirmButtonText: 'OK'
      });
    }
  });
}

 closeModal() {
   this.onCancel()
 }
 onCancel(): void {
   this.dialogRef.close();
 }


  ngOnDestroy() {
    this.dialogRef.close();
  }
}