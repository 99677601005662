<div class="guru-tabs-row">
  <div class="guru-tabs-items">
    <ul>
      <!-- <li
        [ngClass]="{ 'active-tab': step === 'User' }"
        (click)="addUserTab('User')"
      >
        <a>Add User</a>
      </li> -->
      <li
        [ngClass]="{ 'active-tab': step === 'Lass' }"
        (click)="addUserTab('Lass')" *ngIf="this.role === 'RPM' || this.role === 'SuperAdmin'"
      >
        <a>Add Lass</a>
      </li>
      <li
        [ngClass]="{ 'active-tab': step === 'RPM' }"
        (click)="addUserTab('RPM')" *ngIf="role === 'SuperAdmin'"
      >
        <a>Add RPM</a>
      </li>
      <!-- <li
        [ngClass]="{ 'active-tab': step === 'APM' }"
        (click)="addUserTab('APM')" *ngIf="role === 'SuperAdmin'"
      >
        <a>Add APM</a>
      </li> -->
      <!-- <li
        [ngClass]="{ 'active-tab': step === 'Distributor' }"
        (click)="addUserTab('Distributor')"
      >
        <a>Add Distributor</a>
      </li>
      <li
        [ngClass]="{ 'active-tab': step === 'MSR' }"
        (click)="addUserTab('MSR')"
      >
        <a>Add MSR</a>
      </li>
      <li
        [ngClass]="{ 'active-tab': step === 'Role' }"
        (click)="addUserTab('Role')"
      >
        <a>Add Role</a>
      </li> -->
      
      <li
        [ngClass]="{ 'active-tab': step === 'Dealer' }"
        (click)="addUserTab('Dealer')" *ngIf="this.role === 'RPM' || this.role === 'SuperAdmin'"
      >
        <a>Add Dealer</a>
      </li>
    </ul>
  </div>
</div>
<diV class="superadmin-tabs">
  <!-- <app-user-registration *ngIf="step === 'User'"></app-user-registration> -->
  <app-add-rpm *ngIf="step === 'RPM'"></app-add-rpm>
  <!-- <app-add-apm *ngIf="step === 'APM'"></app-add-apm> -->
  <!-- <app-add-distributor *ngIf="step === 'Distributor'"></app-add-distributor>
  <app-add-msr *ngIf="step === 'MSR'"></app-add-msr>
  <app-add-role *ngIf="step === 'Role'"></app-add-role> -->
  <app-add-lass *ngIf="step === 'Lass'"></app-add-lass>
  <app-add-dealer *ngIf="step === 'Dealer'"></app-add-dealer>
</diV>