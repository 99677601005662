import { AfterViewInit, Component, OnInit, ViewChild } from "@angular/core";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTable, MatTableDataSource } from "@angular/material/table";
import { HttpMethod } from "src/app/constants/http-handlers";
import { MSR1ListTableItem } from "src/app/models/apm";
import { AppService } from "src/app/services/app.service";
import { CommonService } from "src/app/services/common.service";
import { DatePipe } from "@angular/common";
import { NgxSpinnerService } from "ngx-spinner";

const ELEMENT_DATA: MSR1ListTableItem[] = [];

@Component({
  selector: "app-msr-list",
  templateUrl: "./msr-list.component.html",
  styleUrls: ["./msr-list.component.scss"],
  providers: [DatePipe],
})
export class MsrListComponent implements OnInit {
  step: string = "all";
  showNodata: boolean = false;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatTable) table!: MatTable<MSR1ListTableItem>;
  dataSource = new MatTableDataSource<MSR1ListTableItem>();

  isLoading = false;
  totalRows = 0;
  pageSize = 10;
  currentPage = 0;
  pgIndex: number = 0;
  filterChipList: any[] = [];
  filterObject: any = [];

  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = [
    "guru_username",
    "num_of_gurus",
    "part_fitment_count",
    "associated_since",
    "phone_number",
  ];
  guruList: any = [];
  constructor(
    private commonService: CommonService,
    private appService: AppService,
    private datePipe: DatePipe,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.getMSRlist();
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }

  getMSRlist() {
    this.spinner.show();
    let apiUrl = `${this.appService.showMSRlist}`;
    let queryParamList = [];

    //step param
    let stepParam = "";
    if (this.step != "all") {
      stepParam = `status=` + this.step;
      queryParamList.push(stepParam);
    }

    //paging
    let pageParam = "";
    if (this.pgIndex != 0 && this.pgIndex != 1) {
      pageParam = `page=` + this.pgIndex;
      queryParamList.push(pageParam);
    }

    let apiQueryParam = "";
    if (queryParamList.length > 0) {
      apiQueryParam = `&` + queryParamList.join("&");
    }

    const url = `${apiUrl}` + apiQueryParam;

    const apiObj = {
      url: url,
      methodType: HttpMethod.GET,
    };
    this.commonService.commonApiCall(apiObj, (res) => {
      if (
        res &&
        res.hasOwnProperty("results") &&
        res.results &&
        res.results.length
      ) {
        let i = 0;
        res.results.forEach((ele) => {
          let dt = "##"; //this.datePipe.transform(ele.associated_since, "dd/MM/YYYY");
          res.results[i]["associated_since"] = this.datePipe.transform(
            ele.associated_since,
            "dd/MM/YYYY"
          );
          res.results[i]["dur"] = this.commonService.getDurationInMonth(
            ele.associated_since
          );
          i++;
          //     //   fitmentdate: this.datePipe.transform(ele.fitment_date, "dd/MM/YYYY")
          //     // this.orders.push(obj);
        });
        this.totalRows = res.count;
        this.dataSource = new MatTableDataSource(res.results);
        this.dataSource.sort = this.sort;
        this.paginator.pageIndex = this.currentPage;
        this.paginator.length = res.count;
        this.isLoading = false;
        this.spinner.hide();
      } else {
        this.spinner.hide();
      }
      if (res.count === 0) {
        this.showNodata = true;
      } else {
        this.showNodata = false;
      }
    });
    //this.isLoading = false;
  }
  pageChanged(event: PageEvent) {
    this.pgIndex = event.pageIndex;
    this.pgIndex = this.pgIndex + 1;
    this.currentPage = event.pageIndex;
    this.isLoading = true;
    this.getMSRlist();
  }

  setMSRlistTab(status) {
    this.step = status;
    //reset page index
    this.pgIndex = 1;
    this.isLoading = true;
    this.getMSRlist();
  }
}
