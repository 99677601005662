<div class="parts-graph-container">
  <div class="parts-graph-col">
    <div class="chart-header">
      <div>
        <span class="medium">Total
          <span>
            <ng-container *ngIf="selectedBrand === 'tgp'">TGP</ng-container>
            <ng-container *ngIf="selectedBrand !== 'tgp'">NON TGP</ng-container>
          </span>
          Fitted - Compare</span>
      </div>
      <div class="legend">
        <div id="legendpink"></div>
        <div class="legend-text">{{ currentfy }}</div>
        <div id="legendblue"></div>
        <div class="legend-text">{{ previousfy }}</div>
      </div>
    </div>
    <div style="height: 360px; padding-top: 40px">
      <canvas baseChart [datasets]="barChartData" [labels]="barChartLabels" [options]="barChartOptions"
        [plugins]="barChartPlugins" [legend]="barChartLegend" [chartType]="barChartType"
        [colors]="barChartColors"></canvas>
    </div>
  </div>
  <div class="parts-value-col comn-box">
    <div class="summary-row">
      <div class="summary-left-col">
        <h4>
          Total
          <span>
            <ng-container *ngIf="selectedBrand === 'tgp'">TGP</ng-container>
            <ng-container *ngIf="selectedBrand !== 'tgp'">NON TGP</ng-container>
          </span>
          Sold
        </h4>
        <div class="summary-col" *ngIf="selectedBrand === 'tgp'">
          <div [class]="
              tgpSoldPercent > 0
                ? 'summary-box increase-col'
                : 'summary-box decrease-col'
            ">
            <h2>
              {{ tgpSoldCurrentYear }}
              <small> FY-{{ commonService.currentYear }}</small>
            </h2>
            <div class="coupan-val">
              <span
                [class]="tgpSoldPercent > 0 ? 'increaseValue' : 'decreaseValue'">{{ tgpSoldPercent | abs | number: "1.0-2" }}%</span>
              <small>(Over last month)</small>
            </div>
          </div>
          <div class="summary-box second">
            <h2>
              {{ tgpSoldLastYear }}
              <small> FY-{{ commonService.prevYear }}</small>
            </h2>
          </div>
        </div>
        <div class="summary-col" *ngIf="selectedBrand !== 'tgp'">
          <div [class]="
              ntgpSoldPercent > 0
                ? 'summary-box increase-col'
                : 'summary-box decrease-col'
            ">
            <h2>
              {{ ntgpSoldCurrentYear }}
              <small> FY-{{ commonService.currentYear }}</small>
            </h2>
            <div class="coupan-val">
              <span
                [class]="ntgpSoldPercent > 0 ? 'increaseValue' : 'decreaseValue'">{{ ntgpSoldPercent | number: "1.0-2" }}%</span>
              <small>(Over last month)</small>
            </div>
          </div>
          <div class="summary-box second">
            <h2>
              {{ ntgpSoldLastYear }}
              <small> FY-{{ commonService.prevYear }}</small>
            </h2>
          </div>
        </div>
      </div>
    </div>
    <div class="summary-row hidden">
      <div class="summary-left-col">
        <h4>Total Points Earned</h4>
        <div class="summary-col">
          <div class="summary-box increase-col">
            <h2>
              1,00,576 <small> {{ commonService.currentYear }}</small>
            </h2>
            <div class="coupan-val">
              <span class="increaseValue">5%</span>
              <small>(Over last month)</small>
            </div>
          </div>
          <div class="summary-box second">
            <h2>
              1,00,000 <small> {{ commonService.prevYear }}</small>
            </h2>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="tb-table-wrapper">
  <div class="guru-tabs-row">
    <div class="guru-tabs-items">
      <h2>
        Part Fitments Detail
        <!-- <small class="changes-subhead">(Last 3 Months)</small> -->
      </h2>
    </div>
    <div class="tb-btn-group">
      <ul>
        <li>
          <div class="tb-searchbox">
            <button class="tb-button guru-flex" (click)="searchRecords()">
              <div class="tb__search_icon"></div>
            </button>
            <input type="text" class="tb-search-text" [(ngModel)]="searchText"
              placeholder="Search by Part / Product Description" (keyup)="checkText($event)" />
          </div>
        </li>
        <li>
          <app-part-summary-filter #childComponent (formEventTrigger)="formControlValue($event)" [searchText]="searchText"></app-part-summary-filter>
        </li>
        <!-- <li>
          <app-gurulist-filter></app-gurulist-filter>
        </li> -->
        <li>
            <button mat-stroked-button class="primary-stroke-button exportBtn" (click)="downloadReport()">
              Export Excel
            </button>
        </li>
        <li>
          <button mat-stroked-button class="primary-stroke-button exportBtn" (click)="refreshList()">
            Refresh
          </button>
      </li>
      </ul>
    </div>
  </div>
  <ng-container *ngIf="isDataReady">
    <guru-table [tableData]="orders" [tableColumns]="ordersTableColumns" (sort)="sortData($event)"
      [isFilterable]="false" [isPageable]="true" [defaultPageSize]="10" [recordCount]="recordCount"
      [currentPageIndex]="pageIndex" (childPageChangeEvent)="receivedPageHandler($event)">
    </guru-table>
  </ng-container>



  
</div>
<div class="action-toaster" *ngIf="showToastermessage">
  <div class="toaster-msg alert alert-danger" *ngIf="showfinalErrorMsg">
      {{ showfinalErrorMsg }}
  </div>
</div>
<ngx-spinner></ngx-spinner>