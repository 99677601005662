<div class="container-dialog">
    <ng-container *ngIf="action == 'delete';else elseDifferentAction">
        <h2> Are you sure you want to delete this image</h2>
    </ng-container>
    <ng-template #elseDifferentAction>
        <h2> Are you sure you want to upload this image again</h2>
    </ng-template>
    <div class="btn-group dialogBtn-group">
        <button mat-stroked-button class="primary-stroke-button" (click)="cancel()">No</button>
        <button mat-flat-button class="primary-flat-button" (click)="confirm()">Yes</button>
    </div>
</div>