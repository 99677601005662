import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Dashboard } from 'aws-sdk/clients/quicksight';
import { Subscription } from 'rxjs';
import { DashboardService } from 'src/app/services/dashboard.service';
import { UserService } from 'src/app/services/user.service';
// import { DashboardService } from 'src/app/services/dashboard.service';
// import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-add-dealer',
  templateUrl: './add-dealer.component.html',
  styleUrls: ['./add-dealer.component.scss']
})
export class AddDealerComponent implements OnInit {
  states: any;

  constructor(private fb: FormBuilder, private userService: UserService,private Dashboard:DashboardService) { }
  sub: Subscription = new Subscription();
  dealerForm: FormGroup;
  isAddUser: boolean = false;
  submitted: boolean = false;
  submitMessage: string;
  showfinalSuccessMsg: string;
  showfinalErrorMsg: string;
  showToastermessage: boolean = false;
  regions = [
    "East",
    "North",
    "South",
    "West"
  ];
  ngOnInit(): void {

    this.dealerForm = this.fb.group({
      is_active: [true],
      is_contractor: [false],
      firstName: [ "",[Validators.required, Validators.pattern(/^[a-zA-Z ]*$/), this.noWhitespaceValidator],],
      lastName: ["", [Validators.required, Validators.pattern(/^[a-zA-Z ]*$/), this.noWhitespaceValidator]],
      mobileNum: ["",[Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$"), Validators.min(6000000000), Validators.maxLength(10),],],
      username: ["", [Validators.required, Validators.pattern(/^[a-zA-Z ]*$/), this.noWhitespaceValidator],],
      email: [""],
      state: ["", Validators.required],
      region: ["", Validators.required],
    });
    this.optyplMaster() 
    // let req = {
    //   "offset": 0,
    //   "limit": 10,
    //   from_date: this.fromDate ? this.fromDate : "",
    //   to_date: this.toDate ? this.toDate : "",
    // }
    // this.getUserList(req);
    // this.getAllRoles();
    //this.websiteMultiCtrl.setValue(this.websites[1]);

  }
  addUserFormData() {
    this.submitted = true;
    // if (this.dealerForm.value.selectedRoleType == 'TML') {
    //   this.dealerForm.get('email').setValidators([
    //     Validators.required,
    //     Validators.pattern(/^\w.+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/),
    //   ]);
    //   this.dealerForm.get('email').updateValueAndValidity();
    // }
    // else {
    //   this.dealerForm.get('email').setValue("");
    //   this.dealerForm.get('email').clearValidators();
    // }
    if (this.dealerForm.valid) {
      // if (this.websiteMultiCtrl.valid) {
      //console.log(this.addUserForm.value);
      let userObj = {
        phone_number: this.dealerForm.value.mobileNum,
        username: this.dealerForm.value.username,
        first_name: this.dealerForm.value.firstName,
        last_name: this.dealerForm.value.lastName,
        role_id: 16,
        role_name: "DEALER",
        is_active: true,
        email_id: this.dealerForm.value.email,
        state: this.dealerForm.value.state,
        region: this.dealerForm.value.region,
        is_contractor: this.dealerForm.value.is_contractor == "Yes" ? true : false
      };
      this.Dashboard.addDealer(userObj).subscribe({
        next: (response: any) => {
          if (response.success) {
            this.showfinalErrorMsg = "";
            this.showfinalSuccessMsg = response.msg;
            this.showToastermessage = true;
            this.submitted = false;
            this.dealerForm.reset({
              is_contractor: this.dealerForm.value.is_contractor == "Yes" ? true : false, // Preserve the value
              is_active: true, // Preserve the value
            });
          }
          else {
            this.showfinalSuccessMsg = "";
            this.showfinalErrorMsg = response.msg;
            this.showToastermessage = true;
          }
          setTimeout(() => {
            this.showToastermessage = false;
          }, 4000);
        },
        error: (error) => {
          this.showToastermessage = true;
          this.showfinalSuccessMsg = "";
          this.showfinalErrorMsg = error.error.msg;
          setTimeout(() => {
            this.showToastermessage = false;
          }, 4000);
          console.log(error);
        },
        complete: () => { },
      });
    }
  }

  optyplMaster() {
    try {
      let data = {};
      this.sub.add(this.Dashboard.optyplMaster(data).subscribe({
        next: (res: any) => {
          if (res && res.success === true) {
            this.states= res.result.state
          }else{} 
        },
        error: (error) => {
          console.log(error);
        }
      }));
    } catch (error) {
      console.log(error);
    }
  }

  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }
  removeSpace() {
    const inputElement = document.getElementById("username") as HTMLInputElement;
    inputElement.addEventListener("keypress", (event: KeyboardEvent) => {
      if (event.keyCode === 32) {
        event.preventDefault();
      }
    });
  }
  keyPressNumbers(event) {
    var charCode = event.which ? event.which : event.keyCode;
    // Only Numbers 0-9
    if (charCode < 48 || charCode > 57) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }
}
