<div class="mb-3">
    <div class="guru-tabs-row">
        <div></div>
        <div class="tb-btn-group">
            <ul>
                <li>
                    <app-door-step-booking-filter #childComponent (formEventTrigger)="formControlValue($event)"></app-door-step-booking-filter>
                </li>
                <li>
                    <button mat-stroked-button class="primary-stroke-button exportBtn" (click)="downloadDoorStepBookingListExcel()">
                        Export Excel
                    </button>
                </li>
                <li>
                    <button mat-stroked-button class="primary-stroke-button exportBtn" (click)="pageChanged(0)">
                    Refresh
                    </button>
                </li>
            </ul>
        </div>
    </div>
    <div class="table-wrapper">
        <div class="guru-list-table rpm-table" *ngIf="!showNodata">
        <table mat-table matTableExporter 
            class="full-width-table tb-table" matSort aria-label="Elements" 
            [dataSource]="dataSource">
            <ng-container matColumnDef="bookingId" sticky>
                <th mat-header-cell *matHeaderCellDef class="bookingId-col" style="background-color: white;">Booking ID</th>
                <td mat-cell *matCellDef="let element" class="bookingId-col  {{ element.id }} text-nowrap" style="box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
                ">
                {{ element.id }}
                </td>
            </ng-container>
            <ng-container matColumnDef="caseType">
                <th mat-header-cell *matHeaderCellDef class="bookingSurce-col">
                Case Type
                </th>
                <td mat-cell *matCellDef="let element" class="bookingSurce-col text-nowrap">
                {{ element.case_type }}
                </td>
            </ng-container>
            <ng-container matColumnDef="bookingSurce">
                <th mat-header-cell *matHeaderCellDef class="bookingSurce-col">
                Booking Source
                </th>
                <td mat-cell *matCellDef="let element" class="bookingSurce-col text-nowrap">
                {{ element.case_type == 'OTC Doorstep Booking'?'BANDHU':(element.case_type == 'Web Doorstep Booking' || element.case_type == 'Web Vehicle Breakdown')?'CUSTOMER CARE':( element.case_type == 'Doorstep Booking' || element.case_type == 'Vehicle Breakdown' )?'FLEETEDGE':'-' }}
                </td>
            </ng-container>
            <ng-container matColumnDef="createdDate">
                <th mat-header-cell *matHeaderCellDef class="createdDate-col">
                Created Date
                </th>
                <td mat-cell *matCellDef="let element" class="createdDate-col">
                {{ element.created_date | date: "dd/MM/yyyy" }}
                </td>
            </ng-container>
            <ng-container matColumnDef="bookingDate">
                <th mat-header-cell *matHeaderCellDef class="bookingDate text-nowrap">
                Booking Date
                </th>
                <td mat-cell *matCellDef="let element" class="bookingDate text-nowrap">
                {{ element.date | date: "dd/MM/yyyy" }}
                </td>
            </ng-container>
            <ng-container matColumnDef="timeslot">
                <th mat-header-cell *matHeaderCellDef class="timeslot-col text-nowrap">
                Time Slot
                </th>
                <td mat-cell *matCellDef="let element" class="timeslot-col text-nowrap text-center">
                {{ element.start_time }} - {{ element.end_time }}
                </td>
            </ng-container>
            <ng-container matColumnDef="vehicleNumber">
                <th mat-header-cell *matHeaderCellDef class="vehicleNumber-col text-nowrap">
                Vechicle Number
                </th>
                <td mat-cell style="text-transform: none !important;" *matCellDef="let element" class="vehicleNumber-col text-nowrap text-center">
                {{ element.registration_number | uppercase }}
                </td>
            </ng-container>
            <ng-container matColumnDef="ownerName">
                <th mat-header-cell *matHeaderCellDef class="ownerName-col text-nowrap">
                Owner Name
                </th>
                <td mat-cell *matCellDef="let element" class="ownerName-col text-nowrap">
                {{ element.fleet.first_name }} {{ element.fleet.last_name }}
                </td>
            </ng-container>
            <ng-container matColumnDef="ownerContactNo">
                <th mat-header-cell *matHeaderCellDef class="ownerContactNo-col text-nowrap">
                Owner Contact Number
                </th>
                <td mat-cell *matCellDef="let element" class="ownerContactNo-col text-nowrap text-center">
                {{ element.fleet.username }}
                </td>
            </ng-container>
            <ng-container matColumnDef="guruId">
                <th mat-header-cell *matHeaderCellDef class="guruId-col text-nowrap">
                Guru Id
                </th>
                <td mat-cell *matCellDef="let element" class="guruId-col text-nowrap text-center">
                {{ element.guru?element.guru.id:'-' }}
                </td>
            </ng-container>
            <ng-container matColumnDef="guruName">
                <th mat-header-cell *matHeaderCellDef class="guruName-col text-nowrap">
                Guru Name
                </th>
                <td mat-cell *matCellDef="let element" class="guruId-col text-nowrap text-center">
                {{ element.guru?element.guru.guru_detail.first_name:'-' }} {{ element.guru?element.guru.guru_detail.last_name:'-' }}
                </td>
            </ng-container>
            <ng-container matColumnDef="guruContactNo">
                <th mat-header-cell *matHeaderCellDef class="guruContactNo-col text-nowrap">
                Guru Contact Number
                </th>
                <td mat-cell *matCellDef="let element" class="guruContactNo-col text-nowrap text-center">
                {{ element.guru?element.guru.guru_detail.username :'-' }} 
                </td>
            </ng-container>
            <ng-container matColumnDef="guruState">
                <th mat-header-cell *matHeaderCellDef class="guruState-col text-nowrap">
                Guru State
                </th>
                <td mat-cell *matCellDef="let element" class="guruState-col text-nowrap">
                {{ element.guru?element.guru.state_name :'-' }} 
                </td>
            </ng-container>
            <ng-container matColumnDef="guruRegion">
                <th mat-header-cell *matHeaderCellDef class="guruRegion-col text-nowrap">
                Guru Region
                </th>
                <td mat-cell *matCellDef="let element" class="guruRegion-col text-nowrap text-center ">
                {{ element.guru?element.guru.zone :'-' }} 
                </td>
            </ng-container>
            <ng-container matColumnDef="sso">
                <th mat-header-cell *matHeaderCellDef class="sso-col text-nowrap text-center">
                SSO
                </th>
                <td mat-cell *matCellDef="let element" class="sso-col text-nowrap text-center ">
                {{ element.guru?element.guru.sso :'-' }} 
                </td>
            </ng-container>
            <ng-container matColumnDef="lassName">
                <th mat-header-cell *matHeaderCellDef class="lassName-col text-nowrap">
                Lass Name
                </th>
                <td mat-cell *matCellDef="let element" class="lassName-col text-nowrap">
                {{ element.guru?element.lass_detail.lass_name :'-' }} 
                </td>
            </ng-container>
            <ng-container matColumnDef="lassNumber">
                <th mat-header-cell *matHeaderCellDef class="lassNumber-col text-nowrap">
                Lass Number
                </th>
                <td mat-cell *matCellDef="let element" class="lassNumber-col text-nowrap">
                {{ element.guru?element.lass_detail.lass_number :'-' }} 
                </td>
            </ng-container>
            <ng-container matColumnDef="lastStatus">
                <th mat-header-cell *matHeaderCellDef class="lastStatus-col text-nowrap text-center ">
                Last Status
                </th>
                <td mat-cell *matCellDef="let element" class="lastStatus-col text-nowrap text-center">
                {{ element.milestone_status?element.milestone_status.status:element.case_status }}
                </td>
            </ng-container>
            <ng-container matColumnDef="lastStatusDate">
                <th mat-header-cell *matHeaderCellDef class="lastStatusDate-col text-nowrap">
                Last Status Date
                </th>
                <td mat-cell *matCellDef="let element" class="lastStatusDate-col text-nowrap text-center">
                {{ element.updated_date | date: "dd/MM/yyyy" }} 
                </td>
            </ng-container>
            <!-- <ng-container matColumnDef="jobCardCreated">
                <th mat-header-cell *matHeaderCellDef class="jobCardCreated-col text-nowrap">
                Job Card Cretaed
                </th>
                <td mat-cell *matCellDef="let element" class="jobCardCreated-col text-nowrap">
                {{ element.milestone_status?element.milestone_status.status:'-' }}   Remove
                </td>
            </ng-container> -->
            <ng-container matColumnDef="jobcardCreatedDate">
                <th mat-header-cell *matHeaderCellDef class="jobcardCreatedDate-col text-nowrap">
                Job Card Created Date
                </th>
                <td mat-cell *matCellDef="let element" class="jobcardCreatedDate-col text-nowrap text-center">
                {{ element.jobcard_details? ( element.jobcard_details.created_date | date: "dd/MM/yyyy" ) :'-' }}
                </td>
            </ng-container>
            <ng-container matColumnDef="jobCardStatus">
                <th mat-header-cell *matHeaderCellDef class="jobCardStatus-col text-nowrap">
                Job Card Status
                </th>
                <td mat-cell *matCellDef="let element" class="jobCardStatus-col jobcard-status-last-col text-nowrap text-center">
                {{ element.jobcard_details?(element.jobcard_details.work_completion >=25 && element.jobcard_details.work_completion <=75?'Job Card Ongoing':(element.jobcard_details.work_completion >=75 && element.jobcard_details.work_completion <=100)?'Job card completed':'-'):'-' }} 
                </td>
            </ng-container>
            <tr class="tb-head-row" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr class="tb-item-row" mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
        </div>
    
        <ng-container *ngIf="showNodata">
        <div class="no-item-found">No record found</div>
        </ng-container>
    
        <mat-paginator
        appPagination
        showFirstLastButtons
        [length]="totalRows"
        [pageIndex]="currentPage"
        [pageSize]="pageSize"
        [hidden]="showNodata"
        (page)="pageChanged($event)"
        class="custom-paginator"
        [hidePageSize]="true"
        >
        </mat-paginator>
    </div>
</div>
<div class="action-toaster" *ngIf="showToastermessage">
    <div class="toaster-msg alert alert-success" *ngIf="showfinalSuccessMsg">
        {{ showfinalSuccessMsg }}
    </div>
</div>
<div class="action-toaster" *ngIf="showToastermessage">
    <div class="toaster-msg alert alert-danger" *ngIf="showfinalErrorMsg">
        {{ showfinalErrorMsg }}
    </div>
</div>
<ngx-spinner></ngx-spinner>