import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { HttpMethod } from "../constants/http-handlers";
import { ApiService } from "./api.service";
import { AppService } from "./app.service";
import { BehaviorSubject, Observable } from "rxjs";
import { Validators } from "@angular/forms";
declare let moment: any; //declare moment
import { Router } from "@angular/router";
import { DatePipe, formatDate } from "@angular/common";

@Injectable({
  providedIn: "root",
})
export class CommonService {
  private messageSource = new BehaviorSubject<string>("");
  globalSearchMessage = this.messageSource.asObservable();

  private filterMessage = new BehaviorSubject<any>({});
  filterObject = this.filterMessage.asObservable();

  private guruListStatus = new BehaviorSubject<any>("all");
  globalguruListStatus = this.guruListStatus.asObservable();

  private selectedUseroleId = new BehaviorSubject<string>("");
  globalselectedUseroleId = this.selectedUseroleId.asObservable();

  public logindetails: BehaviorSubject<object> = new BehaviorSubject<object>(
    {}
  );
  //who ever want value of this strema they access
  loginHolder: Observable<object> = this.logindetails.asObservable();

  // multiLoggedin = new BehaviorSubject<boolean>(false);
  // multiLoggedin$ = this.multiLoggedin.asObservable();
  isMultiLogin: boolean = false;

  //filter objects
  filterPartsFitted = [
    {
      name: "0-1000",
      checked: false,
    },
    {
      name: "1001-2000",
      checked: false,
    },
    {
      name: "2001-3000",
      checked: false,
    },
    {
      name: "3001-4000",
      checked: false,
    },
    {
      name: "Above 5000",
      checked: false,
    },
  ];

  filterAssociatedSince = [
    {
      name: "1 Week (0-7 Days)",
      apiParamName: "1w",
      checked: false,
    },
    {
      name: "Month (8-30 Days)",
      apiParamName: "1m",
      checked: false,
    },
    {
      name: "Quarter (31-90 Days)",
      apiParamName: "3m",
      checked: false,
    },
    {
      name: "6 Months (91-180 Days)",
      apiParamName: "6m",
      checked: false,
    },
    {
      name: "> Year (181-365 Days)",
      apiParamName: "1y",
      checked: false,
    },
    {
      name: "< Year (Above 365 Days)",
      apiParamName: "y",
      checked: false,
    },
  ];

  viewTypeOptions = [
    {
      title: "This Month",
      paramName: "monthly",
    },
    {
      title: "This Quarter",
      paramName: "quarterly",
    },
    {
      title: "This Year",
      paramName: "yearly",
    },
  ];
  viewTypeOptionsTraining = [
    {
      title: "Month",
      paramName: "monthly",
    },
    {
      title: "Quarter",
      paramName: "quarterly",
    },
    {
      title: "Year",
      paramName: "yearly",
    },
  ];

  currentYear = new Date().getFullYear();
  prevYear = this.currentYear - 1;
  // private guruListFilterSource = new BehaviorSubject<string>("");
  // guruListFilterString = this.guruListFilterSource.asObservable();

  constructor(
    private apiService: ApiService,
    private http: HttpClient,
    private appService: AppService,
    private router: Router
  ) {}

  changeMessage(message: string) {
    this.messageSource.next(message);
  }
  changeGurustep(message: string) {
    this.guruListStatus.next(message);
  }
  changeFilterObject(message: any) {
    this.filterMessage.next(message);
  }
  changeselectedUseroleId(message: any) {
    this.selectedUseroleId.next(message);
  }

  assignloginHolder(logincred: object) {
    // debugger;
    // console.log(logincred);
    this.logindetails.next(logincred);
  }

  // common Post Api need to use in all screens
  commonApiCall(obj, callBack, headerContentType = "") {
    this.apiHandler(obj, headerContentType).subscribe(
      (res) => {
        if (res) {
          callBack(res);
        }
      },
      (error) => {
        //debugger;
        if (error.status === 401) {
          this.isMultiLogin = true;
          setTimeout(() => {
            localStorage.clear();
            this.isMultiLogin = false;
            this.router.navigate(["login"]);
          }, 5000);
        }
        callBack(error, error);
      }
    );
  }

  apiHandler(obj, headerContentType = "") {
    switch (obj.methodType) {
      case HttpMethod.POST:
        return this.apiService.postHandler(
          obj.url,
          obj.requestObj,
          headerContentType
        );

      case HttpMethod.PUT:
        return this.apiService.putHandler(obj.url, obj.requestObj);
      case HttpMethod.DELETE:
        return this.apiService.deleteHandler(obj.url);
      case HttpMethod.GET:
        return this.apiService.getHandler(obj.url);
      default:
        return null;
    }
  }
  // getDateRangObject(
  //   sinceDate: string,
  //   endDate: any,
  //   dtFormat = "DD-MM-YYYY"
  // ) {
  //   if (endDate == "") {
  //     // endDate = moment().format(dtFormat);
  //     endDate = new Date();
  //     endDate=formatDate(endDate, 'dd-MM-yyyy', 'en-GB')

  //   } else {
  //     // endDate = moment(endDate, dtFormat).format(dtFormat);
  //     endDate=formatDate(endDate, 'dd-MM-yyyy', 'en-GB')
  //   }
  //   let startDate: any = "";

  //   switch (sinceDate.toLowerCase()) {
  //     case "1 week": {
  //       startDate = moment(endDate, dtFormat)
  //         //  startDate=formatDate(endDate, 'dd-MM-yyyy', 'en-GB')
  //         .subtract(7, "days")
  //         .format(dtFormat);
  //       break;
  //     }
  //     case "weekly": {
  //       startDate = moment(endDate, dtFormat)
  //         .subtract(7, "days")
  //         .format(dtFormat);
  //       break;
  //     }
  //     case "month": {
  //       startDate = moment(endDate, dtFormat)
  //         .subtract(1, "month")
  //         .format(dtFormat);
  //       break;
  //     }
  //     case "this month": {
  //       startDate = moment(endDate, dtFormat)
  //         .subtract(1, "month")
  //         .format(dtFormat);
  //       break;
  //     }
  //     case "monthly": {
  //       startDate = moment(endDate, dtFormat)
  //         .subtract(1, "month")
  //         .format(dtFormat);
  //       break;
  //     }
  //     case "quarter": {
  //       startDate = moment(endDate, dtFormat)
  //         .subtract(3, "months")
  //         .format(dtFormat);
  //       break;
  //     }
  //     case "this quarter": {
  //       startDate = moment(endDate, dtFormat)
  //         .subtract(3, "months")
  //         .format(dtFormat);
  //       break;
  //     }
  //     case "quarterly": {
  //       startDate = moment(endDate, dtFormat)
  //         .subtract(3, "months")
  //         .format(dtFormat);
  //       break;
  //     }
  //     case "6 months": {
  //       startDate = moment(endDate, dtFormat)
  //         .subtract(6, "months")
  //         .format(dtFormat);
  //       break;
  //     }
  //     case "last 6 months": {
  //       startDate = moment(endDate, dtFormat)
  //         .subtract(6, "months")
  //         .format(dtFormat);
  //       break;
  //     }
  //     case "year": {
  //       startDate = moment(endDate, dtFormat)
  //         .subtract(12, "months")
  //         .format(dtFormat);
  //       break;
  //     }
  //     case "last year": {
  //       startDate = moment(endDate, dtFormat)
  //         .subtract(12, "months")
  //         .format(dtFormat);
  //       break;
  //     }
  //     case "yearly": {
  //       startDate = moment(endDate, dtFormat)
  //         .subtract(12, "months")
  //         .format(dtFormat);
  //       break;
  //     }
  //     default: {
  //       startDate = "";
  //     }
  //   }

  //   let dt = {
  //     from: startDate,
  //     to: endDate,
  //   };
  //   return dt;
  // }
  getDateRangObject(
    sinceDate: string,
    endDate: any,
    dtFormat = "dd-MM-yyyy"
  ) {
    if (endDate == "") {
      endDate = new Date();      
      endDate = formatDate(endDate, dtFormat, 'en-GB');
    } else {
      endDate = formatDate(endDate, dtFormat, 'en-GB');
    }
  
    let startDate: any = "";
  
    switch (sinceDate.toLowerCase()) {
      case "1 week": {
        startDate = new Date(endDate);
        startDate.setDate(startDate.getDate() - 7);
        startDate = formatDate(startDate, dtFormat, 'en-GB');
        break;
      }
      case "weekly": {
        startDate = new Date(endDate);
        startDate.setDate(startDate.getDate() - 7);
        startDate = formatDate(startDate, dtFormat, 'en-GB');
        break;
      }
      case "month": {
        startDate = new Date(endDate);
        startDate.setMonth(startDate.getMonth() - 1);
        startDate = formatDate(startDate, dtFormat, 'en-GB');
        break;
      }
      case "this month": {
        startDate = new Date(endDate);
        startDate.setMonth(startDate.getMonth() - 1);
        startDate = formatDate(startDate, dtFormat, 'en-GB');
        break;
      }
      case "monthly": {
              startDate = new Date(endDate);
              startDate.setMonth(startDate.getMonth() - 1);
              startDate = formatDate(startDate, dtFormat, 'en-GB')
              break;
            }
            case "quarter": {
              startDate = new Date(endDate);
              startDate.setMonth(startDate.getMonth() - 3);
              startDate = formatDate(startDate, dtFormat, 'en-GB');
              break;
                  }
     case "this quarter": {
      startDate = new Date(endDate);
      startDate.setMonth(startDate.getMonth() - 3);
      startDate = formatDate(startDate, dtFormat, 'en-GB');
         break;
       }  
       case "quarterly": {
        startDate = new Date(endDate);
        startDate.setMonth(startDate.getMonth() - 3);
        startDate = formatDate(startDate, dtFormat, 'en-GB');
              break;
            }
            case "6 months": {
              startDate = new Date(endDate);
              startDate.setMonth(startDate.getMonth() - 6);
              startDate = formatDate(startDate, dtFormat, 'en-GB');
                   break;
                 }

                 case "last 6 months": {
                  startDate = new Date(endDate);
                  startDate.setMonth(startDate.getMonth() - 6);
                  startDate = formatDate(startDate, dtFormat, 'en-GB');
                       break;
                     }

                     case "year": {
                      startDate = new Date(endDate);
                      startDate.setFullYear(startDate.getFullYear() - 1);
                      startDate = formatDate(startDate, dtFormat, 'en-GB');
                      break;
                         }
                     case "last year": {
                      startDate = new Date(endDate);
                      startDate.setFullYear(startDate.getFullYear() - 1);
                      startDate = formatDate(startDate, dtFormat, 'en-GB');
                      break;
                          }
                      case "yearly": {
                        startDate = new Date(endDate);
                        startDate.setFullYear(startDate.getFullYear() - 1);
                        startDate = formatDate(startDate, dtFormat, 'en-GB');
                        break;
                           }    
      
      default: {
        startDate = "";
      }
    }
  
    let dt = {
      from: startDate,
      to: endDate,
    };
    
  
    return dt;
  }
  async getAsyncRequest(url):Promise<any>{
    return await this.http.get(url, this.getHeaders()).toPromise();
  }

  async postAsyncRequest(url, req):Promise<any>{
    return await this.http.post(url, req, this.getHeaders()).toPromise();
  }

  getHeaders() {
    const token = localStorage.getItem('access_token')
      return  {
        headers: new HttpHeaders({
          'Content-Type': 'application/json;charset=UTF-8',
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + token,
        }),
      };
  }
  
  // getDurationInMonth(sinceDate: any, endDate: any = "") {
  //   let dtFormat = "DD/MM/YYYY";
  //   // sinceDate = '19/09/2021';
  //   let startDate = moment(sinceDate, dtFormat).format("YYYY-MM-DD");

  //   if (endDate != "") {
  //     endDate = moment(endDate, dtFormat).format("YYYY-MM-DD");
  //   } else {
  //     endDate = moment().format("YYYY-MM-DD");
  //   }
  //   let res;
  //   let weekDiff: any;
  //   let diffMonth = moment(endDate).diff(moment(startDate), "months");
  //   res = diffMonth;

  //   if (diffMonth == 0) {
  //     weekDiff = moment(endDate).diff(moment(startDate), "week");
  //     if (weekDiff == 0) {
  //       weekDiff = 1;
  //     }
  //     res = weekDiff + " Week";
  //   } else {
  //     if (diffMonth == 1) {
  //       res = diffMonth + " Month";
  //     } else {
  //       res = diffMonth + " Months";
  //     }
  //   }
  //   return res;
  // }

  getDurationInMonth(sinceDate: any, endDate: any = "") {
    const dtFormat = "dd/MM/yyyy";
    const datePipe = new DatePipe('en-US');
  
    let startDate = datePipe.transform(sinceDate, dtFormat);
  
    if (endDate !== "") {
      endDate = datePipe.transform(endDate, dtFormat);
    } else {
      endDate = datePipe.transform(new Date(), dtFormat);
    }
  
    let res;
    let weekDiff: any;
    let diffMonth = this.getMonthDifference(new Date(endDate), new Date(startDate));
    res = diffMonth;
  
    if (diffMonth === 0) {
      weekDiff = this.getWeekDifference(new Date(endDate), new Date(startDate));
      if (weekDiff === 0) {
        weekDiff = 1;
      }
      res = weekDiff + " Week";
    } else {
      if (diffMonth === 1) {
        res = diffMonth + " Month";
      } else {
        res = diffMonth + " Months";
      }
    }
    return res;
  }
  
  getMonthDifference(endDate: Date, startDate: Date): number {
    return (endDate.getFullYear() - startDate.getFullYear()) * 12 + endDate.getMonth() - startDate.getMonth();
  }
  
  getWeekDifference(endDate: Date, startDate: Date): number {
    const timeDifference = endDate.getTime() - startDate.getTime();
    return Math.ceil(timeDifference / (1000 * 3600 * 24 * 7));
  }
  getViewType(selectedItem: string) {
    let res = "";
    switch (selectedItem.toLowerCase()) {
      case "this month": {
        res = "monthly";
        break;
      }
      case "this quarter": {
        res = "quarterly";
        break;
      }
      case "last year": {
        res = "yearly";
        break;
      }
      case "month": {
        res = "monthly";
        break;
      }
      case "quarter": {
        res = "quarterly";
        break;
      }
      case "year": {
        res = "yearly";
        break;
      }
      case "monthly": {
        res = "monthly";
        break;
      }
      case "quarterly": {
        res = "quarterly";
        break;
      }
      case "yearly": {
        res = "yearly";
        break;
      }
      default: {
        res = "";
      }
    }
    return res;
  }

  getDateFilterValue(selectedItem: string) {
    let res = "";
    switch (selectedItem.toLowerCase()) {
      case "1 week (0-7 days)": {
        res = "1w";
        break;
      }
      case "month (8-30 days)": {
        res = "1m";
        break;
      }
      case "quarter (31-90 days)": {
        res = "3m";
        break;
      }
      case "6 months (91-180 days)": {
        res = "6m";
        break;
      }
      case "> year (181-365 days)": {
        res = "1y";
        break;
      }
      case "< year (above 365 days)": {
        res = "y";
        break;
      }
      case "1 week": {
        res = "1w";
        break;
      }
      case "month": {
        res = "1m";
        break;
      }
      case "quarter": {
        res = "3m";
        break;
      }
      case "6 months": {
        res = "6m";
        break;
      }
      case "last 6 months": {
        res = "6m";
        break;
      }
      case "year": {
        res = "1y";
        break;
      }
      case "last year": {
        res = "1y";
        break;
      }
      default: {
        res = "";
      }
    }
    return res;
  }
}
