<div class="popup-add-roll">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title1">Invoice Payment List</h4>
    <button type="button" class="close" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div class="mandatory mb-3 d-flex justify-content-between align-items-center">
      <span>All * marked fields are mandatory</span>
      <button type="button" class="btn btn-link p-0" (click)="resetForm()">
        <i class="fa fa-undo" style="color: blue;"></i>
      </button>
    </div>
    <div>
      <h3>Job card: {{jobIds}}</h3>
    </div>
    <form [formGroup]="invoicePaymentForm" (ngSubmit)="saveForm()">
      <div class="form-group">
        <div class="row">
          <div class="form-group col-6">
            <label><strong>Payment Type<span class="mandatory">*</span></strong></label>
            <select class="form-control" formControlName="payment_method" required style="margin-top: 5px;">
              <option value="" disabled selected>Select Payment Type</option>
              <option value="DD">DD</option>
              <option value="Cheque">Cheque</option>
              <option value="Cash Payment">Cash</option>
              <option value="Online Transfer">Online Transfer</option>
              <option value="Returned">Returned</option>
              <option value="Mintifi">Mintifi</option>
            </select>
            <div class="invalid-feedback" *ngIf="formSubmitted && this.invoicePaymentForm.controls.payment_method.errors?.required">
              <div>Please Select Payment Type</div>
            </div>
          </div>
          <div class="col-6 form-group">
            <label for="dateOfBirth"><strong>Transaction Date<span class="mandatory">*</span></strong></label>
            <div class="input-group">
              <input class="form-control" placeholder="Select Transaction date" name="dp" formControlName="trans_date" ngbDatepicker [minDate]="minDate" [maxDate]="maxDate" #d="ngbDatepicker">
              <button class="input-group-addon" (click)="d.toggle()" type="button" style="margin-top: 5px;">
                <i class="fa fa-calendar"></i>
              </button>
            </div>
            <div class="invalid-feedback" *ngIf="formSubmitted && this.invoicePaymentForm.controls.trans_date.errors?.required">
              <div>Please Select Transaction Date</div>
            </div>
          </div>
          <div class="form-group col-6">
            <label><strong>Bank Name</strong></label>
            <div class="input-group">
              <input class="form-control" style="width: 100%;" formControlName="bank_name" class="textfield" placeholder="Bank Name" maxlength="20"/>
            </div>
            <!-- <div class="invalid-feedback" *ngIf="formSubmitted && this.invoicePaymentForm.controls.bank_name.errors?.required">
              <div>Please Enter Bank Name</div>
            </div> -->
          </div>
          <div class="form-group col-6">
            <label><strong>Branch Name </strong></label>
            <div class="input-group">
              <input type="text" style="width: 100%;" class="form-control" formControlName="branch_name" class="textfield" placeholder="Branch Name" maxlength="20"/>
            </div>
            <!-- <div class="invalid-feedback" *ngIf="formSubmitted && this.invoicePaymentForm.controls.branch_name.errors?.required">
              <div>Please Enter Branch Name</div>
            </div> -->
          </div>
        </div>
        <div class="row">
          <div class="form-group col-6">
            <label><strong>Reference Number </strong></label>
            <div class="input-group">
              <input style="width: 100%;" class="form-control" maxlength="20" formControlName="reference_number" class="textfield" placeholder="Reference Number" />
            </div>
            <!-- <div class="invalid-feedback" *ngIf="formSubmitted && this.invoicePaymentForm.controls.reference_number.errors?.required">
              <div>Please Enter Reference Number</div>
            </div> -->
          </div>
          <div class="form-group col-6">
            <label><strong>Invoice Paid Amount<span class="mandatory">*</span></strong></label>
            <div class="input-group">
              <input style="width: 100%; background-color: #efefef; pointer-events: none; cursor: not-allowed;" type="text" class="form-control" formControlName="invoice_paid_amount" class="textfield" placeholder="Invoice Paid Amount" />
            </div>
            <!-- <div class="invalid-feedback" *ngIf="formSubmitted && this.invoicePaymentForm.controls.invoice_paid_amount.errors?.required">
              <div>Please Enter Invoice Paid Amount</div>
            </div> -->
          </div>
        </div>
        <div class="row">
          <div class="form-group col-12">
            <label><strong>Remark<span class="mandatory">*</span></strong></label>
            <textarea rows="2" placeholder="Enter Remark" maxlength="100" class="form-control" formControlName="remark" required></textarea>
            <div class="invalid-feedback" *ngIf="formSubmitted && this.invoicePaymentForm.controls.remark.errors?.required">
              <div>Please Enter Remark</div>
            </div>
            <div class="form-text">Maximum 100 Characters Can Be Entered</div>
          </div>
        </div>
      </div>
    </form>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="closeModal()">Cancel</button>
    <button type="button" class="btn btn-success" (click)="saveForm()">Save</button>
  </div>
</div>
