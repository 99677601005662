import { Component, OnInit } from '@angular/core';
import { Sort } from "@angular/material/sort";
import { targetCompetitor } from "./target-vs-competitor";
import { TableColumn } from "src/app/shared/guru-table/TableColumn";

@Component({
  selector: 'app-target-vs-competitor',
  templateUrl: './target-vs-competitor.component.html',
  styleUrls: ['./target-vs-competitor.component.scss']
})
export class TargetVsCompetitorComponent implements OnInit {

  constructor() { }
  vechicleSummarydata: any = [
    {
      part: "Crown Wheel",
      partno:"123456789",
      allfitment: 1200,
      tgpfitment: 1100,
      brandImg: "../../../../assets/images/parts/crownwheel.svg",
    },
    {
      part: "Clutch Plate",
      partno:"123456789",
      allfitment: 1100,
      tgpfitment: 1000,
      brandImg: "../../../../assets/images/parts/clutchplate.svg",
    },
    {
      part: "Oil Filter",
      partno:"123456789",
      allfitment: 1000,
      tgpfitment: 900,
      brandImg: "../../../../assets/images/parts/oilfilter.svg",
    },
    {
      part: "Disc Brake",
      partno:"123456789",
      allfitment: 900,
      tgpfitment: 800,
      brandImg: "../../../../assets/images/parts/discbrake.svg",
    },
  ];
  orders: targetCompetitor[];
  ordersTableColumns: TableColumn[];

  ngOnInit(): void {
    this.initializeColumns();
    this.orders = this.getOrders();
  }

  sortData(sortParameters: Sort) {
    const keyName = sortParameters.active;
    if (sortParameters.direction === "asc") {
      this.orders = this.orders.sort(
        (a: targetCompetitor, b: targetCompetitor) =>
          a[keyName].localeCompare(b[keyName])
      );
      return "";
    } else if (sortParameters.direction === "desc") {
      this.orders = this.orders.sort(
        (a: targetCompetitor, b: targetCompetitor) =>
          b[keyName].localeCompare(a[keyName])
      );
      return "";
    } else {
      return (this.orders = this.getOrders());
    }
  }

  initializeColumns(): void {
    this.ordersTableColumns = [
      {
        name: "Parts Fitted",
        dataKey: "partsFitted",
        position: "center",
        isSortable: true,
      },
      {
        name: "Guru/Garage",
        dataKey: "guru",
        position: "center",
        isSortable: true,
      },
      {
        name: "Area",
        dataKey: "area",
        position: "center",
        isSortable: true,
      },
      {
        name: "Contact",
        dataKey: "contact",
        position: "center",
        isSortable: false,
      },
      {
        name: "Job Card",
        dataKey: "jobCardId",
        position: "center",
        isSortable: true,
      },
      {
        name: "Fitment Date",
        dataKey: "fitmentDate",
        position: "center",
        isSortable: true,
      },
    ];
  }

  getOrders(): any[] {
    return [
      {
        partsFitted:
          'Clutch Plate<span class="small-font">TVS</span>',
        guru: '<a class="guru-name">Balu Singh</a><span class="small-font">Khan Garage</span>',
        contact: '9876543210',
        jobCardId: 1122334456,
        area: 'Balewadi<span class="small-font">74 Kms</span>',
        fitmentDate: "03/06/2020",
      },
      {
        partsFitted:
          'Clutch Plate<span class="small-font">TVS</span>',
        guru: '<a class="guru-name">Balu Singh</a><span class="small-font">Khan Garage</span>',
        contact: '9876543210',
        jobCardId: 1122334456,
        area: 'Balewadi<span class="small-font">74 Kms</span>',
        fitmentDate: "03/06/2020",
      },
      {
        partsFitted:
          'Clutch Plate<span class="small-font">TVS</span>',
        guru: '<a class="guru-name">Balu Singh</a><span class="small-font">Khan Garage</span>',
        contact: '9876543210',
        jobCardId: 1122334456,
        area: 'Balewadi<span class="small-font">74 Kms</span>',
        fitmentDate: "03/06/2020",
      },
      {
        partsFitted:
          'Clutch Plate<span class="small-font">TVS</span>',
        guru: '<a class="guru-name">Balu Singh</a><span class="small-font">Khan Garage</span>',
        contact: '9876543210',
        jobCardId: 1122334456,
        area: 'Balewadi<span class="small-font">74 Kms</span>',
        fitmentDate: "03/06/2020",
      },
      {
        partsFitted:
          'Clutch Plate<span class="small-font">TVS</span>',
        guru: '<a class="guru-name">Balu Singh</a><span class="small-font">Khan Garage</span>',
        contact: '9876543210',
        jobCardId: 1122334456,
        area: 'Balewadi<span class="small-font">74 Kms</span>',
        fitmentDate: "03/06/2020",
      },
      {
        partsFitted:
          'Clutch Plate<span class="small-font">TVS</span>',
        guru: '<a class="guru-name">Balu Singh</a><span class="small-font">Khan Garage</span>',
        contact: '9876543210',
        jobCardId: 1122334456,
        area: 'Balewadi<span class="small-font">74 Kms</span>',
        fitmentDate: "03/06/2020",
      },
      
    ];
  }
}
