import { HttpClient } from "@angular/common/http";
import { Component, OnInit, ViewChild } from "@angular/core";
import { FormGroup, FormControl } from "@angular/forms";
import { AppService } from "src/app/services/app.service";
import { CommonService } from "src/app/services/common.service";
import { HttpMethod } from "src/app/constants/http-handlers";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTable, MatTableDataSource } from "@angular/material/table";
import { MSRListTableItem } from "src/app/models/apm";

@Component({
  selector: "app-part-summary",
  templateUrl: "./part-summary.component.html",
  styleUrls: ["./part-summary.component.scss"],
})
export class PartSummaryComponent implements OnInit {
  step: string;
  showDropdown: boolean = false;
  selected: string = "monthly";
  selectedItem: string = "This Month";
  campaignOne: FormGroup;
  campaignTwo: FormGroup;
  showDropdownOptions: any;

  globalSearchText: string;
  pgIndex: number = 0;
  filterObject: any = [];
  brandType: any = "tgp";

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatTable) table!: MatTable<MSRListTableItem>;
  dataSource = new MatTableDataSource<MSRListTableItem>();

  isLoading = false;
  currentPage = 0;
  showNodata: boolean = false;

  select(index: number) {
    this.selected = this.commonService.viewTypeOptions[index].paramName;
    this.selectedItem = this.commonService.viewTypeOptions[index].title;
    this.showDropdown = false;
  }

  selectValfun() {
    this.showDropdown = !this.showDropdown;
  }
  isActive(item) {
    return this.selected === item;
  }

  onBrandTypeChange(val) {
    this.brandType = val;
  }
  constructor(
    private http: HttpClient,
    private appService: AppService,
    public commonService: CommonService
  ) {
    this.step = "partsfitment";
    this.showDropdownOptions = [
      "This Month",
      "This Quarter",
      "Last 6 Months",
      "Last Year",
      "Custom Period",
    ];
    const today = new Date();
    const month = today.getMonth();
    const year = today.getFullYear();

    this.campaignOne = new FormGroup({
      start: new FormControl(new Date(year, month, 13)),
      end: new FormControl(new Date(year, month, 16)),
    });

    this.campaignTwo = new FormGroup({
      start: new FormControl(new Date(year, month, 15)),
      end: new FormControl(new Date(year, month, 19)),
    });
  }

  ngOnInit(): void {
    // alert(this.campaignTwo.value.start.value.start);
  }

  // dateRangeChange(){
  //   alert("date changes");
  // }
}
