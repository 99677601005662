<div class="comn-box">
  <div class="main-profile-container">
    <div class="profile-container">
      <div class="profile-display-container">
        <div class="tb-status">
          <i [class]="'profile-status ' + recDetails?.status"></i><span>{{ recDetails?.status | titlecase }}</span>
        </div>
        <div class="guru-profile-image">
          <img [src]="
              recDetails?.profile_image
                ? recDetails?.profile_image
                : './assets/images/demo-img.png'
            " />
        </div>

        <div class="profile-text-wrap">
          <h3>{{ recDetails.guru_username }}</h3>
          <div [class]="recDetails?.rating_class">
            <i class="star"></i>
            <i class="star"></i>
            <i class="star"></i>
            <i class="star"></i>
            <i class="star"></i>
          </div>
          <div class="tb-phone">
            <i class="phone-icon"></i>
            <a [href]="'tel:' + recDetails?.guru_contact_number">
              <span>{{ recDetails?.guru_contact_number }}</span>
            </a>
          </div>
          <!-- <a class="link-button">Update Profile</a>
          <a class="link-button view-reg">View Registration Details</a> -->
        </div>
      </div>
    </div>

    <div class="data-container">
      <div class="data-tab-wrapper">
        <div class="bandhu-tabs tb-temp-hide">
          <mat-button-toggle-group name="fontStyle" value="All" aria-label="Font Style">
            <mat-button-toggle value="All">All</mat-button-toggle>
            <mat-button-toggle value="TGP">TGP</mat-button-toggle>
            <mat-button-toggle value="NonTGP">Non TGP</mat-button-toggle>
          </mat-button-toggle-group>
        </div>
        <div class="guru-item-action">
          <ng-container *ngIf="recDetails.status == 'Pending' && role === 'Distributor'">
            <a class="link-button approve" (click)="openDialog('Approve')">Approve</a>
            <a class="link-button reject" (click)="openDialog('Reject')">Reject</a>
          </ng-container>

          <ng-container *ngIf="
              recDetails.status != 'Pending' && recDetails.status != 'Rejected'
            ">
            <a class="link-button" (click)="openDialog('Delete')">Delete</a>
          </ng-container>
        </div>
      </div>
      <div class="coupon-box-wrap" *ngIf="
          recDetails.status !== 'Pending' && recDetails.status !== 'Rejected'
        ">
        <div class="profile-box">
          <h2>{{ recDetails?.parts_fitted }} <small> Parts Fitted</small></h2>
        </div>
        <!-- <div class="profile-box">
          <h2>2432 <small> Points Earned</small></h2>
        </div>
        <div class="profile-box">
          <h2>2432 <small> Points Redeemed</small></h2>
        </div>
        <div class="profile-box">
          <h2>698 <small> Point Balanced</small></h2>
        </div> -->
        <div class="profile-box">
          <h2>{{ recDetails?.job_cards }} <small> Job Cards Created</small></h2>
        </div>
      </div>

      <h5>User Information</h5>
      <div class="coupon-box-wrap">
        <div class="user-information-box">
          <div>
            <h4>
              User ID: <small> {{ recDetails?.guru_user_id }}</small>
            </h4>
          </div>
          <b class="seprator"></b>
          <div>
            <h4>
              Garage Name: <small> {{ recDetails?.garage_name }}</small>
            </h4>
          </div>
          <b class="seprator"></b>
          <div>
            <h4>
              Garage ID: <small> {{ recDetails?.garage_id }}</small>
            </h4>
          </div>
          <b class="seprator"></b>
          <div>
            <h4>
              Garage Address:
              <small> {{ recDetails?.garage_address }}</small>
            </h4>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="guru-info-sec">
  <div class="bandhu-tabs">
    <mat-button-toggle-group name="fontStyle" value="PersonalDetails" aria-label="Font Style">
      <mat-button-toggle value="PersonalDetails" (click)="getGuruItemDetail('PersonalDetails')">Personal Details
      </mat-button-toggle>
      <mat-button-toggle value="WorkDetails" (click)="getGuruItemDetail('WorkDetails')">Work Details</mat-button-toggle>
    </mat-button-toggle-group>
  </div>
  <div class="user-information-box PersonalDetailsec" *ngIf="guruDetail == 'PersonalDetails'">
    <div class="guru-detail-sec PersonalDetails">
      <div class="guru-detail-item">
        <h4>
          <span>Guru Name</span> :
          <small>
            {{
              recDetails?.personal_details.first_name +
                " " +
                recDetails?.personal_details.last_name
            }}</small>
        </h4>
      </div>
      <div class="guru-detail-item">
        <h4>
          <span>Registration Date </span> :
          <small>{{
            recDetails?.personal_details.registration_date | date
          }}</small>
        </h4>
      </div>

      <div class="guru-detail-item">
        <h4>
          <span> Date Of Birth</span> :
          <small>{{ recDetails?.personal_details.dob }}</small>
        </h4>
      </div>
      <div class="guru-detail-item">
        <h4>
          <span>Address </span> :
          <small>{{ recDetails?.personal_details.house_address }} </small>
        </h4>
      </div>
      <div class="guru-detail-item">
        <h4>
          <span>City </span> :
          <small>{{ recDetails?.personal_details.city }} </small>
        </h4>
      </div>
      <div class="guru-detail-item">
        <h4>
          <span>Pin Code</span> :
          <small>{{ recDetails?.personal_details.pincode }}</small>
        </h4>
      </div>
      <div class="guru-detail-item">
        <h4>
          <span>state</span> :
          <small>{{ recDetails?.personal_details.state }}</small>
        </h4>
      </div>
      <div class="guru-detail-item">
        <h4>
          <span>Email Id</span> :
          <small>{{ recDetails?.personal_details.email_id }}</small>
        </h4>
      </div>

      <div class="guru-detail-item">
        <h4>
          <span>Marital</span> :
          <small>{{ recDetails?.personal_details.marital_status }}</small>
        </h4>
      </div>
      <div class="guru-detail-item">
        <h4>
          <span>Education</span> :
          <small>{{ recDetails?.personal_details.education }}</small>
        </h4>
      </div>
      <div class="guru-detail-item">
        <h4>
          <span>ID Proof</span> :
          <small>{{ recDetails?.personal_details.identity_proof }}</small>
        </h4>
      </div>
      <div class="guru-detail-item">
        <h4>
          <span>ID number</span> :
          <small>{{ recDetails?.personal_details.id_number }}</small>
        </h4>
      </div>
    </div>

    <div class="upload-docs">
      <h3>Upload Documents</h3>
      <div class="guru-detail-sec">
        <div class="guru-detail-item" *ngIf="guruId">
          <h4>
            <span>Guru’s ID Card</span> :
            <small>
              <a [href]="guruId" target="_blank" class="link-button">Click here to view</a>
            </small>
          </h4>
        </div>
        <div class="guru-detail-item" *ngIf="guruRegForm">
          <h4>
            <span> Registration Form</span> :
            <small>
              <a [href]="guruRegForm" target="_blank" class="link-button">Click here to view</a>
            </small>
          </h4>
        </div>
        <div class="guru-detail-item" *ngIf="guruOtherDocs">
          <h4>
            <span>Other Documents</span> :
            <small>
              <a [href]="guruOtherDocs" class="link-button" target="_blank">Click here to view
              </a>
            </small>
          </h4>
        </div>
      </div>
    </div>
  </div>

  <div class="user-information-box guru-detail-sec workdetail" *ngIf="guruDetail == 'WorkDetails'">
    <div class="guru-detail-item">
      <h4>
        <span>Garage Name</span> :
        <small>{{ recDetails?.work_details.garage_name }}</small>
      </h4>
    </div>

    <div class="guru-detail-item">
      <h4>
        <span> Garage Owner’s Name</span> :
        <small>{{ recDetails?.work_details.garage_owner_name }}</small>
      </h4>
    </div>
    <div class="guru-detail-item">
      <h4>
        <span>Business Incorporation Date </span> :
        <small>{{
          recDetails?.work_details.business_incorporation_date
        }}</small>
      </h4>
    </div>
    <div class="guru-detail-item">
      <h4>
        <span> GST Number</span> :
        <small>{{ recDetails?.work_details.gst_number }}</small>
      </h4>
    </div>
    <div class="guru-detail-item">
      <h4>
        <span>Garage Address </span> :
        <small>{{ recDetails?.work_details.garage_address }} </small>
      </h4>
    </div>
    <div class="guru-detail-item">
      <h4>
        <span>City </span> :
        <small>{{ recDetails?.work_details.city }} </small>
      </h4>
    </div>
    <div class="guru-detail-item">
      <h4>
        <span>Pin Code</span> :
        <small>{{ recDetails?.work_details.pincode }}</small>
      </h4>
    </div>
    <div class="guru-detail-item">
      <h4>
        <span>state</span> :
        <small>{{ recDetails?.work_details.state }}</small>
      </h4>
    </div>

    <div class="guru-detail-item">
      <h4>
        <span>Country</span> :
        <small>{{ recDetails?.work_details.country }}</small>
      </h4>
    </div>
    <div class="guru-detail-item">
      <h4>
        <span>Vehicle Make</span> :
        <small>{{ recDetails?.work_details.vehicle_make }}</small>
      </h4>
    </div>
    <div class="guru-detail-item">
      <h4>
        <span>Vehicle Model Type Service</span> :
        <small>{{ recDetails?.work_details.vehicle_model }}</small>
      </h4>
    </div>
    <div class="guru-detail-item">
      <h4>
        <span>Major Area Work</span> :
        <small>{{ recDetails?.work_details.major_area_work }}</small>
      </h4>
    </div>
  </div>
</div>