<h2 class="text-capitalize">Add Part Item</h2>
<div class="guru-tabs-row">
  <div class="bandhu-tabs">
    <mat-button-toggle-group
      name="fontStyle"
      value="promoted"
      aria-label="Font Style"
    >
      <mat-button-toggle value="promoted" (click)="setItemType('promotional')"
        >Promoted</mat-button-toggle
      >
      <mat-button-toggle value="introduced" (click)="setItemType('new')"
        >Introduced</mat-button-toggle
      >
    </mat-button-toggle-group>
  </div>
  <!-- <form class="example-form tb-searchbox">
    <input
      class="tb-search-text"
      type="text"
      appOnlyAlphaNumeric
      placeholder="Search by Part number"
      [formControl]="control"
      [matAutocomplete]="auto"
      [(ngModel)]="searchText"
      (keyup)="checkText($event)"
    />
    <button class="tb-button guru-flex">
      <div class="tb__search_icon"></div>
    </button>

    <mat-autocomplete #auto="matAutocomplete">
      <mat-option
        *ngFor="let street of filteredStreets | async"
        [value]="street"
      >
        {{ street }}
      </mat-option>
    </mat-autocomplete>
  </form> -->

  <div class="autocomplete-wrapper">
    <div class="example-full-width tb-searchbox">
      <input
        placeholder="Search Movie"
        class="tb-search-text form-control"
        [(ngModel)]="selectedMovie"
        [matAutocomplete]="auto"
        [formControl]="searchMoviesCtrl"
        placeholder=" Add Promotion / New Parts by Item number"
        (keypress)="keyPressNumbers($event)"
        (keyup)="checkText($event)"
      />
      <!-- <button
        *ngIf="selectedMovie"
        matSuffix
        mat-icon-button
        aria-label="Clear"
        (click)="clearSelection()"
        class="close-icon"
      >
        <mat-icon>close</mat-icon>
      </button> -->
      <mat-autocomplete
        [panelWidth]="400"
        #auto="matAutocomplete"
        (optionSelected)="onSelected()"
        [displayWith]="displayWith"
      >
        <mat-option *ngIf="isLoading" class="is-loading">Loading...</mat-option>
        <ng-container *ngIf="!isLoading">
          <mat-option *ngFor="let movie of filteredMovies" [value]="movie">
            <span
              ><b>{{ movie.part_number }}</b> ({{ movie.part_desc }})</span
            >
          </mat-option>
        </ng-container>
      </mat-autocomplete>
    </div>
    <div class="form-label">Please enter minimum 4 digit</div>
  </div>
</div>
<div class="comn-box add-part-item" *ngIf="selectedMovie.image">
  <div class="part-details">
    <div class="part-item-img">
      <div class="form-label">Part Image</div>
      <div class="part-img">
        <img [src]="selectedMovie.image" alt="" />
      </div>
    </div>
    <div class="part-detail">
      <div class="part-row part-name">
        <span class="form-label">Part Name</span>
        <span class="part-val"> {{ selectedMovie.part_desc }}</span>
      </div>
      <div class="part-row">
        <span class="form-label">Part Number</span>
        <span class="part-val"> {{ selectedMovie.part_number }}</span>
      </div>
      <div class="part-row">
        <span class="form-label">Part Dimensions</span>
        <span class="part-val"> {{ selectedMovie.product_dimensions }}</span>
      </div>
      <div class="part-row">
        <span class="form-label">Part Price</span>
        <span class="part-val">Rs {{ selectedMovie.umrp }}</span>
      </div>
      <div class="part-row">
        <span class="form-label">Part Points</span>
        <span class="part-val"> {{ selectedMovie.part_points }}</span>
      </div>
      <div class="part-row">
        <span class="form-label">Part Type</span>
        <span class="part-val"> {{ step }}</span>
      </div>
    </div>
  </div>
  <div class="part-show-details">
    <form
      [formGroup]="partDetailForm"
      (ngSubmit)="getPartlData()"
      autocomplete="off"
    >
      <div class="form-row">
        <div class="form-col">
          <div class="form-label">
            Please select Date Range <span class="requiredfield">*</span>
          </div>

          <mat-form-field
            appearance="outline"
            floatLabel="always"
            class="form-date"
          >
            <mat-date-range-input [rangePicker]="picker" [min]="today">
              <input
                matStartDate
                placeholder="Start date"
                formControlName="startDate"
                readonly
              />
              <input
                matEndDate
                placeholder="End date"
                formControlName="endDate"
                readonly
              />
            </mat-date-range-input>
            <mat-datepicker-toggle
              matSuffix
              [for]="picker"
            ></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
          </mat-form-field>

          <div *ngIf="isSubmitted && ( partDetailForm.get('startDate').errors || partDetailForm.get('endDate').errors)" class="invalid-field">
            Please select proper Date Range
          </div>
        </div>
      </div>

      <div class="form-row">
        <div class="form-col">
          <label class="form-label" for="description">Part Description</label>
          <textarea
            type="text"
            class="form-control part-desc-textarea"
            id="description"
            formControlName="description"
            placeholder="Enter Description"
          >
          </textarea>
          <div *ngIf="isSubmitted && this.partDetailForm.get('description').errors" class="invalid-field">
            <span *ngIf="this.partDetailForm.get('description').errors.pattern">
              Special characters are not allowed
            </span>
          </div>
        </div>
      </div>
      <div class="submitPartlDataBtn">
        <button mat-stroked-button class="primary-flat-button">Submit</button>
      </div>
    </form>
  </div>
</div>
<div class="action-toaster" *ngIf="showToastermessage">
  <div class="toaster-msg alert alert-success" *ngIf="showfinalSuccessMsg">
    {{ showfinalSuccessMsg }}
  </div>
  <div class="toaster-msg alert alert-danger" *ngIf="showfinalErrorMsg">
    {{ showfinalErrorMsg }}
  </div>
</div>
<app-added-part-list
  [refreshTableItems]="partItemUpdate"
  [partType]="step"
></app-added-part-list>
<ngx-spinner></ngx-spinner>
