import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { GoogleMapsModule } from '@angular/google-maps'
import { HttpClientModule } from "@angular/common/http";
import { AppMaterialModule } from "./app-material/app-material.module";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgIdleKeepaliveModule } from "@ng-idle/keepalive"; // this includes the core NgIdleModule but includes keepalive providers for easy wireup
import { DatePipe, HashLocationStrategy, LocationStrategy } from "@angular/common";
import { HeaderComponent } from "./header/header.component";
import { SubheaderComponent } from "./header/subheader/subheader.component";
import { AddGuruComponent } from "./add-guru/add-guru.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { SelectLocationComponent } from "./add-guru/select-location/select-location.component";

import { MatTableExporterModule } from "mat-table-exporter";
import { ChartsModule } from "ng2-charts";
import { FilterChipComponent } from "./shared/filter-chip/filter-chip.component";
import { GurulistFilterComponent } from "./shared/guru-list-table/gurulist-filter/gurulist-filter.component";

import { CampaignsComponent } from "./shared/campaigns/campaigns.component";
import { CampaignUpcomingComponent } from "./shared/campaigns/campaign-upcoming/campaign-upcoming.component";
import { CampaignOngoingComponent } from "./shared/campaigns/campaign-ongoing/campaign-ongoing.component";
import { GrurkshetraComponent } from "./shared/campaigns/campaign-ongoing/grurkshetra/grurkshetra.component";
import { GrurkshetraSummaryComponent } from "./shared/campaigns/campaign-ongoing/grurkshetra-summary/grurkshetra-summary.component";
import { CampaignLeaderboardComponent } from "./shared/campaigns/campaign-ongoing/campaign-leaderboard/campaign-leaderboard.component";
import { LoginComponent } from "./login/login.component";
import { DataPropertyGetterPipe } from "./shared/guru-table/data-property-getter-pipe/data-property-getter.pipe";
import { GuruTableComponent } from "./shared/guru-table/guru-table.component";
import { PartSummaryComponent } from "./reports/part-summary/part-summary.component";
import { PartsFitmentComponent } from "./reports/part-summary/parts-fitment/parts-fitment.component";
import { JobSummaryComponent } from "./reports/job-summary/job-summary.component";
import { JobCardTGPFitmentComponent } from "./reports/job-summary/job-card-tgp-fitment/job-card-tgp-fitment.component";
import { TmlVehicleDataComponent } from "./reports/job-summary/tml-vehicle-data/tml-vehicle-data.component";
import { TmlVsOtherBrandsComponent } from "./reports/job-summary/tml-vs-other-brands/tml-vs-other-brands.component";
import { TargetVsAchievedComponent } from "./reports/part-summary/target-vs-achieved/target-vs-achieved.component";
import { TargetVsCompetitorComponent } from "./reports/part-summary/target-vs-competitor/target-vs-competitor.component";
import { OtherReportsComponent } from "./reports/other-reports/other-reports.component";
// import { DragDirective } from "./directives/dragDrop.directive";
import { PointsSummaryComponent } from "./loyalty-program/points-summary/points-summary.component";
import { EarnHistoryComponent } from "./loyalty-program/points-summary/earn-history/earn-history.component";
import { RedemptionHistoryComponent } from "./loyalty-program/points-summary/redemption-history/redemption-history.component";
import { CouponHistoryComponent } from "./loyalty-program/coupon-history/coupon-history.component";
import { GiftCatalogueComponent } from "./loyalty-program/gift-catalogue/gift-catalogue.component";

import { MyTargetsComponent } from "./account/my-targets/my-targets.component";
import { CampaignPastComponent } from "./shared/campaigns/campaign-past/campaign-past.component";
import { PartFitmentSummaryComponent } from "./account/my-targets/part-fitment-summary/part-fitment-summary.component";
import { MyProfileComponent } from "./account/my-profile/my-profile.component";
import { OtpGenerateComponent } from "./account/my-profile/otp-generate/otp-generate.component";
import { ChangePasswordComponent } from "./account/change-password/change-password.component";
import { PointsEarnedComponent } from "./account/my-targets/points-earned/points-earned.component";
import { MyVisitComponent } from "./account/my-visit/my-visit.component";
import { SharedModule } from "./shared/shared.module";
import { ConfirmDialogComponent } from "./shared/guru-detail/confirm-dialog/confirm-dialog.component";
import { GuruDetailComponent } from "./shared/guru-detail/guru-detail.component";
import { GuruInfoDetailComponent } from "./shared/guru-detail/guru-info-detail/guru-info-detail.component";
import { GuruInfoTableComponent } from "./shared/guru-detail/guru-info-table/guru-info-table.component";
import { SelectLocationV2Component } from "./add-guru/select-location-v2/select-location-v2.component";
import { AgmCoreModule } from "@agm/core";
import { CommonListModule } from "./common-list/common-list.module";
import { NgxSpinnerModule } from "ngx-spinner";
import { GuruListTableComponent } from "./shared/guru-list-table/guru-list-table.component";
import { NotificationsComponent } from "./header/notifications/notifications.component";
import { StopSCDirective } from "./directives/stop-sc.directive";
import { OnlyAlphaNumericDirective } from "./directives/only-alpha-numeric.directive";
import { OnlyNumericDirective } from "./directives/only-numeric.directive";
import { CustomMinValidatorDirective } from "./directives/custom-min-validator.directive";
import { DropdownDirective } from "./directives/dropdown.directive";
import { DashboardModule } from "./dashboard/dashboard.module";
import { abs } from "./custom-element/pipe/abs.pipe";
import { ConfirmDialogueComponent } from './add-guru/confirm-dialogue/confirm-dialogue.component';
import { ReportsComponent } from './reports/reports.component';
import { DoorStepBookingReportComponent } from './reports/door-step-booking-report/door-step-booking-report.component';
import { DoorStepBookingFilterComponent } from './reports/door-step-booking-report/door-step-booking-filter/door-step-booking-filter.component';
import { OtpGeneratorComponent } from './otp-generator/otp-generator.component';
import { VehicleBreakdownComponent } from './reports/vehicle-breakdown/vehicle-breakdown.component';
import { MatNativeDateModule, MAT_DATE_LOCALE } from "@angular/material/core";
import { ReferrelReportsComponent } from './reports/referrel-reports/referrel-reports.component';
import { GuruReferrelComponent } from './reports/referrel-reports/guru-referrel/guru-referrel.component';
import { ProspectReferrelComponent } from './reports/referrel-reports/prospect-referrel/prospect-referrel.component';
import { ProlifeEngineReferrelComponent } from './reports/referrel-reports/prolife-engine-referrel/prolife-engine-referrel.component';
import { ReferrelReportFilterComponent } from './reports/referrel-reports/referrel-report-filter/referrel-report-filter.component';
import { JobSummaryFilterComponent } from './reports/job-summary/job-summary-filter/job-summary-filter.component';
import { PartSummaryFilterComponent } from './reports/part-summary/part-summary-filter/part-summary-filter.component';
import { ProblemSubProblemComponent } from './reports/problem-sub-problem/problem-sub-problem.component';
import { ProblemSubProblemFilterComponent } from './reports/problem-sub-problem/problem-sub-problem-filter/problem-sub-problem-filter.component';


@NgModule({
    declarations: [
        AppComponent,
        HeaderComponent,
        SubheaderComponent,
        AddGuruComponent,
        SelectLocationComponent,
        GuruListTableComponent,
        FilterChipComponent,
        GurulistFilterComponent,
        GuruDetailComponent,
        GuruInfoTableComponent,
        CampaignsComponent,
        CampaignUpcomingComponent,
        CampaignOngoingComponent,
        GrurkshetraComponent,
        GrurkshetraSummaryComponent,
        CampaignLeaderboardComponent,
        LoginComponent,
        DataPropertyGetterPipe,
        GuruTableComponent,
        PartSummaryComponent,
        PartsFitmentComponent,
        ReportsComponent,
        JobSummaryComponent,
        JobCardTGPFitmentComponent,
        TmlVehicleDataComponent,
        TmlVsOtherBrandsComponent,
        TargetVsAchievedComponent,
        TargetVsCompetitorComponent,
        OtherReportsComponent,
        // DragDirective,
        PointsSummaryComponent,
        EarnHistoryComponent,
        RedemptionHistoryComponent,
        CouponHistoryComponent,
        GiftCatalogueComponent,
        GuruInfoDetailComponent,
        ConfirmDialogComponent,
        MyTargetsComponent,
        CampaignPastComponent,
        PartFitmentSummaryComponent,
        MyProfileComponent,
        OtpGenerateComponent,
        ChangePasswordComponent,
        PointsEarnedComponent,
        MyVisitComponent,
        SelectLocationV2Component,
        NotificationsComponent,
        StopSCDirective,
        OnlyNumericDirective,
        CustomMinValidatorDirective,
        DropdownDirective,
        abs,
        ConfirmDialogueComponent,
        DoorStepBookingReportComponent,
        DoorStepBookingFilterComponent,
        OtpGeneratorComponent,
        VehicleBreakdownComponent,
        ReferrelReportsComponent,
        GuruReferrelComponent,
        ProspectReferrelComponent,
        ProlifeEngineReferrelComponent,
        ReferrelReportFilterComponent,
        JobSummaryFilterComponent,
        PartSummaryFilterComponent,
        ProblemSubProblemComponent,
        ProblemSubProblemFilterComponent,
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        AppMaterialModule,
        ReactiveFormsModule,
        FormsModule,
        NgbModule,
        MatTableExporterModule,
        ChartsModule,
        CommonListModule,
        SharedModule,
        NgxSpinnerModule,
        DashboardModule,
        NgIdleKeepaliveModule.forRoot(),
        MatNativeDateModule,

        AgmCoreModule.forRoot({
            apiKey: "AIzaSyD0J2PBjOAER6mhk_QPGUbmW9teUxWk9v4",
        }),
       GoogleMapsModule,
    ],
    providers: [{ provide: LocationStrategy, useClass: HashLocationStrategy }, { provide: MAT_DATE_LOCALE, useValue: 'en-GB' }, DatePipe ],
    bootstrap: [AppComponent]
})
export class AppModule {}
