<div class="guru-tabs-row">
  <h2>
    <a
      (click)="backToDistlist()"
      href="javascript:void(0)"
      class="backToGurulist"
      >Distributor</a
    >
    <span class="subhead">{{ recDetails.dist_name }}</span>
  </h2>
  <div class="tb-select-dropdown">
    <div class="tb-selectedVal" (click)="selectValfun()">
      {{ selectedItem }}
    </div>
    <ul class="tb-dropdown-options" *ngIf="showDropdown">
      <li
        *ngFor="let item of commonService.viewTypeOptions; let i = index"
        (click)="select(i)"
        [ngClass]="{ selected: isActive(item.paramName) }"
      >
        {{ item.title }}
      </li>
    </ul>
  </div>
</div>
<div class="comn-box">
  <div class="main-profile-container">
    <div class="profile-container">
      <div class="profile-display-container">
        <div class="tb-status">
          <span>{{ recDetails?.status | titlecase }}</span>
        </div>
        <div class="guru-profile-image">
          <img [src]="recDetails?.profile_image" />
        </div>

        <div class="profile-text-wrap">
          <h3>{{ recDetails.dist_name }}</h3>
          <div [class]="recDetails?.rating_class">
            <i class="star"></i>
            <i class="star"></i>
            <i class="star"></i>
            <i class="star"></i>
            <i class="star"></i>
          </div>
          <div class="tb-phone">
            <i class="phone-icon"></i>
            <a [href]="'tel:' + recDetails?.dist_contact_number">
              <span>{{ recDetails?.dist_contact_number }}</span>
            </a>
          </div>
          <!-- <a class="link-button">Update Profile</a>
              <a class="link-button view-reg">View Registration Details</a> -->
        </div>
      </div>
    </div>
    <div class="data-container">
      <h4>MSR Summary</h4>
      <div class="coupon-box-wrap">
        <div class="profile-box">
          <h2>{{ recDetails.total_msr_count }} <small> Total MSRs</small></h2>
        </div>
        <div class="profile-box active">
          <h2>
            {{ recDetails?.active_msr_count }}
            <small> Active </small>
          </h2>
        </div>
        <div class="profile-box transacting">
          <h2>
            {{ recDetails?.transacting_msr_count }}
            <small> Transacting</small>
          </h2>
        </div>
        <div class="profile-box inactive">
          <h2>
            {{ recDetails?.inactive_msr_count }}
            <small>Inactive</small>
          </h2>
        </div>
        <!-- <div class="profile-box">
              <h2>{{ recDetails?.job_cards }} <small> MTD</small></h2>
            </div> -->
      </div>
      <h4>User Information</h4>
      <div class="coupon-box-wrap">
        <div class="user-information-box">
          <div>
            <h4>
              User ID: <small> {{ recDetails?.dist_user_id }}</small>
            </h4>
          </div>
          <b class="seprator"></b>
          <div>
            <h4>
              Area: <small> {{ recDetails?.dist_area }}</small>
            </h4>
          </div>
          <b class="seprator"></b>
          <div>
            <h4>
              Associated Since:
              <small>
                {{ recDetails?.associated_since | date: "dd/MM/yyyy" }}</small
              >
            </h4>
          </div>
          <b class="seprator"></b>
          <div>
            <h4>
              Email ID:
              <small>
                <a [href]="'mailto:' + recDetails?.dist_email_id">{{
                  recDetails?.dist_email_id
                }}</a></small
              >
            </h4>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
