<div class="mb-3">
    <div class="guru-tabs-row">
        <div class="tb-left-col">
            <div class="guru-tabs-items">
                <ul>
                    <li [ngClass]="{ 'active-tab': referrelStep === 'prospect' }" (click)="setReferrelType('prospect')">
                        <a>New Vehicle Enquiry</a>
                    </li>
                    <li [ngClass]="{ 'active-tab': referrelStep === 'guru' }" (click)="setReferrelType('guru')">
                        <a>Guru Referral</a>
                    </li>
                    <li [ngClass]="{ 'active-tab': referrelStep === 'prolife engine' }" (click)="setReferrelType('prolife engine')">
                        <a>Prolife Engine</a>
                    </li>
                </ul>
              </div>
        </div>
        <div class="tb-btn-group">
            <ul>
                <li>
                    <app-referrel-report-filter #childComponent [reportType]="referrelStep" (formEventTrigger)="formControlValue($event)"></app-referrel-report-filter>
                </li>
                <li>
                    <button mat-stroked-button class="primary-stroke-button exportBtn" (click)="downloadReferrelReportExcel()">
                        Export Excel
                    </button>
                </li>
                <li>
                    <button mat-stroked-button class="primary-stroke-button exportBtn" (click)="pageChanged(0)">
                    Refresh
                    </button>
                </li>
            </ul>
        </div>
    </div>
    <div class="table-wrapper">
        <div class="guru-list-table rpm-table" *ngIf="!showNodata">
            <ng-container *ngIf="referrelStep=='guru'">
                <app-guru-referrel [dataSource]="dataSource"></app-guru-referrel>
            </ng-container>
            <ng-container *ngIf="referrelStep=='prospect'">
                <app-prospect-referrel [dataSource]="dataSource"></app-prospect-referrel>
            </ng-container>
            <ng-container *ngIf="referrelStep=='prolife engine'">
                <app-prolife-engine-referrel [dataSource]="dataSource"></app-prolife-engine-referrel>
            </ng-container>
        </div>
        <ng-container *ngIf="showNodata">
        <div class="no-item-found">No record found</div>
        </ng-container>
    
        <mat-paginator
        appPagination
        showFirstLastButtons
        [length]="totalRows"
        [pageIndex]="currentPage"
        [pageSize]="pageSize"
        [hidden]="showNodata"
        (page)="pageChanged($event)"
        class="custom-paginator"
        [hidePageSize]="true"
        >
        </mat-paginator>
    </div>
</div>
<div class="action-toaster" *ngIf="showToastermessage">
    <div class="toaster-msg alert alert-success" *ngIf="showfinalSuccessMsg">
        {{ showfinalSuccessMsg }}
    </div>
</div>
<div class="action-toaster" *ngIf="showToastermessage">
    <div class="toaster-msg alert alert-danger" *ngIf="showfinalErrorMsg">
        {{ showfinalErrorMsg }}
    </div>
</div>
<ngx-spinner></ngx-spinner>
