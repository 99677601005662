import {
  AfterViewInit,
  Component,
  ViewChild,
  OnInit,
  Input,
  OnChanges,
} from "@angular/core";
import { CurrencyPipe, DatePipe, PercentPipe } from "@angular/common";
import { Sort } from "@angular/material/sort";
import { GuruInfo } from "./guru-info";
import { AppService } from "src/app/services/app.service";
import { CommonService } from "src/app/services/common.service";
import { HttpMethod } from "../../../constants/http-handlers";
import { ActivatedRoute } from "@angular/router";
import { TableColumn } from "../../guru-table/TableColumn";
import { NgxSpinnerService } from "ngx-spinner";

//import { MatPaginator } from '@angular/material/paginator';
//import { MatSort } from '@angular/material/sort';
//import { MatTable } from '@angular/material/table';
//import {COMMA, ENTER} from '@angular/cdk/keycodes';
//import { GuruListTableDataSource, GuruListTableItem } from './guru-info-table-datasource';

@Component({
  selector: "app-guru-info-table",
  templateUrl: "./guru-info-table.component.html",
  styleUrls: ["./guru-info-table.component.scss"],
  providers: [CurrencyPipe, DatePipe, PercentPipe],
})
export class GuruInfoTableComponent implements OnInit, OnChanges {
  @Input() selectedView: any;
  @Input() selectedPageIndex: number = 0;
  orders: GuruInfo[];
  recordCount: number = 0;
  newpageind: number = 0;
  isDataReady: boolean = false;
  pageIndex: number = 0;
  currentPage = 0;
  ordersTableColumns: TableColumn[];
  // @Input() public recId: string;
  recId: number;
  step: string = "part-fitment";
  selectedTabIndex: number = 0;
  tabList: any[] = [
    {
      key: "part-fitment",
      name: "Parts Fitment",
      apiUrl: this.appService.msrGuruPartFitment,
      tableColums: [
        {
          name: "Part Name",
          dataKey: "part_name",
          position: "center",
          isSortable: false,
        },
        {
          name: "Part Quantity",
          dataKey: "part_quantity",
          position: "center",
          isSortable: false,
        },
        {
          name: "Type",
          dataKey: "part_type",
          position: "center",
          isSortable: false,
        },
        {
          name: "Fitment Date",
          dataKey: "fitment_date",
          position: "center",
          isSortable: false,
        },
      ],
    },

    {
      key: "job-card",
      name: "Job Card History",
      apiUrl: this.appService.msrGuruJobCard,
      tableColums: [
        {
          name: "Job Card Id",
          dataKey: "job_card_id",
          position: "center",
          isSortable: false,
        },
        
        {
          name: "Vehicle Details",
          dataKey: "vehicle_reg_no",
          position: "center",
          isSortable: false,
        },
        {
          name: "TGP Fitted",
          dataKey: "tgp_fitted",
          position: "center",
          isSortable: true,
        },
        {
          name: "Competitors Parts Fitted",
          dataKey: "competitors_part_fitted",
          position: "center",
          isSortable: true,
        },
        {
          name: "Fitment Date",
          dataKey: "fitment_date",
          position: "center",
          isSortable: false,
        },
      ],
    },

    {
      key: "competitor-fitments",
      name: "Competitor Fitments",
      apiUrl: this.appService.msrGuruCompetitorFitments,
      tableColums: [
        {
          name: "Job Card Id",
          dataKey: "job_card_id",
          position: "center",
          isSortable: false,
        },
        {
          name: "Competitors Parts Fitted",
          dataKey: "competitors_part_fitted",
          position: "center",
          isSortable: true,
        },
        {
          name: "Transaction Date",
          dataKey: "transaction_date",
          position: "center",
          isSortable: true,
        },
      ],
    },
  ];

  constructor(
    private currencyPipe: CurrencyPipe,
    private datePipe: DatePipe,
    private percentPipe: PercentPipe,
    private commonService: CommonService,
    private appService: AppService,
    private actRoute: ActivatedRoute,
    private spinner: NgxSpinnerService
  ) {
    this.recId = this.actRoute.snapshot.params.id;
  }

  ngOnInit(): void {
    //this.getOrders();
  }
  ngOnChanges() {
    this.getOrders();
  }
  changeTab(tabIndex: number): void {
    this.step = this.tabList[tabIndex].key;
    this.selectedTabIndex = tabIndex;
    this.getOrders();
  }

  sortData(sortParameters: Sort) {
    const keyName = sortParameters.active;
    if (sortParameters.direction === "asc") {
      this.orders = this.orders.sort((a: GuruInfo, b: GuruInfo) =>
        a[keyName].localeCompare(b[keyName])
      );
      return "";
    } else if (sortParameters.direction === "desc") {
      this.orders = this.orders.sort((a: GuruInfo, b: GuruInfo) =>
        b[keyName].localeCompare(a[keyName])
      );
      return "";
    } else {
      return "";
      //return (this.orders);
    }
  }

  removeOrder(order: any) {
    this.orders = this.orders.filter(
      (item) => item.couponid !== order.couponid
    );
  }

  receivedPageHandler(valueEmitted: any) {
    this.pageIndex = valueEmitted;
    this.isDataReady = false;
    this.getOrders();
    // console.log("### ",valueEmitted);
  }

  getOrders() {
    this.spinner.show();
    this.isDataReady = false;
    this.orders = [];
    let apiUrl = this.tabList[this.selectedTabIndex].apiUrl;
    this.ordersTableColumns = this.tabList[this.selectedTabIndex].tableColums;
    this.recordCount = 0;
    let queryParamList = [];
    //id param
    let idParam = `guru_id=` + this.recId;
    queryParamList.push(idParam);

    //view type param
    let viewTypeParam = `view_type=` + this.selectedView;
    queryParamList.push(viewTypeParam);

    //paging
    let pageParam = "";
    if (this.pageIndex != 0) {
      this.newpageind = this.pageIndex+1;
      pageParam = `page=` + this.newpageind;
      queryParamList.push(pageParam);
    }

    let apiQueryParam = "";
    if (queryParamList.length > 0) {
      apiQueryParam = `?` + queryParamList.join("&");
    }

    const url = `${apiUrl}` + apiQueryParam;

    const apiObj = {
      url: url,
      methodType: HttpMethod.GET,
    };
    this.commonService.commonApiCall(apiObj, (res) => {
      if (
        res &&
        res.hasOwnProperty("results") &&
        res.results &&
        res.results.length
      ) {
        this.recordCount = res.count;

        if (this.step == "part-fitment") {
          res.results.forEach((ele) => {
            let obj: any = {
              id: ele.id,
              part_name: ele.name + "<div><small>" + ele.id + "</small></div>",
              part_quantity: ele.part_quantity,
              part_type: ele.part_type,
              points: 0,
              couponid: 0,
              fitment_date: this.datePipe.transform(
                ele.fitment_date,
                "dd/MM/YYYY"
              ),
            };
            this.orders.push(obj);
          });
        } else if (this.step == "job-card") {
          res.results.forEach((ele) => {
            let obj: any = {
              job_card_id: ele.id,
              vehicle_reg_no: ele.vehicle_registration_number.toUpperCase(),
              vehicle_brand: "",
              tgp_fitted: "" + ele.tgp_part_count,
              competitors_part_fitted: "" + ele.competitor_part_count,
              fitment_date: this.datePipe.transform(
                ele.fitment_date,
                "dd/MM/YYYY"
              ),
            };
            this.orders.push(obj);
          });
        } else if (this.step == "competitor-fitments") {
          res.results.forEach((ele) => {
            let obj: any = {
              job_card_id: ele.id,
              competitors_part_fitted: "" + ele.competitor_parts_count,
              transaction_date: "",
            };
            if (ele.fitment_dates.length > 0) {
              obj.transaction_date = this.datePipe.transform(
                ele.fitment_dates[0],
                "dd/MM/YYYY"
              );
            }
            this.orders.push(obj);
          });
        }
        this.isDataReady = true;
        this.spinner.hide();
      } else {
        this.isDataReady = true;
        this.spinner.hide();
      }
    });
  }
}
