import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AddGuruComponent } from "./add-guru/add-guru.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { GuruListTableComponent } from "./shared/guru-list-table/guru-list-table.component";
import { SubDashboardComponent } from "./dashboard/sub-dashboard/sub-dashboard.component";
import { LoginComponent } from "./login/login.component";
import { JobSummaryComponent } from "./reports/job-summary/job-summary.component";
import { PartSummaryComponent } from "./reports/part-summary/part-summary.component";
import { TrainingComponent } from "./shared/training/training.component";
import { GuruDetailComponent } from "./shared/guru-detail/guru-detail.component";
import { MsrListComponent } from "./common-list/msr-list/msr-list.component";
import { MsrDetailComponent } from "./common-list/msr-detail/msr-detail.component";
import { TargetsComponent } from "./common-list/targets/targets.component";
import { ApmListComponent } from "./common-list/apm-list/apm-list.component";

import { AuthGuard } from "./auth.guard";
import { AddTrainingComponent } from "./superadmin/add-training/add-training.component";
import { AddUserComponent } from "./superadmin/add-user/add-user.component";
import { MyProfileComponent } from "./account/my-profile/my-profile.component";
import { ApmDetailComponent } from "./common-list/apm-detail/apm-detail.component";
import { AddPartItemComponent } from "./superadmin/add-part-item/add-part-item.component";
import { AddProblemStatmentComponent } from "./superadmin/add-problem-statment/add-problem-statment.component";
import { DistributorListComponent } from "./common-list/distributor-list/distributor-list.component";
import { DistributorDetailComponent } from "./common-list/distributor-detail/distributor-detail.component";
import { DoorStepBookingReportComponent } from "./reports/door-step-booking-report/door-step-booking-report.component";
import { ReportsComponent } from "./reports/reports.component";
import { OtpGeneratorComponent } from "./otp-generator/otp-generator.component";
import { LeadsComponent } from "./superadmin/leads/leads.component";
import { AddDealerComponent } from "./superadmin/add-dealer/add-dealer.component";

const routes: Routes = [
  { path: "login", component: LoginComponent },
  {
    path: "dashboard",
    component: DashboardComponent,
    canActivate: [AuthGuard],
  },
  { path: "add-guru", component: AddGuruComponent, canActivate: [AuthGuard] },
  {
    path: "report",
    children: [
      {
        path: "job-summary",
        canActivate: [AuthGuard],
        component: JobSummaryComponent,
      },
      {
        path: "part-summary",
        component: PartSummaryComponent,
        canActivate: [AuthGuard],
      }
    ],
  },
  {
    path:'reports',
    children:[
      {
        path: "door-step-booking",
        component: DoorStepBookingReportComponent,
        canActivate: [AuthGuard]
      }
    ],
    component:ReportsComponent
  },
  {
    path: "TestC",
    component:OtpGeneratorComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "update-guru/:id",
    component: AddGuruComponent,
    canActivate: [AuthGuard],
  },
  {
    path:"subdashboard",
    component:SubDashboardComponent,
    canActivate:[AuthGuard]
  },
  {
    path: "guru-list",
    canActivate: [AuthGuard],
    children: [
      {
        path: "",
        component: GuruListTableComponent,
      },
      {
        path: "guru-detail/:id",
        component: GuruDetailComponent,
      },
    ],
  },
  {
    path: "msr-list",
    canActivate: [AuthGuard],
    children: [
      {
        path: "",
        component: MsrListComponent,
      },
      {
        path: "msr-detail/:id",
        component: MsrDetailComponent,
      },
    ],
  },

  { path: "training", component: TrainingComponent, canActivate: [AuthGuard] },
  {
    path: "training/:id",
    component: TrainingComponent,
    canActivate: [AuthGuard],
  },

  {
    path: "targets",
    component: TargetsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "apm-list",
    canActivate: [AuthGuard],
    children: [
      {
        path: "",
        component: ApmListComponent,
      },
      {
        path: "apm-detail/:id",
        component: ApmDetailComponent,
      },
    ],
  },
  {
    path: "distributor-list",
    canActivate: [AuthGuard],
    children: [
      {
        path: "",
        component: DistributorListComponent,
      },
      {
        path: "distributor-detail/:id",
        component: DistributorDetailComponent,
      },
    ],
  },

  {
    path: "add-training",
    component: AddTrainingComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "add-user",
    component: AddUserComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "add-part-item",
    component: AddPartItemComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "add-problem-statment",
    component: AddProblemStatmentComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "leads",
    component: LeadsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "add-dealer",
    component: AddDealerComponent,
    canActivate: [AuthGuard],
  },
  // {
  //   path: "points-summary",
  //   component: PointsSummaryComponent,
  //   canActivate: [AuthGuard],
  // },
  // {
  //   path: "coupon-history",
  //   component: CouponHistoryComponent,
  //   canActivate: [AuthGuard],
  // },
  // {
  //   path: "gift-catalogue",
  //   component: GiftCatalogueComponent,
  //   canActivate: [AuthGuard],
  // },
  // {
  //   path: "my-targets",
  //   component: MyTargetsComponent,
  //   canActivate: [AuthGuard],
  // },

  {
    path: "profile",
    component: MyProfileComponent,
    canActivate: [AuthGuard],
  },
  // { path: "my-visit", component: MyVisitComponent, canActivate: [AuthGuard] },
  { path: "**", redirectTo: "/login", pathMatch: "full" },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      onSameUrlNavigation: "reload",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
