<div class="tb-table-wrapper" style="padding-top: 42px">
  <div class="guru-tabs-row">
    <div class="guru-tabs-items">
      <ul>
        <li *ngFor="let item of tabList; let i = index" [ngClass]="{ 'active-tab': step === item.key }"
          (click)="changeTab(i)">
          <a>{{ item.name }}</a>
        </li>
        <!-- <li [ngClass]="{ 'active-tab': step === 'job-card' }" (click)="changeTab('job-card')">
          <a>Job Card History</a>
        </li>
        <li [ngClass]="{ 'active-tab': step === 'earn-history' }" (click)="changeTab('earn-history')">
          <a>Earn History</a>
        </li>
        <li [ngClass]="{ 'active-tab': step === 'competitor-fitments' }" (click)="changeTab('competitor-fitments')">
          <a>Competitor Fitments</a>
        </li> -->
      </ul>
    </div>
    <div class="tb-btn-group">
      <ul>
        <!-- <li>
          <app-gurulist-filter></app-gurulist-filter>
        </li> -->
        <li class="hidden">
          <button mat-stroked-button class="primary-stroke-button exportBtn">
            Export
          </button>
        </li>
      </ul>
    </div>
  </div>
  <div style="min-height:200px">
    <ng-container *ngIf="isDataReady">
      <guru-table [tableData]="orders" [recordCount]="recordCount" [tableColumns]="ordersTableColumns"
        [isFilterable]="false" [isPageable]="true" [currentPageIndex]="pageIndex"
        (childPageChangeEvent)="receivedPageHandler($event)" [defaultPageSize]="10" (sort)="sortData($event)">
      </guru-table>
    </ng-container>
  </div>
</div>
<ngx-spinner></ngx-spinner>