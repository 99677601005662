import {
  Component,
  Input,
  OnInit,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTable, MatTableDataSource } from "@angular/material/table";
import { NgxSpinnerService } from "ngx-spinner";
import { HttpMethod } from "src/app/constants/http-handlers";
import { AppService } from "src/app/services/app.service";
import { CommonService } from "src/app/services/common.service";

@Component({
  selector: "app-added-statment-list",
  templateUrl: "./added-statment-list.component.html",
  styleUrls: ["./added-statment-list.component.scss"],
})
export class AddedStatmentListComponent implements OnInit {
  submittedTrainingForm: boolean = false;
  showfinalSuccessMsg: string;
  showfinalErrorMsg: string;
  showToastermessage: boolean = false;
  trainingPictureFile: any = "";
  trainingItemFile: any = "";
  notificationAlert: boolean = false;
  deleteItemModal: boolean = false;
  deleteProductId: number;
  displayedColumns: string[] = [
    "problemId",
    "prblemName",
    "problemDesc",
    "problemImg",
    "problemDate",
    "action",
  ];
  //dataSource = ELEMENT_DATA;

  showNodata: boolean = false;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatTable) table!: MatTable<any>;
  dataSource = new MatTableDataSource<any>();
  @Input() refreshTableItems: boolean = false;

  isLoading = false;
  totalRows = 0;
  pageSize = 10;
  currentPage = 0;
  pgIndex: number = 0;

  constructor(
    private appService: AppService,
    private commonService: CommonService,
    public dialog: MatDialog,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.problemList();
  }
  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }
  ngOnChanges(changes: SimpleChanges) {
    for (let property in changes) {
      if (this.refreshTableItems) {
        this.problemList();
        this.refreshTableItems = false;
      }
    }
  }
  problemList() {
    let apiUrl = this.appService.problemList;
    let queryParamList = [];

    //paging
    let pageParam = "";
    if (this.pgIndex != 0 && this.pgIndex != 1) {
      pageParam = `page=` + this.pgIndex;
      queryParamList.push(pageParam);
    }

    let apiQueryParam = "";
    if (queryParamList.length > 0) {
      apiQueryParam = `?` + queryParamList.join("&");
    }

    const url = `${apiUrl}`;
    const apiObj = {
      url: url,
      methodType: HttpMethod.GET,
    };
    this.commonService.commonApiCall(apiObj, (res) => {
      if (res) {
        this.dataSource = new MatTableDataSource(res);
        this.dataSource.sort = this.sort;
        this.paginator.pageIndex = this.currentPage;
        this.paginator.length = res.length;
        this.pageSize = res.length;
        this.isLoading = false;
      }
      if (res.length === 0) {
        this.showNodata = true;
      } else {
        this.showNodata = false;
      }
    });
    //this.isLoading = false;
  }
  pageChanged(event: PageEvent) {
    this.pgIndex = event.pageIndex;
    this.pgIndex = this.pgIndex + 1;
    this.currentPage = event.pageIndex;
    this.isLoading = true;
    this.problemList();
  }
}
