import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'kFormat'
})
export class KFormatPipe implements PipeTransform {
    
  // transform(value: number): string {
  //   if (value >= 1000000) {
  //     return (value / 1000000).toFixed(3) + 'M';
  //   } else if (value >= 1000) {
  //     return (value / 1000).toFixed(2) + 'K';
  //   }
  //   return value.toString();
  // }
  transform(value: number): string {
    if (value == null || isNaN(value)) {
      return '0';
    }
    else if (value >= 1000) {
      return (value / 1000).toFixed(2) + 'K';
    }
    return value.toString();
  }
}
