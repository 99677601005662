import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AddUserComponent } from "./add-user/add-user.component";
import { AddMsrComponent } from "./add-user/add-msr/add-msr.component";
import { AddApmComponent } from "./add-user/add-apm/add-apm.component";
import { AddRpmComponent } from "./add-user/add-rpm/add-rpm.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatTableModule } from "@angular/material/table";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatSortModule } from "@angular/material/sort";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSelectModule } from "@angular/material/select";
import { NgxMatSelectSearchModule } from "ngx-mat-select-search";
import { AddTrainingComponent } from "./add-training/add-training.component";
import { MatButtonModule } from "@angular/material/button";
import { NgxSpinnerModule } from "ngx-spinner";
import { SharedModule } from "../shared/shared.module";
import { MatTooltipModule } from "@angular/material/tooltip";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AddPartItemComponent } from "./add-part-item/add-part-item.component";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatInputModule } from "@angular/material/input";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { AddedPartListComponent } from "./add-part-item/added-part-list/added-part-list.component";
import { MatIconModule } from "@angular/material/icon";
import { AddProblemStatmentComponent } from './add-problem-statment/add-problem-statment.component';
import { AddedStatmentListComponent } from './add-problem-statment/added-statment-list/added-statment-list.component';
import { AddDistributorComponent } from './add-user/add-distributor/add-distributor.component';
import { UserRegistrationComponent } from './add-user/user-registration/user-registration.component';
import { AddRoleComponent } from './add-user/add-role/add-role.component';
import { UserFilterComponent } from './add-user/user-filter/user-filter.component';
import { LeadsComponent } from './leads/leads.component';
import { AddLassComponent } from './add-user/add-lass/add-lass.component';
import { AddDealerComponent } from './add-user/add-dealer/add-dealer.component';
import { MatRadioModule } from '@angular/material/radio';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

const superAminComponents = [
  AddUserComponent,
  AddMsrComponent,
  AddApmComponent,
  AddRpmComponent,
];

@NgModule({
  declarations: [
    ...superAminComponents,
    AddTrainingComponent,
    AddPartItemComponent,
    AddedPartListComponent,
    AddProblemStatmentComponent,
    AddedStatmentListComponent,
    AddDistributorComponent,
    UserRegistrationComponent,
    AddRoleComponent,
    UserFilterComponent,
    LeadsComponent,
    AddLassComponent,
    AddDealerComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MatButtonModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatSelectModule,
    MatFormFieldModule,
    NgxMatSelectSearchModule,
    NgxSpinnerModule,
    SharedModule,
    //AppMaterialModule
    MatTooltipModule,
    BrowserAnimationsModule,
    MatButtonToggleModule,
    MatDatepickerModule,
    MatInputModule,
    MatAutocompleteModule,
    MatIconModule,
    MatRadioModule,
    MatSlideToggleModule,
  ],
  exports: [...superAminComponents],
})
export class SuperadminModule {}
