import { Component, OnInit } from "@angular/core";
import { CommonService } from "src/app/services/common.service";

@Component({
  selector: "app-job-summary",
  templateUrl: "./job-summary.component.html",
  styleUrls: ["./job-summary.component.scss"],
})
export class JobSummaryComponent implements OnInit {
  step: string;
 
  showDropdown: boolean = false;
  selected: string = "month";
  selectedItem: string = "This Month";
 
  constructor(public commonService: CommonService) {
    this.step = "jobCard";
  }

  ngOnInit(): void {
  }

  select(index: number) {
    this.selected = this.commonService.viewTypeOptions[index].paramName;
    this.selectedItem = this.commonService.viewTypeOptions[index].title;
    this.showDropdown = false;
  }
  selectValfun() {
    this.showDropdown = !this.showDropdown;
  }
  isActive(item) {
    return this.selected === item;
  }

}
