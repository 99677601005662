<div class="modal-content" style="border: 1px solid black;">
    <div class="modal-header modal-title">
        <div style="display: flex; justify-content: space-between; align-items: center; width: 100%;">
            <div>Opty Details</div>
            <div (click)="closeModal()" style="cursor: pointer; font-size: 24px;">
                ×
            </div>
        </div>
    </div>
    <div class="modal-body">
        <div class="row">
            <div *ngIf="optyData && optyData.length > 0" class="lead-id" style="margin: 3px 0px 3px 0px">
                <strong>Lead Id:</strong> {{optyData[0]?.lead_id}}
            </div>
            <br>
            <div class="table-responsive">
                <table class="table table-md">
                    <thead>
                        <tr>
                            <th class="text-nowrap">Sr No</th>
                            <th class="text-nowrap">Sales Stage</th>
                            <th class="text-nowrap">Updated Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngIf="optyData && optyData.length > 0">
                            <tr *ngFor="let item of optyData; let i = index">
                                <td class="text-nowrap">{{i + 1}}</td>
                                <td class="feedback text-nowrap">{{item?.sales_stage}}</td>
                                <td class="text-nowrap">{{item?.stage_updated_date | date:'dd/MM/yyyy hh:mm:ss a':'UTC+11.00'}}</td>
                            </tr>
                        </ng-container>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>

