import { DatePipe } from "@angular/common";
import { Component, OnInit, ViewChild } from "@angular/core";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTable, MatTableDataSource } from "@angular/material/table";
import { NgxSpinnerService } from "ngx-spinner";
import { HttpMethod } from "src/app/constants/http-handlers";
import { APMListTableItem } from "src/app/models/apm";
import { AppService } from "src/app/services/app.service";
import { CommonService } from "src/app/services/common.service";

@Component({
  selector: "app-apm-list",
  templateUrl: "./apm-list.component.html",
  styleUrls: ["./apm-list.component.scss"],
  providers: [DatePipe],
})
export class ApmListComponent implements OnInit {
  step: string = "all";
  showNodata: boolean = false;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatTable) table!: MatTable<APMListTableItem>;
  dataSource = new MatTableDataSource<APMListTableItem>();

  isLoading = false;
  totalRows = 0;
  pageSize = 10;
  currentPage = 0;
  pgIndex: number = 0;
  filterChipList: any[] = [];
  filterObject: any = [];
  isDataReady: boolean = false;
  pageIndex = 0;
  searchText: string = "";

  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = ["full_name", "total_msr", "apm_msr_part_fitment_count", "contact"];
  guruList: any = [];
  constructor(
    private commonService: CommonService,
    private appService: AppService,
    private datePipe: DatePipe,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.getAPMlist();
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }

  searchRecords() {
    this.pageIndex = 0;
    this.isDataReady = false;
    this.getAPMlist();
  }

  checkText(event: KeyboardEvent) {
    if (event.code == "Enter" || this.searchText.trim() == "") {
      this.searchRecords();
    }
  }

  getAPMlist() {
    this.spinner.show();
    let apiUrl = `${this.appService.showAPMlist}`;
    let queryParamList = [];

    //search
    let searchParam = "";
    if (this.searchText !== "") {
      if (/\D/g.test(this.searchText) == false) {
        searchParam = `phone_number=` + this.searchText;
      } else {
        searchParam = `username=` + this.searchText;
      }
      queryParamList.push(searchParam);
    }

    //paging
    let pageParam = "";
    if (this.pgIndex != 0 && this.pgIndex != 1) {
      pageParam = `page=` + this.pgIndex;
      queryParamList.push(pageParam);
    }

    let apiQueryParam = "";
    if (queryParamList.length > 0) {
      apiQueryParam = `?` + queryParamList.join("&");
    }

    const url = `${apiUrl}` + apiQueryParam;

    const apiObj = {
      url: url,
      methodType: HttpMethod.GET,
    };
    this.commonService.commonApiCall(apiObj, (res) => {
      if (
        res &&
        res.hasOwnProperty("results") &&
        res.results &&
        res.results.length
      ) {
        let i = 0;
        res.results.forEach((ele) => {
          let dt = "##"; //this.datePipe.transform(ele.associated_since, "dd/MM/YYYY");
          res.results[i]["associated_since"] = this.datePipe.transform(
            ele.associated_since,
            "dd/MM/YYYY"
          );
          res.results[i]["dur"] = this.commonService.getDurationInMonth(
            ele.associated_since
          );
          i++;
          //     //   fitmentdate: this.datePipe.transform(ele.fitment_date, "dd/MM/YYYY")
          //     // this.orders.push(obj);
        });
        this.totalRows = res.count;
        this.dataSource = new MatTableDataSource(res.results);
        this.dataSource.sort = this.sort;
        this.paginator.pageIndex = this.currentPage;
        this.paginator.length = res.count;
        this.isLoading = false;
        this.spinner.hide();
      } else {
        this.spinner.hide();
      }
      if (res.count === 0) {
        this.showNodata = true;
      } else {
        this.showNodata = false;
      }
    });
    //this.isLoading = false;
  }
  pageChanged(event: PageEvent) {
    this.pgIndex = event.pageIndex;
    this.pgIndex = this.pgIndex + 1;
    this.currentPage = event.pageIndex;
    this.isLoading = true;
    this.getAPMlist();
  }
}
