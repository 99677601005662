<div class="tb-table-wrapper">
    <div class="guru-tabs-row d-flex" style="justify-content: end;">
        <div class="guru-tabs-items">
            <div class="tb-btn-group">
                <ul>
                  <li *ngIf="!isAddLass">
                    <button mat-stroked-button class="primary-stroke-button exportBtn" (click)="reset()">
                        Refresh
                    </button>
                </li>
                  <li *ngIf="!isAddLass">
                    <div class="tb-filter-wrapper">
                        <button mat-stroked-button class="primary-stroke-button filterBtn" (click)="openDropdown()" [ngClass]="{ open: isOpenDropdown }">
                        Filter
                        </button>
                        <div class="custom-filter-sec" *ngIf="isOpenDropdown">
                        <h3>
                            Filter <button class="filterCloseBtn" (click)="closeDropdown()"></button>
                        </h3>
                        <form [formGroup]="LassFilterForm" name="LassFilterForm" class="form">
                                <div class="custom-filter-body" style="justify-content: space-evenly;">
                                </div>
                                <div class="custom-filter-body" style="justify-content: space-evenly;">
                                  <div class="custom-filter-col">
                                        <mat-form-field>
                                            <mat-label>Mobile Number</mat-label>
                                            <input formControlName="mobileNumber" matInput (keydown.enter)="applyFilter()" maxlength="10"
                                            (keypress)="keyPressNumbers($event)">
                                        </mat-form-field>
                                    </div>
                                    <div class="custom-filter-col"></div>
                                </div>
                                <div class="btn-group filterBtn-group">
                                    <button mat-stroked-button class="primary-stroke-button"(click)="reset()" >Reset</button>
                                    <button mat-flat-button class="primary-flat-button" (click)="applyFilter()">Apply</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </li>
                    <li>
                        <div class="addUser-btn">
                            <button mat-flat-button matStepperNext class="primary-flat-button" 
                            (click)="isAddLass = !isAddLass">
                            {{isAddLass ? 'Back' : 'Add Lass'}}
                            </button>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>

<div class="form-wrap comn-box add-item-content" *ngIf="isAddLass">
    <form
      [formGroup]="addLassForm"
      (ngSubmit)="addLassFormData()"
      autocomplete="off"
    >
      <div class="form-section">
        <div class="form-row">
          <div class="form-col">
            <label for="firstName" class="form-label" 
              >First Name <span class="requiredfield">*</span></label
            >
            <input
              type="text"
              class="form-control"
              id="firstName"
              formControlName="firstName"
              maxlength="30"
              placeholder="Enter First Name"
              appOnlyAlphabet
            />
            <div
              *ngIf="
                submitted && this.addLassForm.controls.firstName.errors
              "
              class="invalid-field"
            >
              <span
                *ngIf="this.addLassForm.controls.firstName.errors.required"
                >First Name is required</span
              >
              <span
                *ngIf="this.addLassForm.controls.firstName.errors.pattern"
                >Please enter valid First Name</span
              >
              <span
                *ngIf="
                  !this.addLassForm.controls.firstName.errors.required &&
                  this.addLassForm.controls.firstName.errors.whitespace
                "
                >Please enter valid data</span
              >
            </div>
          </div>
          <div class="form-col">
            <label for="lastName" class="form-label" 
              >Last Name <span class="requiredfield">*</span></label
            >
            <input
              type="text"
              class="form-control"
              id="lastName"
              formControlName="lastName"
              maxlength="30"
              placeholder="Enter Last Name"
              appOnlyAlphabet
            />
            <div
              *ngIf="
                submitted && this.addLassForm.controls.lastName.errors
              "
              class="invalid-field"
            >
              <span
                *ngIf="this.addLassForm.controls.lastName.errors.required"
                >Last Name is required</span
              >
              <span
                *ngIf="this.addLassForm.controls.lastName.errors.pattern"
                >Please enter valid Last Name</span
              >
              <span
                *ngIf="
                  !this.addLassForm.controls.lastName.errors.required &&
                  this.addLassForm.controls.lastName.errors.whitespace
                "
                >Please enter valid data</span
              >
            </div>
          </div>
        </div>
        <div class="form-row">
          <div class="form-col">
            <label for="mobileNum" class="form-label" 
              >Mobile Number <span class="requiredfield">*</span></label
            >
            <input
              type="text"
              class="form-control"
              id="mobileNum"
              formControlName="mobileNum"
              placeholder="Mobile No"
              maxlength="10"
              (keypress)="keyPressNumbers($event)"
            />
            <div
              *ngIf="
                submitted && this.addLassForm.controls.mobileNum.errors
              "
              class="invalid-field"
            >
              <span
                *ngIf="this.addLassForm.controls.mobileNum.errors.required"
                >Mobile Number is required</span
              >
              <span
                *ngIf="
                  this.addLassForm.controls.mobileNum.errors.pattern ||
                  this.addLassForm.controls.mobileNum.errors.min
                "
                >Please enter valid Mobile Number</span
              >
            </div>
          </div>
          <div class="form-col">
            <label for="email" class="form-label"
              >Tml Email Id <span class="requiredfield">*</span></label
            >
            <input
              type="text"
              class="form-control"  placeholder="Enter TML Email Id. Eg: abc@tatamotors.com"
              id="email"
              formControlName="email"
              (keypress)="removeSpace()"
            />
            <div
              *ngIf="submitted && this.addLassForm.controls.email.errors"
              class="invalid-field"
            >
              <span *ngIf="this.addLassForm.controls.email.errors.required"
                >Tata motors Email Id is required</span
              >
              <span *ngIf="this.addLassForm.controls.email.errors.pattern"
                >Please enter valid Tata motors Email Id</span
              >
            </div>
          </div>
        </div>
      </div>
      <div class="addLass-btn mt-3">
        <button
          mat-flat-button
          matStepperNext
          class="primary-flat-button"
          type="submit"
        >
          Add Lass
        </button>
      </div>
    </form>
  </div>

<div class="table-wrapper" *ngIf="!isAddLass">
    <div class="guru-list-table" *ngIf="!showNodata">
    <table
        mat-table
        matTableExporter
        class="full-width-table tb-table"
        matSort
        aria-label="Elements"
        [dataSource]="dataSource"
    >
        
        <ng-container matColumnDef="firstName">
            <th class="firstName-col" mat-header-cell *matHeaderCellDef style="width: 2cqmax;">
                First Name
            </th>
            <td class="firstName-col" mat-cell *matCellDef="let row">
                {{ row.first_name}}
            </td>
        </ng-container>
        <ng-container matColumnDef="lastName">
            <th class="lastName-col" mat-header-cell *matHeaderCellDef style="width: 20%;">
            Last Name
            </th>
            <td class="lastName-col" mat-cell *matCellDef="let row">
            {{ row.last_name}}
            </td>
        </ng-container>
        <ng-container matColumnDef="email">
            <th class="email-col" mat-header-cell *matHeaderCellDef style="width: 20%;">
              TML Email
            </th>
            <td class="email-col" mat-cell *matCellDef="let row">
                {{ row.email }}
            </td>
        </ng-container>

        <ng-container matColumnDef="phoneNumber">
          <th class="phoneNumber-col" mat-header-cell *matHeaderCellDef style="width: 20%;">Phone Number</th>
            <td class="phoneNumber-col" mat-cell *matCellDef="let row">
                {{ (row.username).substr(0,10) }}
            </td>
        </ng-container>
        <!-- <ng-container matColumnDef="status">
          <th class="status-col" mat-header-cell *matHeaderCellDef style="width: 20%;">Status</th>
            <td class="status-col" mat-cell *matCellDef="let row">
              <mat-slide-toggle
              class="example-margin"
              [(ngModel)]="row.status">
              {{ row.status ? "Active" : "Inactive" }}
          </mat-slide-toggle>
            </td>
        </ng-container> -->

        <ng-container matColumnDef="status">
          <th class="status-col" mat-header-cell *matHeaderCellDef style="width: 20%;">Status</th>
          <td class="status-col" mat-cell *matCellDef="let row">
            <mat-slide-toggle
              class="example-margin"
              [(ngModel)]="row.is_active"
              (change)="toggleLasStatus($event.checked, row)">
              {{ row.is_active ? "Active" : "Inactive" }}
            </mat-slide-toggle>
          </td>
        </ng-container>
        
        
        
        <tr
        class="tb-head-row"
        mat-header-row
          *matHeaderRowDef="displayedColumns"
        ></tr>
        <tr
        class="tb-item-row"
        mat-row
          *matRowDef="let row; columns: displayedColumns"
        ></tr>
    </table>
    </div>

    <ng-container *ngIf="showNodata">
        <div class="no-item-found">No record found</div>
    </ng-container>

    <mat-paginator
      appPagination
      showFirstLastButtons
      [length]="totalRows"
      [pageIndex]="currentPage"
      [pageSize]="pageSize"
      [hidden]="showNodata"
      (page)="pageChanged($event)"
      class="custom-paginator"
      [hidePageSize]="true"
    >
    </mat-paginator>
</div>
  <div class="action-toaster" *ngIf="showToastermessage">
    <div class="toaster-msg alert alert-success" *ngIf="showfinalSuccessMsg">
    {{ showfinalSuccessMsg }}
    </div>
    <div class="toaster-msg alert alert-danger" *ngIf="showfinalErrorMsg">
    {{ showfinalErrorMsg }}
    </div>
</div>

<ngx-spinner></ngx-spinner>