import { Component, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { NgxSpinnerService } from "ngx-spinner";
import { HttpMethod } from "src/app/constants/http-handlers";
import { AppService } from "src/app/services/app.service";
import { CommonService } from "src/app/services/common.service";

@Component({
  selector: "app-add-problem-statment",
  templateUrl: "./add-problem-statment.component.html",
  styleUrls: ["./add-problem-statment.component.scss"],
})
export class AddProblemStatmentComponent implements OnInit {
  addProblemStatmentForm: FormGroup;
  formData: FormData = new FormData();
  submittedTrainingForm: boolean = false;
  showfinalSuccessMsg: string;
  showfinalErrorMsg: string;
  showToastermessage: boolean = false;
  problemPictureFile: any = "";
  notificationAlert: boolean = false;
  problemItemUpdate: boolean = false;

  constructor(
    private _formBuilder: FormBuilder,
    private appService: AppService,
    private commonService: CommonService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.addProblemStatmentForm = this._formBuilder.group({
      problemTitle: [
        "",
        [
          Validators.required,
          Validators.pattern(/^[a-zA-Z ]*$/),
          Validators.maxLength(50),
          this.noWhitespaceValidator,
        ],
      ],

      longDesc: ["", [Validators.required, this.noWhitespaceValidator]],
      uploadthumbnailFile: [this.problemPictureFile, Validators.required],
    });
  }
  addProblemStatmentFormData() {
    debugger;
    this.submittedTrainingForm = true;

    if (this.addProblemStatmentForm.valid) {
      this.formData.append(
        "problem_name",
        this.addProblemStatmentForm.get("problemTitle").value
      );
      this.formData.append(
        "problem_desc",
        this.addProblemStatmentForm.get("longDesc").value
      );
      let apiUrl = this.appService.addProblemItem;
      this.notificationAlert = false;
      const apiObj = {
        url: apiUrl,
        methodType: HttpMethod.POST,
        requestObj: this.formData,
      };
      this.spinner.show();
      this.commonService.commonApiCall(
        apiObj,
        (res: any, error) => {
          if (error != undefined) {
            this.showToastermessage = true;
            this.showfinalErrorMsg = error.error.msg;
            this.spinner.hide();
            setTimeout(() => {
              this.showfinalErrorMsg = "";
              this.showToastermessage = false;
            }, 3000);
          } else {
            this.showfinalSuccessMsg =
              "Problem statment has been created successfully !!!";
            this.showToastermessage = true;
            this.spinner.hide();
            this.problemItemUpdate = true;
            setTimeout(() => {
              this.showfinalSuccessMsg = "";
              this.showToastermessage = false;
            }, 3000);
          }
          this.formData.delete("problemTitle");
          this.formData.delete("longDesc");
          this.addProblemStatmentForm.reset();
          this.submittedTrainingForm = false;
          this.problemPictureFile = "";
        },
        "multipart/form-data"
      );
    }
  }
  uploadthumbnailFile(event: Event) {
    //debugger;
    const target = event.target as HTMLInputElement;
    const files = target.files as FileList;
    this.formData.delete("image_url");

    if (
      files[0].type == "image/png" ||
      files[0].type == "image/jpg" ||
      files[0].type == "image/jpeg"
    ) {
      if (files[0].size < 5242880) {
        this.formData.append("image_url", files[0]);
        this.problemPictureFile = files[0].name;
      } else {
        this.showToastermessage = true;
        this.showfinalErrorMsg = "File size can’t be over 5MB.";
        setTimeout(() => {
          this.showfinalErrorMsg = "";
          this.showToastermessage = false;
        }, 3000);
      }
    } else {
      this.showToastermessage = true;
      this.showfinalErrorMsg = "png, jpeg, jpg file formats are allowed.";
      setTimeout(() => {
        this.showfinalErrorMsg = "";
        this.showToastermessage = false;
      }, 3000);
    }
  }
  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || "").trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { whitespace: true };
  }
}
