import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-msr-dashboard',
  templateUrl: './msr-dashboard.component.html',
  styleUrls: ['./msr-dashboard.component.scss']
})
export class MsrDashboardComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
