import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'absnew'
})
export class AbsnewPipe implements PipeTransform {

  transform(num: number, args?: any): any {
    return Math.abs(num);
  }

}
