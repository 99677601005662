import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { Router, ActivatedRoute, RouterEvent, NavigationEnd } from "@angular/router";
import { HttpMethod } from "src/app/constants/http-handlers";
import { AppService } from "src/app/services/app.service";
import { CommonService } from "src/app/services/common.service";
import { NgxSpinnerService } from "ngx-spinner";
import { Subscription } from "rxjs";
import { DataService } from "src/app/services/data.service";
import { filter } from 'rxjs/operators';

@Component({
  selector: "app-training",
  templateUrl: "./training.component.html",
  styleUrls: ["./training.component.scss"],
})
export class TrainingComponent implements OnInit {
  public get dataService(): DataService {
    return this._dataService;
  }
  public set dataService(value: DataService) {
    this._dataService = value;
  }
  showDropdown: boolean = false;
  selected: string = "monthly";
  selectedItem: string = "Month";
  showmoreContent: boolean = false;
  searchText: string = "";
  serachVideoItem: string;
  chipfilterOptions: any[] = [{ value: "All Videos", id: "all" }];
  selectedCatItem: string = "all";
  videosList: any[] = [];
  selectedVideo: any;
  nextMovingUrl: string;
  prevMovingUrl: string;
  splitUrl: any[];
  disableNextPaginations: boolean;
  disablePrevPaginations: boolean;
  trainingVideoID: any;
  selectedMessage: any;
  message: string;
  subscription: Subscription;
  deletedVideo: string = "";

  @ViewChild("chips", { read: ElementRef }) public chips: ElementRef<any>;
  @ViewChild("videoFileContainer", { static: false }) videoFileContainer;

  constructor(
    public commonService: CommonService,
    private appService: AppService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private activateRoute: ActivatedRoute,
    private _dataService: DataService
  ) {}

  isSelected(item) {
    return this.selectedCatItem === item;
  }

  scrollRight(): void {
    this.chips.nativeElement.scrollTo({
      left: this.chips.nativeElement.scrollLeft + 150,
      behavior: "smooth",
    });
  }

  scrollLeft(): void {
    this.chips.nativeElement.scrollTo({
      left: this.chips.nativeElement.scrollLeft - 150,
      behavior: "smooth",
    });
  }

  select(index) {
    this.selected = this.commonService.viewTypeOptionsTraining[index].paramName;
    this.selectedItem = this.commonService.viewTypeOptionsTraining[index].title;
    this.showDropdown = false;
    this.showTrainningItem();
  }
  selectValfun() {
    this.showDropdown = !this.showDropdown;
  }
  isActive(item) {
    return this.selected === item;
  }

  ngOnInit(): void {
    //debugger;
    // this.showTrainningItem();
    
    this.showTrainningCatItem();
    this.activateRoute.paramMap.subscribe((params) => {
      this.trainingVideoID = params.get("id");
      
      if (this.trainingVideoID){
        this.showTrainingVideoSingle();
      }
      else{
        this.showTrainningItem();
      }
    });
    
    
    //console.log(this.trainingVideoID);
    // this.subscription = this._dataService.currentMessage.subscribe((message) =>
    //   console.log(message)
    // );
    // console.log(this.subscription);
  }
  searchData() {
    this.serachVideoItem = this.searchText.trim();
    this.showTrainningItem();
  }
  checkText(event: KeyboardEvent) {
    if (
      event.code == "Enter" ||
      (event.code == "Backspace" && this.searchText.trim() == "")
    ) {
      this.searchData();
    }
  }
  showTrainningCatItem() {
    const url = `${this.appService.trainingVideoCategoryItems}`;
    const apiObj = {
      url: url,
      methodType: HttpMethod.GET,
    };
    this.commonService.commonApiCall(apiObj, (res) => {
      this.chipfilterOptions.push(...res);
    });
  }
  showTrainingVideoSingle() {
    const url = `${this.appService.trainingVideoSingle}${this.trainingVideoID}`;
    const apiObj = {
      url: url,
      methodType: HttpMethod.GET,
    };
    this.commonService.commonApiCall(apiObj, (res,error) => {
      if (("status" in res)){
        this.selectedVideo = 0;
        this.selectedCatItemfun("all");
        this.deletedVideo = res.error.detail;
      }
      else{
        this.videosList.push(res);
        this.selectedVideo = res.id;
        this.selectedCatItemfun(res.category);
        this.deletedVideo = "";
      }
    });
  }

  showTrainningItem() {
    this.spinner.show();
    this.videosList = [];
    let url;
    if (this.selectedCatItem == "all") {
      url = `${this.appService.trainingVideoItems}`;
    } else {
      url = `${this.appService.trainingVideoItems}${this.selectedCatItem}`;
    }
    let queryParamList = [];
    if (this.searchText.length > 0) {
      let searchParam = `title=` + this.searchText;
      queryParamList.push(searchParam);
    }

    let dtObj = this.commonService.getDateRangObject(
      this.selected,
      "",
      "YYYY-MM-dd"
    );

    //view type param
    let viewTypeParamFrom = `from_date=` + dtObj.from;
    queryParamList.push(viewTypeParamFrom);

    let viewTypeParamTo = `to_date=` + dtObj.to;
    queryParamList.push(viewTypeParamTo);

    let apiQueryParam = "";
    if (queryParamList.length > 0) {
      apiQueryParam = `?` + queryParamList.join("&");
    }

    url += `${apiQueryParam}`;
    //console.log(url);
    const apiObj = {
      url: url,
      methodType: HttpMethod.GET,
    };
    this.commonService.commonApiCall(apiObj, (res) => {
      if (res && res.hasOwnProperty("results") && res.results) {
        for (var i = 0; i < res.results.length; i++) {
          this.videosList.push(res.results[i]);
        }

         var Catindex = res.results.findIndex(
           (x) => x.id == this.trainingVideoID
         );

         if (this.trainingVideoID) {
           this.selectedVideo = this.videosList[Catindex];
         } else {
          this.selectedVideo = this.videosList[0];
        }

        //console.log(this.selectedVideo)
        this.prevMovingUrl = res.previous;
        if (this.prevMovingUrl == null) {
          this.disablePrevPaginations = true;
        } else {
          this.disablePrevPaginations = false;
        }

        this.nextMovingUrl = res.next;
        if (this.nextMovingUrl == null) {
          this.disableNextPaginations = true;
        } else {
          this.disableNextPaginations = false;
        }
        this.spinner.hide();
        this.trainingVideoID = "";
      } else {
        this.selectedVideo = "";
        this.spinner.hide();
      }
    });
  }

  selectedCatItemfun(catId) {
    this.selectedCatItem = catId;
    this.showTrainningItem();
  }
  showHideContent() {
    this.showmoreContent = !this.showmoreContent;
  }
  playVideo(index) {
    //this.selectedVideo = this.videosList[index];
    // this.router.navigate(["training", "highlight-video"]);
    this.deletedVideo = "";
    this.selectedVideo = null;
    //if (this.videoFileContainer) {
      this.selectedVideo = this.videosList[index];
      this.videoFileContainer.nativeElement.load();
      this.videoFileContainer.nativeElement.play();
    //}
    window.scroll(0, 0);
  }
  prevnextMovingItems(index) {
    this.spinner.show();
    let url;
    if (this.selectedCatItem == "all") {
      url = `${this.appService.trainingVideoItems}?`;
    } else {
      url = `${this.appService.trainingVideoItems}${this.selectedCatItem}?`;
    }
    if (index === "next") {
      if (this.nextMovingUrl != null) {
        this.splitUrl = this.nextMovingUrl.split("?");
        url += this.splitUrl[1];
      }
    } else {
      if (this.prevMovingUrl != null) {
        this.splitUrl = this.prevMovingUrl.split("?");
        url += this.splitUrl[1];
      }
    }
    this.videosList = [];
    const apiObj = {
      url: url,
      methodType: HttpMethod.GET,
    };
    this.commonService.commonApiCall(apiObj, (res) => {
      if (res && res.hasOwnProperty("results") && res.results) {
        for (var i = 0; i < res.results.length; i++) {
          this.videosList.push(res.results[i]);
        }
        this.prevMovingUrl = res.previous;
        if (this.prevMovingUrl == null) {
          this.disablePrevPaginations = true;
        } else {
          this.disablePrevPaginations = false;
        }

        this.nextMovingUrl = res.next;
        if (this.nextMovingUrl == null) {
          this.disableNextPaginations = true;
        } else {
          this.disableNextPaginations = false;
        }
        this.spinner.hide();
      } else {
        this.spinner.hide();
      }
    });
  }
}
