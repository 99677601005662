<div class="guru-tabs-row">
  <h2>
    <a
      (click)="backToGurulist()"
      href="javascript:void(0)"
      class="backToGurulist"
      >MSR</a
    >
    <span class="subhead">{{ recDetails.msr_name }}</span>
  </h2>
  <div class="tb-select-dropdown">
    <div class="tb-selectedVal" (click)="selectValfun()">
      {{ selectedItem }}
    </div>
    <ul class="tb-dropdown-options" *ngIf="showDropdown">
      <li
        *ngFor="let item of commonService.viewTypeOptions; let i = index"
        (click)="select(i)"
        [ngClass]="{ selected: isActive(item.paramName) }"
      >
        {{ item.title }}
      </li>
    </ul>
  </div>
</div>
<div class="comn-box">
  <div class="main-profile-container">
    <div class="profile-container">
      <div class="profile-display-container">
        <div class="tb-status">
          <span>{{ recDetails?.status | titlecase }}</span>
        </div>
        <div class="guru-profile-image">
          {{ recDetails?.profile_image }}
          <img
            [src]="
              recDetails?.profile_image
                ? recDetails?.profile_image
                : './assets/images/demo-img.png'
            "
          />
        </div>

        <div class="profile-text-wrap">
          <h3>{{ recDetails.msr_name }}</h3>
          <div [class]="recDetails?.rating_class">
            <i class="star"></i>
            <i class="star"></i>
            <i class="star"></i>
            <i class="star"></i>
            <i class="star"></i>
          </div>
          <div class="tb-phone">
            <i class="phone-icon"></i>
            <a [href]="'tel:' + recDetails?.msr_contact_number">
              <span>{{ recDetails?.msr_contact_number }}</span>
            </a>
          </div>
          <!-- <a class="link-button">Update Profile</a>
          <a class="link-button view-reg">View Registration Details</a> -->
        </div>
      </div>
    </div>
    <div class="data-container">
      <div class="data-tab-wrapper">
        <div class="bandhu-tabs tb-temp-hide">
          <mat-button-toggle-group
            name="fontStyle"
            value="All"
            aria-label="Font Style"
          >
            <mat-button-toggle value="All">All</mat-button-toggle>
            <mat-button-toggle value="TGP">TGP</mat-button-toggle>
            <mat-button-toggle value="Durafit">Durafit</mat-button-toggle>
            <mat-button-toggle value="Profile">Profile</mat-button-toggle>
          </mat-button-toggle-group>
        </div>
        <div *ngIf="userDetails.role == 'Distributor'"><a class="link-button" (click)="openDialog()">Delete</a></div>
      </div>
      <h4>Guru Summary</h4>
      <div class="coupon-box-wrap">
        <div class="profile-box">
          <h2>{{ recDetails.total_guru_count }} <small> Total Gurus</small></h2>
        </div>
        <div class="profile-box active">
          <h2>
            {{ recDetails?.active_gurus_count }}
            <small> Active</small>
          </h2>
        </div>
        <div class="profile-box transacting">
          <h2>
            {{ recDetails?.transacting_gurus_count }}
            <small> Transacting</small>
          </h2>
        </div>
        <div class="profile-box inactive">
          <h2>
            {{ recDetails?.inactive_gurus_count }}
            <small> Inactive</small>
          </h2>
        </div>
        <!-- <div class="profile-box">
          <h2>{{ recDetails?.job_cards }} <small> MTD</small></h2>
        </div> -->
      </div>
      <h4>User Information</h4>
      <div class="coupon-box-wrap">
        <div class="user-information-box">
          <div>
            <h4>
              User ID: <small> {{ recDetails?.msr_user_id }}</small>
            </h4>
          </div>
          <b class="seprator"></b>
          <div>
            <h4>
              Area: <small> {{ recDetails?.msr_area }}</small>
            </h4>
          </div>
          <b class="seprator"></b>
          <div>
            <h4>
              Associated Since:
              <small> {{ recDetails?.associated_since }}</small>
            </h4>
          </div>
          <b class="seprator"></b>
          <div>
            <h4>
              Email ID:
              <small>
                <a [href]="'mailto:' + recDetails?.msr_email_id">{{
                  recDetails?.msr_email_id
                }}</a></small
              >
            </h4>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
