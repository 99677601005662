<div class="tb-table-wrapper">
  <h2>MSR List</h2>
  <div class="guru-tabs-row">
    <div class="bandhu-tabs">
      <mat-button-toggle-group
        name="fontStyle"
        value="All"
        aria-label="Font Style"
      >
        <mat-button-toggle value="All" (click)="setMSRlistTab('all')"
          >All</mat-button-toggle
        >
        <mat-button-toggle value="Low" (click)="setMSRlistTab('low')"
          >Low</mat-button-toggle
        >
        <mat-button-toggle value="Average" (click)="setMSRlistTab('average')"
          >Average</mat-button-toggle
        >
        <mat-button-toggle value="High" (click)="setMSRlistTab('high')"
          >High</mat-button-toggle
        >
      </mat-button-toggle-group>
    </div>
    <div class="tb-btn-group">
      <ul>
        <!-- <li>
          <div class="tb-filter-wrapper">
            <button mat-stroked-button class="primary-stroke-button filterBtn">
              Filter
            </button>
          </div>
        </li> -->
        <!-- <li>
            <button mat-stroked-button class="primary-stroke-button exportBtn">
              Export
            </button>
          </li> -->
      </ul>
    </div>
  </div>

  <div class="guru-list-table" *ngIf="!showNodata">
    <!-- <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar> -->
    <table
      mat-table
      matTableExporter
      class="full-width-table tb-table"
      matSort
      aria-label="Elements"
      [dataSource]="dataSource"
    >
      <ng-container matColumnDef="guru_username">
        <th class="guru-detailed-col" mat-header-cell *matHeaderCellDef>
          MSRs
        </th>
        <td class="guru-detailed-col" mat-cell *matCellDef="let element">
          <div class="comn-img-cont">
            <div class="comn-item-img">
              <img
                [src]="
                  element.profile_image
                    ? element.profile_image
                    : './assets/images/demo-img.png'
                "
                alt=""
              />
            </div>
            <div class="comn-item-detail">
              <a
                class="comn-item-name"
                [routerLink]="['msr-detail', element.id]"
                >{{ element.full_name }}</a
              ><span class="small-font">{{ element.location }}</span>
            </div>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="num_of_gurus">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="guru-col">
          Total Gurus
        </th>
        <td mat-cell *matCellDef="let element" class="guru-col">
          {{ element.num_of_gurus }}
        </td>
      </ng-container>

      <!-- <ng-container matColumnDef="actGurus">
        <th class="guru-col" mat-header-cell *matHeaderCellDef></th>
        <td class="guru-col" mat-cell *matCellDef="let element">
          {{ element.num_of_active_gurus }}
        </td>
      </ng-container>

      <ng-container matColumnDef="trangurus">
        <th class="guru-col" mat-header-cell *matHeaderCellDef></th>
        <td class="guru-col" mat-cell *matCellDef="let element">
          {{ element.num_of_transacting_gurus }}
        </td>
      </ng-container>

      <ng-container matColumnDef="ptsEarned">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          {{ element.num_of_inactive_gurus }}
        </td>
      </ng-container> -->

      <ng-container matColumnDef="part_fitment_count">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Part Item Fitments
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.part_fitment_count }}
        </td>
      </ng-container>

      <!-- <ng-container matColumnDef="ptsEarned">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Pts. Earned</th>
          <td mat-cell *matCellDef="let element">{{ element.ptsEarned }}</td>
        </ng-container> -->

      <ng-container matColumnDef="associated_since">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Associated Since
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.associated_since }}
          <!-- <span class="small-font">{{ element.associated_since }}</span> -->
        </td>
      </ng-container>

      <ng-container matColumnDef="phone_number">
        <th class="contact-col" mat-header-cell *matHeaderCellDef>Contact</th>
        <td
          class="contact-col guru-last-col"
          mat-cell
          *matCellDef="let element"
        >
          {{ element.phone_number }}
        </td>
      </ng-container>

      <!-- <ng-container matColumnDef="firstEmpty">
        <th mat-header-cell *matHeaderCellDef colspan="1"></th>
      </ng-container>
      <ng-container matColumnDef="totalGurus">
        <th mat-header-cell *matHeaderCellDef colspan="1">
          Total
        </th>
      </ng-container>
      <ng-container matColumnDef="activeGurus">
        <th mat-header-cell *matHeaderCellDef colspan="1">
          Active
        </th>
      </ng-container>
      <ng-container matColumnDef="transactingurus">
        <th mat-header-cell *matHeaderCellDef colspan="1">
          Transacting
        </th>
      </ng-container>
      <ng-container matColumnDef="inactivegurus">
        <th mat-header-cell *matHeaderCellDef colspan="1">
          In active
        </th>
      </ng-container>
      <ng-container matColumnDef="lastEmpty">
        <th mat-header-cell *matHeaderCellDef colspan="4"></th>
      </ng-container>

      
      <tr class="tb-head-row tb-sechead-row" mat-header-row *matHeaderRowDef="[
          'firstEmpty',
          'totalGurus',
          'activeGurus',
          'transactingurus',
          'inactivegurus',
          'lastEmpty'
        ]"></tr> -->
      <tr
        class="tb-head-row"
        mat-header-row
        *matHeaderRowDef="displayedColumns"
      ></tr>
      <tr
        class="tb-item-row"
        mat-row
        *matRowDef="let row; columns: displayedColumns"
      ></tr>
    </table>
  </div>
  <ng-container *ngIf="showNodata">
    <div class="no-item-found">No record found</div>
  </ng-container>

  <!-- <div class="no-item-found" *ngIf="showNodata">No record founds</div> -->

  <mat-paginator
    appPagination
    showFirstLastButtons
    [length]="totalRows"
    [pageIndex]="currentPage"
    [pageSize]="pageSize"
    (page)="pageChanged($event)"
    [hidden]="showNodata"
    class="custom-paginator"
    [hidePageSize]="true"
  >
  </mat-paginator>
</div>

<ngx-spinner></ngx-spinner>
