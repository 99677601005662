import { Component, OnInit } from "@angular/core";
import { HttpMethod } from "src/app/constants/http-handlers";
import { AppService } from "src/app/services/app.service";
import { CommonService } from "src/app/services/common.service";

@Component({
  selector: "app-top-transacting-gurus",
  templateUrl: "./top-transacting-gurus.component.html",
  styleUrls: ["./top-transacting-gurus.component.scss"],
})
export class TopTransactingGurusComponent implements OnInit {
  topGurusItems: any[] = [];
  constructor(
    private commonService: CommonService,
    private appService: AppService
  ) {}

  ngOnInit(): void {
    const url = `${this.appService.topGurus}`;
    const apiObj = {
      url: url,
      methodType: HttpMethod.GET,
    };
    this.commonService.commonApiCall(apiObj, (res) => {
      for (var i = 0; i < res.length; i++) {
        this.topGurusItems.push(res[i]);
      }
    });
  }
}
