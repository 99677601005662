import { Component, OnInit } from "@angular/core";
import { HttpMethod } from "src/app/constants/http-handlers";
import { AppService } from "src/app/services/app.service";
import { CommonService } from "src/app/services/common.service";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: "app-line-item-summary",
  templateUrl: "./line-item-summary.component.html",
  styleUrls: ["./line-item-summary.component.scss"],
})
export class LineItemSummaryComponent implements OnInit {
  promotionalItems: any[] = [];
  selectedTabItem: string;
  searchText: string = "";
  serachPartItem: string;
  prevMovingUrl: string;
  nextMovingUrl: string;
  splitUrl: any[];
  disableNextPaginations: boolean;
  disablePrevPaginations: boolean;
  globalUserRoleId: any;

  constructor(
    private commonService: CommonService,
    private appService: AppService,
    private spinner: NgxSpinnerService
  ) {}

  selectedTab(index) {
    this.selectedTabItem = index;
    this.getmovingParts();
  }
  searchData() {
    this.serachPartItem = this.searchText.trim();
    //console.log(this.serachPartItem);
    this.getmovingParts();
  }

  checkText(event: KeyboardEvent) {
    if (event.code == "Enter" || this.searchText.trim() == "") {
      this.searchData();
    }
  }
  ngOnInit(): void {
    this.selectedTabItem = "slow";
    this.getmovingParts();
  }

  getmovingParts() {
    this.spinner.show();
    this.commonService.globalselectedUseroleId.subscribe(
      (message) => (this.globalUserRoleId = message)
    );
    this.promotionalItems = [];
    let url;
    if (this.selectedTabItem == "slow") {
      // url = `${this.appService.slowmovingsParts}?page_size=8`;
      if (this.globalUserRoleId !== "") {
        url = `${this.appService.slowmovingsParts}?page_size=8&user_id=${this.globalUserRoleId}`;
      } else {
        url = `${this.appService.slowmovingsParts}?page_size=8`;
      }
    } else {
      //url = `${this.appService.fastmovingsParts}?page_size=8`;
      if (this.globalUserRoleId !== "") {
        url = `${this.appService.fastmovingsParts}?page_size=8&user_id=${this.globalUserRoleId}`;
      } else {
        url = `${this.appService.fastmovingsParts}?page_size=8`;
      }
    }
    let queryParamList = [];
    if (this.searchText.length > 0) {
      let searchParam = `filter=` + this.searchText;
      queryParamList.push(searchParam);
    }

    let apiQueryParam = "";
    if (queryParamList.length > 0) {
      apiQueryParam = `&` + queryParamList.join("&");
    }
    url += `${apiQueryParam}`;

    const apiObj = {
      url: url,
      methodType: HttpMethod.GET,
    };
    this.commonService.commonApiCall(apiObj, (res) => {
      if (res && res.hasOwnProperty("results") && res.results) {
        for (var i = 0; i < res.results.length; i++) {
          this.promotionalItems.push(res.results[i]);
        }
        this.prevMovingUrl = res.previous;
        if (this.prevMovingUrl == null) {
          this.disablePrevPaginations = true;
        } else {
          this.disablePrevPaginations = false;
        }

        this.nextMovingUrl = res.next;
        if (this.nextMovingUrl == null) {
          this.disableNextPaginations = true;
        } else {
          this.disableNextPaginations = false;
        }
        this.spinner.hide();
      } else {
        this.spinner.hide();
      }
    });
  }

  prevnextMovingItems(index) {
    this.spinner.show();
    let url;
    if (index === "next") {
      if (this.nextMovingUrl != null) {
        this.promotionalItems = [];
        this.splitUrl = this.nextMovingUrl.split("?");
        if (this.selectedTabItem == "slow") {
          url = `${this.appService.slowmovingsParts}?`;
        } else {
          url = `${this.appService.fastmovingsParts}?`;
        }
        url += this.splitUrl[1];
      }
    } else {
      if (this.prevMovingUrl != null) {
        this.promotionalItems = [];
        this.splitUrl = this.prevMovingUrl.split("?");
        if (this.selectedTabItem == "slow") {
          url = `${this.appService.slowmovingsParts}?`;
        } else {
          url = `${this.appService.fastmovingsParts}?`;
        }
        url += this.splitUrl[1];
      }
    }
    //console.log(url);

    const apiObj = {
      url: url,
      methodType: HttpMethod.GET,
    };
    this.commonService.commonApiCall(apiObj, (res) => {
      if (res && res.hasOwnProperty("results") && res.results) {
        for (var i = 0; i < res.results.length; i++) {
          this.promotionalItems.push(res.results[i]);
        }
        this.prevMovingUrl = res.previous;
        if (this.prevMovingUrl == null) {
          this.disablePrevPaginations = true;
        } else {
          this.disablePrevPaginations = false;
        }
        this.nextMovingUrl = res.next;
        if (this.nextMovingUrl == null) {
          this.disableNextPaginations = true;
        } else {
          this.disableNextPaginations = false;
        }
        this.spinner.hide();
      } else {
        this.spinner.hide();
      }
    });
  }
}
