<h2>
  {{ gurutype }} <span class="subhead">registration form-Personal details</span>
</h2>
<!-- #stepper linear -->
<mat-stepper class="guruform-stepper" #stepper linear>
  <mat-step [stepControl]="personalDetailForm" [editable]="isEditable">
    <form [formGroup]="personalDetailForm" (ngSubmit)="getPersonalData()" autocomplete="off">
      <ng-template matStepLabel>Personal Details</ng-template>
      <div class="form-wrap comn-box">
        <div class="form-section">
          <div class="form-row">
            <div class="form-col">
              <div class="form-label">
                Registration Date <span class="requiredfield">*</span>
              </div>
              <mat-form-field appearance="outline" floatLabel="always" class="form-date">
                <input matInput [matDatepicker]="registerationdate" id="registerationdate" type="text" readonly
                  placeholder="MM/DD/YYYY" formControlName="registerationdate" [max]="today"
                  (keypress)="keyDateFormat($event)" />
                <mat-datepicker-toggle matSuffix [for]="registerationdate"></mat-datepicker-toggle>
                <mat-datepicker #registerationdate></mat-datepicker>
              </mat-form-field>

              <div *ngIf="submitted && personalinvalid.registerationdate.errors" class="invalid-field">
                <span *ngIf="personalinvalid.registerationdate.errors.required">Registration Date is required</span>
              </div>
            </div>
          </div>
        </div>
        <div class="form-section">
          <div class="form-row">
            <div class="form-col">
              <label for="firstName" class="form-label">First Name <span class="requiredfield">*</span></label>
              <input type="text" class="form-control" id="firstName" formControlName="firstName"
                [readonly]="updateGuruForm" appOnlyAlphabet />
              <div *ngIf="submitted && personalinvalid.firstName.errors" class="invalid-field">
                <span *ngIf="personalinvalid.firstName.errors.required">First Name is required</span>
                <span *ngIf="personalinvalid.firstName.errors.pattern">Please enter valid First Name</span>
                <span
                  *ngIf="!personalinvalid.firstName.errors.required && personalinvalid.firstName.errors.whitespace">Please
                  enter valid data</span>
              </div>
            </div>
            <div class="form-col">
              <label for="middlename" class="form-label">Middle Name </label>
              <input type="text" class="form-control" id="middlename" formControlName="middlename" appOnlyAlphabet />
              <div *ngIf="submitted && personalinvalid.middlename.errors" class="invalid-field">
                <span *ngIf="personalinvalid.middlename.errors.pattern">Please enter valid Middle Name</span>
              </div>
            </div>
            <div class="form-col">
              <label for="lastName" class="form-label">Last Name <span class="requiredfield">*</span></label>
              <input type="text" class="form-control" [readonly]="updateGuruForm" id="lastName"
                formControlName="lastName" appOnlyAlphabet />
              <div *ngIf="submitted && personalinvalid.lastName.errors" class="invalid-field">
                <span *ngIf="personalinvalid.lastName.errors.required">Last Name is required</span>
                <span *ngIf="personalinvalid.lastName.errors.pattern">Please enter valid Last Name</span>
                <span
                  *ngIf="!personalinvalid.lastName.errors.required && personalinvalid.lastName.errors.whitespace">Please
                  enter valid data</span>
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="form-col">
              <div class="form-label">
                Date of Birth <span class="requiredfield">*</span>
              </div>
              <mat-form-field appearance="outline" floatLabel="always" class="form-date">
                <input matInput [matDatepicker]="guruDOB" type="text" readonly placeholder="MM/DD/YYYY" id="guruDOB"
                  formControlName="guruDOB" [max]="dobdate" (keypress)="keyDateFormat($event)" />
                <mat-datepicker-toggle matSuffix [for]="guruDOB"></mat-datepicker-toggle>
                <mat-datepicker #guruDOB></mat-datepicker>
              </mat-form-field>
              <div *ngIf="submitted && personalinvalid.guruDOB.errors" class="invalid-field">
                <span *ngIf="personalinvalid.guruDOB.errors.required">Date of Birth is required</span>
              </div>
            </div>
          </div>

          <div class="form-row">
            <div class="form-col form-full">
              <label for="homeaddress" class="form-label">House Address <span class="requiredfield">*</span></label>
              <textarea class="form-control" id="homeaddress" formControlName="homeaddress" autocomplete="none"
                maxlength="200"></textarea>
              <div *ngIf="submitted && personalinvalid.homeaddress.errors" class="invalid-field">
                <span *ngIf="personalinvalid.homeaddress.errors.required">House Address is required</span>
                <span
                  *ngIf="!personalinvalid.homeaddress.errors.required && personalinvalid.homeaddress.errors.whitespace">Please
                  enter valid data</span>
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="form-col">
              <label for="pinCode" class="form-label">Pin Code <span class="requiredfield">*</span></label>
              <input type="text" (focusout)="getpincode()" class="form-control" id="pinCode" formControlName="pinCode"
                maxlength="6" autocomplete="none" (keyup)="checkText($event)" (keypress)="keyPressNumbers($event)" />
              <div *ngIf="submitted && personalinvalid.pinCode.errors" class="invalid-field">
                <span *ngIf="personalinvalid.pinCode.errors.required">Pin Code is required</span>
                <span *ngIf="personalinvalid.pinCode.errors.pattern">Pin Code is not valid</span>
              </div>
              <div *ngIf="!personalinvalid.pinCode.errors && pincodeErrorMsg" class="invalid-field">
                {{ pincodeErrorMsg }}
              </div>
            </div>
            <div class="form-col">
              <label for="city" class="form-label">City/Town </label>
              <input type="text" class="form-control" id="city" #city readonly formControlName="city" />
            </div>
            <div class="form-col">
              <label for="district" class="form-label">District </label>
              <input type="text" class="form-control" id="district" #district [value]="districtname" readonly
                formControlName="district" />
            </div>
          </div>
          <div class="form-row">
            <div class="form-col">
              <label for="state" class="form-label">State </label>
              <input type="text" class="form-control" id="state" #state readonly formControlName="state" />
            </div>
            <div class="form-col">
              <label for="region" class="form-label">Region </label>
              <input type="text" class="form-control" id="region" #region readonly formControlName="region" />
            </div>
            <div class="form-col">
              <label for="country" class="form-label">Country </label>
              <input type="text" class="form-control" id="country" #country readonly formControlName="country" />
            </div>
          </div>
          <div class="form-row">
            <div class="form-col">
              <label for="primarycontact" class="form-label">Contact 1 <span class="requiredfield">*</span></label>
              <input type="text" (focusout)="checkuser($event)" class="form-control" id="primarycontact"
                formControlName="primarycontact" maxlength="10" [readonly]="updateGuruForm"
                (keypress)="keyPressNumbers($event)" />
              <div *ngIf="submitted && personalinvalid.primarycontact.errors" class="invalid-field">
                <ng-container *ngIf="!userExitStatus">
                  <span *ngIf="
                      personalinvalid.primarycontact.errors.pattern ||
                      personalinvalid.primarycontact.errors.min
                    ">Please enter valid Contact Number</span>
                  <span *ngIf="personalinvalid.primarycontact.errors.required">Contact is required</span>
                </ng-container>
              </div>
              <div class="invalid-field" *ngIf="userExitStatus">
                <span>{{ userExitStatus }}</span>
              </div>
            </div>
            <div class="form-col">
              <label for="secondarycontact" class="form-label">Contact 2
              </label>
              <input type="text" class="form-control" id="secondarycontact" formControlName="secondarycontact"
                maxlength="10" (keypress)="keyPressNumbers($event)" />
              <div *ngIf="submitted && personalinvalid.secondarycontact.errors" class="invalid-field">
                <span *ngIf="
                    personalinvalid.secondarycontact.errors.pattern ||
                    personalinvalid.primarycontact.errors.min
                  ">Please enter valid Number</span>
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="form-col form-half">
              <label for="emailId" class="form-label">Email ID </label>
              <input type="text" class="form-control" id="emailId" formControlName="emailId" />
              <div *ngIf="submitted && personalinvalid.emailId.errors" class="invalid-field">
                <span *ngIf="personalinvalid.emailId.errors.pattern">Please enter valid Email ID</span>
              </div>
            </div>
          </div>
        </div>

        <div class="form-section">
          <div class="form-row">
            <div class="form-col col-full">
              <span class="form-label">Marital Status <span class="requiredfield">*</span></span>
              <mat-radio-group aria-label="Marital Status" color="primary" class="form-radio"
                formControlName="maritalState">
                <mat-radio-button *ngFor="let maritalState of maritalStatus" [value]="maritalState"
                  (click)="setMaritalState(maritalState)">
                  {{ maritalState }}</mat-radio-button>
              </mat-radio-group>
              <div *ngIf="submitted && personalinvalid.maritalState.errors" class="invalid-field">
                <span *ngIf="personalinvalid.maritalState.errors.required">Marital Status is required</span>
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="form-col">
              <div class="form-label">Wedding Date</div>
              <mat-form-field appearance="outline" floatLabel="always" class="form-date"
                [ngClass]="{ disabled: disableWeddingDate }">
                <input matInput [matDatepicker]="weddingdate" id="weddingdate" type="text" placeholder="MM/DD/YYYY"
                  formControlName="weddingdate" [max]="today" (keypress)="keyDateFormat($event)" readonly />
                <mat-datepicker-toggle matSuffix [for]="weddingdate"></mat-datepicker-toggle>
                <mat-datepicker #weddingdate></mat-datepicker>
              </mat-form-field>
              <div *ngIf="submitted && personalinvalid.weddingdate.errors" class="invalid-field">
                <span *ngIf="personalinvalid.weddingdate.errors.required">Wedding Date is required</span>
              </div>
            </div>
          </div>
        </div>

        <div class="form-section">
          <div class="form-row">
            <div class="form-col col-full">
              <span class="form-label">Education <span class="requiredfield">*</span></span>

              <mat-radio-group aria-label="Education" color="primary" class="form-radio" formControlName="education">
                <mat-radio-button *ngFor="let education of educations" [value]="education">
                  {{ education }}
                </mat-radio-button>
              </mat-radio-group>
              <div *ngIf="submitted && personalinvalid.education.errors" class="invalid-field">
                <span *ngIf="personalinvalid.education.errors.required">Education detail is required</span>
              </div>
            </div>
          </div>
        </div>
        <div class="form-section">
          <div class="form-row">
            <div class="form-col col-full">
              <span class="form-label">Identity Proof <span class="requiredfield">*</span></span>
              <mat-radio-group aria-label="Identity Proof" color="primary" class="form-radio" formControlName="idproof">
                <mat-radio-button *ngFor="let idproof of idproofs" [value]="idproof"
                  (click)="setIdentityProof(idproof)">
                  {{ idproof }}</mat-radio-button>
              </mat-radio-group>
              <div *ngIf="submitted && personalinvalid.idproof.errors" class="invalid-field">
                <span *ngIf="personalinvalid.idproof.errors.required">Identity proof detail is required</span>
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="form-col form-half">
              <label for="idnumber" class="form-label">ID Number <span class="requiredfield">*</span></label>
              <input type="text" class="form-control" id="idnumber" formControlName="idnumber"
                (keypress)="keyPressSpclCharacter($event)" />
              <div *ngIf="submitted && personalinvalid.idnumber.errors" class="invalid-field">
                <span *ngIf="personalinvalid.idnumber.errors.required">ID Number is required</span>
                <span *ngIf="personalinvalid.idnumber.errors.pattern">Please enter valid ID Number</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="guruform-btn">
        <button mat-flat-button matStepperNext class="primary-flat-button">
          Next
        </button>
      </div>
    </form>
  </mat-step>
  <mat-step [stepControl]="workDetailForm" [editable]="isEditable">
    <form [formGroup]="workDetailForm" (ngSubmit)="getWokrData()">
      <ng-template matStepLabel>Work Details</ng-template>
      <div class="form-wrap comn-box">
        <div class="form-section">
          <div class="form-row">
            <div class="form-col">
              <label for="garageName" class="form-label">Garage Name <span class="requiredfield">*</span></label>
              <input type="text" class="form-control" id="garageName" formControlName="garageName" />
              <div *ngIf="getWokrDatasubmitted && workinvalid.garageName.errors" class="invalid-field">
                <span *ngIf="workinvalid.garageName.errors.required">Garage Name is required</span>
                <span *ngIf="workinvalid.garageName.errors.pattern">Please enter valid Garage Name</span>
                <span *ngIf="!workinvalid.garageName.errors.required && workinvalid.garageName.errors.whitespace">Please
                  enter valid data</span>
              </div>
            </div>
            <div class="form-col">
              <label for="garageOwnerName" class="form-label">Garage Owner’s Name <span
                  class="requiredfield">*</span></label>
              <input type="text" class="form-control" id="garageOwnerName" formControlName="garageOwnerName"
                appOnlyAlphabet />
              <div class="invalid-field" *ngIf="
                  getWokrDatasubmitted && workinvalid.garageOwnerName.errors
                ">
                <span *ngIf="workinvalid.garageOwnerName.errors.required">Garage Owner’s Name is required</span>
                <span *ngIf="workinvalid.garageOwnerName.errors.pattern">Please enter valid Garage Owner’s Name</span>
                <span
                  *ngIf="!workinvalid.garageOwnerName.errors.required && workinvalid.garageOwnerName.errors.whitespace">Please
                  enter valid data</span>
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="form-col">
              <div class="form-label">
                Business Incorporation Date
                <!-- <span class="requiredfield">*</span> -->
              </div>
              <mat-form-field appearance="outline" floatLabel="always" class="form-date">
                <input matInput [matDatepicker]="businessIncorporationDate" id="businessIncorporationDate" type="text"
                  placeholder="MM/DD/YYYY" formControlName="businessIncorporationDate" [max]="today"
                  (keypress)="keyDateFormat($event)" readonly />
                <mat-datepicker-toggle matSuffix [for]="businessIncorporationDate"></mat-datepicker-toggle>
                <mat-datepicker #businessIncorporationDate></mat-datepicker>
              </mat-form-field>

              <!-- <div *ngIf="
                  getWokrDatasubmitted &&
                  workinvalid.businessIncorporationDate.errors
                " class="invalid-field">
                <span *ngIf="workinvalid.businessIncorporationDate.errors.required">Business Incorporation Date is
                  required</span>
              </div> -->
            </div>
          </div>
          <div class="form-row">
            <div class="form-col">
              <label for="gstNumber" class="form-label">GST Number
                <!-- <span class="requiredfield">*</span>
                 -->
              </label>
              <input type="text" placeholder="XXXXXXXXXXXXXXX" class="form-control" id="gstNumber"
                formControlName="gstNumber" (keypress)="keyPressSpclCharacter($event)" maxlength="15" />
              <div class="invalid-field" *ngIf="getWokrDatasubmitted && workinvalid.gstNumber.errors">
                <!-- <span *ngIf="workinvalid.gstNumber.errors.required">GST Number is required</span> -->
                <span *ngIf="workinvalid.gstNumber.errors.pattern">Please enter valid GST Number</span>
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="form-col form-full">
              <label for="garageaddress" class="form-label">Garage Address <span class="requiredfield">*</span></label>
              <textarea class="form-control" id="garageaddress" formControlName="garageaddress"
                maxlength="200"></textarea>
              <div class="invalid-field" *ngIf="getWokrDatasubmitted && workinvalid.garageaddress.errors">
                <span *ngIf="workinvalid.garageaddress.errors.required">Garage Address is required</span>
                <span
                  *ngIf="!workinvalid.garageaddress.errors.required && workinvalid.garageaddress.errors.whitespace">Please
                  enter valid data</span>
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="form-col">
              <label for="garragepinCode" class="form-label">Pin Code <span class="requiredfield">*</span></label>
              <input type="text" (focusout)="getgaragepincode()" class="form-control" id="garragepinCode"
                formControlName="garragepinCode" maxlength="6" (keyup)="garragecheckText($event)"
                (keypress)="keyPressNumbers($event)" />
              <div *ngIf="
                  getWokrDatasubmitted && workinvalid.garragepinCode.errors
                " class="invalid-field">
                <span *ngIf="workinvalid.garragepinCode.errors.required">Pin Code is required</span>
                <span *ngIf="workinvalid.garragepinCode.errors.pattern">Pin Code is not valid</span>
              </div>
              <div *ngIf="
                  !workinvalid.garragepinCode.errors && garragepincodeErrorMsg
                " class="invalid-field">
                {{ garragepincodeErrorMsg }}
              </div>
              <!-- <div
                *ngIf="!getWokrDatasubmitted && wrongPincode"
                class="invalid-field"
              >
                No Data found
              </div> -->
            </div>
            <div class="form-col">
              <label for="garragecity" class="form-label">City/Town </label>
              <input type="text" class="form-control" #garragecity id="garragecity" readonly
                formControlName="garragecity" />
            </div>
            <div class="form-col">
              <label for="garragedistrict" class="form-label">District </label>
              <input type="text" class="form-control" #garragedistrict id="garragedistrict" readonly
                formControlName="garragedistrict" />
            </div>
          </div>
          <div class="form-row">
            <div class="form-col">
              <label for="garragestate" class="form-label">State </label>
              <input type="text" class="form-control" #garragestate id="garragestate" readonly
                formControlName="garragestate" />
            </div>
            <div class="form-col">
              <label for="garrageregion" class="form-label">Region </label>
              <input type="text" class="form-control" #garrageregion id="garrageregion" readonly
                formControlName="garrageregion" />
            </div>
            <div class="form-col">
              <label for="garragecountry" class="form-label">Country </label>
              <input type="text" class="form-control" #garragecountry id="garragecountry" readonly
                formControlName="garragecountry" />
            </div>
          </div>
        </div>
        <!-- <div class="form-section">
          <div class="form-row">
            <div class="form-col form-full">
              <div class="form-label">Select Garage Location on Map</div>
              <app-select-location-v2 (coords)="getCoords($event)" [lat]="updatelat" [lng]="updatelng">
              </app-select-location-v2>
            </div>
          </div>
        </div> -->
        <div class="form-section">
          <div class="form-row">
            <div class="form-col form-full">
              <div class="form-label">Selected Location</div>
              <app-select-location [newLat]=updatelat [newLng]=updatelng (coords)="getCoords($event)">
              </app-select-location>
            </div>
          </div>
        </div>
        <div class="form-section">
          <div class="form-row">
            <div class="form-col">
              <div class="form-label">
                Vehicle Make <span class="requiredfield">*</span>
              </div>

              <div class="form-check" *ngFor="let vehicleOverhead of vehicleOverheads">
                <mat-checkbox id="vehicleOverhead{{ vehicleOverhead.id }}" [checked]="vehicleOverhead.checked" (change)="
                    onvMakeChange(vehicleOverhead.value, $event.checked)
                  " class="form-chkbox" color="primary">
                  {{ vehicleOverhead.value }}
                </mat-checkbox>

                <!--  -->
              </div>
              <div class="invalid-field" *ngIf="
                  getWokrDatasubmitted &&
                  this.workDetailForm.controls['vehicalMakeSel'].errors
                    ?.required
                ">
                <span>Vehicle Make is required, select atleast one value.</span>
              </div>
            </div>
            <div class="form-col">
              <div class="form-label">
                Vehicle Model Type Service <span class="requiredfield">*</span>
              </div>
              <div id="vehicleModel">
                <div class="form-check" *ngFor="
                    let vehicleModelTypeService of vehicleModelTypeServices
                  ">
                  <mat-checkbox id="vehicleModel{{ vehicleModelTypeService.id }}"
                    [checked]="vehicleModelTypeService.checked" (change)="
                      onvModelChange(
                        vehicleModelTypeService.value,
                        $event.checked
                      )
                    " class="form-chkbox" color="primary">
                    {{ vehicleModelTypeService.value }}
                  </mat-checkbox>
                </div>
              </div>
              <div class="invalid-field" *ngIf="
                  getWokrDatasubmitted &&
                  this.workDetailForm.controls['vehicalModelSel'].errors
                    ?.required
                ">
                <span>Vehicle Model Type is required, select atleast one
                  value.</span>
              </div>
            </div>
          </div>
        </div>
        <div class="form-section">
          <div class="form-row">
            <div class="form-col form-full">
              <div class="form-label">
                Major Area Work <span class="requiredfield">*</span>
              </div>
              <div class="form-check" *ngFor="let majorArea of majorAreas">
                <mat-checkbox id="majorArea{{ majorArea.id }}" [checked]="majorArea.checked"
                  (change)="onvMajorChange(majorArea.value, $event.checked)" class="form-chkbox" color="primary">
                  {{ majorArea.value }}
                </mat-checkbox>
              </div>
              <div class="invalid-field" *ngIf="
                  getWokrDatasubmitted &&
                  this.workDetailForm.controls['majorAreaSel'].errors?.required
                ">
                <span>Major Area Work is required, select atleast one value.</span>
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="form-col form-half">
              <label for="otherSpecificArea" class="form-label">Any other specific area of work</label>
              <input type="text" class="form-control" id="otherSpecificArea" formControlName="otherSpecificArea" />
            </div>
          </div>
        </div>
      </div>
      <div class="guruform-btn">
        <button mat-stroked-button matStepperPrevious class="primary-flat-button">
          Previous
        </button>
        <button mat-flat-button matStepperNext class="primary-stroke-button" type="submit">
          Next
        </button>
      </div>
    </form>
  </mat-step>
  <mat-step [stepControl]="dependantForm" [editable]="isEditable">
    <form [formGroup]="dependantForm" (ngSubmit)="dependantFormData()">
      <ng-template matStepLabel>Assistant Details</ng-template>
      <div class="form-wrap comn-box">
        <div class="form-section">
          <div class="form-row">
            <div class="form-col">
              <span class="form-label">Number of Assistant
                <!-- <span class="requiredfield">*</span> -->
              </span>
              <select class="form-control" formControlName="numOfDependants" (change)="addDependant()">
                <option value="">Please Select Num of Assistant</option>
                <option *ngFor="let num of numOfDependants" [value]="num">
                  {{ num }}
                </option>
              </select>

              <!-- <div *ngIf="
                  getdependentsubmitted &&
                  dependendinvalid.numOfDependants.errors
                " class="invalid-field">
                <span *ngIf="dependendinvalid.numOfDependants.errors.required">Number of Dependants is required</span>
              </div> -->
            </div>
          </div>
          <ng-container *ngIf="depedendentItem1">
            <div class="form-row" id="dependent1">
              <div class="form-col">
                <span class="form-label">Name of Assistant <span class="requiredfield">*</span></span>
                <input type="text" class="form-control" id="name1" formControlName="name1"
                  (change)="dependantFormData()" appOnlyAlphabet />
                <div *ngIf="getdependentsubmitted && dependendinvalid.name1.errors" class="invalid-field">
                  <span *ngIf="dependendinvalid.name1.errors.required">Assistant Name is required</span>
                  <span *ngIf="dependendinvalid.name1.errors.pattern">Please enter valid Assistant Name
                  </span>
                  <span
                    *ngIf="!dependendinvalid.name1.errors.required && dependendinvalid.name1.errors.whitespace">Please
                    enter valid data</span>
                </div>
              </div>
              <div class="form-col">
                <label for="yearOfservice1" class="form-label">Year of Service <span
                    class="requiredfield">*</span></label>
                <select name="yearOfservice1" class="form-control" id="yearOfservice1" formControlName="service_year1">
                  <option value="">Please select Year of Service</option>
                  <option *ngFor="let yearOfservice of yearOfservices" [value]="yearOfservice">
                    {{ yearOfservice }}
                  </option>
                </select>
                <div *ngIf="
                  getdependentsubmitted && dependendinvalid.service_year1.errors
                " class="invalid-field">
                  <span *ngIf="dependendinvalid.service_year1.errors.required">Year of Service is required</span>
                </div>
              </div>
              <div class="form-col">
                <div class="form-label">
                  Date of Birth <span class="requiredfield">*</span>
                </div>
                <mat-form-field appearance="outline" floatLabel="always" class="form-date">
                  <input matInput [matDatepicker]="picker" id="dependantDOB1" placeholder="MM/DD/YYYY"
                    formControlName="dob1" (keypress)="keyDateFormat($event)" [max]="dobdate" readonly />
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
                <div *ngIf="getdependentsubmitted && dependendinvalid.dob1.errors" class="invalid-field">
                  <span *ngIf="dependendinvalid.dob1.errors.required">
                    Date of Birth is required</span>
                </div>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="depedendentItem2">
            <div class="form-row" id="dependent2">
              <div class="form-col">
                <span class="form-label">Name of Assistant <span class="requiredfield">*</span></span>
                <input type="text" class="form-control" id="name2" formControlName="name2"
                  (change)="dependantFormData()" appOnlyAlphabet />
                <div *ngIf="getdependentsubmitted && dependendinvalid.name2.errors" class="invalid-field">
                  <span *ngIf="dependendinvalid.name2.errors.required">Assistant Name is required</span>
                  <span *ngIf="dependendinvalid.name2.errors.pattern">Please enter valid Assistant Name
                  </span>
                  <span
                    *ngIf="!dependendinvalid.name2.errors.required && dependendinvalid.name2.errors.whitespace">Please
                    enter valid data</span>
                </div>
              </div>
              <div class="form-col">
                <label for="yearOfservice2" class="form-label">Year of Service <span
                    class="requiredfield">*</span></label>
                <select name="yearOfservice2" class="form-control" id="yearOfservice2" formControlName="service_year2">
                  <option value="">Please select Year of Service</option>
                  <option *ngFor="let yearOfservice of yearOfservices" [value]="yearOfservice">
                    {{ yearOfservice }}
                  </option>
                </select>
                <div *ngIf="
                    getdependentsubmitted &&
                    dependendinvalid.service_year2.errors
                  " class="invalid-field">
                  <span *ngIf="dependendinvalid.service_year2.errors.required">Year of Service is required</span>
                </div>
              </div>
              <div class="form-col">
                <div class="form-label">
                  Date of Birth <span class="requiredfield">*</span>
                </div>
                <mat-form-field appearance="outline" floatLabel="always" class="form-date">
                  <input matInput [matDatepicker]="picker" id="dependantDOB2" placeholder="MM/DD/YYYY"
                    formControlName="dob2" (keypress)="keyDateFormat($event)" [max]="dobdate" readonly />
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
                <div *ngIf="getdependentsubmitted && dependendinvalid.dob2.errors" class="invalid-field">
                  <span *ngIf="dependendinvalid.dob2.errors.required">
                    Date of Birth is required</span>
                </div>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="depedendentItem3">
            <div class="form-row" id="dependent3">
              <div class="form-col">
                <span class="form-label">Name of Assistant <span class="requiredfield">*</span></span>
                <input type="text" class="form-control" id="name3" formControlName="name3"
                  (change)="dependantFormData()" appOnlyAlphabet />
                <div *ngIf="getdependentsubmitted && dependendinvalid.name3.errors" class="invalid-field">
                  <span *ngIf="dependendinvalid.name3.errors.required">Assistant Name is required</span>
                  <span *ngIf="dependendinvalid.name3.errors.pattern">Please enter valid Assistant Name
                  </span>
                  <span
                    *ngIf="!dependendinvalid.name3.errors.required && dependendinvalid.name3.errors.whitespace">Please
                    enter valid data</span>
                </div>
              </div>
              <div class="form-col">
                <label for="yearOfservice3" class="form-label">Year of Service <span
                    class="requiredfield">*</span></label>
                <select name="yearOfservice3" class="form-control" id="yearOfservice3" formControlName="service_year3">
                  <option value="">Please select Year of Service</option>
                  <option *ngFor="let yearOfservice of yearOfservices" [value]="yearOfservice">
                    {{ yearOfservice }}
                  </option>
                </select>
                <div *ngIf="
                    getdependentsubmitted &&
                    dependendinvalid.service_year3.errors
                  " class="invalid-field">
                  <span *ngIf="dependendinvalid.service_year3.errors.required">Year of Service is required</span>
                </div>
              </div>
              <div class="form-col">
                <div class="form-label">
                  Date of Birth <span class="requiredfield">*</span>
                </div>
                <mat-form-field appearance="outline" floatLabel="always" class="form-date">
                  <input matInput [matDatepicker]="picker" id="dependantDOB3" placeholder="MM/DD/YYYY"
                    formControlName="dob3" (keypress)="keyDateFormat($event)" [max]="dobdate" readonly />
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
                <div *ngIf="getdependentsubmitted && dependendinvalid.dob3.errors" class="invalid-field">
                  <span *ngIf="dependendinvalid.dob3.errors.required">
                    Date of Birth is required</span>
                  <!-- <span
                    *ngIf="
                      !dependendinvalid.dob3.valid &&
                      !dependendinvalid.dob3.errors.required
                    "
                    >Please enter valid Date of Birth</span
                  > -->
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
      <div class="guruform-btn">
        <button mat-stroked-button matStepperPrevious class="primary-flat-button">
          Previous
        </button>
        <button mat-flat-button matStepperNext class="primary-stroke-button" type="submit"
          (click)="changeDependantdate()">
          Next
        </button>
      </div>
    </form>
  </mat-step>
  <mat-step [stepControl]="otherDetailForm" [editable]="isEditable">
    <form [formGroup]="otherDetailForm">
      <ng-template matStepLabel>Other Details</ng-template>
      <div class="form-wrap comn-box">
        <div class="form-section">
          <div class="form-row">
            <div class="form-col">
              <label for="faceid" class="form-label">Facebook ID</label>
              <input type="text" class="form-control" id="faceid" formControlName="faceid" />
            </div>
          </div>
          <div class="form-row">
            <div class="form-col">
              <label for="hobbies" class="form-label">Hobbies </label>
              <input type="text" class="form-control" id="hobbies" formControlName="hobbies" />
            </div>
            <div class="form-col">
              <label for="interestPreference" class="form-label">Interests & Preferences</label>
              <input type="text" class="form-control" id="interestPreference" formControlName="interestPreference" />
            </div>
            <div class="form-col">
              <label for="hobbies" class="form-label">Aspirations </label>
              <input type="text" class="form-control" id="aspirations" formControlName="aspirations" />
            </div>
          </div>
          <ng-container *ngIf="!disableWeddingDate">
            <div class="form-row">
              <div class="form-col">
                <label for="spouseName" class="form-label">Spouse Name</label>
                <input type="text" class="form-control" id="spouseName" formControlName="spouseName" appOnlyAlphabet />
                <div *ngIf="getOthersubmitted && otherinvalid.spouseName.errors" class="invalid-field">
                  <span *ngIf="otherinvalid.spouseName.errors.pattern">Please enter valid Spouse Name</span>
                </div>
              </div>
              <div class="form-col">
                <div class="form-label">Spouse Date of Birth</div>
                <mat-form-field appearance="outline" floatLabel="always" class="form-date">
                  <input matInput [matDatepicker]="SpouseDOB" id="SpouseDOB" type="text" placeholder="MM/DD/YYYY"
                    formControlName="SpouseDOB" [max]="dobdate" (keypress)="keyDateFormat($event)" readonly />
                  <mat-datepicker-toggle matSuffix [for]="SpouseDOB"></mat-datepicker-toggle>
                  <mat-datepicker #SpouseDOB></mat-datepicker>
                </mat-form-field>
              </div>
            </div>
            <div class="form-row">
              <div class="form-col">
                <label for="spouseContact" class="form-label">Spouse Contact
                </label>
                <input type="text" class="form-control" id="spouseContact" formControlName="spouseContact"
                  (keypress)="keyPressNumbers($event)" maxlength="10" />
                <div *ngIf="getOthersubmitted && otherinvalid.spouseContact.errors" class="invalid-field">
                  <span *ngIf="
                      otherinvalid.spouseContact.errors.min ||
                      otherinvalid.spouseContact.errors.pattern
                    ">Please enter valid Contact</span>
                </div>
              </div>
              <div class="form-col">
                <label for="spouseEmail" class="form-label">Spouse Email ID
                </label>
                <input type="text" class="form-control" id="spouseEmail" placeholder="xyz@gmail.com"
                  formControlName="spouseEmail" />
                <div *ngIf="getOthersubmitted && otherinvalid.spouseEmail.errors" class="invalid-field">
                  <span *ngIf="otherinvalid.spouseEmail.errors.pattern">Please enter valid Email ID</span>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
        <ng-container *ngIf="!disableWeddingDate">
          <div class="form-section">
            <div class="form-row">
              <div class="form-col">
                <span class="form-label">Number of Kids </span>
                <select class="form-control" formControlName="kidsNum" (change)="addKid()" [(ngModel)]="selectedKids">
                  <option *ngFor="let item of numOfkidsItem" [value]="item">
                    {{ item }}
                  </option>
                </select>
              </div>
            </div>

            <div id="kids1">
              <div class="form-row">
                <div class="form-col">
                  <label for="kidname1" class="form-label">Name </label>
                  <input type="text" class="form-control" id="kidname1" formControlName="kidname1" appOnlyAlphabet />
                  <div *ngIf="getOthersubmitted && otherinvalid.kidname1.errors" class="invalid-field">
                    <span *ngIf="otherinvalid.kidname1.errors.pattern">Please enter valid Name</span>
                  </div>
                </div>
                <div class="form-col">
                  <label for="kidGender1" class="form-label">Gender </label>
                  <select name="kidGender1" class="form-control" id="kidGender1" formControlName="kidGender1">
                    <option value="">Please select Gender</option>
                    <option *ngFor="let genderItem of Gender" [value]="genderItem">
                      {{ genderItem }}
                    </option>
                  </select>
                </div>
                <div class="form-col">
                  <label for="kidseducation1" class="form-label">Education
                  </label>
                  <input type="text" class="form-control" id="kidseducation1" formControlName="kidseducation1" />
                </div>
              </div>
            </div>

            <div id="kids2" *ngIf="kid2Detail">
              <div class="form-row">
                <div class="form-col">
                  <label for="kidname2" class="form-label">Name </label>
                  <input type="text" class="form-control" id="kidname2" formControlName="kidname2" appOnlyAlphabet />
                  <div *ngIf="getOthersubmitted && otherinvalid.kidname2.errors" class="invalid-field">
                    <span *ngIf="otherinvalid.kidname2.errors.pattern">Please enter valid Name</span>
                  </div>
                </div>
                <div class="form-col">
                  <label for="kidGender2" class="form-label">Gender </label>
                  <select name="kidGender2" class="form-control" id="kidGender2" formControlName="kidGender2">
                    <option value="">Please select Gender</option>
                    <option *ngFor="let genderItem of Gender" [value]="genderItem">
                      {{ genderItem }}
                    </option>
                  </select>
                </div>
                <div class="form-col">
                  <label for="kidseducation2" class="form-label">Education
                  </label>
                  <input type="text" class="form-control" id="kidseducation2" formControlName="kidseducation2" />
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </form>
    <div class="guruform-btn">
      <button mat-stroked-button matStepperPrevious class="primary-flat-button">
        Previous
      </button>
      <button mat-flat-button matStepperNext class="primary-stroke-button" type="submit"
        (click)="otherDetailFormData()">
        Next
      </button>
    </div>
  </mat-step>
  <mat-step [stepControl]="uploadDocForm">
    <ng-template matStepLabel>Upload Documents</ng-template>
    <div class="form-wrap comn-box">
      <div class="form-section">
        <div class="form-row">
          <div class="form-col form-col-half form-file">
            <div class="form-label">Upload Guru’s Picture</div>
            <div class="test">
              <input [hidden]="true" type="file" accept="image/jpeg, image/png, image/jpg" #fileSelect
                (change)="openDialogUploadUpdate(fileSelect.files, 1,$event)" />

              <button class="uploadBtn" mat-button (click)="fileSelect.click()">
                Choose File
              </button>

              <span class="fileName">
                <ng-container *ngIf="guruPictureFile.length">{{
                  guruPictureFile
                }}<mat-icon class="workshop-icon" (click)="openDialog('profile_image')">close</mat-icon>
                </ng-container>
                <ng-container *ngIf="!guruPictureFile.length"></ng-container>
              </span>
            </div>
            <div class="formfield-hint">
              Upload jpg or png. File size can’t be over 5MB.
            </div>
          </div>
          <div class="form-col form-col-half form-file">
            <div class="form-label">Upload Guru’s ID Card</div>
            <!-- <mat-form-field
              appearance="outline"
              floatLabel="always"
              class="form-file"
            > -->
            <input [hidden]="true" (change)="openDialogUploadUpdate(fileSelect2.files, 2,$event)" type="file"
              accept="image/jpeg, image/png, image/jpg" #fileSelect2 />
            <!-- <input
                readonly
                matInput
                [(ngModel)]="filename2"
                placeholder="Select..."
              /> -->
            <button class="uploadBtn" mat-button (click)="fileSelect2.click()">
              Choose File
            </button>

            <span class="fileName">
              <ng-container *ngIf="guruIDFile.length">{{
                guruIDFile
              }}<mat-icon class="workshop-icon" (click)="openDialog('guru_id_card')">close</mat-icon>
              </ng-container>
              <ng-container *ngIf="!guruIDFile.length"></ng-container>
            </span>
            <!-- </mat-form-field> -->
            <div class="formfield-hint">
              Upload jpg or png. File size can’t be over 5MB.
            </div>
          </div>
        </div>
        <div class="form-row">
          <div class="form-col form-col-half form-file">
            <div class="form-label">Upload Registration Form</div>
            <!-- <mat-form-field
              appearance="outline"
              floatLabel="always"
              class="form-file"
            > -->
            <input [hidden]="true" (change)="openDialogUploadUpdate(fileSelect3.files, 3,$event)" type="file"
              accept="image/jpeg, image/png, image/jpg" #fileSelect3 />
            <!-- <input
                readonly
                matInput
                [(ngModel)]="filename3"
                placeholder="Select..."
              /> -->
            <button class="uploadBtn" mat-button (click)="fileSelect3.click()">
              Choose File
            </button>

            <span class="fileName">
              <ng-container *ngIf="guruRegistrationForm.length">{{
                guruRegistrationForm
              }}<mat-icon class="workshop-icon" (click)="openDialog('registration_form')">close</mat-icon>
              </ng-container>
              <ng-container *ngIf="!guruRegistrationForm.length"></ng-container>
            </span>
            <!-- </mat-form-field> -->
            <div class="formfield-hint">
              Upload jpg or png. File size can’t be over 5MB.
            </div>
          </div>
          <div class="form-col form-col-half form-file">
            <div class="form-label">Upload Other Documents</div>

            <input [hidden]="true" (change)="openDialogUploadUpdate(fileSelect4.files, 4,$event)" type="file"
              accept="image/jpeg, image/png, image/jpg" #fileSelect4 />

            <button class="uploadBtn" mat-button (click)="fileSelect4.click()">
              Choose File
            </button>
            <span class="fileName">
              <ng-container *ngIf="guruOtherDocument.length">{{
                guruOtherDocument
              }}<mat-icon class="workshop-icon" (click)="openDialog('other_documents')">close</mat-icon>
              </ng-container>
              <ng-container *ngIf="!guruOtherDocument.length"></ng-container>
            </span>
            <!-- </mat-form-field> -->
            <div class="formfield-hint">
              Upload jpg or png. File size can’t be over 5MB.
            </div>
          </div>
        </div>
        <div class="form-row">
          <div class="form-col form-col-half form-file">
            <div class="form-label">Upload Workshop Photos</div>
            <div class="test">
              <input [hidden]="true" type="file" multiple="multiple" accept="image/jpeg, image/png, image/jpg"
                #fileSelect5 (change)="openDialogUploadUpdate(fileSelect5.files, 5,$event)" />

              <button class="uploadBtn" mat-button (click)="fileSelect5.click()">
                Choose File
              </button>


            </div>
            <div class="formfield-hint">
              Upload jpg or png. File size can’t be over 5MB.
            </div>
            <div class="formfield-hint">
              You can select upto 5 files.
            </div>
            <ng-container *ngIf="workshopImage.length">
              <ul class="tb-dropdown-options workshop-ul">
                <li *ngFor="let workshopImage of workshopImage ; let i = index" class="workshop-li">
                  <span class="workshopText">{{ workshopImage.name }}</span>
                  <mat-icon class="workshop-icon" (click)="openDialog(i)">close</mat-icon>
                </li>

              </ul>
            </ng-container>
            <ng-container *ngIf="!workshopImage.length"></ng-container>
          </div>
        </div>
      </div>
    </div>
    <div class="guruform-btn">
      <button mat-stroked-button matStepperPrevious class="primary-flat-button">
        Previous
      </button>
      <button mat-flat-button matStepperNext (click)="onFinalSubmit()" class="primary-stroke-button">
        Send for Approval
      </button>
    </div>
  </mat-step>
</mat-stepper>

<div class="action-toaster" *ngIf="showToastermessage">
  <div class="toaster-msg alert alert-success" *ngIf="showfinalSuccessMsg">
    {{ showfinalSuccessMsg }}
  </div>
  <div class="toaster-msg alert alert-danger" *ngIf="showfinalErrorMsg">
    {{ showfinalErrorMsg }}
  </div>
</div>
<ngx-spinner></ngx-spinner>