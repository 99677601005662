import { Component, OnInit } from "@angular/core";
import { ChartDataSets, ChartOptions, ChartType } from "chart.js";
import { Label, SingleDataSet } from "ng2-charts";
import { HttpMethod } from "src/app/constants/http-handlers";
import { AppService } from "src/app/services/app.service";
import { CommonService } from "src/app/services/common.service";

@Component({
  selector: "app-gurus-status",
  templateUrl: "./gurus-status.component.html",
  styleUrls: ["./gurus-status.component.scss"],
})
export class GurusStatusComponent implements OnInit {
  activeGurus: any;
  transactingGurus: any;
  inactiveGurus: any;
  changesactiveGurus: any;
  changesInactiveGurus: any;
  changesTransactingGurus: any;
  goldStatus: any;
  silverStatus: any;
  platinumStatus: any;
  globalUserRoleId: any;

  topgurus = [];
  // Doughnut
  public doughnutChartLabels: Label[] = ["Active", "Transacting", "Inactive"];
  public doughnutChartData: SingleDataSet = [];
  public chartColors: Array<any> = [
    { backgroundColor: ["#2BB24C", "#FC9D45", "#D83232"] },
  ];
  public doughnutChartType: ChartType = "doughnut";
  public doughnutChartOptions: ChartOptions = {
    maintainAspectRatio: false,
    cutoutPercentage: 87,
    responsive: true,
    elements: {
      arc: {
        borderWidth: 3,
      },
    },
    legend: {
      display: false,
      position: "right",
      labels: {
        fontColor: "#757575",
        fontSize: 12,
        fontStyle: "normal",
        fontFamily: "Uni Neue",
        usePointStyle: false,
        padding: 18,
        boxWidth: 8,
      },
    },
    animation: {
      onComplete: function () {
        var chartInstance = this.chart,
          ctx = chartInstance.ctx;
        //console.log(ctx);
        ctx.textAlign = "center";
        ctx.textBaseline = "bottom";
        this.data.datasets.forEach(function (dataset, i) {
          var meta = chartInstance.controller.getDatasetMeta(i);
          var totalguru = 0;
          meta.data.forEach(function (line, index) {
            var data = dataset.data[index];
            //console.log(data);
            totalguru = data + totalguru;
            var radius = 40;
            ctx.beginPath();
            ctx.arc(
              line._model.x,
              line._model.y,
              radius,
              0,
              2 * Math.PI,
              false
            );
            ctx.fillStyle = "#fff";
            ctx.fill();
            ctx.textAlign = "center";
            ctx.textBaseline = "bottom";
            ctx.fillStyle = "#282828";
            ctx.font = "25px Uni Neue";
            ctx.fillText(totalguru, line._model.x, line._model.y + 5);
            ctx.fillStyle = "#757575";
            ctx.font = "12px Uni Neue";
            ctx.fillText("Total Gurus", line._model.x, line._model.y + 25);
          });
        });
      },

    },
    tooltips: { enabled: false },
    hover: { mode: null },
    events: ["click"],
  };
  constructor(
    private commonService: CommonService,
    private appService: AppService
  ) {}

  ngOnInit(): void {
    this.commonService.globalselectedUseroleId.subscribe(
      (message) => (this.globalUserRoleId = message)
    );
    let url;
    if (this.globalUserRoleId !== '') {
      url = `${this.appService.guruStatus}?user_id=${this.globalUserRoleId}`;
    } else {
      url = `${this.appService.guruStatus}`;
    }

    const apiObj = {
      url: url,
      methodType: HttpMethod.GET,
    };
    this.commonService.commonApiCall(apiObj, (res) => {
      //console.log(res);
      this.activeGurus = res.current_month.active;
      this.transactingGurus = res.current_month.transacting;
      this.inactiveGurus = res.current_month.inactive;
      this.doughnutChartData = [
        [this.activeGurus, this.transactingGurus, this.inactiveGurus],
      ];
      this.changesactiveGurus = res.active_change;
      this.changesInactiveGurus = res.inactive_change;
      this.changesTransactingGurus = res.transacting_change;
      this.goldStatus = res.guru_tier.gold;
      this.silverStatus = res.guru_tier.silver;
      this.platinumStatus = res.guru_tier.bronze;
    });
  }
}
