import { Component, OnInit } from "@angular/core";
import { CommonService } from "src/app/services/common.service";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: "app-guru-detail",
  templateUrl: "./guru-detail.component.html",
  styleUrls: ["./guru-detail.component.scss"],
})
export class GuruDetailComponent implements OnInit {
  showDropdown: boolean = false;
  selected: string = "monthly";
  selectedItem: string = "This Month";
  recId: number;
  guruName: string;
  constructor(
    public commonService: CommonService,
    private actRoute: ActivatedRoute,
    private router: Router
  ) {
    this.recId = this.actRoute.snapshot.params.id;
  }
  select(index) {
    this.selected = this.commonService.viewTypeOptions[index].paramName;
    this.selectedItem = this.commonService.viewTypeOptions[index].title;
    this.showDropdown = false;
  }
  selectValfun() {
    this.showDropdown = !this.showDropdown;
  }
  isActive(item: string) {
    return this.selected === item;
  }
  receivedMessageHandler(p) {
    //alert("received" + p);
  }
  ngOnInit(): void {}
  backToGurulist() {
    this.router.navigate(["guru-list"]);
  }
}
