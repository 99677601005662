<div class="guru-tabs-row d-flex" style="justify-content: end;">
  <div class="tb-btn-group">
    <ul>
      <li>
        <app-sub-dash-filter #filterComponent [filterFields]="filterFields"
          (formEventTrigger)="formControlValue($event)"></app-sub-dash-filter>
      </li>
      <!-- <li>
          <button mat-stroked-button class="primary-stroke-button exportBtn" (click)="downloadGuruListExcel()">
              Export Excel
          </button>
      </li> -->
      <!-- <li>
        <button mat-stroked-button class="primary-stroke-button exportBtn">
          Refresh
        </button>
      </li> -->
    </ul>
  </div>
</div>


<section class="main-wrapper dashboard">
  <div class="row">
    <div class="col-12">
      <div class="l-container">
        <div class="row">

          <div class="col-6 small-charts card-shadow seperate-div-order-invoice-level-right">
            <div class="row">
              <div class="col-6">
                <span class="title titlte-order-level"> Total And Closed Jobcards </span>
              </div>
              <div class="col-6">
                <div class="guru-tabs-row d-flex" style="justify-content: end;">
                  <div class="tb-btn-group">
                    <div class="tb-filter-wrapper">
                      <button mat-stroked-button class="primary-stroke-button filterBtn"
                        (click)="openDropdown('filter8')" [ngClass]="{ open: isjobstatics }">
                        Filter
                      </button>
                      <div class="custom-filter-sec" *ngIf="isjobstatics">
                        <h3>
                          Filter <button class="filterCloseBtn" (click)="closeDropdown('filter8')"></button>
                        </h3>
                        <form [formGroup]="jobstaticsfilter" name="doorStepFilterForm" class="form">
                          <div class="custom-filter-body" style="justify-content: space-evenly;">
                            <div class="custom-filter-col">
                              <mat-form-field *ngIf="role=='SuperAdmin'">
                                <mat-label label for="Zone" class="form-label"> Select Region <span
                                    class="requiredfield">*</span></mat-label>
                                <mat-select formControlName="region">
                                  <!-- <mat-option value="one">Please select zone</mat-option> -->
                                  <mat-option [value]="zone" *ngFor="let zone of regions">{{
                                    zone }}</mat-option>
                                </mat-select>
                              </mat-form-field>

                              <mat-form-field>
                                <mat-label label for="region" class="form-label"> Select State <span
                                    class="requiredfield">*</span></mat-label>
                                <mat-select formControlName="state_name"
                                  (selectionChange)="stateSelected = true;this.dropdownjob('lass_name')" multiple>
                                  <!-- <mat-option value="one">Please select State</mat-option> -->
                                  <mat-option [value]="state" *ngFor="let state of states">{{
                                    state}}</mat-option>
                                </mat-select>
                              </mat-form-field>

                              <mat-form-field>
                                <mat-label label for="lob" class="form-label"> Select Lob <span
                                    class="requiredfield">*</span></mat-label>
                                <mat-select formControlName="lob" multiple>
                                  <!-- <mat-option value="one">Please select lob</mat-option> -->
                                  <mat-option [value]="lob" *ngFor="let lob of lobData">{{
                                    lob }}</mat-option>
                                </mat-select>
                              </mat-form-field>

                              <mat-form-field>
                                <mat-label label for="pl" class="form-label"> Select Pl <span
                                    class="requiredfield">*</span></mat-label>
                                <mat-select formControlName="pl" multiple>
                                  <!-- <mat-option value="one">Please select pl</mat-option> -->
                                  <mat-option [value]="pl" *ngFor="let pl of pldata">{{
                                    pl }}</mat-option>
                                </mat-select>
                              </mat-form-field>

                              <mat-form-field>
                                <mat-label>LASS Name </mat-label>
                                <input matInput placeholder="Enter LASS Name" value="" formControlName="lass_name"
                                  (input)="dropdownjob('lass_name')" [readonly]="!stateSelected"
                                  [matAutocomplete]="auto">
                                <mat-autocomplete #auto="matAutocomplete">

                                  <mat-option *ngFor="let item of lassName" [value]="item">{{item}}</mat-option>

                                </mat-autocomplete>
                              </mat-form-field>
                            </div>

                            <div class="custom-filter-col">
                              <mat-form-field>
                                <mat-label>Lass Mobile No</mat-label>
                                <input matInput placeholder="Enter Lass Mobile No." value=""
                                  formControlName="lass_number">
                              </mat-form-field>

                              <mat-form-field>
                                <mat-label label for="PPl" class="form-label"> Select PPL <span
                                    class="requiredfield">*</span></mat-label>
                                <mat-select formControlName="ppl" multiple>
                                  <!-- <mat-option value="one">Please select ppl</mat-option> -->
                                  <mat-option [value]="ppl" *ngFor="let ppl of ppldata">{{
                                    ppl }}</mat-option>
                                </mat-select>
                              </mat-form-field>

                              <mat-form-field>
                                <mat-label label for="region" class="form-label"> Select Emission Norm <span
                                    class="requiredfield">*</span></mat-label>
                                <mat-select formControlName="emission_norm" multiple>
                                  <!-- <mat-option value="one">Please select Emission Norm</mat-option> -->
                                  <mat-option [value]="item" *ngFor="let item of emissionormdata">{{
                                    item }}</mat-option>
                                </mat-select>
                              </mat-form-field>

                              <mat-form-field>
                                <mat-label label for="region" class="form-label"> Select Problem Name <span
                                    class="requiredfield">*</span></mat-label>
                                <mat-select formControlName="problem_name" >
                                  <!-- <mat-option value="one">Please select Problem</mat-option> -->
                                  <mat-option [value]="problem" *ngFor="let problem of ProblemData">{{
                                    problem }}</mat-option>
                                </mat-select>
                              </mat-form-field>

                            </div>
                          </div>




                          <div class="btn-group filterBtn-group">
                            <button mat-stroked-button class="primary-stroke-button"
                              (click)="reset('jobstaticsfilter')">Reset</button>
                            <button mat-flat-button class="primary-flat-button" (click)="applyfilter()">Apply</button>
                          </div>

                        </form>
                      </div>
                    </div>

                    <div class="action-toaster" *ngIf="showToastermessage">
                      <div class="toaster-msg alert alert-danger" *ngIf="showfinalErrorMsg">
                        {{ showfinalErrorMsg }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row padding-from-top pt-0">
              <div style="display: block;">
                <figure class="highcharts-figure">
                  <div id="JobCountGraph"></div>
                </figure>
              </div>
            </div>

            <ngx-ui-loader loaderId="loader-01" loaderType="double-bounce"></ngx-ui-loader>
          </div>

          <div class="col-6 small-charts card-shadow seperate-div-order-invoice-level-left">
            <div class="row">
              <div class="col-6">
                <span class="title titlte-order-level">Doorstep Booking </span>
              </div>
              <div class="col-6">
                <div class="guru-tabs-row d-flex" style="justify-content: end;">
                  <div class="tb-btn-group">
                    <div class="tb-filter-wrapper">
                      <button mat-stroked-button class="primary-stroke-button filterBtn"
                        (click)="openDropdown('filter6')" [ngClass]="{ open: doorstepedopen }">
                        Filter
                      </button>
                      <div class="custom-filter-sec" *ngIf="doorstepedopen">
                        <h3>
                          Filter <button class="filterCloseBtn" (click)="closeDropdown('filter6')"></button>
                        </h3>
                        <form [formGroup]="doorstepfilter" name="doorStepFilterForm" class="form">
                          <div class="custom-filter-body" style="justify-content: space-evenly;">
                            <div class="custom-filter-col">

                              <mat-form-field *ngIf="role=='SuperAdmin'">
                                <mat-label label for="Zone" class="form-label"> Select Region <span
                                    class="requiredfield">*</span></mat-label>
                                <mat-select formControlName="region">
                                  <!-- <mat-option value="one">Please select zone</mat-option> -->
                                  <mat-option [value]="zone" *ngFor="let zone of regions">{{
                                    zone }}</mat-option>
                                </mat-select>
                              </mat-form-field>

                              <mat-form-field>
                                <mat-label label for="state" class="form-label"> Select State <span
                                    class="requiredfield">*</span></mat-label>
                                <mat-select formControlName="state_name"
                                  (selectionChange)="stateSelected = true;this.dropdown('lass_name')" multiple>
                                  <!-- <mat-option value="one">Please select State</mat-option> -->
                                  <mat-option [value]="state" *ngFor="let state of states">{{
                                    state }}</mat-option>
                                </mat-select>
                              </mat-form-field>

                              <mat-form-field>
                                <mat-label label for="lob" class="form-label"> Select Lob <span
                                    class="requiredfield">*</span></mat-label>
                                <mat-select formControlName="lob" multiple>
                                  <!-- <mat-option value="one">Please select lob</mat-option> -->
                                  <mat-option [value]="lob" *ngFor="let lob of lobData">{{
                                    lob }}</mat-option>
                                </mat-select>
                              </mat-form-field>

                              <mat-form-field>
                                <mat-label label for="pl" class="form-label"> Select Pl <span
                                    class="requiredfield">*</span></mat-label>
                                <mat-select formControlName="pl" multiple>
                                  <!-- <mat-option value="one">Please select pl</mat-option> -->
                                  <mat-option [value]="pl" *ngFor="let pl of pldata">{{
                                    pl }}</mat-option>
                                </mat-select>
                              </mat-form-field>

                              <mat-form-field>
                                <mat-label>LASS Name </mat-label>
                                <input matInput placeholder="Enter LASS Name" value="" formControlName="lass_name"
                                  (input)="dropdown('lass_name')" [readonly]="!stateSelected" [matAutocomplete]="auto">
                                <mat-autocomplete #auto="matAutocomplete">

                                  <mat-option *ngFor="let item of lassName" [value]="item">{{item}}</mat-option>

                                </mat-autocomplete>
                              </mat-form-field>
                            </div>

                            <div class="custom-filter-col">
                              <mat-form-field>
                                <mat-label>Lass Mobile No</mat-label>
                                <input matInput placeholder="Enter Lass Mobile No." value=""
                                  formControlName="lass_mobile_no">
                              </mat-form-field>

                              <mat-form-field>
                                <mat-label label for="PPl" class="form-label"> Select PPL <span
                                    class="requiredfield">*</span></mat-label>
                                <mat-select formControlName="ppl" multiple>
                                  <!-- <mat-option value="one">Please select ppl</mat-option> -->
                                  <mat-option [value]="ppl" *ngFor="let ppl of ppldata">{{
                                    ppl }}</mat-option>
                                </mat-select>
                              </mat-form-field>

                              <mat-form-field>
                                <mat-label label for="region" class="form-label"> Select Emission Norm <span
                                    class="requiredfield">*</span></mat-label>
                                <mat-select formControlName="emission_norm" multiple>
                                  <!-- <mat-option value="one">Please select Emission Norm</mat-option> -->
                                  <mat-option [value]="item" *ngFor="let item of emissionormdata">{{
                                    item }}</mat-option>
                                </mat-select>
                              </mat-form-field>

                              <mat-form-field>
                                <mat-label label for="region" class="form-label"> Select Problem Name <span
                                    class="requiredfield">*</span></mat-label>
                                <mat-select formControlName="problem_name" >
                                  <!-- <mat-option value="one">Please select Problem</mat-option> -->
                                  <mat-option [value]="problem" *ngFor="let problem of ProblemData">{{
                                    problem }}</mat-option>
                                </mat-select>
                              </mat-form-field>
                            </div>
                          </div>




                          <div class="btn-group filterBtn-group">
                            <button mat-stroked-button class="primary-stroke-button"
                              (click)="reset('doorstepfilter')">Reset</button>
                            <button mat-flat-button class="primary-flat-button" (click)="applyfilter()">Apply</button>
                          </div>

                        </form>
                      </div>
                    </div>

                    <div class="action-toaster" *ngIf="showToastermessage">
                      <div class="toaster-msg alert alert-danger" *ngIf="showfinalErrorMsg">
                        {{ showfinalErrorMsg }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row padding-from-top pt-0">

              <div style="display: block;">
                <figure class="highcharts-figure">
                  <div id="DoorstepBooking"></div>
                </figure>
              </div>
            </div>
            <ngx-ui-loader loaderId="loader-02" loaderType="double-bounce"></ngx-ui-loader>
          </div>

        </div>
      </div>
    </div>
  </div>

</section>

<section class="main-wrapper dashboard">
  <div class="row">
    <div class="col-12">
      <div class="l-container">
        <div class="row">
          <div class="col-6 small-charts card-shadow seperate-div-order-invoice-level-right mt-3">

            <div class="row">
              <div class="col-6">
                <span class="title titlte-order-level">LASS Wise  </span>
              </div>
              <div class="col-6">
                <div class="guru-tabs-row d-flex" style="justify-content: end;">
                  <div class="tb-btn-group">
                    <div class="tb-filter-wrapper">
                      <button mat-stroked-button class="primary-stroke-button filterBtn"
                        (click)="openDropdown('filter1')" [ngClass]="{ open: isOpendropdown }">
                        Filter
                      </button>
                      <div class="custom-filter-sec" *ngIf="isOpendropdown">
                        <h3>
                          Filter <button class="filterCloseBtn" (click)="closeDropdown('filter1')"></button>
                        </h3>
                        <form [formGroup]="lasswiseFilter" name="doorStepFilterForm" class="form">
                          <div class="custom-filter-body" style="justify-content: space-evenly;">
                            <div class="custom-filter-col">

                              <mat-form-field>
                                <mat-label label for="region" class="form-label"> Select State <span
                                    class="requiredfield">*</span></mat-label>
                                <mat-select formControlName="state" multiple>
                                  <!-- <mat-option value="one">Please select State</mat-option> -->
                                  <mat-option [value]="state" *ngFor="let state of states">{{
                                    state }}</mat-option>
                                </mat-select>
                              </mat-form-field>
                            </div>

                            <div class="custom-filter-col">

                              <mat-form-field>
                                <mat-label>Phone Number</mat-label>
                                <input matInput placeholder="Enter Phone Number" value="" formControlName="phonenumber">
                              </mat-form-field>
                            </div>
                          </div>
                          <div class="btn-group filterBtn-group">
                            <button mat-stroked-button class="primary-stroke-button"
                              (click)="reset('lasswiseFilter')">Reset</button>
                            <button mat-flat-button class="primary-flat-button" (click)="applyfilter()">Apply</button>
                          </div>

                        </form>
                      </div>
                    </div>

                    <div class="action-toaster" *ngIf="showToastermessage">
                      <div class="toaster-msg alert alert-danger" *ngIf="showfinalErrorMsg">
                        {{ showfinalErrorMsg }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div class="row padding-from-top pt-0" *ngIf="lassWiseData">
              <div class="col-3 mt-3">
                <div class="small-charts__aside card-shadow bg-white h-100">
                  <!-- <div class="icons"><img src="assets/images/new-orders.svg" alt=""></div> -->
                  <div class="data">
                    <p class="textFont m-0 text-13  line-height-1">Open Job Cards</p>
                    <span style="color: #00bfff;"
                      class="text-16 font-weight-bold text-blue">#{{lassWiseData?.result?.open_job_cards}}</span><br>

                  </div>
                </div>
              </div>
              <div class="col-3 mt-3">
                <div class="small-charts__aside card-shadow bg-white h-100">
                  <!-- <div class="icons"><img src="assets/images/new-orders.svg" alt=""></div> -->
                  <div class="data">
                    <p class="textFont m-0 text-13  line-height-1">Parts Consumption Value </p>
                    <span style="color: #00bfff;" class="text-16 font-weight-bold text-blue">
                      ₹ {{ lassWiseData?.result?.total_parts_consumtion_value |kFormat }}</span><br>

                  </div>
                </div>
              </div>
              <div class="col-3 mt-3">
                <div class="small-charts__aside card-shadow bg-white h-100">
                  <!-- <div class="icons"><img src="assets/images/new-orders.svg" alt=""></div> -->
                  <div class="data">
                    <p class="textFont m-0 text-13  line-height-1">Total Part Consumed (Quantity)</p>
                    <span style="color: #00bfff;" class="text-16 font-weight-bold text-blue">
                      #{{ lassWiseData?.result?.total_part_consumed}}</span><br>

                  </div>
                </div>
              </div>
              <div class="col-3 mt-3">
                <div class="small-charts__aside card-shadow bg-white h-100">
                  <!-- <div class="icons"><img src="assets/images/new-orders.svg" alt=""></div> -->
                  <div class="data">
                    <p class="textFont m-0 text-13  line-height-1">Unique Lines</p>
                    <span style="color: #00bfff;" class="text-16 font-weight-bold text-blue">
                      #{{ lassWiseData?.result?.unique_lines }}</span><br>

                  </div>
                </div>
              </div>
              <div class="col-3 mt-3">
                <div class="small-charts__aside card-shadow bg-white h-100">
                  <!-- <div class="icons"><img src="assets/images/new-orders.svg" alt=""></div> -->
                  <div class="data">
                    <p class="textFont m-0 text-13  line-height-1">Active Guru Count</p>
                    <span style="color: #00bfff;" class="text-16 font-weight-bold text-blue">
                      #{{ lassWiseData?.result?.guru_count }}</span><br>

                  </div>
                </div>
              </div>
              <div *ngIf=" lassWiseData.msg">
                <h5>No Data Found</h5>
              </div>

            </div>
            <ngx-ui-loader loaderId="loader-07" loaderType="double-bounce"></ngx-ui-loader>
          </div>

          <div class="col-6 small-charts card-shadow seperate-div-order-invoice-level-right mt-3">

            <div class="row">
              <div class="col-6">
                <span class="title titlte-order-level">Mechanic Wise  </span>
              </div>
              <div class="col-6">
                <div class="guru-tabs-row d-flex" style="justify-content: end;">
                  <div class="tb-btn-group">
                    <div class="tb-filter-wrapper">
                      <button mat-stroked-button class="primary-stroke-button filterBtn"
                        (click)="openDropdown('filter4')" [ngClass]="{ open: isMechwiseOpened }">
                        Filter
                      </button>
                      <div class="custom-filter-sec" *ngIf="isMechwiseOpened">
                        <h3>
                          Filter <button class="filterCloseBtn" (click)="closeDropdown('filter4')"></button>
                        </h3>
                        <form [formGroup]="mechwiseFilter" name="doorStepFilterForm" class="form">
                          <div class="custom-filter-body" style="justify-content: space-evenly;">
                            <div class="custom-filter-col">

                              <mat-form-field>
                                <mat-label label for="region" class="form-label"> Select State <span
                                    class="requiredfield">*</span></mat-label>
                                <mat-select formControlName="state" multiple>
                                  <!-- <mat-option value="one">Please select State</mat-option> -->
                                  <mat-option [value]="state" *ngFor="let state of states">{{
                                    state}}</mat-option>
                                </mat-select>
                              </mat-form-field>
                            </div>

                            <div class="custom-filter-col">

                              <mat-form-field>
                                <mat-label>Phone Number</mat-label>
                                <input matInput placeholder="Enter Phone Number" value="" formControlName="phonenumber">
                              </mat-form-field>
                            </div>
                          </div>




                          <div class="btn-group filterBtn-group">
                            <button mat-stroked-button class="primary-stroke-button"
                              (click)="reset('mechwiseFilter')">Reset</button>
                            <button mat-flat-button class="primary-flat-button" (click)="applyfilter()">Apply</button>
                          </div>

                        </form>
                      </div>
                    </div>

                    <div class="action-toaster" *ngIf="showToastermessage">
                      <div class="toaster-msg alert alert-danger" *ngIf="showfinalErrorMsg">
                        {{ showfinalErrorMsg }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div class="row padding-from-top pt-0" *ngIf="mechanicalWiseData">


              <div class="col-3 mt-3">
                <div class="small-charts__aside card-shadow bg-white h-100">
                  <!-- <div class="icons"><img src="assets/images/new-orders.svg" alt=""></div> -->
                  <div class="data">
                    <p class="textFont m-0 text-13  line-height-1">Open Job Cards</p>
                    <span style="color: #00bfff;"
                      class="text-16 font-weight-bold text-blue">#{{mechanicalWiseData?.result?.open_job_cards}}</span><br>

                  </div>
                </div>
              </div>
              <div class="col-3 mt-3">
                <div class="small-charts__aside card-shadow bg-white h-100">
                  <!-- <div class="icons"><img src="assets/images/new-orders.svg" alt=""></div> -->
                  <div class="data">
                    <p class="textFont m-0 text-13  line-height-1">Parts Consumption Value</p>
                    <span style="color: #00bfff;" class="text-16 font-weight-bold text-blue">
                      ₹ {{ mechanicalWiseData?.result?.total_parts_consumtion_value |kFormat}}</span><br>

                  </div>
                </div>
              </div>
              <div class="col-3 mt-3">
                <div class="small-charts__aside card-shadow bg-white h-100">
                  <!-- <div class="icons"><img src="assets/images/new-orders.svg" alt=""></div> -->
                  <div class="data">
                    <p class="textFont m-0 text-13  line-height-1">Total Part Consumed (Quantity)</p>
                    <span style="color: #00bfff;" class="text-16 font-weight-bold text-blue">
                      #{{ mechanicalWiseData?.result?.total_part_consumed}}</span><br>

                  </div>
                </div>
              </div>
              <div class="col-3 mt-3">
                <div class="small-charts__aside card-shadow bg-white h-100">
                  <!-- <div class="icons"><img src="assets/images/new-orders.svg" alt=""></div> -->
                  <div class="data">
                    <p class="textFont m-0 text-13  line-height-1">Unique Lines</p>
                    <span style="color: #00bfff;" class="text-16 font-weight-bold text-blue">
                      #{{ mechanicalWiseData?.result?.unique_lines }}</span><br>

                  </div>
                </div>
              </div>
              <div class="col-3 mt-3">
                <div class="small-charts__aside card-shadow bg-white h-100">
                  <!-- <div class="icons"><img src="assets/images/new-orders.svg" alt=""></div> -->
                  <div class="data">
                    <p class="textFont m-0 text-13  line-height-1">Active Guru Count</p>
                    <span style="color: #00bfff;" class="text-16 font-weight-bold text-blue">
                      #{{ mechanicalWiseData?.result?.guru_count }}</span><br>

                  </div>
                </div>
              </div>



            </div>
            <ngx-ui-loader loaderId="loader-08" loaderType="double-bounce"></ngx-ui-loader>
          </div>

        </div>
      </div>
    </div>
  </div>

</section>

<section class="main-wrapper dashboard">
  <div class="row">
    <!-- <div class="col-12"> -->
    <div class="l-container">
      <div class="row">
        <div class=" col-6 small-charts card-shadow seperate-div-order-invoice-level-right mt-3"
          style="margin-left: 0%;">
          <div class="row">
            <div class="col-6">
              <span class="title titlte-order-level">Parts Consumptions  </span>
            </div>
            <div class="col-6">
              <div class="guru-tabs-row d-flex" style="justify-content: end;">
                <div class="tb-btn-group">
                  <div class="tb-filter-wrapper">
                    <button mat-stroked-button class="primary-stroke-button filterBtn" (click)="openDropdown('filter2')"
                      [ngClass]="{ open: isOpendropdowned }">
                      Filter
                    </button>
                    <div class="custom-filter-sec" *ngIf="isOpendropdowned">
                      <h3>
                        Filter <button class="filterCloseBtn" (click)="closeDropdown('filter2')"></button>
                      </h3>
                      <form [formGroup]="partsFilter" name="doorStepFilterForm" class="form">
                        <div class="custom-filter-body" style="justify-content: space-evenly;">
                          <div class="custom-filter-col">
                            <mat-form-field *ngIf="role=='SuperAdmin'">
                              <mat-label label for="Zone" class="form-label"> Select Region <span
                                  class="requiredfield">*</span></mat-label>
                              <mat-select formControlName="region">
                                <!-- <mat-option value="one">Please select zone</mat-option> -->
                                <mat-option [value]="zone" *ngFor="let zone of regions">{{
                                  zone }}</mat-option>
                              </mat-select>
                            </mat-form-field>

                            <mat-form-field>
                              <mat-label label for="region" class="form-label"> Select State <span
                                  class="requiredfield">*</span></mat-label>
                              <mat-select formControlName="state"
                                (selectionChange)="stateSelected = true;this.patrssconsss('lass_name')" multiple>
                                <!-- <mat-option value="one">Please select State</mat-option> -->
                                <mat-option [value]="state" *ngFor="let state of states">{{
                                  state}}</mat-option>
                              </mat-select>
                            </mat-form-field>



                            <mat-form-field>
                              <mat-label label for="lob" class="form-label"> Select Lob <span
                                  class="requiredfield">*</span></mat-label>
                              <mat-select formControlName="lob" multiple>
                                <!-- <mat-option value="one">Please select lob</mat-option> -->
                                <mat-option [value]="lob" *ngFor="let lob of lobData">{{
                                  lob }}</mat-option>>
                              </mat-select>
                            </mat-form-field>

                            <mat-form-field>
                              <mat-label label for="pl" class="form-label"> Select Pl <span
                                  class="requiredfield">*</span></mat-label>
                              <mat-select formControlName="pl" multiple>
                                <!-- <mat-option value="one">Please select pl</mat-option> -->
                                <mat-option [value]="pl" *ngFor="let pl of pldata">{{
                                  pl }}</mat-option>
                              </mat-select>
                            </mat-form-field>

                            <mat-form-field>
                              <mat-label>LASS Name </mat-label>
                              <input matInput placeholder="Enter LASS Name" value="" formControlName="lass_name"
                                (input)="patrssconsss('lass_name')" [readonly]="!stateSelected"
                                [matAutocomplete]="auto">
                              <mat-autocomplete #auto="matAutocomplete">
                                <mat-option *ngFor="let item of lassName" [value]="item">{{item}}</mat-option>
                              </mat-autocomplete>
                            </mat-form-field>
                          </div>

                          <div class="custom-filter-col">
                            <mat-form-field>
                              <mat-label>Lass Mobile No</mat-label>
                              <input matInput placeholder="Enter Lass Mobile No." value=""
                                formControlName="lass_mobile_no">
                            </mat-form-field>

                            <mat-form-field>
                              <mat-label label for="PPl" class="form-label"> Select PPL <span
                                  class="requiredfield">*</span></mat-label>
                              <mat-select formControlName="ppl" multiple>
                                <!-- <mat-option value="one">Please select ppl</mat-option> -->
                                <mat-option [value]="ppl" *ngFor="let ppl of ppldata">{{
                                  ppl }}</mat-option>
                              </mat-select>
                            </mat-form-field>

                            <mat-form-field>
                              <mat-label label for="region" class="form-label"> Select Emission Norm <span
                                  class="requiredfield">*</span></mat-label>
                              <mat-select formControlName="emission_norm" multiple>
                                <!-- <mat-option value="one">Please select Emission Norm</mat-option> -->
                                <mat-option [value]="item" *ngFor="let item of emissionormdata">{{
                                  item }}</mat-option>
                              </mat-select>
                            </mat-form-field>



                          </div>
                        </div>




                        <div class="btn-group filterBtn-group">
                          <button mat-stroked-button class="primary-stroke-button"
                            (click)="reset('partsFilter')">Reset</button>
                          <button mat-flat-button class="primary-flat-button" (click)="applyfilter()">Apply</button>
                        </div>

                      </form>
                    </div>
                  </div>

                  <div class="action-toaster" *ngIf="showToastermessage">
                    <div class="toaster-msg alert alert-danger" *ngIf="showfinalErrorMsg">
                      {{ showfinalErrorMsg }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row padding-from-top pt-0">

            <div style="display: block;">
              <figure class="highcharts-figure">
                <div id="partsconsgraph"></div>
              </figure>
            </div>

            <ngx-ui-loader loaderId="loader-03" loaderType="double-bounce"></ngx-ui-loader>

          </div>
        </div>

        <div class="col-6 small-charts card-shadow seperate-div-order-invoice-level-right mt-3">
          <div class="row">
            <div class="col-6">
              <span class="title titlte-order-level">Active Guru  </span>
            </div>
            <div class="col-6">
              <div class="guru-tabs-row d-flex" style="justify-content: end;">
                <div class="tb-btn-group">
                  <div class="tb-filter-wrapper">
                    <button mat-stroked-button class="primary-stroke-button filterBtn" (click)="openDropdown('filter5')"
                      [ngClass]="{ open: isActiveopend }">
                      Filter
                    </button>
                    <div class="custom-filter-sec" *ngIf="isActiveopend">
                      <h3>
                        Filter <button class="filterCloseBtn" (click)="closeDropdown('filter5')"></button>
                      </h3>
                      <form [formGroup]="activejobFilter" name="doorStepFilterForm" class="form">
                        <div class="custom-filter-body" style="justify-content: space-evenly;">
                          <div class="custom-filter-col">

                            <mat-form-field *ngIf="role=='SuperAdmin'">
                              <mat-label label for="Zone" class="form-label"> Select Region <span
                                  class="requiredfield">*</span></mat-label>
                              <mat-select formControlName="region">
                                <!-- <mat-option value="one">Please select zone</mat-option> -->
                                <mat-option [value]="zone" *ngFor="let zone of regions">{{
                                  zone }}</mat-option>
                              </mat-select>
                            </mat-form-field>

                            <mat-form-field>
                              <mat-label label for="state" class="form-label"> Select State <span
                                  class="requiredfield">*</span></mat-label>
                              <mat-select formControlName="state"
                                (selectionChange)="stateSelected = true;this.Activedrop('lass_name')" multiple>
                                <!-- <mat-option value="one">Please select State</mat-option> -->
                                <mat-option [value]="state" *ngFor="let state of states">{{
                                  state}}</mat-option>
                              </mat-select>
                            </mat-form-field>

                            <mat-form-field>
                              <mat-label label for="lob" class="form-label"> Select Lob <span
                                  class="requiredfield">*</span></mat-label>
                              <mat-select formControlName="lob" multiple>
                                <!-- <mat-option value="one">Please select lob</mat-option> -->
                                <mat-option [value]="lob" *ngFor="let lob of lobData">{{
                                  lob }}</mat-option>
                              </mat-select>
                            </mat-form-field>

                            <mat-form-field>
                              <mat-label label for="pl" class="form-label"> Select Pl <span
                                  class="requiredfield">*</span></mat-label>
                              <mat-select formControlName="pl" multiple>
                                <!-- <mat-option value="one">Please select pl</mat-option> -->
                                <mat-option [value]="pl" *ngFor="let pl of pldata">{{
                                  pl }}</mat-option>
                              </mat-select>
                            </mat-form-field>

                            <mat-form-field>
                              <mat-label>LASS Name </mat-label>
                              <input matInput placeholder="Enter LASS Name" value="" formControlName="lass_name"
                                (input)="Activedrop('lass_name')" [readonly]="!stateSelected" [matAutocomplete]="auto">
                              <mat-autocomplete #auto="matAutocomplete">
                                <mat-option *ngFor="let item of lassName" [value]="item">{{item}}</mat-option>
                              </mat-autocomplete>
                            </mat-form-field>
                          </div>

                          <div class="custom-filter-col">
                            <mat-form-field>
                              <mat-label>Lass Mobile No</mat-label>
                              <input matInput placeholder="Enter Lass Mobile No." value=""
                                formControlName="lass_mobile_no">
                            </mat-form-field>

                            <mat-form-field>
                              <mat-label label for="PPl" class="form-label"> Select PPL <span
                                  class="requiredfield">*</span></mat-label>
                              <mat-select formControlName="ppl" multiple>
                                <!-- <mat-option value="one">Please select ppl</mat-option> -->
                                <mat-option [value]="ppl" *ngFor="let ppl of ppldata">{{
                                  ppl }}</mat-option>
                              </mat-select>
                            </mat-form-field>

                            <mat-form-field>
                              <mat-label label for="region" class="form-label"> Select Emission Norm <span
                                  class="requiredfield">*</span></mat-label>
                              <mat-select formControlName="emission_norm" multiple>
                                <!-- <mat-option value="one">Please select Emission Norm</mat-option> -->
                                <mat-option [value]="item" *ngFor="let item of emissionormdata">{{
                                  item }}</mat-option>
                              </mat-select>
                            </mat-form-field>

                            <mat-form-field>
                              <mat-label>Minimun Job Card Count</mat-label>
                              <input matInput type="number" placeholder="Enter Job Card Count" value=""
                                formControlName="jobcard_count">
                            </mat-form-field>


                          </div>
                        </div>




                        <div class="btn-group filterBtn-group">
                          <button mat-stroked-button class="primary-stroke-button"
                            (click)="reset('activejobFilter')">Reset</button>
                          <button mat-flat-button class="primary-flat-button" (click)="applyfilter()">Apply</button>
                        </div>

                      </form>
                    </div>
                  </div>

                  <div class="action-toaster" *ngIf="showToastermessage">
                    <div class="toaster-msg alert alert-danger" *ngIf="showfinalErrorMsg">
                      {{ showfinalErrorMsg }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row padding-from-top pt-0">

            <div style="display: block;">
              <figure class="highcharts-figure">
                <div id="ActiveJobdata"></div>
              </figure>
            </div>
            <ngx-ui-loader loaderId="loader-04" loaderType="double-bounce"></ngx-ui-loader>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- </div> -->
</section>

<section class="main-wrapper dashboard">
  <div class="row">
    <div class="col-12">
      <div class="l-container">
        <div class="row">
          <div class=" col-6 small-charts card-shadow seperate-div-order-invoice-level-right mt-3"
            style="margin-left: 0%;">
            <div class="row">
              <div class="col-6">
                <span class="title titlte-order-level">Vehicle Referral </span>
              </div>
              <div class="col-6">
                <div class="guru-tabs-row d-flex" style="justify-content: end;">
                  <div class="tb-btn-group">
                    <div class="tb-filter-wrapper">
                      <button mat-stroked-button class="primary-stroke-button filterBtn"
                        (click)="openDropdown('filter3')" [ngClass]="{ open: isLobopended }">
                        Filter
                      </button>
                      <div class="custom-filter-sec" *ngIf="isLobopended">
                        <h3>
                          Filter <button class="filterCloseBtn" (click)="closeDropdown('filter3')"></button>
                        </h3>
                        <form [formGroup]="lobFilter" name="doorStepFilterForm" class="form">
                          <div class="custom-filter-body" style="justify-content: space-evenly;">

                            <div class="custom-filter-col">

                              <mat-form-field *ngIf="role=='SuperAdmin'">
                                <mat-label label for="Zone" class="form-label"> Select Region <span
                                    class="requiredfield">*</span></mat-label>
                                <mat-select formControlName="region">
                                  <!-- <mat-option value="one">Please select zone</mat-option> -->
                                  <mat-option [value]="zone" *ngFor="let zone of regions">{{
                                    zone }}</mat-option>
                                </mat-select>
                              </mat-form-field>



                              <mat-form-field>
                                <mat-label label for="lob" class="form-label"> Select Lob <span
                                    class="requiredfield">*</span></mat-label>
                                <mat-select formControlName="lobs" multiple>
                                  <!-- <mat-option value="one">Please select lob</mat-option> -->
                                  <mat-option [value]="lob" *ngFor="let lob of lobData">{{
                                    lob }}</mat-option>
                                </mat-select>
                              </mat-form-field>

                            </div>


                            <div class="custom-filter-col">

                              <mat-form-field>
                                <mat-label label for="region" class="form-label"> Select State <span
                                    class="requiredfield">*</span></mat-label>
                                <mat-select formControlName="state" multiple>
                                  <!-- <mat-option value="one">Please select State</mat-option> -->
                                  <mat-option [value]="state" *ngFor="let state of states">{{
                                    state}}</mat-option>
                                </mat-select>
                              </mat-form-field>

                              <mat-form-field>
                                <mat-label>Lass Number</mat-label>
                                <input matInput placeholder="Enter Lass Number" formControlName="lass_number">
                                <mat-error *ngIf="lobFilter.get('lass_number').hasError('required')">Lass Number is
                                  required</mat-error>
                                <mat-error *ngIf="lobFilter.get('lass_number').hasError('pattern')">Invalid Lass
                                  Number</mat-error>
                              </mat-form-field>

                            </div>

                          </div>

                          <div class="btn-group filterBtn-group">
                            <button mat-stroked-button class="primary-stroke-button"
                              (click)="reset('lobFilter')">Reset</button>
                            <button mat-flat-button class="primary-flat-button" (click)="applyfilter()">Apply</button>
                          </div>

                        </form>
                      </div>
                    </div>

                    <div class="action-toaster" *ngIf="showToastermessage">
                      <div class="toaster-msg alert alert-danger" *ngIf="showfinalErrorMsg">
                        {{ showfinalErrorMsg }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- <span class="title titlte-order-level">Job Count </span> -->
            <div class="row padding-from-top pt-0">
              <div style="display: block;">
                <figure class="highcharts-figure">
                  <div id="vehiclerefralgraph"></div>
                </figure>

                <!-- <canvasjs-chart [options]="chartOptions" [styles]="{width: '100%', height:'360px'}"></canvasjs-chart> -->
              </div>
            </div>
            <ngx-ui-loader loaderId="loader-06" loaderType="double-bounce"></ngx-ui-loader>
          </div>
          <div class=" col-6 small-charts card-shadow seperate-div-order-invoice-level-right mt-3"
            style="margin-left: 0%;">
            <div class="row">
              <div class="col-6">
                <span class="title titlte-order-level">Prolife Referral </span>
              </div>
              <div class="col-6">
                <div class="guru-tabs-row d-flex" style="justify-content: end;">
                  <div class="tb-btn-group">
                    <div class="tb-filter-wrapper">
                      <button mat-stroked-button class="primary-stroke-button filterBtn"
                        (click)="openDropdown('filter7')" [ngClass]="{ open: isprolife }">
                        Filter
                      </button>
                      <div class="custom-filter-sec" *ngIf="isprolife">
                        <h3>
                          Filter <button class="filterCloseBtn" (click)="closeDropdown('filter7')"></button>
                        </h3>
                        <form [formGroup]="prolifefilter" name="doorStepFilterForm" class="form">
                          <div class="custom-filter-body" style="justify-content: space-evenly;">

                            <div class="custom-filter-col">

                              <mat-form-field *ngIf="role=='SuperAdmin'">
                                <mat-label label for="Zone" class="form-label"> Select Region <span
                                    class="requiredfield">*</span></mat-label>
                                <mat-select formControlName="region">
                                  <!-- <mat-option value="one">Please select zone</mat-option> -->
                                  <mat-option [value]="zone" *ngFor="let zone of regions">{{
                                    zone }}</mat-option>
                                </mat-select>
                              </mat-form-field>



                              <mat-form-field>
                                <mat-label label for="lob" class="form-label"> Select Lob <span
                                    class="requiredfield">*</span></mat-label>
                                <mat-select formControlName="lobs" multiple>
                                  <!-- <mat-option value="one">Please select lob</mat-option> -->
                                  <mat-option [value]="lob" *ngFor="let lob of lobData">{{
                                    lob }}</mat-option>
                                </mat-select>
                              </mat-form-field>

                            </div>


                            <div class="custom-filter-col">

                              <mat-form-field>
                                <mat-label label for="region" class="form-label"> Select State <span
                                    class="requiredfield">*</span></mat-label>
                                <mat-select formControlName="state" multiple>
                                  <!-- <mat-option value="one">Please select State</mat-option> -->
                                  <mat-option [value]="state" *ngFor="let state of states">{{
                                    state}}</mat-option>
                                </mat-select>
                              </mat-form-field>

                              <mat-form-field>
                                <mat-label>Lass Number</mat-label>
                                <input matInput placeholder="Enter Lass Number" value="" type="number" maxlength="10"
                                  formControlName="lass_number">
                              </mat-form-field>

                            </div>

                          </div>

                          <div class="btn-group filterBtn-group">
                            <button mat-stroked-button class="primary-stroke-button"
                              (click)="reset('prolifefilter')">Reset</button>
                            <button mat-flat-button class="primary-flat-button" (click)="applyfilter()">Apply</button>
                          </div>

                        </form>
                      </div>
                    </div>

                    <div class="action-toaster" *ngIf="showToastermessage">
                      <div class="toaster-msg alert alert-danger" *ngIf="showfinalErrorMsg">
                        {{ showfinalErrorMsg }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- <span class="title titlte-order-level">Job Count </span> -->
            <div class="row padding-from-top pt-0">
              <div style="display: block;">
                <figure class="highcharts-figure">
                  <div id="proliferefralgraph"></div>
                </figure>
              </div>
              <ngx-ui-loader loaderId="loader-05" loaderType="double-bounce"></ngx-ui-loader>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<div class="action-toaster" *ngIf="showToastermessage">
  <div class="toaster-msg alert alert-success" *ngIf="showfinalSuccessMsg">
    {{ showfinalSuccessMsg }}
  </div>
  <div class="toaster-msg alert alert-danger" *ngIf="showfinalErrorMsg">
    {{ showfinalErrorMsg }}
  </div>
</div>