import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { HttpMethod } from "src/app/constants/http-handlers";
import { AppService } from "src/app/services/app.service";
import { CommonService } from "src/app/services/common.service";
import { ChartDataSets, ChartOptions, ChartType } from "chart.js";
import { Color, BaseChartDirective, Label } from "ng2-charts";

@Component({
  selector: "app-target-acheived",
  templateUrl: "./target-acheived.component.html",
  styleUrls: ["./target-acheived.component.scss"],
})
export class TargetAcheivedComponent implements OnInit {
  public lineChartData: ChartDataSets[] = [];
  public lineChartLabels: Label[] = [];
  public lineChartOptions: ChartOptions = {
    responsive: true,
    layout: {
      padding: {
        top: 30,
        // left: 0,
        // bottom: 0,
        // right: 0,
      },
    },
    maintainAspectRatio: false,
    animation: {
      onComplete: function () {
        var chartInstance = this.chart,
          ctx = chartInstance.ctx;
        //console.log(ctx);

        ctx.textAlign = "center";
        ctx.textBaseline = "bottom";

        this.data.datasets.forEach(function (dataset, i) {
          var meta = chartInstance.controller.getDatasetMeta(i);
          //debugger;
          //console.log(meta);

          meta.data.forEach(function (line, index) {
            var data = dataset.data[index];
            //console.log(data);
            if (data != 0) {
              ctx.beginPath();
              ctx.fillStyle = "#fff";
              ctx.shadowOffsetX = 0;
              ctx.shadowOffsetY = 2;
              ctx.shadowBlur = 12;
              ctx.shadowColor = "rgba(0, 0, 0, 0.12)";
              ctx.fillRect(line._model.x - 20, line._model.y - 30, 40, 19);
              ctx.fillStyle = "#fff";
              ctx.beginPath();
              ctx.lineTo(line._model.x + 7, line._model.y - (20 - 5));
              ctx.lineTo(line._model.x + 0, line._model.y - (20 - 13));
              ctx.lineTo(line._model.x - 7, line._model.y - (20 - 5));
              ctx.lineTo(line._model.x - 3, line._model.y - (20 - 5));
              ctx.fill();
              ctx.shadowOffsetX = 0;
              ctx.shadowOffsetY = 0;
              ctx.shadowBlur = 0;
              ctx.shadowColor = "rgba(0, 0, 0, 0)";
              ctx.fillStyle = "#282828";
              ctx.fillText(data, line._model.x, line._model.y - 12);
            }
          });
        });
      },
    },
    scales: {
      // We use this empty structure as a placeholder for dynamic theming.
      xAxes: [
        {
          id: "x-axis-0",
          display: true,
          gridLines: {
            display: false,
            color: "rgba(0, 0, 0, 0)",
            drawBorder: false,
          },

          ticks: {
            display: true,
            fontColor: "#7B7B7B",
            fontFamily: "Uni Neue",
            fontSize: 14,
            fontStyle: "500",
            beginAtZero: true,
          },
        },
      ],
      yAxes: [
        {
          id: "y-axis-0",
          display: true,
          position: "left",
          gridLines: {
            color: "rgba(212, 227, 240, 1)",
            drawBorder: false,
          },
          ticks: {
            fontColor: "#7B7B7B",
            fontFamily: "Uni Neue",
            fontSize: 14,
            fontStyle: "500",
            beginAtZero: true,
            //max: 600,
            min: 0,
            padding: 20,
            reverse: false,
          },
        },
      ],
    },
    legend: {
      display: false,
    },
    tooltips: { enabled: false },
    hover: { mode: null },
    events: ["click"], //"mousemove", "mouseout", "touchstart", "touchmove", "touchend"
  };
  public lineChartColors: Color[] = [
    {
      backgroundColor: "rgba(46, 226, 53, 0.1)",
      borderColor: "rgba(46, 226, 53, 1)",
      pointBackgroundColor: "#fff",
      pointBorderColor: "rgba(46, 226, 53, 1)",
      pointHoverBackgroundColor: "#fff",
      pointHoverBorderColor: "rgba(46, 226, 53, 0.8)",
    },
  ];
  public lineChartLegend = true; //: ChartLegendOptions[] = [{
  // display:true,
  //position:'bottom',
  //}];
  public lineChartType: ChartType = "line";

  @ViewChild(BaseChartDirective, { static: true }) chart: BaseChartDirective;

  promotionalItems: any[] = [];
  recId: number;

  constructor(
    private commonService: CommonService,
    private appService: AppService,
    private activatedRoute: ActivatedRoute
  ) {
    this.recId = this.activatedRoute.snapshot.params.id;
  }

  ngOnInit(): void {
    let apiUrl = `${this.appService.msrPartPromo}` + this.recId;
    const url = apiUrl;
    const apiObj = {
      url: url,
      methodType: HttpMethod.GET,
    };

    this.commonService.commonApiCall(apiObj, (res) => {
      this.promotionalItems = res;
      //console.log(this.promotionalItems);
    });
    let apiUrl2 = `${this.appService.msrPartFitmentTrend}` + this.recId;
    const url2 = apiUrl2;
    const apiObj2 = {
      url: url2,
      methodType: HttpMethod.GET,
    };

    this.commonService.commonApiCall(apiObj2, (res) => {
      let weekCollection = res.map(function (el) {
        return "Week " + el.week_num;
      });
      let data1 = res.map(function (el) {
        return el.counts;
      });
      this.lineChartData = [{ data: data1 }];
      this.lineChartLabels = weekCollection;
    });
  }
}
