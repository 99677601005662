<div class="target-top-container">
  <h2>
    My Target vs Achieved <small class="changes-subhead">(Current month)</small>
  </h2>
  <div class="target-container comn-box">
    <div class="col-full">
      <div class="col-half" style="width: 100%">
        <h3>Part Items Fitment</h3>
        <div class="target-text-wrap">
          <div class="progress">
            <div
              class="progress-bar"
              role="progressbar"
              aria-valuenow="75"
              aria-valuemin="10"
              aria-valuemax="100"
              attr.data-value="{{ fitmentValue }}"
              [attr.style]="style_perctantage"
            >
              <span
                class="popOver"
                data-toggle="tooltip"
                data-placement="top"
                [title]="perctantage_target"
              ></span>
            </div>
          </div>
        </div>
        <div class="progress-text">
          <span class="target-min-value">0</span>
          <span class="target-max-value">{{ current_month_parts_target }}</span>
        </div>
        <p class="target-text-wrap-last">
          Last month: {{ total_parts_last_month }}/{{ last_month_parts_target }}
        </p>
      </div>
    </div>
  </div>
</div>
