<div class="top-gurus-list-container">
  <h3>Top Transacting Gurus</h3>
  <h5>
    No. of parts to be consumed or points to be<br />
    accumulated to become active.
  </h5>
  <div class="guru-list-dashboard-table">
    <div class="guru-list-point-table">
      <div class="top-guru-head-row top-guru-row">
        <div class="guru-img-cont">Guru Name</div>
        <div class="top-guru-itemval">Part Items</div>
        <!-- <div class="top-guru-point">Points</div> -->
      </div>
      <div class="top-guru-body">
        <div class="top-guru-row" *ngFor="let topGuruitem of topGurusItems">
          <div class="guru-img-cont">
            <div class="guru-img">
              <ng-container *ngIf="topGuruitem.guru_details.profile_image">
                <img [src]="topGuruitem.guru_details.profile_image" />
              </ng-container>
              <ng-container *ngIf="!topGuruitem.guru_details.profile_image">
                <img src="./assets/images/demo-img.png" />
              </ng-container>
            </div>
            <div class="guru-detail">
              <a
                class="guru-name"
                [routerLink]="[
                  '/guru-list',
                  'guru-detail',
                  topGuruitem.guru_details.id
                ]"
                >{{
                  topGuruitem.guru_details.first_name +
                    " " +
                    " " +
                    topGuruitem.guru_details.last_name
                }}</a
              >
              <span class="guru-id small-font">{{
                topGuruitem.guru_details.contact_number
              }}</span>
            </div>
          </div>
          <div class="top-guru-itemval">
            {{ topGuruitem.total_parts_fitted }}
          </div>
          <!-- <div class="top-guru-point">20</div> -->
        </div>
      </div>
    </div>
  </div>
</div>
