import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  OnChanges,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ConfirmDialogComponent } from "../confirm-dialog/confirm-dialog.component";
import { ActivatedRoute } from "@angular/router";
import { AppService } from "src/app/services/app.service";
import { CommonService } from "src/app/services/common.service";
import { HttpMethod } from "../../../constants/http-handlers";
@Component({
  selector: "app-guru-info-detail",
  templateUrl: "./guru-info-detail.component.html",
  styleUrls: ["./guru-info-detail.component.scss"],
})
export class GuruInfoDetailComponent implements OnInit, OnChanges {
  @Input() selectedView: any;
  @Output() sendGuruName: EventEmitter<string> = new EventEmitter<string>();

  recId: number;
  recDetails: any = {
    id: 0,
    guru_user_id: "",
    guru_username: "",
    profile_image: null,
    id_number: null,
    guru_contact_number: "",
    garage_name: "",
    garage_address: null,
    garage_id: null,
    membership: "",
    ratings: 0,
    parts_fitted: 0,
    points_earned: 0,
    points_balance: 0,
    points_redeemed: 0,
    job_cards: 0,
    brand_type: "",
    status: "",
    personal_details: {
      registration_date: "",
      first_name: "",
      middle_name: "",
      last_name: "",
      dob: "",
      house_address: "",
      pincode: "",
      city: "",
      state: "",
      district: "",
      region: "",
      country: "",
      contact_1: "",
      contact_2: "",
      email_id: "",
      marital_status: "",
      wedding_date: "",
      education: "",
      identity_proof: "",
      id_number: "",
    },
    work_details: {
      garage_name: "",
      garage_owner_name: "",
      business_incorporation_date: "",
      gst_number: "",
      garage_address: "",
      pincode: "",
      city: "",
      state: "",
      district: "",
      region: "",
      country: "",
      garage_lat: "",
      garage_long: "",
      vehicle_make: [],
      vehicle_model: [],
      major_area_work: [],
      other_area_work: "",
    },
    upload_documents: { profile_image: "", documents: [] },
  };
  rating: string[] = ["zero", "one", "two", "three", "four", "five"];
  guruDetail: string = "PersonalDetails";
  guruId: string;
  guruProfileImg: string;
  guruRegForm: string;
  guruOtherDocs: string;
  guruName: string;
  role: string;

  constructor(
    public dialog: MatDialog,
    private actRoute: ActivatedRoute,
    private commonService: CommonService,
    private appService: AppService
  ) {
    this.recId = this.actRoute.snapshot.params.id;
    if (localStorage.getItem("userDetail")) {
      this.role = JSON.parse(localStorage.getItem("userDetail")).userdetails.role;
    }
  }

  ngOnInit(): void {
    //this.getGuruDetails();
  }
  ngOnChanges(): void {
    this.getGuruDetails();
  }
  getGuruItemDetail(val) {
    this.guruDetail = val;
  }
  getGuruDetails() {
    let apiUrl =
      this.appService.msrGuruDetail +
      this.recId +
      "/?view_type=" +
      this.selectedView;
    const url = `${apiUrl}`;
    const apiObj = {
      url: url,
      methodType: HttpMethod.GET,
    };
    this.commonService.commonApiCall(apiObj, (res) => {
      if (res != null) {
        // debugger;
        this.recDetails = res;
        this.recDetails.rating_class =
          "rating " + this.rating[this.recDetails.ratings] + "-star";
        if (this.recDetails.upload_documents.documents.length) {
          this.guruId = this.recDetails.upload_documents.documents[0].url;
          this.guruProfileImg = this.recDetails.upload_documents.profile_image;
          this.guruRegForm = this.recDetails.upload_documents.documents[1].url;
          this.guruOtherDocs =
            this.recDetails.upload_documents.documents[2].url;
        }
        this.guruName =
          this.recDetails.personal_details.first_name +
          " " +
          this.recDetails.personal_details.last_name;
        this.sendGuruName.emit(this.recDetails.guru_username);
      }
    });
  }
  openDialog(action) {
    let dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: "607px",
      height: "398px",
      disableClose: true,
    });
    dialogRef.componentInstance.recId = this.recId;
    dialogRef.componentInstance.action = action;
    dialogRef.componentInstance.guruFlagSource = "detail";
  }
}
