<h2>Notifications</h2>

<div class="notifications-wrap-items" *ngIf="showNotifications && showNotifications.length > 0">
  <ng-container *ngIf="!showAllItems">
    <div class="notification-item" *ngFor="let item of showThreeNotifications"
      (click)="redirectToTraining(item.notification_source)">
      <div class="notification-icon {{ item.category }}"></div>
      <div class="notification-content">
        <h3>
          <b>{{ item.category }} </b> - <span>{{ item.title }}</span>
        </h3>
        <p>{{ item.description }}</p>
        <div class="notification-time">{{ item.start_date | date }}</div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="showAllItems">
    <div class="notification-item" *ngFor="let item of showNotifications"
      (click)="redirectToTraining(item.notification_source)">
      <div class="notification-icon {{ item.category }}"></div>
      <div class="notification-content">
        <h3>
          <b>{{ item.category }} </b> - <span>{{ item.title }}</span>
        </h3>
        <p>{{ item.description }}</p>
        <div class="notification-time">{{ item.start_date | date }}</div>
      </div>
    </div>
  </ng-container>
  <!-- <div class="view-all" (click)="showAllNotification()">
    <ng-container *ngIf="!showAllItems">Show More</ng-container>
    <ng-container *ngIf="showAllItems">Show Less</ng-container>
  </div> -->
</div>
<div class="no-notification-found" *ngIf="showNotifications?.length <= 0">
  No new Notification found
</div>