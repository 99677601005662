// import { Component, OnInit } from "@angular/core";
// import { FormGroup, FormBuilder, Validators } from "@angular/forms";
// import { Router } from "@angular/router";
// import { HttpMethod } from "../constants/http-handlers";
// import { AppService } from "../services/app.service";
// import { CommonService } from "../services/common.service";
// import { v4 as uuidv4 } from "uuid";

// @Component({
//   selector: "app-login",
//   templateUrl: "./login.component.html",
//   styleUrls: ["./login.component.scss"],
// })
// export class LoginComponent implements OnInit {
//   loginForm: FormGroup;
//   enterOTPForm: FormGroup;
//   resendOtpmsg: string;
//   isSubmitted: boolean;
//   isOTPSubmitted: boolean;
//   showloginForm: boolean = true;
//   showMessage: string;
//   showClasses: boolean = false;
//   showOTPErrorMessage: string;
//   showLockedMessage: string;
//   showmultipleErrorMessage: boolean = false;
//   showSuccessEmailMessage: boolean = false;
//   getMobileNum: number;
//   deviceInfo = null;
//   loginHolder: object;

//   constructor(
//     private _formBuilder: FormBuilder,
//     private router: Router,
//     private commonService: CommonService,
//     private appService: AppService
//   ) {}

//   ngOnInit(): void {
//     localStorage.clear();
//     this.loginForm = this._formBuilder.group({
//       mobileNum: [
//         "",
//         [
//           Validators.required,
//           Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$"),
//           Validators.min(6000000000),
//           Validators.maxLength(10),
//         ],
//       ],
//     });
//     this.enterOTPForm = this._formBuilder.group({
//       otp: [
//         "",
//         [Validators.required, Validators.pattern("^((\\-?)|0)?[0-9]{6}$")],
//       ],
//     });
//     this.loginForm.valueChanges.subscribe((res) => {
//       this.showMessage = "";
//     });
//   }

//   loginFormData() {
//     if (this.loginForm.invalid) {
//       this.showMessage = "";
//       this.isSubmitted = true;
//       return;
//     } else {
//       this.getMobileNum = this.loginForm.value.mobileNum;
//       let userObj = {
//         phone_number: this.loginForm.value.mobileNum,
//         app_name: "com.tatamotors.bandhuadmportal",
//       };
//       const url = this.appService.login;
//       const apiObj = {
//         url: url,
//         methodType: HttpMethod.POST,
//         requestObj: userObj,
//       };
//       this.commonService.commonApiCall(apiObj, (res, error) => {
//         this.enterOTPForm.reset();
//         if (error !== undefined) {
//           if (error.status === 403) {
//             this.showClasses = true;
//             this.showLockedMessage = error.error.msg;
//           } else if (error.status === 412) {
//             this.showClasses = true;
//             this.showLockedMessage = error.error.msg;
//             setTimeout(() => {
//               this.showClasses = false;
//               //this.showloginForm = false;
//               this.showLockedMessage = "";
//             }, 2000);
//           }
          
//         } else {
//           this.showOTPErrorMessage = "";
//           this.showClasses = false;
//           this.showLockedMessage = res.msg;
//           setTimeout(() => {
//             this.showloginForm = false;
//             this.showLockedMessage = "";
//           }, 2000);
//         }
//       });
//     }
//   }
//   resendOtp() {
//     let userObj = {
//       phone_number: this.loginForm.value.mobileNum,
//       app_name: "com.tatamotors.bandhuadmportal",
//     };
//     const url = this.appService.login;
//     const apiObj = {
//       url: url,
//       methodType: HttpMethod.POST,
//       requestObj: userObj,
//     };
//     this.commonService.commonApiCall(apiObj, (res, error) => {
//       this.showOTPErrorMessage = "";
//       if (error !== undefined) {
//         this.resendOtpmsg = error.error.msg;
//         setTimeout(() => {
//           this.resendOtpmsg = "";
//         }, 2000);
//       } else {
//         this.resendOtpmsg = res.msg;
//         setTimeout(() => {
//           this.resendOtpmsg = "";
//         }, 2000);
//       }
//     });
//   }

//   enterOTP() {
//     if (this.enterOTPForm.invalid) {
//       this.showOTPErrorMessage = "";
//       this.isOTPSubmitted = true;
//       return;
//     } else {
//       let userObj = {
//         phone_number: this.loginForm.value.mobileNum,
//         app_name: "com.tatamotors.bandhuadmportal",
//         otp_number: this.enterOTPForm.value.otp,
//         device_id: uuidv4(),
//       };
//       const url = this.appService.verifyotp;
//       const apiObj = {
//         url: url,
//         methodType: HttpMethod.POST,
//         requestObj: userObj,
//       };
//       this.commonService.commonApiCall(apiObj, (res, error) => {
//         if (error !== undefined) {
//           if (error.error.msg === "Already logged in user") {
//             this.showmultipleErrorMessage = true;
//             return;
//           } else {
//             this.showOTPErrorMessage = error.error.msg;
//             return;
//           }
//         }
//         localStorage.clear();
//         if (res && res.hasOwnProperty("access_token") && res.access_token) {
//           localStorage.setItem("access_token", res.access_token);
//         }
//         if (res && res.hasOwnProperty("user") && res.user) {
//           this.commonService.assignloginHolder(res);
//           localStorage.setItem("loginUser", JSON.stringify(res.user));
//           localStorage.setItem("userDetail", JSON.stringify(res.userdetails));
//           this.router.navigate(["/", "dashboard"]);
//         }
//       });
//     }
//   }
//   clearPrevSession() {
//     let userObj = {
//       username: this.loginForm.value.mobileNum,
//     };
//     const url = this.appService.clearPrevSession;
//     const apiObj = {
//       url: url,
//       methodType: HttpMethod.POST,
//       requestObj: userObj,
//     };
//     this.commonService.commonApiCall(apiObj, (res, error) => {
//       this.pagerefresh();
//     });
//   }
//   pagerefresh() {
//     this.router.navigate(["/", "login"]).then(() => {
//       window.location.reload();
//     });
//   }
//   activeLoginForm() {
//     this.showloginForm = true;
//   }
// }







import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { HttpMethod } from "../constants/http-handlers";
import { AppService } from "../services/app.service";
import { CommonService } from "../services/common.service";
import { v4 as uuidv4 } from "uuid";
import * as CryptoJS from "crypto-js";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  enterOTPForm: FormGroup;
  resendOtpmsg: string;
  isSubmitted: boolean;
  isOTPSubmitted: boolean;
  showloginForm: boolean = true;
  showMessage: string;
  showClasses: boolean = false;
  showOTPErrorMessage: string;
  showLockedMessage: string;
  showmultipleErrorMessage: boolean = false;
  showSuccessEmailMessage: boolean = false;
  getMobileNum: number;
  deviceInfo = null;
  loginHolder: object;

  constructor(
    private _formBuilder: FormBuilder,
    private router: Router,
    private commonService: CommonService,
    private appService: AppService
  ) {}

  ngOnInit(): void {
    localStorage.clear();
    this.loginForm = this._formBuilder.group({
      mobileNum: [
        "8976456456",
        [
          Validators.required,
          Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$"),
          Validators.min(6000000000),
          Validators.maxLength(10),
        ],
      ],
      username: ["", [Validators.required, Validators.maxLength(50)]],
      password: ["", [Validators.required, Validators.maxLength(50)]],
    });
    this.enterOTPForm = this._formBuilder.group({
      otp: [
        "864433",
        [Validators.required, Validators.pattern("^((\\-?)|0)?[0-9]{6}$")],
      ],
    });
    this.loginForm.valueChanges.subscribe((res) => {
      this.showMessage = "";
    });
  }
  apiErrorMessage: string = '';
  login() {
    console.log('formdata  ', this.loginForm.value);
    if (this.loginForm.invalid) {
      this.showMessage = "";
      this.isSubmitted = true;
      return;
    }
    else {
      const url = this.appService.loginV2;
      var skey = "edp@$3#drishti"
      var salt = "dRishtI";
      var iterations = 128;
      var bytes = CryptoJS.PBKDF2(skey, salt, { keySize: 48, iterations: iterations });
      var iv = CryptoJS.enc.Hex.parse(bytes.toString().slice(0, 32));
      var key = CryptoJS.enc.Hex.parse(bytes.toString().slice(32, 96));
      let userObj = {
        username: this.loginForm.value.username,
        password: CryptoJS.AES.encrypt(this.loginForm.value.password, key, { iv: iv }).toString(),
        version: "v2"
      };
      const apiObj = {
        url: url,
        methodType: HttpMethod.POST,
        requestObj: userObj,
      };
      this.commonService.commonApiCall(apiObj, (res, error) => {
        if (error !== undefined) {
          if (error.error.msg === "Already logged in user") {
            this.showmultipleErrorMessage = true;
            return;
          } else {
             
            this.showOTPErrorMessage = error.error.msg;

            return;
          }
        }
        else{
          this.apiErrorMessage = res.msg;
        }
        localStorage.clear();
        if (res.data && res.data.hasOwnProperty("token") && res.data.token) {
          localStorage.setItem("access_token", res.data.token.access_token);
        }
        if (res && res.data.userdetails.hasOwnProperty("role") && res.data) {
          this.commonService.assignloginHolder(res.data);
          localStorage.setItem("loginUser", JSON.stringify(res.data));
          localStorage.setItem("userDetail", JSON.stringify(res.data));
          this.router.navigate(["/", "dashboard"]);
        }else{
          console.log("roleName_not_found")
        }
      });
    }

  }
  loginFormData() {
    if (this.loginForm.invalid) {
      this.showMessage = "";
      this.isSubmitted = true;
      return;
    } else {
      this.getMobileNum = this.loginForm.value.mobileNum;
      let userObj = {
        phone_number: this.loginForm.value.mobileNum,
        app_name: "com.tatamotors.bandhuadmportal",
      };
      const url = this.appService.login;
      const apiObj = {
        url: url,
        methodType: HttpMethod.POST,
        requestObj: userObj,
      };
      this.commonService.commonApiCall(apiObj, (res, error) => {
        this.enterOTPForm.reset();
        if (error !== undefined) {
          if (error.status === 403) {
            this.showClasses = true;
            this.showLockedMessage = error.error.msg;
          } else if (error.status === 412) {
            this.showClasses = true;
            this.showLockedMessage = error.error.msg;
            setTimeout(() => {
              this.showClasses = false;
              //this.showloginForm = false;
              this.showLockedMessage = "";
            }, 2000);
          }

        } else {
          this.showOTPErrorMessage = "";
          this.showClasses = false;
          this.showLockedMessage = res.msg;
          setTimeout(() => {
            this.showloginForm = false;
            this.showLockedMessage = "";
          }, 2000);
        }
      });
    }
  }
  resendOtp() {
    let userObj = {
      phone_number: this.loginForm.value.mobileNum,
      app_name: "com.tatamotors.bandhuadmportal",
    };
    const url = this.appService.login;
    const apiObj = {
      url: url,
      methodType: HttpMethod.POST,
      requestObj: userObj,
    };
    this.commonService.commonApiCall(apiObj, (res, error) => {
      this.showOTPErrorMessage = "";
      if (error !== undefined) {
        this.resendOtpmsg = error.error.msg;
        setTimeout(() => {
          this.resendOtpmsg = "";
        }, 2000);
      } else {
        this.resendOtpmsg = res.msg;
        setTimeout(() => {
          this.resendOtpmsg = "";
        }, 2000);
      }
    });
  }

  enterOTP() {
    if (this.enterOTPForm.invalid) {
      this.showOTPErrorMessage = "";
      this.isOTPSubmitted = true;
      return;
    } else {
      let userObj = {
        phone_number: this.loginForm.value.mobileNum,
        app_name: "com.tatamotors.bandhuadmportal",
        otp_number: this.enterOTPForm.value.otp,
        device_id: uuidv4(),
      };
      const url = this.appService.verifyotp;
      const apiObj = {
        url: url,
        methodType: HttpMethod.POST,
        requestObj: userObj,
      };
      this.commonService.commonApiCall(apiObj, (res, error) => {
        if (error !== undefined) {
          if (error.error.msg === "Already logged in user") {
            this.showmultipleErrorMessage = true;
            return;
          } else {
            this.showOTPErrorMessage = error.error.msg;
            return;
          }
        }
        localStorage.clear();
        if (res && res.hasOwnProperty("access_token") && res.access_token) {
          localStorage.setItem("access_token", res.access_token);
        }
        if (res && res.hasOwnProperty("user") && res.user) {
          this.commonService.assignloginHolder(res);
          localStorage.setItem("loginUser", JSON.stringify(res.user));
          localStorage.setItem("userDetail", JSON.stringify(res.userdetails));
          this.router.navigate(["/", "dashboard"]);
        }
      });
    }
  }
  clearPrevSession() {
    let userObj = {
      username: this.loginForm.value.mobileNum,
    };
    const url = this.appService.clearPrevSession;
    const apiObj = {
      url: url,
      methodType: HttpMethod.POST,
      requestObj: userObj,
    };
    this.commonService.commonApiCall(apiObj, (res, error) => {
      this.pagerefresh();
    });
  }
  pagerefresh() {
    this.router.navigate(["/", "login"]).then(() => {
      window.location.reload();
    });
  }
  activeLoginForm() {
    this.showloginForm = true;
  }
}
