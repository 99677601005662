<div class="modal-content" style="border: 0.5px solid black;">
  <div class="modal-header modal-title">
    <div style="display: flex; justify-content: space-between; align-items: center; width: 100%;">
      <div><strong>Edit Guru Type</strong></div>
      <div (click)="closeModal()" style="cursor: pointer; font-size: 24px;">&times;</div>
    </div>
  </div>
  <div class="modal-body">
    <mat-radio-group [(ngModel)]="selectedGuruType" (change)="onRadioChange($event.value)" color="primary">
      <mat-radio-button value="GURU-Rural">GURU-Rural</mat-radio-button>
      <mat-radio-button value="GURU-Urban">GURU-Urban</mat-radio-button>
      <mat-radio-button value="Star Guru">Star Guru</mat-radio-button>
    </mat-radio-group>
  </div>
  <div mat-dialog-actions class="buttons" align="end">
    <button mat-button class="cancel-button" (click)="onCancel()">Cancel</button>
    <button mat-button class="save-button" (click)="onSave()">Next</button>
  </div>
</div>
