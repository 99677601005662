<div class="added-part-sec">
  <h2 class="text-capitalize">List of Problem Statement</h2>
  <div class="part-items-table guru-list-table">
    <table
      mat-table
      [dataSource]="dataSource"
      class="full-width-table tb-table"
    >
      <!-- title Column -->
      <ng-container matColumnDef="problemId">
        <th mat-header-cell *matHeaderCellDef class="problemId-col">Id</th>
        <td mat-cell *matCellDef="let element" class="problemId-col">
          {{ element.id }}
        </td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="prblemName">
        <th mat-header-cell *matHeaderCellDef class="prblemName-col">
          Problem Name
        </th>
        <td mat-cell *matCellDef="let element" class="prblemName-col">
          {{ element.problem_name }}
          <!-- {{ element.short_desc }} -->
        </td>
      </ng-container>

      <!-- Weight Column -->
      <ng-container matColumnDef="problemDesc">
        <th mat-header-cell *matHeaderCellDef class="problemDesc-col">
          Problem Description
        </th>
        <td mat-cell *matCellDef="let element" class="problemDesc-col">
          {{ element.problem_desc }}
        </td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="problemImg">
        <th mat-header-cell *matHeaderCellDef class="problemImg">
          Problem Image
        </th>
        <td mat-cell *matCellDef="let element" class="problemImg">
          <a [href]="element.image_url" target="_blank">
            Click here to show Image</a
          >
        </td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="problemDate">
        <th mat-header-cell *matHeaderCellDef class="problemDate">
          Created On
        </th>
        <td mat-cell *matCellDef="let element" class="problemDate">
          {{ element.created_on | date: "dd/MM/yyyy" }}
        </td>
      </ng-container>

      <!-- Symbol Column -->
      <ng-container matColumnDef="action">
        <th
          mat-header-cell
          *matHeaderCellDef
          class="contact-col text-center hide"
        >
          Action
        </th>
        <td
          mat-cell
          *matCellDef="let element"
          class="contact-col text-center hide"
        >
          <button mat-flat-button type="submit">
            <img src="assets/images/delete_black_24dp.svg" alt="" />
          </button>
          {{ element.action }}
        </td>
      </ng-container>

      <tr
        class="tb-head-row"
        mat-header-row
        *matHeaderRowDef="displayedColumns"
      ></tr>
      <tr
        class="tb-item-row"
        mat-row
        *matRowDef="let row; columns: displayedColumns"
      ></tr>
    </table>
  </div>
  <ng-container *ngIf="showNodata">
    <div class="no-item-found">No record found</div>
  </ng-container>

  <mat-paginator
    appPagination
    showFirstLastButtons
    [length]="totalRows"
    [pageIndex]="currentPage"
    [pageSize]="pageSize"
    [hidden]="showNodata"
    (page)="pageChanged($event)"
    class="custom-paginator"
    [hidePageSize]="true"
  >
  </mat-paginator>
</div>
