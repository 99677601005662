<div class="added-part-sec">
  <h2 class="text-capitalize">List of parts added</h2>
  <div class="part-items-table guru-list-table">
    <table
      mat-table
      [dataSource]="dataSource"
      class="full-width-table tb-table"
    >
      <!-- title Column -->
      <ng-container matColumnDef="partType">
        <th mat-header-cell *matHeaderCellDef class="partType-col">Type</th>
        <td
          mat-cell
          *matCellDef="let element"
          class="partType-col  {{ element.part_type }}"
        >
          {{ element.part_type }}
        </td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="partNameNum">
        <th mat-header-cell *matHeaderCellDef class="partNameNum-col">
          Part Name and Number
        </th>
        <td mat-cell *matCellDef="let element" class="partNameNum-col">
          {{ element.part_name }}
          <span class="small-font">{{ element.part_number }}</span>
          <!-- {{ element.short_desc }} -->
        </td>
      </ng-container>

      <!-- Weight Column -->
      <ng-container matColumnDef="partPrice">
        <th mat-header-cell *matHeaderCellDef class="partPrice-col">
          Part Price
        </th>
        <td mat-cell *matCellDef="let element" class="partPrice-col">
          Rs {{ element.price }}
        </td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="StartEndDate">
        <th mat-header-cell *matHeaderCellDef class="StartEndDate">
          Start and End Date
        </th>
        <td mat-cell *matCellDef="let element" class="StartEndDate">
          {{ element.start_date | date: "dd/MM/yyyy" }}
          <br />{{ element.end_date | date: "dd/MM/yyyy" }}
        </td>
      </ng-container>

      <!-- Symbol Column -->
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef class="contact-col text-center">
          Action
        </th>
        <td mat-cell *matCellDef="let element" class="contact-col text-center">
          <button
            mat-flat-button
            type="submit"
            (click)="deleteProdItem(element.id)"
          >
            <img src="assets/images/delete_black_24dp.svg" alt="" />
          </button>
          {{ element.action }}
        </td>
      </ng-container>

      <tr
        class="tb-head-row"
        mat-header-row
        *matHeaderRowDef="displayedColumns"
      ></tr>
      <tr
        class="tb-item-row"
        mat-row
        *matRowDef="let row; columns: displayedColumns"
      ></tr>
    </table>
  </div>
  <ng-container *ngIf="showNodata">
    <div class="no-item-found">No record found</div>
  </ng-container>

  <mat-paginator
    appPagination
    showFirstLastButtons
    [length]="totalRows"
    [pageIndex]="currentPage"
    [pageSize]="pageSize"
    [hidden]="showNodata"
    (page)="pageChanged($event)"
    class="custom-paginator"
    [hidePageSize]="true"
  >
  </mat-paginator>
</div>

<div class="action-toaster" *ngIf="showToastermessage">
  <div class="toaster-msg alert alert-success" *ngIf="showfinalSuccessMsg">
    {{ showfinalSuccessMsg }}
  </div>
  <div class="toaster-msg alert alert-danger" *ngIf="showfinalErrorMsg">
    {{ showfinalErrorMsg }}
  </div>
</div>

<div class="confirm-notification" *ngIf="deleteItemModal">
  <div class="container-dialog">
    <h2>
      Are you sure, <br />
      you want to delete selected Item?
    </h2>
    <div class="btn-group dialogBtn-group">
      <button
        mat-stroked-button
        class="primary-stroke-button"
        (click)="cancelDeleteItemModal()"
      >
        No
      </button>
      <button
        mat-flat-button
        class="primary-flat-button"
        (click)="confirmDeleteItem()"
      >
        Yes
      </button>
    </div>
  </div>
</div>

<ngx-spinner></ngx-spinner>
