<div class="guru-tabs-row">
  <h2>
    <a
      (click)="backToGurulist()"
      href="javascript:void(0)"
      class="backToGurulist"
      >GURUS</a
    >
    <span class="subhead">{{ guruName }}</span>
  </h2>
  <div class="tb-select-dropdown">
    <div class="tb-selectedVal" (click)="selectValfun()">
      {{ selectedItem }}
    </div>
    <ul class="tb-dropdown-options" *ngIf="showDropdown">
      <li
        *ngFor="let item of commonService.viewTypeOptions; let i = index"
        (click)="select(i)"
        [ngClass]="{ selected: isActive(item.paramName) }"
      >
        {{ item.title }}
      </li>
    </ul>
  </div>
</div>
<app-guru-info-detail
  (sendGuruName)="guruName = $event"
  [selectedView]="selected"
></app-guru-info-detail>
<app-guru-info-table
  [selectedView]="selected"
  (childPageChangeEvent)="receivedMessageHandler($event)"
></app-guru-info-table>
