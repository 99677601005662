import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { HttpMethod } from "src/app/constants/http-handlers";
import { AppService } from "src/app/services/app.service";
import { CommonService } from "src/app/services/common.service";
import { OtpGenerateComponent } from "./otp-generate/otp-generate.component";

@Component({
  selector: "app-my-profile",
  templateUrl: "./my-profile.component.html",
  styleUrls: ["./my-profile.component.scss"],
})
export class MyProfileComponent implements OnInit {
  contactNum = "+91 9876543210";
  showEditnumber: boolean = false;
  profileDetail: any = {};

  constructor(
    public dialog: MatDialog,
    private appService: AppService,
    private commonService: CommonService
  ) {}

  ngOnInit(): void {
    this.getRoleDetail();
  }
  showEditnumberSec() {
    this.showEditnumber = !this.showEditnumber;
  }
  openOTPDialog() {
    this.dialog.open(OtpGenerateComponent, {
      width: "409px",
    });
  }
  getRoleDetail() {
    const url = `${this.appService.profile}`;
    const apiObj = {
      url: url,
      methodType: HttpMethod.GET,
    };
    this.commonService.commonApiCall(apiObj, (res) => {
      console.log(res);
      this.profileDetail = res;
    });
  }
}
