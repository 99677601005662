<!-- <div class="jobCard-TGP-wrapper col-full">
  <div class="jobCardData-col col-half">
    <div class="comn-box">
      <h3>Job Card Data</h3> -->
      <!-- {{ jobSummary | json }} -->
      <!-- {{ jobSummary?.jobcards.current_year}} -->
      <!-- <div class="summary-row">
        <div class="summary-left-col">
          <h4>Job Cards</h4>
          <div class="summary-col">
            <div
              [class]="
                jobSummary?.jobcards?.percent_change_over_last_month < 0
                  ? 'summary-box decrease-col'
                  : 'summary-box increase-col'
              "
            >
              <h2>
                {{ jobSummary?.jobcards?.current_year }}
                <small> FY-{{ currentYear }}</small>
              </h2>
              <div class="coupan-val">
                <span
                  [class]="
                    jobSummary?.jobcards?.percent_change_over_last_month < 0
                      ? 'decreaseValue'
                      : 'increaseValue'
                  "
                  >{{
                    jobSummary?.jobcards?.percent_change_over_last_month < 0
                      ? jobSummary?.jobcards?.percent_change_over_last_month *
                        -1
                      : (jobSummary?.jobcards?.percent_change_over_last_month
                        | number: "1.0-2")
                  }}%</span
                >
                <small>(Over last month)</small>
              </div>
            </div>
            <div class="summary-box fourth">
              <h2>
                {{ jobSummary?.jobcards?.previous_year }}
                <small> FY-{{ prevYear }}</small>
              </h2>
            </div>
          </div>
        </div>
        <div class="summary-right-col">
          <h4>Gurus</h4>
          <div class="summary-col">
            <div
              [class]="
                jobSummary?.guru?.percent_change_over_last_month < 0
                  ? 'summary-box decrease-col'
                  : 'summary-box increase-col'
              "
            >
              <h2>
                {{ jobSummary?.guru?.current_year }}
                <small> FY-{{ currentYear }}</small>
              </h2>
              <div class="coupan-val">
                <span
                  [class]="
                    jobSummary?.guru?.percent_change_over_last_month < 0
                      ? 'decreaseValue'
                      : 'increaseValue'
                  "
                  >{{
                    jobSummary?.guru?.percent_change_over_last_month < 0
                      ? jobSummary?.guru?.percent_change_over_last_month * -1
                      : (jobSummary?.guru?.percent_change_over_last_month
                        | number: "1.0-2")
                  }}%</span
                >
                <small>(Over last month)</small>
              </div>
            </div>
            <div class="summary-box second">
              <h2>
                {{ jobSummary?.guru?.previous_year }}
                <small> FY-{{ prevYear }}</small>
              </h2>
            </div>
          </div>
        </div>
      </div>
      <div class="summary-row">
        <div class="summary-left-col">
          <h4>TGP Fitted</h4>
          <div class="summary-col">
            <div
              [class]="
                jobSummary?.tgp_fitted?.percent_change_over_last_month < 0
                  ? 'summary-box decrease-col'
                  : 'summary-box increase-col'
              "
            >
              <h2>
                {{ jobSummary?.tgp_fitted?.current_year }}
                <small> FY-{{ currentYear }}</small>
              </h2>
              <div class="coupan-val">
                <span
                  [class]="
                    jobSummary?.tgp_fitted?.percent_change_over_last_month < 0
                      ? 'decreaseValue'
                      : 'increaseValue'
                  "
                  >{{
                    jobSummary?.tgp_fitted?.percent_change_over_last_month < 0
                      ? jobSummary?.tgp_fitted?.percent_change_over_last_month *
                        -1
                      : (jobSummary?.tgp_fitted?.percent_change_over_last_month
                        | number: "1.0-2")
                  }}%</span
                >
                <small>(Over last month)</small>
              </div>
            </div>
            <div class="summary-box fourth">
              <h2>
                {{ jobSummary?.tgp_fitted?.previous_year }}
                <small> FY-{{ prevYear }}</small>
              </h2>
            </div>
          </div>
        </div>
        <div class="summary-right-col">
          <h4>Competitor Parts Fitted</h4>
          <div class="summary-col">
            <div
              [class]="
                jobSummary?.ntgp_fitted?.percent_change_over_last_month < 0
                  ? 'summary-box decrease-col'
                  : 'summary-box increase-col'
              "
            >
              <h2>
                {{ jobSummary?.ntgp_fitted?.current_year }}
                <small> FY-{{ currentYear }}</small>
              </h2>
              <div class="coupan-val">
                <span
                  [class]="
                    jobSummary?.ntgp_fitted?.percent_change_over_last_month < 0
                      ? 'decreaseValue'
                      : 'increaseValue'
                  "
                  >{{
                    jobSummary?.ntgp_fitted?.percent_change_over_last_month < 0
                      ? jobSummary?.ntgp_fitted
                          ?.percent_change_over_last_month * -1
                      : (jobSummary?.ntgp_fitted?.percent_change_over_last_month
                        | number: "1.0-2")
                  }}%</span
                >
                <small>(Over last month)</small>
              </div>
            </div>
            <div class="summary-box second">
              <h2>
                {{ jobSummary?.ntgp_fitted?.previous_year }}
                <small> FY-{{ prevYear }}</small>
              </h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="fitmentComparison-col col-half hidden">
    <div class="comn-box">
      <h3>Fitment Comparison</h3>
      <div class="summary-row">
        <div class="summary-left-col">
          <h4>Job Cards</h4>
          <div class="summary-col">
            <div class="summary-box decrease-col">
              <h2>3290 <small> 2021</small></h2>
              <div class="coupan-val">
                <span class="decreaseValue">8%</span>
                <small>(Over last month)</small>
              </div>
            </div>
            <div class="summary-box fourth">
              <h2>2457 <small> 2020</small></h2>
            </div>
          </div>
        </div>
        <div class="summary-right-col">
          <h4>Gurus</h4>
          <div class="summary-col">
            <div class="summary-box increase-col">
              <h2>3290 <small> 2021</small></h2>
              <div class="coupan-val">
                <span class="increaseValue">8%</span>
                <small>(Over last month)</small>
              </div>
            </div>
            <div class="summary-box second">
              <h2>2457 <small> 2020</small></h2>
            </div>
          </div>
        </div>
      </div>
      <div class="summary-row">
        <div class="summary-left-col">
          <h4>TGP Fitted</h4>
          <div class="summary-col">
            <div class="summary-box decrease-col">
              <h2>2459 <small> 2021</small></h2>
              <div class="coupan-val">
                <span class="decreaseValue">8%</span>
                <small>(Over last month)</small>
              </div>
            </div>
            <div class="summary-box fourth">
              <h2>4000 <small> 2020</small></h2>
            </div>
          </div>
        </div>
        <div class="summary-right-col">
          <h4>Competitor Parts Fitted</h4>
          <div class="summary-col">
            <div class="summary-box increase-col">
              <h2>2459 <small> 2021</small></h2>
              <div class="coupan-val">
                <span class="increaseValue">8%</span>
                <small>(Over last month)</small>
              </div>
            </div>
            <div class="summary-box second">
              <h2>4000 <small> 2020</small></h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->

<div class="tb-table-wrapper">
  <div class="guru-tabs-row">
    <div class="guru-tabs-items">
      <h2>
        Job Card Details
        <!-- <small class="changes-subhead">(Last 3 Months)</small> -->
      </h2>
    </div>
    <div class="tb-btn-group">
      <ul>
        <li>
          <div class="tb-searchbox">
            <button class="tb-button guru-flex" (click)="searchRecords()">
              <div class="tb__search_icon"></div>
            </button>
            <input
              type="text"
              class="tb-search-text"
              placeholder="Search by Guru Name"
              [(ngModel)]="searchText"
              (keyup)="checkText($event)"
              appStopSC
            />
          </div>
        </li>
        <li>
          <!-- [reportType]="referrelStep"  -->
            <app-job-summary-filter #childComponent (formEventTrigger)="formControlValue($event)"></app-job-summary-filter>
        </li>
        <li>
          <button mat-stroked-button class="primary-stroke-button exportBtn" (click)="downloadReport()">
            Export Excel
          </button>
        </li>
        <li>
          <button mat-stroked-button class="primary-stroke-button exportBtn" (click)="refreshList()">
            Refresh
          </button>
      </li>
      </ul>
    </div>
    <!-- <div class="tb-rigiht-col door_step_filter">
      <div class="form-col select-item">
          <div class="tb-btn-group">
              <ul>
                  <li>
                     <app-referrel-report-filter #childComponent (formEventTrigger)="formControlValue($event)"></app-referrel-report-filter>
                  </li>
              </ul>
          </div>
      </div>
  </div> -->
  </div>
  <ng-container *ngIf="isDataReady">
    <guru-table
      [tableData]="orders"
      [tableColumns]="ordersTableColumns"
      (sort)="sortData($event)"
      [isFilterable]="false"
      [isPageable]="true"
      [currentPageIndex]="pageIndex"
      [defaultPageSize]="10"
      [recordCount]="recordCount"
      (childPageChangeEvent)="receivedPageHandler($event)"
      [componentName]="'job-card-tgp-fitment'"
    >
    </guru-table>
  </ng-container>
</div>
<div class="action-toaster" *ngIf="showToastermessage">
  <div class="toaster-msg alert alert-danger" *ngIf="showfinalErrorMsg">
      {{ showfinalErrorMsg }}
  </div>
</div>
<ngx-spinner></ngx-spinner>
