import { Component, DoCheck, OnChanges, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { HttpMethod } from "src/app/constants/http-handlers";
import { AppService } from "src/app/services/app.service";
import { CommonService } from "src/app/services/common.service";

@Component({
  selector: "app-subheader",
  templateUrl: "./subheader.component.html",
  styleUrls: ["./subheader.component.scss"],
})
export class SubheaderComponent implements OnInit {
  role: string;
  activeClass: any;
  showToastermessage:boolean=false;
  showfinalErrorMsg:string="";

  constructor(
    private appService:AppService,
    private spinner: NgxSpinnerService,
    private commonService: CommonService
  ) {
    if (localStorage.getItem("userDetail")) {
      this.role = JSON.parse(localStorage.getItem("userDetail")).userdetails.role;
    }
  }
  ngOnInit(): void {
    this.activeClass = "/subdashboard";
  }
  navigate() {
    this.commonService.changeGurustep("all");
  }
  downloadPdFMannual(){
    try{
      let apiUrl = this.appService.downloadManual;
      const url = `${apiUrl}`;
      let date = new Date();
      var formData:FormData = new FormData();
      formData.append('action_type','download');
      formData.append('type','manual');
      const apiObj = {
        requestObj:formData,
        url: url,
        methodType: HttpMethod.POST,
      };
      this.spinner.show();
      this.commonService.commonApiCall(apiObj, (res) => {
        if ( res ) {
          this.spinner.hide();
          var redirect = <HTMLAnchorElement>document.createElement("a"); 
          document.body.appendChild(redirect); //required in FF, optional for Chrome
          redirect.href = res;
          redirect.target = '_blank'; 
          redirect.click();
        }else {
          this.spinner.hide();
          this.showfinalErrorMsg = res.error.msg;
          this.showToastermessage = true;
        }
      },'multipart/form-data');
    }catch(error){
      this.spinner.hide();
      this.showfinalErrorMsg = error.error.msg;
      this.showToastermessage = true;
    }
  }
}
