<div class="guru-tabs-row">
    <div class="guru-tabs-items">
      <ul>
        <li [ngClass]="{ 'active-tab': step === 'part-summary' }" >
          <a (click)="changeTab('part-summary')">Part Summary</a>
        </li>
        <li [ngClass]="{ 'active-tab': step === 'job-summary' }" >
          <a (click)="changeTab('job-summary')">Job Summary</a>
        </li>
        <li [ngClass]="{ 'active-tab': step === 'door-step-booking' }" >
          <a (click)="changeTab('door-step-booking')">Door Step Booking</a>
        </li>
        <li [ngClass]="{ 'active-tab': step === 'vehicle-breakdown' }" >
          <a (click)="changeTab('vehicle-breakdown')">Vehicle Breakdown</a>
        </li>
        <li [ngClass]="{ 'active-tab': step === 'referrel-report' }" >
          <a (click)="changeTab('referrel-report')">Referral Report</a>
        </li>
        <li [ngClass]="{ 'active-tab': step === 'problemSubProblem-report' }" >
          <a (click)="changeTab('problemSubProblem-report')">Problem Sub Problem Report</a>
        </li>
      </ul>
    </div>
</div>
<diV class="superadmin-tabs">
  <app-job-summary *ngIf="step === 'job-summary'"></app-job-summary>
  <app-part-summary *ngIf="step === 'part-summary'"></app-part-summary>
  <app-door-step-booking-report *ngIf="step === 'door-step-booking'"></app-door-step-booking-report>
  <app-vehicle-breakdown *ngIf="step === 'vehicle-breakdown'"></app-vehicle-breakdown>
  <app-referrel-reports *ngIf="step =='referrel-report'"></app-referrel-reports>
  <app-problem-sub-problem *ngIf="step == 'problemSubProblem-report'"></app-problem-sub-problem>
</diV>