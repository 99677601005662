import { Component, OnInit, ViewChild, TemplateRef, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-opty-detail',
  templateUrl: './opty-detail.component.html',
  styleUrls: ['./opty-detail.component.scss']
})
export class OptyDetailComponent implements OnInit {
  @Input() optyData: any;
  constructor(private modalService: NgbModal) { }

  ngOnInit(): void {
  }

  closeModal(): void {
    this.modalService.dismissAll()
  }

}
