<div class="mb-3">
  <div class="guru-tabs-row">
    <h2>targets</h2>

    <div class="tb-rigiht-col">
      <div class="form-col select-item">
        <form #dropform="ngForm">
          <select class="form-control" (change)="downloadfile(dropform.value)" name="dropdvalue" ngModel>
            <option value="">Please Select Target Month and Year</option>
            <option [value]="currentMonthYearVal">
              {{ currentMonthYear }}
            </option>
            <option [value]="nextMonthYearVal">{{ nextMonthYear }}</option>
          </select>
        </form>
      </div>
      <a mat-stroked-button class="primary-flat-button msrGuruTargetTemplateBtn" href="{{ msrGuruTargetTemplateURL }}"
        [disabled]="!enableGuruTargetTemplateBtn">
        <ng-container *ngIf="role === 'MSR'">Download Guru Target List
        </ng-container>
        <ng-container *ngIf="role === 'Distributor'">
          Download MSR Target List
        </ng-container>
      </a>
    </div>
  </div>
  <div class="comn-box target-instruction">
    <h3>Instruction for Upload Documents</h3>
    <ul>
      <li><b>.xlx & .xlsx</b> File required</li>
      <li>File Size should be upto <b>2MB</b></li>
      <li>Please Select Target Month and Year to view records</li>
    </ul>
  </div>
</div>

<div class="targets-container">
  <div class="uploadReport-container">
    <div class="dropzone upload-file-sec" appDrag (onFileDropped)="uploadFile($event)">
      <div class="upload-file-item">
        <img src="./assets/images/upload-icon.svg" alt="" />
        <div class="centered">Drag and Drop to upload documents!</div>
      </div>
      <!-- <div class="upload-img-item" *ngFor="let file of files">
        <img *ngIf="file" [src]="file.url" />
      </div> -->
    </div>

    <!--droped image preview-->

    <div class="or-wrap">Or</div>
    <div class="file-input">
      <input type="file" name="file-input" [disabled]="!enableGuruTargetTemplateBtn" id="file-input"
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        class="file-input__input msrGuruTargetTemplateBtn" (change)="uploadFile1($event)" />
      <label class="file-input__label" for="file-input"><span>Choose File to Upload</span></label>
    </div>
  </div>
  <div class="target-table">
    <div class="guru-list-table" *ngIf="!showNodata">
      <table mat-table matTableExporter class="full-width-table tb-table target-list-table" matSort
        aria-label="Elements" [dataSource]="dataSource" *ngIf="role === 'MSR'">
        <ng-container matColumnDef="guru_id">
          <th class="guru-col" mat-header-cell *matHeaderCellDef>Guru ID</th>
          <td class="guru-col" mat-cell *matCellDef="let element">
            {{ element.guru }}
          </td>
        </ng-container>

        <ng-container matColumnDef="guru_name">
          <th mat-header-cell *matHeaderCellDef class="guru-name-col">
            <ng-container *ngIf="role === 'MSR'">Guru Name </ng-container>
          </th>
          <td mat-cell *matCellDef="let element" class="guru-name-col">
            {{ element.guru_name }}
          </td>
        </ng-container>

        <ng-container matColumnDef="job_card">
          <th mat-header-cell *matHeaderCellDef>
            Job <br />
            Cards
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.job_card_creation }}
          </td>
        </ng-container>

        <ng-container matColumnDef="part_Consumed">
          <th mat-header-cell *matHeaderCellDef class="part_Consumed-col">
            Parts <br />
            Consumed
          </th>
          <td mat-cell *matCellDef="let element" class="part_Consumed-col">
            {{ element.parts_consumed }}
          </td>
        </ng-container>
        <ng-container matColumnDef="success">
          <th mat-header-cell *matHeaderCellDef>Success</th>
          <td mat-cell *matCellDef="let element">
            {{ element.success }}
          </td>
        </ng-container>
        <ng-container matColumnDef="failure_reason">
          <th mat-header-cell *matHeaderCellDef>
            Failure <br />
            Reason
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.failure_reason }}
          </td>
        </ng-container>

        <tr class="tb-head-row" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr class="tb-item-row" mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>

      <table mat-table matTableExporter class="full-width-table tb-table target-list-table" matSort
        aria-label="Elements" [dataSource]="dataSource" *ngIf="role === 'Distributor'">
        <ng-container matColumnDef="msr_id">
          <th class="guru-col" mat-header-cell *matHeaderCellDef>MSR ID</th>
          <td class="guru-col" mat-cell *matCellDef="let element">
            {{ element.msr_user_id }}
          </td>
        </ng-container>

        <ng-container matColumnDef="msr_name">
          <th mat-header-cell *matHeaderCellDef class="guru-name-col">
            MSR Name
          </th>
          <td mat-cell *matCellDef="let element" class="guru-name-col">
            {{ element.msr_user_name }}
          </td>
        </ng-container>

        <ng-container matColumnDef="guru_registered">
          <th mat-header-cell *matHeaderCellDef>
            Guru To Be<br> Registered
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.guru_registered }}
          </td>
        </ng-container>

        <ng-container matColumnDef="job_card">
          <th mat-header-cell *matHeaderCellDef>
            Job <br />
            Cards
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.job_cards_creation }}
          </td>
        </ng-container>

        <ng-container matColumnDef="part_Consumed">
          <th mat-header-cell *matHeaderCellDef class="part_Consumed-col">
            Parts To Be<br/> Consumed
          </th>
          <td mat-cell *matCellDef="let element" class="part_Consumed-col">
            {{ element.parts_consumed }}
          </td>
        </ng-container>
        <ng-container matColumnDef="success">
          <th mat-header-cell *matHeaderCellDef>Success</th>
          <td mat-cell *matCellDef="let element">
            {{ element.success }}
          </td>
        </ng-container>
        <ng-container matColumnDef="failure_reason">
          <th mat-header-cell *matHeaderCellDef>
            Failure <br />
            Reason
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.failure_reason }}
          </td>
        </ng-container>

        <tr class="tb-head-row" mat-header-row *matHeaderRowDef="displayedColumns2"></tr>
        <tr class="tb-item-row" mat-row *matRowDef="let row; columns: displayedColumns2"></tr>
      </table>
    </div>
    <ng-container *ngIf="showNodata">
      <div class="no-item-found">No record found</div>
    </ng-container>

    <mat-paginator appPagination showFirstLastButtons [length]="totalRows" [pageIndex]="currentPage"
      [pageSize]="pageSize" (page)="pageChanged($event)" [hidden]="showNodata" class="custom-paginator"
      [hidePageSize]="true">
    </mat-paginator>
  </div>
</div>

<div class="action-toaster" *ngIf="showToastermessage">
  <div class="toaster-msg alert alert-success" *ngIf="showfinalSuccessMsg">
    {{ showfinalSuccessMsg }}
  </div>
  <div class="toaster-msg alert alert-danger" *ngIf="showfinalErrorMsg">
    {{ showfinalErrorMsg }}
  </div>
</div>