<h2 class="text-capitalize">Add Problem Statement</h2>
<form [formGroup]="addProblemStatmentForm" autocomplete="off">
  <div class="ps-container form-wrap comn-box add-item-content">
    <div class="form-row">
      <div class="form-col">
        <label for="problemTitle" class="form-label"
          >Problem Statement Title <span class="requiredfield">*</span></label
        >
        <input
          type="text"
          class="form-control"
          id="problemTitle"
          formControlName="problemTitle"
          maxlength="100"
        />
        <div
          class="invalid-field"
          *ngIf="
            submittedTrainingForm &&
            this.addProblemStatmentForm.controls.problemTitle.errors
          "
        >
          <span
            *ngIf="
              this.addProblemStatmentForm.controls.problemTitle.errors.required
            "
            >Problem Statement Title is required</span
          >
          <span
            *ngIf="
              this.addProblemStatmentForm.controls.problemTitle.errors.pattern
            "
            >Please enter valid Problem Statement Title
          </span>
          <span
            *ngIf="
              !this.addProblemStatmentForm.controls.problemTitle.errors
                .pattern &&
              !this.addProblemStatmentForm.controls.problemTitle.errors
                .whitespace &&
              this.addProblemStatmentForm.controls.problemTitle.errors.maxlength
            "
          >
            Max 50 characters allowed
          </span>
          <span
            *ngIf="
              !this.addProblemStatmentForm.controls.problemTitle.errors
                .required &&
              this.addProblemStatmentForm.controls.problemTitle.errors
                .whitespace
            "
            >Please enter valid data</span
          >
        </div>
      </div>

      <div class="form-col">
        <label for="problemTitle" class="form-label"
          >Problem Statement Thumbnail
          <span class="formfield-hint">
            (Upload jpg or png. File size can’t be over 5MB.) </span
          ><span class="requiredfield">*</span></label
        >
        <div class="file-input video-upload form-control">
          <input
            [hidden]="true"
            type="file"
            accept="image/png, image/jpeg, image/jpg"
            #fileSelect
            (change)="uploadthumbnailFile($event)"
            formControlName="uploadthumbnailFile"
          />
          <span class="fileName">
            <ng-container *ngIf="problemPictureFile.length">{{
              problemPictureFile
            }}</ng-container>
            <ng-container *ngIf="!problemPictureFile.length"
              >Please select a file</ng-container
            >
          </span>
          <button class="choose-file" mat-button (click)="fileSelect.click()">
            Choose File
          </button>
        </div>
        <div
          class="invalid-field"
          *ngIf="
            submittedTrainingForm &&
            this.addProblemStatmentForm.controls.uploadthumbnailFile.errors
          "
        >
          <span> Problem Statement Thumbnails is required</span>
        </div>
      </div>
    </div>
    <div class="form-row">
      <div class="form-full">
        <label for="longDesc" class="form-label"
          >Problem Statement Description<span class="requiredfield"
            >*</span
          ></label
        >
        <textarea
          type="text"
          class="form-control"
          id="longDesc"
          formControlName="longDesc"
        ></textarea>
        <div
          class="invalid-field"
          *ngIf="
            submittedTrainingForm &&
            this.addProblemStatmentForm.controls.longDesc.errors
          "
        >
          <span
            *ngIf="
              this.addProblemStatmentForm.controls.longDesc.errors.required
            "
            >Problem Statement Description is required</span
          >
          <span
            *ngIf="this.addProblemStatmentForm.controls.longDesc.errors.pattern"
            >Please enter valid Problem Statement Descriptione</span
          >
          <span
            *ngIf="
              !this.addProblemStatmentForm.controls.longDesc.errors.required &&
              this.addProblemStatmentForm.controls.longDesc.errors.whitespace
            "
            >Please enter valid data</span
          >
        </div>
      </div>
    </div>

    <div class="ps-btn text-right col-12">
      <button
        mat-flat-button
        class="primary-flat-button"
        type="submit"
        (click)="addProblemStatmentFormData()"
      >
        Submit
      </button>
    </div>
  </div>
</form>
<div class="action-toaster" *ngIf="showToastermessage">
  <div class="toaster-msg alert alert-success" *ngIf="showfinalSuccessMsg">
    {{ showfinalSuccessMsg }}
  </div>
  <div class="toaster-msg alert alert-danger" *ngIf="showfinalErrorMsg">
    {{ showfinalErrorMsg }}
  </div>
</div>

<app-added-statment-list
  [refreshTableItems]="problemItemUpdate"
></app-added-statment-list>

<ngx-spinner></ngx-spinner>
